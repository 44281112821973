export enum ColorUtils {
  primaryColor = "#105476",
  darkPrimaryColor = "#051923",

  hintColor = "#667085",
  darkHintColor = "#7098AD",

  hintSecondaryColor = "#1F2326",
  darkHintSecondaryColor = "#E7EEF1",

  cardColor = "#FFFFFF",
  darkCardColor = "#06222F",

  headerColor = "#000000",
  darkHeaderColor = "#FFFFFF",

  lightIdentifierSelect = "#E6F7F9",
  darkIdentifierSelect = "#082A3B",

  tertiaryButtonColor = "#FFFFFF",
  darkTertiaryButtonColor = "#082A3B",

  primaryBackgroundColor = "#F9FAFB",
  darkPrimaryBackgroundColor = "#051923",

  primaryTextColor = "#5E6871",
  darkPrimaryTextColor = "#CFDDE4",

  disableIconColor = "#B3E8EC",
  darkDisableIconColor = "#9FBBC8",

  secondaryTextColor = "#FFFFFF",
  darkSecondaryTextColor = "#7098AD",

  invertTextColor = "#FFFFFF",
  darkInvertTextColor = "#000000",

  primaryButtonColor = "#008E9A",
  darkPrimaryButtonColor = "#00B2C1",

  secondaryButtonColor = "#F5F7F8",
  darkSecondaryButtonColor = "#E7EEF1",

  dividerColor = "#EBEFF2",
  darkDividerColor = "#0A3247",

  lightShadowColor = "#F2F4F7",
  darkShadowColor = "#06222F",

  lightBoxShadowColor = "rgba(16, 84, 118, 0.12)",
  darkBoxShadowColor = "rgba(6, 34, 47, 1)",

  lightSearchBarFillColor = "#EAECF0",
  darkSearchBarFillColor = "#051923",

  lightSwitchColor = "#0C9400",
  darkSwitchColor = "#0FB900",

  lightHoverColor = "#475467",
  darkHoverColor = "#9FBBC8",

  lightIndicatorColor = "#101828",
  darkIndicatorColor = "#FFFFFF",

  borderColor = "#5C5752",
  successColor = "#0FB900",
  warningColor = "#FCB034",
  failureColor = "#D2202F",
  accTypeDark = "#008E9A",
  lightFIDataFilters = "#667085",

  formLabelColor = "#344054",
  authFormborderColor = "#D0D5DD",

  transparent = "transparent",

  splashColor = "rgba(179, 232, 236, 0.2)",

  lightOTPSelectedBorder = "#66D1DA",

  lightBoxShadow = "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E6F7F9",
  darkBoxShadow = "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #407691",

  lightAcknowledgeModalIcon = "#E7F8E6",
  darkAcknowledgeModalIcon = "#0FB9001A",

  successBackgroundColor = "#E7F8E6",
  successFontColor = "#0C9400",
  darkSuccessBackgroundColor = "#0FB9001A",
  darkSuccessFontColor = "#0FB900",

  errorBackgroundColor = "#FBE9EA",
  errorFontColor = "#A81A26",
  darkErrorBackgroundColor = "#D2202F1A",
  darkErrorFontColor = "#D2202F",

  warningBackgroundColor = "#FFF7EB",
  warningFontColor = "#CA8D2A",
  darkWarningBackgroundColor = "#FCB0341A",
  darkWarningFontColor = "#FCB034",

  staleBackgroundColor = "#E7EEF1",
  staleFontColor = "#0D435E",
  darkStaleBackgroundColor = "#1054761A",
  darkStaleFontColor = "#105476",
}
