export interface UserRespose {
  firstName: string;

  lastName: string;

  vuaId: string;

  mobileNumber: string;

  isUserPin: boolean;
}
const initState = {
  user: {
    firstName: "",
    lastName: "",
    vuaId: "",
    mobileNumber: "",
    isUserPin: true,
  },
};

export default function user(
  state: { user: UserRespose } = initState,
  action: any
) {
  switch (action.type) {
    case "STORE_USER":
      return {
        ...state,
        user: action.payload.user,
      };
    default:
      return state;
  }
}
