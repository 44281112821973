import { setThemeStorage, getThemeStorage } from "Services/StorageServices";

const getSystemTheme = () => {
  const themeMatch = window.matchMedia("(prefers-color-scheme: dark)");
  if (themeMatch.matches) {
    return "dark";
  } else {
    return "light";
  }
};

export const getTheme = () => {
  const theme = getThemeStorage();
  if (theme && (theme === "light" || theme === "dark" || theme === "system")) {
    if (theme === "system") return getSystemTheme();
    else return theme;
  } else {
    setThemeStorage("light");
    return "light";
  }
};
