import { CheckOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTheme } from "App";
import { translation } from "i18n/translation";
import "./index.css";
import React from "react";

interface AcknowledgeModalProps {
  isOpen: boolean;
  okText: string;
  title: string;
  subTitle?: string;
  onClose: () => void;
}

const AcknowledgeModal: React.FC<AcknowledgeModalProps> = ({
  isOpen,
  okText,
  title,
  subTitle,
  onClose,
}) => {
  const theme = useTheme();

  const loading: boolean = useSelector(
    (state: any) => state.loader.searchLoader
  );
  const { t } = useTranslation();
  return (
    <Modal
      width={"30%"}
      className={`${theme.modalClass} paddingBottom0`}
      closable={false}
      open={isOpen}
      cancelButtonProps={{ className: "displayNone" }}
      okButtonProps={{
        className: "displayNone",
      }}
      destroyOnClose={true}
    >
      <div>
        <div className={`${theme.acknowledgeModalIconSuccess} iconBackground`}>
          <CheckOutlined
            className={`${theme.switchColor} modalSymbol acknowledgeIcon`}
          />
        </div>
        {title && (
          <Typography.Text className={`${theme.indicatorColor} title1`}>
            {t(title)}
          </Typography.Text>
        )}

        {subTitle && (
          <Typography.Text className={`${theme.authSubTitle} subTitle`}>
            {t(subTitle)}
          </Typography.Text>
        )}
        <Button
          loading={loading}
          className={`${theme.textTheme.authButton} commonButtonBackground primaryButton formItemButton loginButton`}
          htmlType="submit"
          onClick={onClose}
        >
          {okText && okText.length > 0 ? okText : t(translation.lblLogin)}
        </Button>
      </div>
    </Modal>
  );
};

export default AcknowledgeModal;
