import axios from "axios";
import {
  ConsentApproveVerificationRequest,
  MultiConsentApproveVerificationRequest,
} from "../../Models/Request/Consent/ConsentApprove";
import { ConsentUpdateVerificationRequest } from "../../Models/Request/Consent/UpdateConsent";

export function getConsentByConsentId(consentUniqueId: string) {
  return axios.get(`/User/Consent/${consentUniqueId}`);
}

export function getConsentByConsentHandle(consentUniqueId: string) {
  return axios.get(`/User/Consent/handle/${consentUniqueId}`);
}

export function consentApprovalInitiation(
  consentUniqueId: string,
  approvalStatus: string
) {
  return axios.post(
    `/User/Consent/Approval/${consentUniqueId}/${approvalStatus}`
  );
}

export function consentApprove(body: ConsentApproveVerificationRequest) {
  if (!body.accounts?.length) {
    delete body.accounts;
  } else {
    body.accounts = body.accounts.map((account) => {
      return {
        linkRefNumber: account.linkRefNumber,
        fipHandle: account.fipHandle,
      };
    });
  }
  return axios.post(`/User/Consent/Approval/Verification`, body, {
    headers: { medium: "WEB" },
  });
}

export function multiConsentsApprove(
  body: MultiConsentApproveVerificationRequest
) {
  if (!body.accounts?.length) {
    delete body.accounts;
  } else {
    body.accounts = body.accounts.map((account) => {
      return {
        linkRefNumber: account.linkRefNumber,
        fipHandle: account.fipHandle,
      };
    });
  }
  return axios.post(`/User/Consents/Approval/Verification`, body, {
    headers: { medium: "WEB" },
  });
}

export function updateConsentStatus(consentId: string, status: string) {
  return axios.post(`/User/Consent/${consentId}/${status}`);
}

export function updateConsentStatusVerification(
  body: ConsentUpdateVerificationRequest
) {
  return axios.post("/User/Consent/Update/Status/Verification", body);
}

export function getPaginatedConsentByStatus(
  page: number,
  size: number,
  status: string
) {
  return axios.get(
    `/User/Consent?page=${page}&size=${size}${
      status ? "&consentStatusType=" + status : ""
    }`
  );
}

export function getByConsentHandlesMultiConsent(consenthandles: string) {
  return axios.get(`/User/Consent/handle?consentHandle=${consenthandles}`);
}

export function getConsetsByAccount(
  linkRefNumber: string,
  page: number,
  size: number
) {
  return axios.get(`/User/account/${linkRefNumber}?page=${page}&size=${size}`);
}

export function getConsentCounts() {
  return axios.get(`/User/Consent/Count`);
}
