import axios from "axios";
import { LoginWithPinRequest } from "../../Models/Request/Authentication/Login";
import {
  DeleteUserOtp,
  MobileNumberVerification,
  SendOtpRequest,
  VerifyOtpRequest,
} from "../../Models/Request/Authentication/Otp";
import { SignupConfirmationRequest } from "../../Models/Request/Authentication/Signup";

export function sendOtp(sendOtpBody: SendOtpRequest) {
  return axios.post("/public/user/send-otp", sendOtpBody,
    {
      headers: {
        "medium": "web"
      }
    });
}

export function verifyOtp(verifyOtpBody: VerifyOtpRequest) {
  return axios.post("/public/user/verify-otp", verifyOtpBody);
}

export function verifyEncryptedOtp(verifyOtpBody: VerifyOtpRequest) {
  return axios.post("/public/user/verify-encrypted-otp", verifyOtpBody);
}

export function signupMobileVerificationInitiate(sendOtpBody: SendOtpRequest) {
  return axios.post("/public/user/signup/mobile", sendOtpBody,
  {
    headers: {
      "medium": "web"
    }
  });
}

export function signupMobileVerification(
  sendOtpBody: MobileNumberVerification
) {
  return axios.post("/public/user/signup/mobile/verify", sendOtpBody);
}

export function signupMobileVerificationWithEncryptedOTP(
  sendOtpBody: MobileNumberVerification
) {
  return axios.post("/public/user/signup/mobile/verify-encrypted", sendOtpBody);
}

export function signupMobileVerificationRedirection(
  sendOtpBody: MobileNumberVerification
) {
  return axios.post(
    "/public/user/signup/mobile/verify/redirection",
    sendOtpBody
  );
}

export function signupVerification(
  verificationBody: SignupConfirmationRequest
) {
  return axios.post("/public/user/signup/verify", verificationBody);
}

export function logInWithPin(verificationBody: LoginWithPinRequest) {
  return axios.post("/public/user/login", verificationBody);
}

export function getAccessToken(refreshToken: string | null) {
  return axios.post(`/public/user/refreshtoken`, {
    refreshToken: refreshToken,
  });
}

export function forgotPin(newPin: string, token?: string) {
  return axios.post(
    `/User/forgotPassword`,
    { newPin },
    { headers: { Authorization: `Bearer ${token}` } }
  );
}

export function signOut(sessionId: string) {
  return axios.get(`/User/sign-out?sessionId=${sessionId}`);
}

export function deleteUserInitiate() {
  return axios.delete(`/User`);
}

export function deletUser(body: DeleteUserOtp) {
  return axios.delete(`/User/Verify`, { data: body });
}

export function deletUserEncrypted(body: DeleteUserOtp) {
  return axios.delete(`/User/Verify-encrypted`, { data: body });
}

export function isVuaExists(body: any) {
  return axios.post(`/public/User/isVUAIDExists`, body);
}

export function isTwoFactorEnabled() {
  return axios.get("/User/configure/2fa");
}

export function changeTwoFactorEnabled() {
  axios.post("/User/configure/2fa");
}

export function updateUser(body: any) {
  axios.put(`/User`, body);
}
