import { Button } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useTheme } from "../../../../../App";
import "./index.css";

interface IModalFooterProps {
  onOtpModalFooterSubmit: () => void;
  isResendActive?: boolean;
  buttonLabel: string;
  counter: number;
  subButtonLabel: string;
  onCancel: () => void;
}

const OTPInputModalFooter: React.FC<IModalFooterProps> = ({
  onOtpModalFooterSubmit,
  buttonLabel,
  subButtonLabel,
  onCancel,
}) => {
  const loading: boolean = useSelector(
    (state: any) => state.loader.searchLoader
  );
  const theme = useTheme();
  return (
    <>
      <Button
        onClick={onCancel}
        type="text"
        className={`${theme.hintColor} subClass`}
      >
        {subButtonLabel}
      </Button>
      <Button
        className={`${theme.textTheme.authButton} primaryButton`}
        loading={loading}
        id="submitButton"
        onClick={onOtpModalFooterSubmit}
      >
        {buttonLabel}
      </Button>
    </>
  );
};

export default OTPInputModalFooter;
