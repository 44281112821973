import { Button, Card, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../App";
import { translation } from "../../../../i18n/translation";
import { GetConsentByIdResponse } from "../../../../Models/Response/Consent/GetConsentById";
import { ConsentStatusType } from "../../../../Utils/Constants/statusTypes";
import CommonConsentDetails from "../CommonConsentDetails";
import "./index.css";

interface IActiveConsentProps {
  consentDetailsById: GetConsentByIdResponse;
  type: string;
  onConsentStatusUpdate: (type: string) => void;
}

const ConsentByStatus: React.FC<IActiveConsentProps> = ({
  consentDetailsById,
  type,
  onConsentStatusUpdate,
}) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const theme = useTheme();

  const activeConsentStatusUpdate = (consentType: ConsentStatusType) => {
    if (type === ConsentStatusType.ACTIVE) {
      onConsentStatusUpdate(consentType);
    }
  };

  const revokeConsent = () => {
    onConsentStatusUpdate(ConsentStatusType.REVOKED);
  };

  const activateConsent = () => {
    onConsentStatusUpdate(ConsentStatusType.ACTIVE);
  };

  return (
    <>
      <div className="consenTypesRoot">
        <CommonConsentDetails
          type={type}
          consentByUniqueIdDetails={consentDetailsById}
          userAction={activeConsentStatusUpdate}
        />
        {type !== ConsentStatusType.REJECTED &&
        type !== ConsentStatusType.EXPIRED &&
        type !== ConsentStatusType.REVOKED ? (
          <>
            <Text className={`${theme.textTheme.headline1} typoText`}>
              {t(translation.lblLinkedAccounts)}
            </Text>
            <div className="consentDetailsAccount">
              {consentDetailsById && consentDetailsById.Accounts && (
                <>
                  {consentDetailsById.Accounts.map((accounts, index) => (
                    <Card
                      key={index}
                      className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCard flexDirection `}
                      id={index.toString()}
                      bordered={false}
                    >
                      <Text className={`${theme.indicatorColor} maskedNumber`}>
                        {accounts.maskedAccNumber}
                      </Text>
                      <Text className={`${theme.hintColor} accTypeConsent`}>
                        {accounts.fiType === "DEPOSIT"
                          ? t(_.capitalize(accounts.accType))
                          : t(_.capitalize(accounts.fiType))}
                      </Text>
                    </Card>
                  ))}
                  {consentDetailsById.Accounts.length % 2 !== 0 ? (
                    <div className="consentDetailsCard"></div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {type === ConsentStatusType.PAUSED ? (
        <div className="buttonGroupRoot">
          <div className={`${theme.menuDividerColor} activeButtonGroup`}>
            <Button
              onClick={revokeConsent}
              type="text"
              className={`${theme.hintColor} subClass subButton`}
            >
              {t(translation.lblRevoke)}
            </Button>

            <Button
              className={`${theme.textTheme.authButton} ${theme.formItemMarginBottom} borderNoneHeight50 priButton`}
              onClick={activateConsent}
            >
              {t(translation.lblReactivateConsent)}
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ConsentByStatus;
