import { LoadingOutlined } from "@ant-design/icons";
import { createTheming } from "@callstack/react-theme-provider";
import { ConfigProvider, Spin, Typography } from "antd";
import "antd/dist/antd.css";
import "antd/dist/antd.variable.min.css";
import "App.css";
import "i18n/config";
import LoginComponent from "Pages/Authentication/Components/Login";
import Download from "Pages/Download";
import SignupComponent from "Pages/Authentication/Components/SignUp";
import LanguageSelectComponent from "Pages/LanguageSelectComponent";
import PrivacyPolicy from "Pages/PrivacyPolicy";
import Redirection from "Pages/Redirection";
import Root from "Pages/Root";
import TermsAndCondition from "Pages/TermsAndCondition";
import UrlShortener from "Pages/UrlShortener";
import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { versionDispatch } from "redux/actions";
import store from "redux/store";
import {
  getFiuCustomStyles,
  getOtherAppCustomization,
  getSrcRef,
} from "Services/StorageServices";
import { getVersion } from "Services/UserProfileServices";
import { themes } from "Theme";
import { Routes } from "Utils/Constants/routes";
import { version } from "Utils/Constants/version";
import interceptor from "Utils/interceptor";

const { ThemeProvider, withTheme, useTheme } = createTheming(themes.light);
const Context = createContext({});

const App: React.FC = () => {
  const loading: boolean = useSelector(
    (state: any) => state.loader.searchLoader
  );
  const currentTheme = useSelector((state: any) => state.themes.theme);

  useEffect(() => {
    let linkTag = document.getElementById("root");
    if (linkTag)
      linkTag.setAttribute(
        "class",
        currentTheme === "light" ? "light" : "dark"
      );
  }, [currentTheme]);

  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty(
      "--doc-height",
      `${
        window.innerHeight || doc.clientHeight || document.body.clientHeight
      }px`
    );
  };
  window.addEventListener("resize", documentHeight);
  documentHeight();

  const { i18n } = useTranslation();
  const history = useHistory();
  const [menubar, setMenubar] = useState(getSrcRef() ? false : true);
  const [styles, setStyles] = useState<any>({});
  const [otherAppCustomization, setOtherAppCustomization] = useState<any>({});
  const [isVersionLoaded, setVersioLoaded] = useState(false);
  useState(() => {
    interceptor(history);

    getVersion()
      .then((res) => {
        store.dispatch(versionDispatch(`${version}.B${res.data}`));
      })
      .catch(() => {
        store.dispatch(versionDispatch(`${version}.B`));
      })
      .finally(() => {
        setVersioLoaded(true);
      });
  });

  useEffect(() => {
    if (!!getFiuCustomStyles()) {
      setStyles(JSON.parse(getFiuCustomStyles() || ""));
    }

    if (!!getOtherAppCustomization())
      setOtherAppCustomization(JSON.parse(getOtherAppCustomization() || ""));
  }, []);

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24, color: "var(--primaryColor)" }}
      spin={true}
    />
  );

  const renderprivacy = (props: any) => <PrivacyPolicy {...props} />;

  return (
    <>
      {styles && Object.keys(styles).length > 0 ? (
        <style>
          {`
        :root{
          ${Object.keys(styles)
            .map((key) =>
              key === "fontFamily"
                ? `--${key}:"${styles[key]}";`
                : `--${key}:${styles[key]};`
            )
            .toString()
            .replaceAll(",", "")}   
        }
        `}
        </style>
      ) : (
        <></>
      )}
      <ThemeProvider
        theme={currentTheme === "light" ? themes.light : themes.dark}
      >
        <Context.Provider
          value={{
            menubar,
            setMenubar,
            setStyles,
            setOtherAppCustomization,
            otherAppCustomization,
          }}
        >
          <Spin
            indicator={antIcon}
            tip={
              <Typography.Text style={{ color: "var(--primaryColor)" }}>
                Loading...
              </Typography.Text>
            }
            spinning={loading}
          >
            {isVersionLoaded ? (
              <ConfigProvider
                direction={i18n.language === "ur" ? "rtl" : "ltr"}
              >
                <Switch>
                  <Route
                    exact={true}
                    path={"/"}
                    component={LanguageSelectComponent}
                  />
                  <Route
                    exact={true}
                    path={Routes.Terms}
                    component={TermsAndCondition}
                  />
                  <Route
                    exact={true}
                    path={Routes.Privacy}
                    component={renderprivacy}
                  />

                  <Route
                    exact={true}
                    path={Routes.Login}
                    component={LoginComponent}
                  />
                  <Route
                    exact={true}
                    path={Routes.Signup}
                    component={SignupComponent}
                  />
                  <Route
                    exact={true}
                    path={Routes.Download}
                    component={Download}
                  />
                  <Route exact={true} path="/webview" component={Redirection} />
                  <Route exact={true} path="/a/**" component={UrlShortener}/>
                  <Route exact={true} path="*" component={Root} />
                </Switch>
              </ConfigProvider>
            ) : (
              <></>
            )}
          </Spin>
        </Context.Provider>
      </ThemeProvider>
    </>
  );
};
export { Context, ThemeProvider, useTheme, withTheme };

export default App;
