import { Context, useTheme } from "App";
import BreadCrumbs from "Pages/Common/Components/BreadCrumbs";
import { getGrievanceIssuesTypes } from "Services/GrievanceServices/GrievanceService";
import { getSrcRef } from "Services/StorageServices";
import { ConsentStatusType, DeleteType } from "Utils/Constants/statusTypes";
import { Button, Divider, Form, Input, Typography } from "antd";
import { translation } from "i18n/translation";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "./index.css";

interface UpdateConsentProps {
  bankName?: string;
  setVisible: (value: boolean) => void;
  setIsFormVisible: (value: boolean) => void;
  onFormSubmit: (values: any) => void;
  type: string;
}

const FeedbackForm: React.FC<UpdateConsentProps> = ({
  bankName,
  setVisible,
  setIsFormVisible,
  onFormSubmit,
  type,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [reasons, setReasons] = useState<any>([]);

  const { setMenubar }: any = useContext(Context);
  const handleCancel = () => {
    setIsFormVisible(false);
    setVisible(true);
  };
  const handleUpdateConsent = (values: any) => {
    onFormSubmit(values);
  };
  useEffect(() => {
    getGrievanceIssuesTypes()
      .then((res) => res.data)
      .then((res) => {
        setReasons(res);
      });
  }, []);

  useEffect(() => {
    setMenubar(false);
    return () => {
      if (!!!getSrcRef()) {
        setMenubar(true);
      }
    };
  }, [setMenubar]);

  useEffect(() => {
    form.setFieldsValue({
      reasonType: reasons[Object.keys(reasons).sort()[0]],
    });
  }, [reasons, form]);
  const getStatusType = () => {
    switch (type) {
      case ConsentStatusType.REVOKED:
        return "revoke";
      case ConsentStatusType.FAILED:
        return "reject";
      case DeleteType.ACCOUNTDELETE:
        return "delete account";
      default:
        return type;
    }
  };
  const location = useLocation();

  return (
    <>
      <div className={`${theme.mainbackgroundColor} updateConsentRoot`}>
        <div className="deleteAccountRoot">
          <BreadCrumbs
            className="changePasswordbreadCrumbs"
            breadcrumbItems={[
              {
                route:
                  type === DeleteType.ACCOUNTDELETE ? "/account" : "/consents",
                label:
                  type === DeleteType.ACCOUNTDELETE
                    ? t(translation.lblAccounts)
                    : t(translation.lblConsents),
              },
              {
                route: location.pathname,
                label: `${bankName} `,
              },
            ]}
          />
          <Typography.Title
            level={3}
            className={`${theme.textTheme.headline1} titleClass deleteTitle`}
          >
            {getStatusType() === "delete account"
              ? t(translation.lblDeleteAccount)
              : t(`${getStatusType()}_consent`, { fiu_name: bankName })}
          </Typography.Title>
          <Typography.Text className={theme.textTheme.subtitle1}>
            {t(translation.lblYourFeedback)}
          </Typography.Text>
          <Form
            form={form}
            onFinish={handleUpdateConsent}
            autoComplete="off"
            requiredMark={false}
            scrollToFirstError={true}
            layout="vertical"
            className="deleteForm"
            name="updateConsentStatusForm"
          >
            <div className="formContainer">
              <div className="formDetails">
                <div className="formLabel">
                  <Typography.Text className={`${theme.authFormLabels}`}>
                    {t(translation.lblFixingIssue)}
                  </Typography.Text>
                </div>

                <div className=" formLabel">
                  <Typography.Text className={`${theme.authFormLabels}`}>
                    {t(translation.lblMessage)}
                  </Typography.Text>
                </div>
                <Form.Item
                  name="message"
                  className={`${theme.authFormLabels} form`}
                >
                  <Input.TextArea
                    className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} ticketMessage`}
                    rows={5}
                    placeholder={t(translation.lblShareWithUs)}
                  />
                </Form.Item>
              </div>

              <div className="buttonsRoot">
                <Divider className={theme.dividerColor} />
                <div className="formButtons">
                  <Form.Item>
                    <Button
                      onClick={handleCancel}
                      className={`${theme.textTheme.subtitle1} cancelButton`}
                    >
                      {t(translation.lblCancel)}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={` updateStatusButton  ${
                        type === "PAUSED"
                          ? theme.warningStatus + " " + theme.warningStatusBG
                          : theme.errorStatusBG + " " + theme.errorStatus
                      }`}
                    >
                      {getStatusType() === "delete account"
                        ? t(translation.lblDeleteAccount)
                        : t(`${getStatusType()} consent`)}
                      {/* todo change key */}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default FeedbackForm;
