import axios from "axios";

export function getGrievanceIssuesTypes() {
  return axios.get(`/public/Grievance/Issues`);
}

export function getGrievanceOfUser(page: number, size: number) {
  return axios.get(`/User/Grievance/?page=${page}&size=${size}`);
}

export function getGrievanceOfUserByStatus(page: number, size: number,type:string) {
  return axios.get(`/User/Grievance?page=${page}&size=${size}&type=${type}`);
}

export function createNewGrievance(body: any) {
  return axios.post(`User/Grievance`, body);
}
