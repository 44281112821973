import { CalendarOutlined, DownOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  notification,
  Radio,
  Select,
  Typography,
} from "antd";
import _ from "lodash";
import { Identifier } from "Models/FIPModel";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTheme } from "../../App";
import { translation } from "../../i18n/translation";
import {
  addIdentifier,
  addIdentifierVerificationEncrypted,
  getIdentifiers,
} from "../../Services/IdentifierService/Identifier";
import { IdentifierType } from "../../Utils/Constants/identifierType";
import {
  validAadhar,
  validAccNo,
  validCRN,
  validEmail,
  validMobile,
  validOther,
  validPan,
  validPPAN,
} from "../../Utils/Constants/identifierValidator";
import OTPInputComponent from "../Common/Components/OTPModal";
import "./index.css";
import { getDob, getPan, getRedirect, getSessionId, getTxnId } from "Services/StorageServices";
import { EncryptOTP } from "Utils/EncryptionUtils";

interface IIdentifiersProps {
  onIdentifierValueSelect: (e: any) => void;
  inputSearchValue: string;
  isAddIdentifierVisible: boolean;
  identifierTypes: Identifier[];
}

interface IdentifierState {
  [key: string]: {
    type: string;
    value: string;
    categoryType: string;
  };
}

interface IdentifierList {
  type: string;
  value: string;
  categoryType: string;
}

const mapIdentifier = Object.values(IdentifierType);

const LinkAccountIdentifiers: React.FC<IIdentifiersProps> = ({
  onIdentifierValueSelect,
  inputSearchValue,
  isAddIdentifierVisible,
  identifierTypes,
}) => {
  const [identifiers, setIndentifiers] = useState<IdentifierList[]>([]);
  const [identifiersToBeShown, setIdentifiersToBeShown] = useState<
    IdentifierList[]
  >([]);
  const [form] = Form.useForm();

  const [isAddIdentifierVerification, setVerification] = useState(false);
  const [otpUniqueID, setOTPUniqueId] = useState("");
  const [selectedIdentifier, setSelectedIdentifier] = useState<IdentifierState>(
    {
      [IdentifierType.MOBILE]: {
        type: "",
        value: "",
        categoryType: "",
      },
      [IdentifierType.AADHAR]: {
        type: "",
        value: "",
        categoryType: "",
      },
      [IdentifierType.ACCNO]: {
        type: "",
        value: "",
        categoryType: "",
      },
      [IdentifierType.CRN]: {
        type: "",
        value: "",
        categoryType: "",
      },
      [IdentifierType.DOB]: {
        type: "",
        value: "",
        categoryType: "",
      },
      [IdentifierType.EMAIL]: {
        type: "",
        value: "",
        categoryType: "",
      },
      [IdentifierType.OTHER]: {
        type: "",
        value: "",
        categoryType: "",
      },
      [IdentifierType.PPAN]: {
        type: "",
        value: "",
        categoryType: "",
      },
      [IdentifierType.PAN]: {
        type: "",
        value: "",
        categoryType: "",
      },
    }
  );
  const [isOtpError, setIsOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [selectedIdentifierType, setSelectedIdentifierType] = useState(
    IdentifierType.MOBILE
  );
  const [errorIdentifier, setErrorIdentifier] = useState(true);
  const [notPresentTypes, setNotPresentTypes] = useState<any[]>([]);
  const [notSelectedTypes, setNotSelectedTypes] = useState<any[]>([]);
  const [supportedTypes, setSupportedTypes] = useState<any[]>([]);
  const [weakIdentifiers, setWeakIdentifiers] = useState<IdentifierList[]>([]);

  const loading: boolean = useSelector(
    (state: any) => state.loader.searchLoader
  );

  useEffect(() => {
    if (!!getRedirect() && !!getTxnId() && !!getSessionId()) {
      const pan = getPan();
      const dob = getDob();
      if (pan !== null) {
        weakIdentifiers.push(
          {
            type: "PAN",
            value: pan ? pan : "",
            categoryType: "WEAK",
          }
        );
      }
      if (dob !== null) {
        // Here dob should be in form of YYYY-MM-DD as per redirection specification 1.4.0
        // Link - https://sahamati.gitbook.io/aa-redirection-guidelines/v/1.4.0/specification/request-specification
        var parts = dob.split('-');
        var formattedDate = parts[2] + '/' + parts[1] + '/' + parts[0];
        weakIdentifiers.push(
          {
            type: "DOB",
            value: formattedDate ? formattedDate : "",
            categoryType: "WEAK",
          }
        );
      }
      setWeakIdentifiers(weakIdentifiers)
    }
  }, [weakIdentifiers])

  useEffect(() => {
    setIdentifiersToBeShown(
      inputSearchValue
        ? identifiers.filter(
          (p) =>
            p.value.toUpperCase().indexOf(inputSearchValue.toUpperCase()) !==
            -1
        )
        : identifiers
    );
  }, [inputSearchValue, identifiers]);

  useEffect(() => {
    if (!errorIdentifier) {
      onIdentifierValueSelect(
        Object.values(selectedIdentifier).filter(
          (r) => r.value !== "" && r.type !== ""
        )
      );
    }
  }, [errorIdentifier, selectedIdentifier, onIdentifierValueSelect]);

  useEffect(() => {
    setSupportedTypes(identifierTypes.map((e) => e.type));
  }, [identifierTypes]);

  const postProcessIdentifierGet = useCallback(
    (res: any) => {
      const type: string[] = [];
      const filteredIdentifiers = res.filter(
        (r: any) =>
          identifierTypes.filter((p) => {
            if (p.type === r.type && !type.includes(p.type)) {
              type.push(p.type);
            }
            return p.type === r.type;
          })[0]
      );

      setNotPresentTypes(
        identifierTypes.filter((x) => !type.includes(x.type)).map((e) => e.type)
      );

      let clonedSelectIdentifier = selectedIdentifier;
      filteredIdentifiers.forEach((r: any) => {
        if (clonedSelectIdentifier[r.type].type === "") {
          clonedSelectIdentifier = {
            ...clonedSelectIdentifier,
            [r.type]: {
              ...clonedSelectIdentifier[r.type],
              type: r.type,
              value: r.value,
              categoryType: r.categoryType,
            },
          };
        }
      });
      setSelectedIdentifier(clonedSelectIdentifier);
      setIndentifiers(_.sortBy(filteredIdentifiers, "type"));
      setIdentifiersToBeShown(_.sortBy(filteredIdentifiers, "type"));
    },
    [identifierTypes, selectedIdentifier]
  );

  useEffect(() => {
    getIdentifiers()
      .then((res) => res.data)
      .then((res) => {
        let listOfIdentifiers = res;
        let existingWeakIdentifiers = weakIdentifiers ?? [];
        existingWeakIdentifiers = weakIdentifiers ?? [];
        existingWeakIdentifiers?.forEach((iden) => {
          listOfIdentifiers.push(iden);
        })
        postProcessIdentifierGet(listOfIdentifiers);
      });
  }, [postProcessIdentifierGet, weakIdentifiers]);

  const onCancel = () => {
    setVerification(false);
    setIsOtpError(false);
  };

  const identifierSelectionChange = (identifier: IdentifierList) => {
    setSelectedIdentifier((old) => ({
      ...old,
      [identifier.type]: {
        ...old[identifier.type],
        type: identifier.type,
        value: identifier.value,
        categoryType: identifier.categoryType,
      },
    }));
  };

  useEffect(() => {
    if (supportedTypes.length > 0) {
      const filterSupportedTypes = supportedTypes.filter(
        (p) =>
          !Object.keys(selectedIdentifier)
            .filter((p) => {
              if (selectedIdentifier[p].type !== "") return p;
              else return "";
            })
            .includes(p)
      );
      setNotSelectedTypes(filterSupportedTypes);

      if (filterSupportedTypes.length === 0) {
        setErrorIdentifier(false);
      }
    }

    if (notPresentTypes.length) {
      setSelectedIdentifierType(notPresentTypes[0]);
    } else {
      setSelectedIdentifierType(supportedTypes[0]);
    }
  }, [selectedIdentifier, supportedTypes, notPresentTypes]);

  const onInputChange = (e: any) => {
    if (selectedIdentifierType === IdentifierType.MOBILE) {
      if (e.target.value[0] === "0") {
        e.target.value = e.target.value.substring(1);
      }
      e.target.value = e.target.value.replace(/[^0-9]/gi, "");
    }
    if (selectedIdentifierType === IdentifierType.PAN) {
      e.target.value = e.target.value.toUpperCase();
    }
  };

  const formSubmit = (iNumber: any) => {
    iNumber.type = selectedIdentifierType;
    iNumber.categoryType =
      selectedIdentifierType === IdentifierType.MOBILE ? "STRONG" : "WEAK";
    if (iNumber.categoryType === "WEAK") {
      let existingWeakIdentifiers = weakIdentifiers ?? [];
      existingWeakIdentifiers?.push(
        {
          "type": iNumber.type,
          "categoryType": iNumber.categoryType,
          "value": iNumber.value
        }
      );
      setWeakIdentifiers(existingWeakIdentifiers);
      getIdentifiers()
        .then((resIdentifier) => resIdentifier.data)
        .then((resIdentifier) => {
          let listOfIdentifiers = resIdentifier;
          existingWeakIdentifiers = weakIdentifiers ?? [];
          existingWeakIdentifiers?.forEach((iden) => {
            listOfIdentifiers.push(iden);
          })
          postProcessIdentifierGet(listOfIdentifiers);
        });
      form.resetFields();
      showNotification();
    } else {
      addIdentifier(iNumber)
        .then((res) => res.data)
        .then((res) => {
          if (selectedIdentifierType === IdentifierType.MOBILE) {
            setVerification(true);
            setOTPUniqueId(res.mobile.otpUniqueID);
          } else {
            getIdentifiers()
              .then((resIdentifier) => resIdentifier.data)
              .then((resIdentifier) => {
                let listOfIdentifiers = resIdentifier;
                let existingWeakIdentifiers = weakIdentifiers ?? [];
                existingWeakIdentifiers?.forEach((iden) => {
                  listOfIdentifiers.push(iden);
                })
                postProcessIdentifierGet(listOfIdentifiers);
              });
            form.resetFields();
            showNotification();
          }
        });
    }
  };
  const { t } = useTranslation();
  const showNotification = () => {
    notification.open({
      className: `${theme.successAlert} alertClass`,
      message: t(translation.identifierAddSuccess, {
        identifierType: selectedIdentifierType,
      }),
      placement: "top",
      duration: 5,
      icon: <img alt="" src={theme.successIcon} className="alertIcon" />,
    });
  };

  const onOTPValueSubmit = (otp: string) => {
    const encryptedOTP = EncryptOTP(otp, otpUniqueID);
    addIdentifierVerificationEncrypted({
      phoneNumber: form.getFieldValue("value"),
      code: encryptedOTP,
      otpUniqueID,
      identifierType: selectedIdentifierType,
    })
      .then(() => {
        getIdentifiers()
          .then((res) => res.data)
          .then((res) => {
            let listOfIdentifiers = res;
            weakIdentifiers?.forEach((iden) => {
              listOfIdentifiers.push(iden);
            })
            postProcessIdentifierGet(listOfIdentifiers);
          });
        setVerification(false);
        setIsOtpError(false);
        form.resetFields();
        showNotification();
      })
      .catch((error) => {
        setOtpErrorMessage(error.response?.data.errorCode);
        setIsOtpError(true);
      });
  };

  const onIdentifierTypeChange = (value: any) => {
    setSelectedIdentifierType(value);
    form.setFieldValue("value", "");
  };
  const customValidator = (_u: any, value: string) => {
    if (value) {
      switch (selectedIdentifierType) {
        case IdentifierType.MOBILE: {
          if (!validMobile.test(value)) {
            return Promise.reject(
              new Error(t(translation.errorInvalidPhoneNumber))
            );
          }
          break;
        }
        case IdentifierType.AADHAR: {
          if (!validAadhar.test(value)) {
            return Promise.reject(
              new Error(t(translation.errorEnterValidAadhaar))
            );
          }
          break;
        }
        case IdentifierType.PAN: {
          if (!validPan.test(value)) {
            return Promise.reject(new Error(t(translation.errorEnterValidPan)));
          }
          break;
        }
        case IdentifierType.EMAIL: {
          if (!validEmail.test(value)) {
            return Promise.reject(
              new Error(t(translation.errorEnterValidEmail))
            );
          }
          break;
        }
        case IdentifierType.ACCNO: {
          if (!validAccNo.test(value)) {
            return Promise.reject(
              new Error(t(translation.errorEnterValidAccountNumber))
            );
          }
          break;
        }
        case IdentifierType.CRN: {
          if (!validCRN.test(value)) {
            return Promise.reject(
              new Error(t(translation.errorEnterValidAccountNumber))
            );
          }
          break;
        }
        case IdentifierType.PPAN: {
          if (!validPPAN.test(value)) {
            return Promise.reject(
              new Error(t(translation.errorEnterValidAccountNumber))
            );
          }
          break;
        }
        case IdentifierType.OTHER: {
          if (!validOther.test(value)) {
            return Promise.reject(
              new Error(t(translation.errorEnterValidAccountNumber))
            );
          }
          break;
        }
      }
    }
    return Promise.resolve();
  };

  const theme = useTheme();
  const disabledDate = (current: any) => {
    return current && current.valueOf() > Date.now();
  };

  return (
    <>
      {isAddIdentifierVerification ? (
        <OTPInputComponent
          title={t(translation.lblVerify)}
          isOpen={isAddIdentifierVerification}
          onOtpSubmit={onOTPValueSubmit}
          onCancel={onCancel}
          okText={t(translation.lblConfirm)}
          onResend={() => form.submit()}
          mobileNumber={form.getFieldValue("value")}
          isError={isOtpError}
          setIsError={setIsOtpError}
          errorMessage={otpErrorMessage}
        />
      ) : (
        <></>
      )}
      <div className="identificationRoot">
        {errorIdentifier && !loading ? (
          <Alert
            style={{ width: "98%", borderRadius: 10, marginTop: 20 }}
            message={
              notPresentTypes.length
                ? t(translation.lblAddRequiredIdentifiers)
                : t(translation.lblSelectRequiredIdentifier)
            }
            description={
              notPresentTypes.length
                ? t(translation.lblAddIdentifiers) +
                ": " +
                notPresentTypes.toString()
                : "" + notSelectedTypes.length
                  ? t(translation.lblSelectIdentifiers) +
                  ": " +
                  notSelectedTypes.toString()
                  : ""
            }
            type="warning"
            showIcon={true}
          />
        ) : (
          <></>
        )}
        {identifiersToBeShown &&
          identifiersToBeShown.length &&
          identifiersToBeShown[0].type !== "" ? (
          <>
            {identifiersToBeShown.map((identifier, index) => (
              <div key={index} className="identifierContainer">
                <Typography.Text className={theme.textTheme.headline1}>
                  {identifier.type.toUpperCase() === "PAN" ? t(`lbl${identifier.type}`).toUpperCase() : t(`lbl${identifier.type}`)}
                </Typography.Text>
                <Card
                  key={identifier.value}
                  className={`${selectedIdentifier[identifier.type].value ===
                    identifier.value
                    ? "selectedNumber"
                    : ""
                    }  ${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder
                    } addIdentifierCard linkAccountMobile`}
                  onClick={() => identifierSelectionChange(identifier)}
                >
                  {identifier.value}
                  <Radio
                    checked={
                      selectedIdentifier[identifier.type].value ===
                      identifier.value
                    }
                    value={identifier.value}
                    className={`${theme.textTheme.headline5} ${theme.textTheme.headline6} ${theme.checkBoxBorder} checboxSelectAll`}
                  />
                </Card>
              </div>
            ))}
          </>
        ) : (
          <></>
        )}
        {identifiersToBeShown.length % 2 !== 0 ? (
          <div className="identifierContainer" />
        ) : (
          <></>
        )}
      </div>
      {(errorIdentifier || isAddIdentifierVisible) && !loading ? (
        <div className="addIdentifierContainer linkAccountSection">
          <Typography.Text className={theme.textTheme.headline1}>
            {t(translation.lblAddIdentifier)}
          </Typography.Text>

          <Form
            form={form}
            name="basic"
            onFinish={formSubmit}
            autoComplete="off"
            scrollToFirstError={true}
            className="addidentifierForm"
          >
            <Input.Group className="identifierSelectorContainer">
              <Select
                onChange={onIdentifierTypeChange}
                popupClassName={theme.selectorColor}
                className={`${theme.selectorColor} ${theme.textTheme.headline1} titleClass addidentifierCard  width25`}
                value={selectedIdentifierType}
                suffixIcon={
                  <DownOutlined className={theme.textTheme.headline1} />
                }
              >
                {mapIdentifier
                  .filter(
                    (type) => identifierTypes.filter((p) => p.type === type)[0]
                  )
                  .map((type, index) => {
                    return (
                      <Select.Option
                        key={index}
                        className={`${theme.textTheme.headline1} ${theme.selectorColor} issueSelector `}
                        value={type}
                      >
                        {type === IdentifierType.MOBILE
                          ? t(translation.lblMobileNumber)
                          : type === IdentifierType.PAN ?
                            t(`lbl${type}`).toUpperCase()
                            : t(`lbl${type}`)}
                      </Select.Option>
                    );
                  })}
              </Select>
              <Form.Item
                name="value"
                rules={[
                  {
                    required: true,
                    message: t(translation.lblRequiredField),
                  },
                  {
                    validator: customValidator,
                  },
                ]}
                className="addidentifierFormItem"
              >
                {selectedIdentifierType === IdentifierType.DOB ? (
                  <div className="dob">
                    <DatePicker
                      disabledDate={disabledDate}
                      popupClassName={`${theme.dobPicker} dobPicker`}
                      onChange={(_, dateString: string) =>
                        form.setFieldValue("value", dateString)
                      }
                      suffixIcon={
                        <CalendarOutlined
                          className={theme.textTheme.headline1}
                        />
                      }
                      format={"DD/MM/YYYY"}
                      className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormLabels} ${theme.authFormBorder} identifierInputForm numberContainer identifierInput`}
                      name="value"
                      showToday={false}
                    />
                    <Form.Item>
                      <Button
                        className={`${theme.textTheme.headline5} addButton`}
                        htmlType="submit"
                        type="text"
                      >
                        {t(translation.lblAdd)}
                      </Button>
                    </Form.Item>
                  </div>
                ) : (
                  <Input
                    placeholder={t(
                      `Enter your ${_.capitalize(selectedIdentifierType)}`
                    )}
                    onInput={onInputChange}
                    className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormLabels} ${theme.authFormBorder} identifierInputForm numberContainer identifierInput`}
                    suffix={
                      <Form.Item>
                        <Button
                          className={`${theme.textTheme.headline5} addButton`}
                          htmlType="submit"
                          type="text"
                        >
                          {t(translation.lblAdd)}
                        </Button>
                      </Form.Item>
                    }
                    value={form.getFieldValue("value")}
                  />
                )}
              </Form.Item>
            </Input.Group>
          </Form>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default LinkAccountIdentifiers;
