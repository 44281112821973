import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Empty, Image, Input, Skeleton, Typography } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "../../../App";
import default_bank from "../../../Assets/ic_default_bank.png";
import { translation } from "../../../i18n/translation";
import LinkedDelinkedAccountResponse, {
  LinkedDelinkedAccountObject,
} from "../../../Models/Response/Account/LinkedDelinkedAccounts";
import { getLinkedAccountsOfUser } from "../../../Services/AccountsServices/AccountService";
import { logoBaseUrl } from "../../../Utils/Constants/baseUrl";
import { Routes } from "../../../Utils/Constants/routes";
import "./index.scss";

const Accounts: React.FC = () => {
  const [linkedAccounts, setLinkedAccounts] = useState(
    new Array<LinkedDelinkedAccountObject>()
  );
  const loading: boolean = useSelector(
    (state: any) => state.loader.searchLoader
  );
  const [linkedAccountsTobeShown, setLinkedAccountsTobeShown] = useState(
    new Array<LinkedDelinkedAccountObject>()
  );

  const { t } = useTranslation();
  const { push } = useHistory();

  useEffect(() => {
    getLinkedAccounts();
  }, []);

  const getLinkedAccounts = () => {
    getLinkedAccountsOfUser()
      .then((res) => res.data)
      .then((res: LinkedDelinkedAccountResponse) => {
        setLinkedAccounts(res);
        setLinkedAccountsTobeShown(res);
      });
  };

  const linkAccounts = () => push(Routes.LinkAccounts);

  const onSearchInputChange = (value: any) => {
    setLinkedAccountsTobeShown(
      value.target.value
        ? linkedAccounts.filter(
            (account) =>
              account.maskedAccNumber
                .toUpperCase()
                .indexOf(value.target.value.toUpperCase()) !== -1 ||
              account.fipName
                .toUpperCase()
                .indexOf(value.target.value.toUpperCase()) !== -1
          )
        : linkedAccounts
    );
  };

  const onAccountCardClick = (account: LinkedDelinkedAccountObject) => {
    const accountId = account.accountRefNumber;
    push({
      pathname: `${Routes.Account}/${accountId}`,
      state: { account },
    });
  };

  const theme = useTheme();
  return (
    <>
      <div className={`${theme.bodyBackgroundColor} accountsRoot`}>
        <div className="accountRootContainer">
          {loading || (linkedAccounts && linkedAccounts.length) ? (
            <>
              <div className="displayFlex spaceBetween flexStart">
                <Typography.Text className={`${theme.indicatorColor} header`}>
                  {t(translation.lblLinkAccounts)}
                </Typography.Text>
                <Button
                  className={`${theme.textTheme.authButton} ${theme.formItemMarginBottom} borderNoneHeight50 priButton`}
                  onClick={linkAccounts}
                >
                  <PlusOutlined /> {t(translation.lblLinkAccount)}
                </Button>
              </div>

              <div className="searchInputRoot">
                <Input
                  className={`${theme.authFormBorder} ${theme.hintColor} ${theme.backgroundColor} searchInput`}
                  prefix={<SearchOutlined className="font16" />}
                  placeholder={t(translation.lblAccountSearch)}
                  onChange={onSearchInputChange}
                />
              </div>
              <div className="listAccountsContainer">
                {loading ||
                (linkedAccountsTobeShown && linkedAccountsTobeShown.length) ? (
                  <Skeleton loading={loading} active>
                    {linkedAccountsTobeShown.map((accounts, index) => (
                      <Card
                        hoverable={true}
                        className={`${theme.cardColor} ${theme.consentCardBorder} accountsCard`}
                        bordered={false}
                        key={index}
                        id={index.toString()}
                        onClick={() => onAccountCardClick(accounts)}
                      >
                        <div className="cardFirstRow">
                          <Image
                            className="fipLogo"
                            src={logoBaseUrl + accounts.fipHandle + ".jpeg"}
                            fallback={default_bank}
                            preview={false}
                          />
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography.Text
                              className={`${theme.textTheme.headline3} fipName`}
                            >
                              {accounts.fipName}
                            </Typography.Text>
                            <Typography.Text
                              className={`${theme.hintColor} accountType`}
                            >
                              {_.capitalize(accounts.fiType)}
                            </Typography.Text>
                          </div>
                        </div>
                        <Typography.Text
                          className={`${theme.accountMaskedNumber} maskedAccNumber`}
                        >
                          {accounts.maskedAccNumber}
                        </Typography.Text>

                        <Typography.Text
                          className={`${theme.hintColor} accountType`}
                        >
                          {_.capitalize(accounts.accountType)}
                        </Typography.Text>
                      </Card>
                    ))}
                    {linkedAccountsTobeShown &&
                    linkedAccountsTobeShown.length % 2 !== 0 ? (
                      <div className="accountsCard" />
                    ) : (
                      <></>
                    )}
                  </Skeleton>
                ) : (
                  <Empty
                    image={theme.noAccountFound}
                    className={`empty marginTop10`}
                    description={
                      <Typography.Text
                        className={`${theme.indicatorColor} consentErrorMsg`}
                      >
                        {t(translation.errorNoAccountsFound)}
                      </Typography.Text>
                    }
                  />
                )}
              </div>
            </>
          ) : (
            <Empty
              image={theme.noAccountFound}
              className={`empty accountEmpty`}
              description={
                <>
                  <Typography.Text
                    className={`${theme.indicatorColor} consentErrorMsg`}
                  >
                    {t(translation.lblDontHaveAccounts)}
                  </Typography.Text>
                  <br />
                  <Typography.Text className={theme.textTheme.subtitle1}>
                    {t(translation.msgDontHaveAccounts)}
                  </Typography.Text>
                  <br />
                  <Button
                    className={`${theme.textTheme.authButton} ${theme.formItemMarginBottom} borderNoneHeight50  emptyLinkAccountButton`}
                    onClick={linkAccounts}
                  >
                    {t(translation.lblLinkAccount)}
                  </Button>
                </>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Accounts;
