import {
  Button,
  Card,
  Divider,
  Dropdown,
  Empty,
  Menu,
  Modal,
  Tabs,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "App";
import {
  getGrievanceOfUser,
  getGrievanceOfUserByStatus,
} from "Services/GrievanceServices/GrievanceService";
import "./index.css";
import { useHistory } from "react-router-dom";
import { Routes } from "Utils/Constants/routes";
import "./index.css";
import TicketStatus from "Pages/Grievance/Components/TicketStatus";
import useINF from "Pages/Common/Components/InfiniteScroll";
import { CalendarIcon } from "Assets/icons";
import moment from "moment";
import { useSelector } from "react-redux";
import { UserRespose } from "redux/Reducer/userReducer";
import "./constants";
import {
  email_general,
  email_officer,
  mobile_number,
  officer_address,
  team_address,
} from "./constants";
import { MoreOutlined } from "@ant-design/icons";
import { translation } from "i18n/translation";

const pageSize = 20;
const Grievance: React.FC = () => {
  const [grievance, setGrievance] = useState([]);
  const [grievancePending, setGrievancePending] = useState([]);
  const [grievanceCompleted, setGrievanceCompleted] = useState([]);
  const [officeModalViisible, setOfficeModalViisible] = useState(false);
  const [grievanceType, setgrievanceType] = useState("ALL");
  const [page, setPage] = useState(1);
  const user: UserRespose = useSelector((state: any) => state.user.user);
  const theme = useTheme();
  const { TabPane } = Tabs;
  const { Title } = Typography;
  const { t } = useTranslation();
  const [hasMore, setHasMore] = useState(true);

  const observer = useRef<any>();
  const { newObserver, ref } = useINF(observer, hasMore, setPage);
  observer.current = newObserver.current;

  useEffect(() => {
    switch (grievanceType) {
      case "ALL":
        getGrievanceOfUser(page - 1, pageSize)
          .then((res) => res.data)
          .then((res) => {
            setHasMore(!res.last);
            setGrievance((prevState) => prevState.concat(res.content));
          });
        break;
      case "PENDING":
        getGrievanceOfUserByStatus(page - 1, pageSize, "CREATED")
          .then((res) => res.data)
          .then((res) => {
            setHasMore(!res.last);
            setGrievancePending((prevState) => prevState.concat(res.content));
          });
        break;

      case "SOLVED":
        getGrievanceOfUserByStatus(page - 1, pageSize, "RESOLVED")
          .then((res) => res.data)
          .then((res) => {
            setHasMore(!res.last);
            setGrievanceCompleted((prevState) => prevState.concat(res.content));
          });
        break;
    }
  }, [page, grievanceType]);

  const onTabChange = (key: string) => {
    setHasMore(true);
    setPage(1);
    setGrievance([]);
    setGrievancePending([]);
    setGrievanceCompleted([]);

    switch (key) {
      case "1":
        setgrievanceType("ALL");
        break;
      case "2":
        setgrievanceType("PENDING");
        break;
      case "3":
        setgrievanceType("SOLVED");
        break;
    }
  };
  const renderGrievance = (type: string) => {
    const renderCoulmn = (grievanceColumn: any, column: number) => {
      return (
        <>
          {grievanceColumn
            .filter((_g: any, index: number) =>
              column === 1 ? index % 2 === 0 : index % 2 !== 0
            )
            .map((g: any) => (
              <Card
                hoverable={true}
                key={g.id}
                className={`${theme.cardColor} descriptionRoot`}
                onClick={() => showDetails(g.id, g)}
              >
                <Typography.Title
                  level={5}
                  className={`${theme.highlightColor}`}
                >
                  {t(g.issueType)}
                </Typography.Title>

                <Divider className={`${theme.dividerColor} helpCardDivider`} />

                <div className="issueDetails">
                  <Typography.Text className={`${theme.textTheme.subtitle1}`}>
                    <div className="issueDetailsDate">
                      <CalendarIcon
                        className={`${theme.hintColor} marginRight6 icon`}
                      />
                      <div className="issueDate">
                        {t(moment(g.updatedOn).format("Do MMM, YY"))}
                      </div>
                    </div>
                  </Typography.Text>

                  <Typography.Text className="marginTop8">
                    <TicketStatus status={g.status} />
                  </Typography.Text>
                </div>
              </Card>
            ))}
          <div ref={ref} className="descriptionRoot" />
        </>
      );
    };

    const grievanceTemp =
      type === "ALL"
        ? grievance
        : type === "PENDING"
        ? grievancePending
        : grievanceCompleted;
    return (
      <div className="grievanceContainer">
        {grievanceTemp && grievanceTemp.length ? (
          <>
            <div className="grievnaceColumns">
              {renderCoulmn(grievanceTemp, 1)}
            </div>

            <div className="grievnaceColumns">
              {renderCoulmn(grievanceTemp, 2)}
            </div>
          </>
        ) : (
          <Empty
            image={theme.noConsentFound}
            className={`empty`}
            description={
              <Typography.Text
                className={`${theme.indicatorColor} consentErrorMsg`}
              >
                {t(translation.errorNoDetailsFound)}
              </Typography.Text>
            }
          />
        )}
      </div>
    );
  };

  const history = useHistory();
  const showDetails = (id: string, g: any) => {
    history.push({
      pathname: `${Routes.Grievance}/${id}`,
      state: { gr: g },
    });
  };
  const genrateTicket = () => {
    history.push({
      pathname: `${Routes.Grievance}/generate-ticket`,
    });
  };

  const showOfficerDetails = () => {
    setOfficeModalViisible(true);
  };
  const closeOfficerDetails = () => {
    setOfficeModalViisible(false);
  };
  const menu = (
    <Menu className={`${theme.cardColor} menu`}>
      <Menu.Item
        className={`${theme.hintColor} overlayMenuItem`}
        key={"Delete"}
        onClick={showOfficerDetails}
      >
        {t(translation.grievanceOfficer)}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={`${theme.mainbackgroundColor} grievanceRoot`}>
      <Modal
        width={"40%"}
        className={`${theme.modalClass} grievanceModal `}
        footer={null}
        open={officeModalViisible}
        onCancel={closeOfficerDetails}
        destroyOnClose={true}
      >
        <div className="grievanceOfficerDetails">
          <Typography.Paragraph className={`${theme.textTheme.headline1}`}>
            {t("Hey")}
            {` ${user.firstName}!`}
          </Typography.Paragraph>
          <Typography.Paragraph className={`${theme.textTheme.subtitle1}`}>
            {t("grievanceOfficer_1")}{" "}
            <a
              href={`mailto: ${email_general}`}
              className={`${theme.menubarSelectColor}`}
            >
              {" "}
              {email_general}{" "}
            </a>
            {t("grievanceOfficer_2")}{" "}
            <a href={`tel:+91${mobile_number}`}>{mobile_number}</a>{" "}
            {t("grievanceOfficer_3")}
          </Typography.Paragraph>
          <Typography.Paragraph
            className={`${theme.menubarSelectColor} weight500`}
          >
            {team_address()}
          </Typography.Paragraph>
          <Typography.Paragraph className={`${theme.textTheme.subtitle1} `}>
            {t("grievanceOfficer_4")}
          </Typography.Paragraph>
          <Typography.Paragraph
            className={`${theme.menubarSelectColor} weight500`}
          >
            {officer_address()}

            <a href={`mailto: ${email_officer}`}> {email_officer} </a>
          </Typography.Paragraph>
        </div>
      </Modal>
      <div className="grievanceHeader">
        <Typography>
          <Title
            level={3}
            className={`${theme.textTheme.headline1} titleClass grievanceTitle`}
          >
            {t(translation.lblHelpCenter)}
          </Title>
          <Typography.Text
            className={`${theme.textTheme.subtitle1} grievanceSubtitle`}
          >
            {t(translation.helpCenterDes)}
          </Typography.Text>
        </Typography>
        <div className="grievanceButtons">
          <Button
            className={`${theme.textTheme.authButton} reportButton`}
            type="primary"
            onClick={genrateTicket}
          >
            {t(translation.lblCreateTicket)}
          </Button>
          <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
            <MoreOutlined
              className={`${theme.notificationBackgroundColor} ${theme.hintColor} ${theme.dividerColorBorder} moreIcon accountMoreIcon`}
            />
          </Dropdown>
        </div>
      </div>

      <Tabs
        defaultActiveKey="1"
        onChange={onTabChange}
        className={`${theme.tabColor} grievanceTabs`}
      >
        <TabPane tab={t(translation.lblAllTickets)} key="1">
          {renderGrievance("ALL")}
        </TabPane>

        <TabPane tab={t(translation.lblPendingConsent)} key="2">
          {renderGrievance("PENDING")}
        </TabPane>
        <TabPane tab={t(translation.lblSolved)} key="3">
          {renderGrievance("COMPLETED")}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Grievance;
