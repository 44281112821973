import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useTheme } from "App";
import default_bank from "Assets/ic_default_bank.png";
import { SelectedAccounts } from "Models/Request/Account/AccountLink";
import { DiscoverAccountObject } from "Models/Response/Account/DiscoverAccount";
import LinkedDelinkedAccountResponse, { LinkedDelinkedAccountObject } from "Models/Response/Account/LinkedDelinkedAccounts";
import { getLinkedAccountsOfUser } from "Services/AccountsServices/AccountService";
import { getFipId } from "Services/StorageServices";
import { convertFiType } from "Utils/Constants/accTypeConversion";
import { logoBaseUrl } from "Utils/Constants/baseUrl";
import { QueryParams, Routes } from "Utils/Constants/routes";
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Empty,
  Image,
  Input,
  Skeleton,
  Typography,
} from "antd";
import { translation } from "i18n/translation";
import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

interface AutoDiscoverAccountLinkProps {
  isAutoDiscovery?: boolean;
  consentHandle?: string;
  onOk: (accounts: SelectedAccounts[]) => void;
}

export interface AutoDiscoveryMap {
  [fipId: string]: AutoDiscoveryMapAccounts;
}

interface AutoDiscoveryMapAccounts {
  fipName: string;
  accounts: DiscoverAccountObject[];
  checkAll?: boolean;
  indeterminate?: boolean;
}

const UpdatedConsentJourneyCustomized: React.FC<AutoDiscoverAccountLinkProps> = ({
  isAutoDiscovery = false,
  consentHandle,
  onOk
}) => {
  const history = useHistory();
  const fipId = getFipId();
  const theme = useTheme();
  const [linkedAccounts, setLinkedAccounts] = useState(
    new Array<LinkedDelinkedAccountObject>()
  );
  const [linkedAccountsToBeShown, setLinkedAccountsToBeShown] = useState<any>(
    {}
  );
  const [linkedAccountsForFilter, setLinkedAccountsForFilter] = useState<any>(
    {}
  );
  const [selectedFIP, setSelectedFIP] = useState("");
  const [totalNumberOfAccounts, setTotalAccounts] = useState(0);
  const [selectedAccounts, setSelectedAccounts] = useState(new Array<string>());
  const loading: boolean = useSelector(
    (state: any) => state.loader.searchLoader
  );
  const mapToLinkedRefNumberAndFIPHandle = (selectedCard: string[]) => {
    const accounts: SelectedAccounts[] = linkedAccounts
      .filter(
        (account) => selectedCard.indexOf(account.accountRefNumber) !== -1
      )
      .map((account) => {
        return {
          linkRefNumber: account.linkRefNumber,
          fipHandle: account.fipHandle,
          maskedNumber: account.maskedAccNumber,
        };
      });
    onOk(accounts);
  };

  const filterByMaskedAccNumber = (value: any) => {
    const filteredMap: any = {};

    Object.keys(linkedAccountsForFilter).forEach(key => {
      const filteredAccounts = linkedAccountsForFilter[key].accounts.filter(
        (account: any) => account.maskedAccNumber
          .toUpperCase()
          .indexOf(value.toUpperCase()) !== -1 ||
          linkedAccountsForFilter[key].fipName
            .toUpperCase()
            .indexOf(value.toUpperCase()) !== -1
      );
      if (filteredAccounts.length > 0) {
        filteredMap[key] = { ...linkedAccountsForFilter[key], accounts: filteredAccounts };
      }
    });
    return filteredMap;
  };

  const onSearchInputChange = (value: any) => {
    setLinkedAccountsToBeShown(
      value.target.value
        ? filterByMaskedAccNumber(value.target.value)
        : linkedAccountsForFilter
    );
  };

  const onAccountsSelect = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    fip: string
  ) => {
    if (!isAutoDiscovery || selectedFIP === fip) {
      if (selectedAccounts.indexOf(event.currentTarget.id) === -1) {
        setSelectedAccounts([...selectedAccounts, event.currentTarget.id]);
      } else {
        setSelectedAccounts(
          selectedAccounts.filter((card) => card !== event.currentTarget.id)
        );
      }
    } else {
      setSelectedFIP(fip);
      setSelectedAccounts([event.currentTarget.id]);
    }
  };

  const linkAccountsRedirect = () => {
    history.push({
      pathname: Routes.LinkAccounts,
      search: `?${QueryParams.Redirect}=${history.location.pathname}`,
      state: consentHandle,
    });
  };

  useEffect(() => {
    getLinkedAccountsOfUser(consentHandle, fipId ? fipId : undefined)
      .then((res) => res.data)
      .then((res: LinkedDelinkedAccountResponse) => {
        setLinkedAccounts(res);
        let t: AutoDiscoveryMap = {};
        res.forEach((e) => {
          if (t[e.fipHandle]) {
            t = {
              ...t,
              [e.fipHandle]: {
                ...t[e.fipHandle],
                accounts: [
                  ...t[e.fipHandle].accounts,
                  {
                    maskedAccNumber: e.maskedAccNumber,
                    accRefNumber: e.accountRefNumber,
                    accType: e.accountType,
                    FIType: e.fiType,
                  },
                ],
              },
            };
          } else {
            t = {
              ...t,
              [e.fipHandle]: {
                fipName: e.fipName,
                accounts: [
                  {
                    maskedAccNumber: e.maskedAccNumber,
                    accRefNumber: e.accountRefNumber,
                    accType: e.accountType,
                    FIType: e.fiType,
                  },
                ],
              },
            };
          }
        });
        setLinkedAccountsToBeShown(t);
        let accounts: string[] = [];
        let tolalAccounts: number = 0;
        Object.keys(t).forEach((fip) => {
          const localAccounts: string[] = t[fip].accounts.map(
            (value: { accRefNumber: any; }) => value.accRefNumber
          );
          tolalAccounts += localAccounts.length;
          accounts = [...accounts, ...localAccounts];
        });
        setTotalAccounts(tolalAccounts);
        setSelectedAccounts(accounts);
        setLinkedAccountsForFilter(t);
      });
  }, [consentHandle, fipId]);

  useEffect(() => {
    mapToLinkedRefNumberAndFIPHandle(selectedAccounts);
    // eslint-disable-next-line
  }, [selectedAccounts]);

  return (
    <div style={{ margin: 0 }}>
      <div className="selectAccountsTitleCustomized">
        <div className="customizedButtonDiv">
          <Button
            className={`${theme.formItemMarginBottom} customizedLinkAccountButton`}
            onClick={linkAccountsRedirect}
          >
            <PlusOutlined /> {t(translation.lblAccounts)}
          </Button>
        </div>
        <Input
          className={`${theme.authFormBorder} ${theme.hintColor} ${theme.backgroundColor} searchInputCustomized`}
          prefix={<SearchOutlined className="font16" />}
          placeholder={t(translation.lblSearchBarPlaceHolder)}
          onChange={onSearchInputChange}
        />
      </div>
      <Skeleton loading={loading}>
        {Object.keys(linkedAccountsToBeShown).length > 0 ? (
          <>
            <div className="selectAccountsTitleHeadingParent">
              <div>
                <div className="selectAccountsTitleHeading">
                  {t(translation.lblSelectAccounts)}
                </div>
                <div className="selectAccountsTitleHeadingChild">
                  {/* Here, dangerouslySetInnerHTML is used for not escaping special character which is forward slash */}
                  {"("}
                  <span dangerouslySetInnerHTML={{ __html: t(translation.lblAccountsSelected, { value: selectedAccounts.length.toString() + " / " + totalNumberOfAccounts.toString() }) }}>
                  </span>
                  {")"}
                </div>
              </div>
              <div className="customizedButtonDivForMobileOnly">
                <Button
                  className={`${theme.formItemMarginBottom} customizedLinkAccountButtonMobile`}
                  icon={<PlusOutlined />}
                  onClick={linkAccountsRedirect}
                >
                  {t(translation.lblAccounts)}
                </Button>
              </div>
            </div>
            <Collapse
              activeKey={Object.keys(linkedAccountsToBeShown).map(
                (r, index) => index
              )}
              bordered={false}
              style={{ margin: 0, padding: 0 }}
            >
              {Object.keys(linkedAccountsToBeShown).map((fipId, index: number) =>
                linkedAccountsToBeShown[fipId].accounts &&
                  linkedAccountsToBeShown[fipId].accounts.length > 0 ? (
                  <Collapse.Panel
                    style={{ margin: 0, padding: 0 }}
                    header={null}
                    key={index}
                    showArrow={false}
                    className={`${theme.cardColor} ${theme.entityCard} accountDiscoveryCard consentA accountDiscoveryCardCustom`}
                  >
                    <div className="selectAccountsTitle" style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                      {linkedAccountsToBeShown[fipId].accounts.map(
                        (accounts: DiscoverAccountObject, indexD: number) => (
                          <>
                            <Card
                              bordered={false}
                              size="small"
                              key={indexD}
                              id={accounts.accRefNumber}
                              onClick={(e) => onAccountsSelect(e, fipId)}
                            >
                              <div className={selectedAccounts.indexOf(accounts.accRefNumber) !== -1 ? "cardFirstRowCustomizedSelected" : "cardFirstRowCustomizedUnselected"}>
                                <div style={{ display: 'flex' }}>
                                  <div className={`fiuHeader`}>
                                    <Image
                                      loading="lazy"
                                      className="customizedFipLogo"
                                      src={logoBaseUrl + fipId + ".jpeg"}
                                      fallback={default_bank}
                                      preview={false}
                                    />
                                  </div>
                                  <div>
                                    <div>
                                      <div className="customizedFipName">
                                        <Typography.Text>
                                          {linkedAccountsToBeShown[fipId].fipName}
                                        </Typography.Text>
                                      </div>
                                    </div>
                                    <div>
                                      <Typography.Text
                                        className="customizedAccountType"
                                      >
                                        {accounts.FIType === "DEPOSIT"
                                          ? t(_.capitalize(accounts.accType))
                                          : convertFiType(accounts.FIType)}
                                      </Typography.Text>
                                      {" "}
                                      <Typography.Text
                                        className="customizedMaskedAccNumber"
                                      >
                                        {accounts.maskedAccNumber.slice(
                                          accounts.maskedAccNumber.length - 7
                                        )}
                                      </Typography.Text>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ paddingRight: '15px' }}>
                                  <Checkbox
                                    checked={
                                      selectedAccounts.indexOf(
                                        accounts.accRefNumber
                                      ) === -1
                                        ? false
                                        : true
                                    }
                                    className={`${theme.textTheme.headline5} ${theme.textTheme.headline6} ${theme.checkBoxBorder} checboxSelectForAccountCustomized`}
                                  />
                                </div>
                              </div>
                            </Card >
                          </>
                        )
                      )}
                      {linkedAccountsToBeShown[fipId].accounts &&
                        linkedAccountsToBeShown[fipId].accounts.length % 2 !== 0 ? (
                        <div />
                      ) : (
                        <></>
                      )}
                    </div>
                  </Collapse.Panel>
                ) : (
                  <></>
                )
              )}
            </Collapse>
          </>
        ) : (
          <Empty
            image={theme.noAccountFound}
            className={`empty accountEmpty`}
            description={
              <>
                <Typography.Text
                  className={`${theme.indicatorColor} consentErrorMsg`}
                >
                  {t(translation.lblDontHaveAccounts)}
                </Typography.Text>
              </>
            }
          />
        )
        }
      </Skeleton >
    </div>
  );
};

export default UpdatedConsentJourneyCustomized;
