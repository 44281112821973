import { useCallback } from "react";

function useINF(newObserver: any, hasMore: boolean, setPage: any) {
  const ref = useCallback(
    (node) => {
      if (newObserver.current) {
        newObserver.current.disconnect();
      }
      newObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prev: number) => prev + 1);
        }
      });
      if (node) {
        newObserver.current.observe(node);
      }
    },
    [hasMore, newObserver, setPage]
  );

  return { newObserver, ref };
}

export default useINF;
