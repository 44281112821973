import { Form, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../App";
import { translation } from "../../../../i18n/translation";
import { forgotPin } from "../../../../Services/AuthenticationServices/AuthenticationServices";
import AcknowledgeModal from "../../../Common/Components/Ackowledge";
import PinInput from "../../../Common/Components/PinInput";
import ScaffoldComponent from "../../../Common/Components/Scaffold";
import Wrapper from "../../../Common/Components/Wrapper";
import "./index.css";
interface IForgotPasswordProps {
  token: string;
  onClose?: () => void;
  buttonText?: string;
}

const ForgotPassword: React.FC<IForgotPasswordProps> = ({
  token,
  onClose,
  buttonText,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [form] = Form.useForm();
  const [visible, setVisibility] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);

  const forgotPasswordUpdate = (value: any) => {
    forgotPin(value.newPin, token).then(() => {
      form.resetFields();
      setVisibility(true);
    });
  };

  const [updatedPin, setUpdatePin] = useState({
    newPin: "",
    confirmNewPin: "",
  });

  const checkConfirmPassword = (_: any, value: string) => {
    if (value && value !== updatedPin.newPin && value.length === 6)
      return Promise.reject(new Error(t(translation.errorPasscodeNotMatch)));
    return Promise.resolve();
  };

  const onCancel = () => {
    setVisibility(false);
    setPasswordReset(true);
    onClose && onClose();
  };

  return (
    <>
      <AcknowledgeModal
        isOpen={visible}
        okText={buttonText ? buttonText : ""}
        title={t(translation.msgPinResetSuccess)}
        onClose={onCancel}
      />
      {!passwordReset && (
        <ScaffoldComponent className="width">
          <Form
            form={form}
            name="basic"
            onFinish={forgotPasswordUpdate}
            className="width displayFlexJustifyCenter"
            autoComplete="off"
            scrollToFirstError={true}
          >
            <Wrapper title={t(translation.lblResetPin)}>
              <div className="userProfile">
                <Typography.Text
                  className={`${theme.authFormLabels} font14weight400marginTop32`}
                >
                  {t(translation.lblNewPin)}
                </Typography.Text>
                <PinInput
                  label={t(translation.lblNewPin)}
                  nameField="newPin"
                  onChange={(pin) =>
                    setUpdatePin((old) => ({ ...old, newPin: pin }))
                  }
                />
              </div>

              <div className="userProfile">
                <Typography.Text
                  className={`${theme.authFormLabels} font14weight400marginTop32`}
                >
                  {t(translation.lblConfirmPin)}
                </Typography.Text>
                <PinInput
                  label={t(translation.lblConfirmPin)}
                  nameField="confirmNewPin"
                  customRule={checkConfirmPassword}
                  onChange={(pin) =>
                    setUpdatePin((old) => ({ ...old, confirmNewPin: pin }))
                  }
                />
              </div>
            </Wrapper>
          </Form>
        </ScaffoldComponent>
      )}
    </>
  );
};
export default ForgotPassword;
