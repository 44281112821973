export enum IdentifierType {
  MOBILE = "MOBILE",
  AADHAR = "AADHAR",
  EMAIL = "EMAIL",
  PAN = "PAN",
  DOB = "DOB",
  ACCNO = "ACCNO",
  PPAN = "PPAN",
  CRN = "CRN",
  OTHER = "OTHER",
}
