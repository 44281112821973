import { Form, Input, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../App";
import { translation } from "../../../i18n/translation";
import OTPInputComponent from "../../Common/Components/OTPModal";
import ScaffoldComponent from "../../Common/Components/Scaffold";
import Wrapper from "../../Common/Components/Wrapper";
import "../index.css";

interface IAddUserIdentificationProps {
  handleCancel: () => void;
  identifierEntered: (otp: any) => void;
  isAddIdentifierVerification: boolean;
  onOTPSubmit: (otp: string, identifierNumber: string) => void;
  label?: string;
  isOtpError: boolean;
  setIsOtpError: (isOtpError: boolean) => void;
  otpErrorMessage: string;
}

const AddUserIdentification: React.FC<IAddUserIdentificationProps> = ({
  handleCancel,
  identifierEntered,
  isAddIdentifierVerification,
  onOTPSubmit,
  isOtpError,
  setIsOtpError,
  otpErrorMessage,
}) => {
  const theme = useTheme();
  const [identifier, setIdentifierNumber] = useState("");
  const [form] = Form.useForm();

  const onOTPValueSubmit = (otp: string) => {
    onOTPSubmit(otp, identifier);
  };

  const onCancel = () => {
    handleCancel();
  };

  const onIdentifierEntered = () => {
    identifierEntered({ value: identifier });
  };
  const { t } = useTranslation();

  const onMobileChange = (e: any) => {
    if (e.target.value[0] === "0") {
      e.target.value = e.target.value.substring(1);
    }
    e.target.value = e.target.value.replace(/[^0-9]/gi, "");
    setIdentifierNumber(e.target.value);
  };

  return (
    <>
      {isAddIdentifierVerification ? (
        <OTPInputComponent
          title={t(translation.lblVerify)}
          isOpen={isAddIdentifierVerification}
          onOtpSubmit={onOTPValueSubmit}
          onCancel={onCancel}
          okText={t(translation.lblConfirm)}
          onResend={onIdentifierEntered}
          mobileNumber={identifier}
          isError={isOtpError}
          setIsError={setIsOtpError}
          errorMessage={otpErrorMessage}
        />
      ) : (
        <></>
      )}

      <ScaffoldComponent className="width">
        <Form
          className="width displayFlexJustifyCenter"
          form={form}
          onFinish={identifierEntered}
        >
          <Wrapper
            title="Forgot your passcode?"
            subTitle="Enter your registered mobile number to reset your passcode"
          >
            <div className="wrapperLabel">
              <Typography.Text
                className={`${theme.authFormLabels} font14weight400marginTop32`}
              >
                {t(translation.lblMobileNumber)}
              </Typography.Text>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t(translation.lblRequiredField),
                  },
                  {
                    len: 10,
                    message: t(translation.errorInvalidPhoneNumber),
                  },
                ]}
                name="value"
                className={`${theme.formItemMarginBottom}`}
              >
                <Input
                  className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} identifierInput width100borderRadius8MarginTop8Height50`}
                  onInput={onMobileChange}
                  placeholder={t(translation.lblMobileNumber)}
                  type="tel"
                  maxLength={10}
                  // onChange={validateMobile}
                />
              </Form.Item>
            </div>
          </Wrapper>
        </Form>
      </ScaffoldComponent>
    </>
  );
};
export default AddUserIdentification;
