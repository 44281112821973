const initState = { searchLoader: false };

export default function loader(state = initState, action: any) {
  switch (action.type) {
    case "START_SEARCH":
      return { searchLoader: true };

    case "FINISH_SEARCH":
      return { searchLoader: false };

    default:
      return state;
  }
}
