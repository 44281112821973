import { Statistic } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../App";
import "./index.css";

interface ITimeFromNowProps {
  date: string;
  title?: string;
}

const TimeFromNow: React.FC<ITimeFromNowProps> = ({ date, title }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Statistic
      className={`statastics ${theme.textTheme.subtitle1}`}
      title={title ? title : t("Created")}
      value={moment(date).fromNow()}
    />
  );
};

export default TimeFromNow;
