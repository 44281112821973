import { Button, Menu, notification } from "antd";
import { useTheme } from "App";
import {
  AccountsIcon,
  ConsentsIcon,
  HelpCenterIcon,
  HistoryIcon,
  SettingsIcon,
} from "Assets/icons";
import logout from "Assets/logout.png";
import saafeDark from "Assets/saafe_dark.png";
import saafeLight from "Assets/saafe_light.png";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { UserRespose } from "redux/Reducer/userReducer";
import {
  clearCredLocalStorage,
  getSessionState,
} from "Services/StorageServices";
import { Routes } from "Utils/Constants/routes";

import { CloseOutlined } from "@ant-design/icons";
import { translation } from "i18n/translation";
import { signOut } from "Services/AuthenticationServices/AuthenticationServices";
import "./index.css";

const renderNavLink = (item: any, index: number, activeKey: string[], themeL: any): React.ReactElement => {
  const NavLinkComponent = NavLink as unknown as React.ComponentClass<any>;

  return (
    <NavLinkComponent
      exact={true}
      key={index.toString()}
      to={item.route}
      className={`${
        activeKey[0] === index.toString()
          ? themeL.menubarSelectColor
          : ""
      } menuTextColor menubarText`}
    >
      {item.name}
    </NavLinkComponent>
  );
};

const Menubar: React.FC<{
  user: UserRespose;
  setCollapsed?: (collapsed: boolean) => void;
  currentTheme: string;
}> = ({ user, setCollapsed, currentTheme }) => {
  const themeL = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [activeKey, setActiveKey] = useState(
    location.pathname.includes(Routes.Account)
      ? ["0"]
      : location.pathname.includes(Routes.Consent)
        ? ["1"]
        : location.pathname.includes(Routes.UserActivity)
          ? ["2"]
          : location.pathname.includes(Routes.Grievance)
            ? ["3"]
            : location.pathname.includes(Routes.Settings)
              ? ["4"]
              : ["1"]
  );

  useEffect(() => {
    setActiveKey(
      location.pathname.includes(Routes.Account)
        ? ["0"]
        : location.pathname.includes(Routes.Consent)
          ? ["1"]
          : location.pathname.includes(Routes.UserActivity)
            ? ["2"]
            : location.pathname.includes(Routes.Grievance)
              ? ["3"]
              : location.pathname.includes(Routes.Settings)
                ? ["4"]
                : ["1"]
    );
  }, [location.pathname]);

  const onMenuItemSelect = (e: any) => {
    setActiveKey([e.key]);
    setCollapsed ? setCollapsed(true) : <></>;
  };

  const menuBarItems = [
    {
      name: t(translation.lblAccounts),
      route: Routes.Account,
      icon: (
        <AccountsIcon
          className={`${activeKey[0] === "0" ? themeL.menubarSelectIcon : themeL.menubarIcon
            } menubarIcon fill`}
        />
      ),
    },
    {
      name: t(translation.lblConsents),
      route: Routes.Consent,
      icon: (
        <ConsentsIcon
          className={`${activeKey[0] === "1" ? themeL.menubarSelectIcon : themeL.menubarIcon
            } menubarIcon stroke`}
        />
      ),
    },
    {
      name: t(translation.lblHistory),
      route: Routes.UserActivity,
      icon: (
        <HistoryIcon
          className={`${activeKey[0] === "2" ? themeL.menubarSelectIcon : themeL.menubarIcon
            } menubarIcon stroke`}
        />
      ),
    },
    {
      name: t(translation.lblHelpCenter),
      route: Routes.Grievance,
      icon: (
        <HelpCenterIcon
          className={`${activeKey[0] === "3" ? themeL.menubarSelectIcon : themeL.menubarIcon
            } menubarIcon stroke`}
        />
      ),
    },
    {
      name: t(translation.lblSettings),
      route: Routes.Settings,
      icon: (
        <SettingsIcon
          className={`${activeKey[0] === "4" ? themeL.menubarSelectIcon : themeL.menubarIcon
            } menubarIcon stroke`}
        />
      ),
    },
  ];
  const theme = useTheme();
  const logoutUser = () => {
    signOut(getSessionState() ?? "").finally(() => {
      clearCredLocalStorage();
      history.push(Routes.Login);
      notification.open({
        className: `${theme.infoAlert} alertClass`,
        icon: <img alt="" src={theme.infoIcon} className="alertIcon" />,
        placement: "top",
        message: t(translation.succesLogout),
        duration: 5,
      });
    });
  };

  const navLink = (item: any, index: number) => {
    return renderNavLink(item, index, activeKey, themeL);
  };

  return (
    <div
      className={`${themeL.backgroundColor} subClass webMenuBar ${themeL.menuBorderRight} `}
    >
      <Menu
        className={`${themeL.backgroundColor} menubarRoot `}
        onSelect={onMenuItemSelect}
        mode="inline"
      >
        <div className="brandingDivContainer">
          <div className="brandingDiv">
            <img
              alt=""
              src={currentTheme === "dark" ? saafeDark : saafeLight}
              height={40}
            />
          </div>
          {setCollapsed ? (
            <Button
              onClick={() => setCollapsed(true)}
              className="menuCloseIcon"
            >
              <CloseOutlined />
            </Button>
          ) : (
            <></>
          )}
        </div>

        {menuBarItems.map((item, index) => (
          <Menu.Item
            className={`${activeKey[0] === index.toString() ? themeL.menubarSelect : ""
              } menuTextColor menubar`}
            key={index.toString()}
            icon={item.icon}
          >
            {navLink(item, index)}
          </Menu.Item>
        ))}
      </Menu>

      <div className={`${themeL.menuDividerColor} userInfo`}>
        <div>
          <div
            className={`${themeL.menuUserBackgroundColor} ${themeL.menuUserFontColor} userInitials`}
          >
            {user &&
              user.firstName.charAt(0).toUpperCase() +
              user.lastName.charAt(0).toUpperCase()}
          </div>
          <div className="userInfoContainer">
            <div className={`${themeL.textTheme.headline3} userName`}>
              {user && user.firstName + " " + user.lastName}
            </div>
            <div className={`${themeL.hintColor}`}>
              {user && user.mobileNumber}
            </div>
          </div>
        </div>
        <img
          className={`${themeL.hintColor} logoutUserIcon`}
          onClick={logoutUser}
          alt=""
          src={logout}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.user,
  currentTheme: state.themes.theme,
});

export default connect(mapStateToProps)(Menubar);
