import axios from "axios";
import {
  Decode,
  Encode,
  EventsRequest,
} from "../../Models/Request/Redirection/Redirection";

export function decode(body: Decode) {
  return axios.post("/public/decode", body);
}

export function encode(body: Encode) {
  return axios.post("/public/encode", body);
}

export function metaInformation(body: any) {
  return axios.post("/public/user/info", body);
}

export function storeEvents(data: EventsRequest) {
  return axios.post("/public/events", data);
}
