import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../App";
import { translation } from "../../../../i18n/translation";
import { LoginSignupFormType } from "../../../../Utils/Constants/loginSignupFormTypes";
import "./index.css";

interface IPinInputComponentProps {
  onChange?: (event: string) => void;
  className?: string;
  label?: string;
  id?: string;
  enterPressed?: () => void;
  nameField?: string;
  customRule?: any;
  addonBefore?: ReactNode;
  maxLength?: number;
  disabled?: boolean;
}

const PinInputComponent: React.FC<IPinInputComponentProps> = ({
  className,
  label,
  id,
  enterPressed,
  onChange,
  nameField,
  customRule,
  addonBefore,
  maxLength,
  disabled,
}) => {
  const theme = useTheme();
  const onPasswordChange = (e: any) => {
    e.target.value = e.target.value.replace(/[^0-9]/gi, "");
  };
  const { t } = useTranslation();
  return (
    <Form.Item
      className={`${className} ${theme.formItemMarginBottom}`}
      name={nameField ? nameField : LoginSignupFormType.Pin}
      rules={[
        {
          required: true,
          message: t(translation.errorEnterPin),
        },
        {
          len: 6,
          message: t(translation.errorInvalidPin),
        },
        {
          validator: customRule,
        },
      ]}
    >
      <Input.Password
        addonBefore={addonBefore}
        type="password"
        autoComplete="off"
        id={id}
        iconRender={(visible) =>
          visible ? (
            <EyeOutlined style={{ color: theme.hintColorStyle }} />
          ) : (
            <EyeInvisibleOutlined style={{ color: theme.hintColorStyle }} />
          )
        }
        disabled={disabled}
        inputMode="numeric"
        maxLength={maxLength ? maxLength : 6}
        placeholder={label ? label : t(translation.lblEnterPin)}
        onPressEnter={enterPressed}
        onInput={onPasswordChange}
        onChange={(event: any) => onChange && onChange(event.target.value)}
        className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} ${theme.formItem} formItemPinInput`}
      />
    </Form.Item>
  );
};

export default PinInputComponent;
