import { Form, Input, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserRespose } from "redux/Reducer/userReducer";
import { useTheme } from "../../App";
import { translation } from "../../i18n/translation";
import "./index.css";

enum fromFields {
  FirstName = "firstName",
  LastName = "lastName",
}

interface IProfileProps {
  updateValues: (type: string, value: any) => void;
  onblur: () => void;

  changedValues: {
    firstName: string;
    lastName: string;
  };
  user: UserRespose;
}

const Profile: React.FC<IProfileProps> = ({
  updateValues,
  onblur,
  changedValues,
  user,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    updateValues(fromFields.FirstName, user.firstName);
    updateValues(fromFields.LastName, user.lastName);
    updateValues("default", {
      firstName: user.firstName,
      lastName: user.lastName,
    });
  }, [user, updateValues]);

  const updateUserInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.id === fromFields.FirstName
      ? updateValues(fromFields.FirstName, event.target.value)
      : updateValues(fromFields.LastName, event.target.value);
  };

  const updateUserData = () => {
    onblur();
  };
  const onNameChange = (e: any) => {
    if (e.target.value.length <= 1) {
      e.target.value = e.target.value.toUpperCase();
    }
    e.target.value = e.target.value.replace(/[^A-Za-z]+/g, "");
  };

  const theme = useTheme();
  const [form] = Form.useForm();

  return (
    <>
      <Form
        className="profileForm"
        form={form}
        layout="inline"
        name="basic"
        autoComplete="off"
        scrollToFirstError={true}
      >
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: t(translation.lblRequiredField),
            },
          ]}
        >
          <div>
            <Typography.Text className={`${theme.authFormLabels} inputLabels`}>
              {t(translation.lblFirstName)}
            </Typography.Text>

            <Input
              className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} profileInputs`}
              onInput={onNameChange}
              id="firstName"
              value={changedValues.firstName}
              onChange={updateUserInfo}
              onBlur={updateUserData}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: t(translation.lblRequiredField),
            },
          ]}
        >
          <div>
            <Typography.Text className={`${theme.authFormLabels} inputLabels`}>
              {t(translation.lblLastName)}
            </Typography.Text>

            <Input
              className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} profileInputs`}
              onInput={onNameChange}
              id="lastName"
              value={changedValues.lastName}
              onChange={updateUserInfo}
              onBlur={updateUserData}
            />
          </div>
        </Form.Item>
      </Form>
      <div className="inputContainer">
        <Typography.Text className={`${theme.authFormLabels} inputLabels`}>
          {t(translation.lblUserName)}
        </Typography.Text>
        <Input
          className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} profileInputs cursorDefault`}
          id="vuaid"
          value={user.vuaId}
          readOnly
        />
      </div>
      <div className="inputContainer"></div>
    </>
  );
};

export default Profile;
