import { Typography } from "antd";
import React from "react";
import "./index.css";

const ConsentStatus: React.FC<{ status: string; className: string }> = ({
  status,
  className,
}) => {
  return (
    <Typography.Text className={`${className} consentStatus`}>
      {status}
    </Typography.Text>
  );
};

export default ConsentStatus;
