import {
  DecodeResponse,
  metaResponse,
} from "Models/Response/Redirection/redirection";

export enum storageConstants {
  VuaId = "vuaId",
  RefreshToken = "refresh-token",
  PhoneNumber = "phoneNumber",
  Pan = "pan",
  Dob = "dob",
  AccessToken = "access-token",
  UserId = "userid",
  SessionId = "sessionid",
  Redirect = "redirect",
  SrcRef = "srcref",
  TxnId = "txnid",
  FipId = "fipid",
  FiuId = "fiuid",
  FiuName = "fiuname",
  RedirectionVersion = "redirectionversion",
  FiuCustomStyles = "fiuCustomStyles",
  OtherAppCustomization = "otherAppCustomization",
  IsAutoDiscoveryEnabled = "isAutoDiscoveryEnabled",
  Theme = "theme",
  I18nextLng = "i18nextLng",
  Session_state = "session_state",
  TrackingId = "trackingId",
  ExpiryTime = "sessionExpiry",
  FiTypesRequiredForConsent = "fiTypesRequiredForConsent"
}

export function setThemeStorage(theme: string) {
  localStorage.setItem(storageConstants.Theme, theme);
}

export function setExpiryTime(expiry: string) {
  sessionStorage.setItem(storageConstants.ExpiryTime, expiry);
}

export function getExpiryTime() {
  return sessionStorage.getItem(storageConstants.ExpiryTime);
}

export function getThemeStorage() {
  return localStorage.getItem(storageConstants.Theme);
}

export function setLocale(lang: string) {
  localStorage.setItem(storageConstants.I18nextLng, lang);
}

export function getLocale() {
  return localStorage.getItem(storageConstants.I18nextLng);
}

export function setAccessToken(accessToken: string) {
  localStorage.setItem(storageConstants.AccessToken, accessToken);
}

export function setPhoneNumber(phoneNumber: string) {
  sessionStorage.setItem(storageConstants.PhoneNumber, phoneNumber);
}

export function setPan(pan: string) {
  sessionStorage.setItem(storageConstants.Pan, pan);
}

export function setDob(dob: string) {
  sessionStorage.setItem(storageConstants.Dob, dob);
}

export function getPhoneNumber() {
  return sessionStorage.getItem(storageConstants.PhoneNumber);
}

export function getPan() {
  return sessionStorage.getItem(storageConstants.Pan);
}

export function getDob() {
  return sessionStorage.getItem(storageConstants.Dob);
}

export function setRefreshToken(refreshToken: string) {
  localStorage.setItem(storageConstants.RefreshToken, refreshToken);
}

export function setVuaId(vuaId: string) {
  localStorage.setItem(storageConstants.VuaId, vuaId);
}

export function getLocalStorageItem(item: string) {
  return localStorage.getItem(item);
}

export function setCredentialsLocalStorage(res: any) {
  setAccessToken(res.access_token);
  setRefreshToken(res.refresh_token);
  setVuaId(res.vuaId);
  setSessionState(res.session_state);

  var tTime = new Date();
  tTime.setSeconds(tTime.getSeconds() + parseInt(res.refresh_expires_in));
  setExpiryTime(tTime.toString());
}

export function clearCredLocalStorage() {
  localStorage.removeItem(storageConstants.AccessToken);
  localStorage.removeItem(storageConstants.RefreshToken);
  localStorage.removeItem(storageConstants.VuaId);
  localStorage.removeItem(storageConstants.Session_state);
}

export function setSessionState(sessionState: string) {
  localStorage.setItem(storageConstants.Session_state, sessionState);
}

export function getSessionState() {
  return localStorage.getItem(storageConstants.Session_state);
}

export function setUserId(userId: string) {
  sessionStorage.setItem(storageConstants.UserId, userId);
}

export function getUserId() {
  return sessionStorage.getItem(storageConstants.UserId);
}

export function setSessionId(sessionId: string) {
  sessionStorage.setItem(storageConstants.SessionId, sessionId);
}

export function getSessionId() {
  return sessionStorage.getItem(storageConstants.SessionId);
}

export function setRedirect(redirect: string) {
  sessionStorage.setItem(storageConstants.Redirect, redirect);
}

export function getRedirect() {
  return sessionStorage.getItem(storageConstants.Redirect);
}

export function setSrcRef(srcRef: string) {
  sessionStorage.setItem(storageConstants.SrcRef, srcRef);
}

export function getSrcRef() {
  return sessionStorage.getItem(storageConstants.SrcRef);
}

export function setTxnId(txnId: string) {
  sessionStorage.setItem(storageConstants.TxnId, txnId);
}

export function getTxnId() {
  return sessionStorage.getItem(storageConstants.TxnId);
}

export function setFipId(fipId?: string) {
  sessionStorage.setItem(storageConstants.FipId, fipId ? fipId : "");
}

export function getFipId() {
  return sessionStorage.getItem(storageConstants.FipId);
}

export function setFiuId(fiuId: string) {
  sessionStorage.setItem(storageConstants.FiuId, fiuId);
}

export function getFiuId() {
  return sessionStorage.getItem(storageConstants.FiuId);
}

export function setFiTypesRequiredForConsent(FiTypesRequiredForConsent: any) {
  sessionStorage.setItem(storageConstants.FiTypesRequiredForConsent, FiTypesRequiredForConsent);
}

export function getFiTypesRequiredForConsent() {
  return sessionStorage.getItem(storageConstants.FiTypesRequiredForConsent);
}


export function setFiuName(fiuName: string) {
  sessionStorage.setItem(storageConstants.FiuName, fiuName);
}

export function getFiuName() {
  return sessionStorage.getItem(storageConstants.FiuName);
}

export function setFiuCustomStyles(customStyles: string) {
  sessionStorage.setItem(storageConstants.FiuCustomStyles, customStyles);
}

export function getFiuCustomStyles() {
  return sessionStorage.getItem(storageConstants.FiuCustomStyles);
}

export function setOtherAppCustomization(otherAppCustomization: string) {
  sessionStorage.setItem(
    storageConstants.OtherAppCustomization,
    otherAppCustomization
  );
}

export function getOtherAppCustomization() {
  return sessionStorage.getItem(storageConstants.OtherAppCustomization);
}

export function setRedirectionVersion(redirectionVersion: string) {
  sessionStorage.setItem(
    storageConstants.RedirectionVersion,
    redirectionVersion
  );
}

export function getRedirectionVersion() {
  return sessionStorage.getItem(storageConstants.RedirectionVersion);
}

export function setIsAutoDiscoveryEnabled(isAutoDiscovery: string) {
  sessionStorage.setItem(
    storageConstants.IsAutoDiscoveryEnabled,
    isAutoDiscovery
  );
}

export function getIsAutoDiscoveryEnabled() {
  return sessionStorage.getItem(storageConstants.IsAutoDiscoveryEnabled);
}

export function setDecryptedCredential(res: DecodeResponse) {
  setUserId(res.userid);
  setPhoneNumber(res.phoneNumber);
  if (res.pan && res.pan !== null) {
    setPan(res.pan);
  }
  if (res.dob && res.dob !== null) {
    setDob(res.dob);
  }
  setRedirect(res.redirect);
  setSrcRef(res.srcref);
  setSessionId(res.sessionid);
  setTxnId(res.txnid);
  setFipId(res.fipId);
  setFiuId(res.fiuId);
  setRedirectionVersion(res.redirectionVersion);
  setFiuName(res.fiuName);
  setFiTypesRequiredForConsent(res.fiTypesRequiredForConsent);
  if (res.customization) {
    if (res.customization.fiu_custom_styles)
      setFiuCustomStyles(res.customization.fiu_custom_styles);
    if (res.customization.otherAppCustomization)
      setOtherAppCustomization(res.customization.otherAppCustomization);
  }
  setIsAutoDiscoveryEnabled(res.isAutoDiscoveryEnabled.toString());
}

export function setTrackingId(trackingId: string) {
  sessionStorage.setItem(storageConstants.TrackingId, trackingId);
}

export function setMetaDataResponse(res: metaResponse) {
  setTrackingId(res.trackingId);
}

export function getTrackingId() {
  return sessionStorage.getItem(storageConstants.TrackingId);
}
