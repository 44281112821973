export const team_address = () => {
  return (
    <>
      Customer Service Team
      <br />
      Dashboard Account Aggregation Services Private Limited,
      <br />
      Workafella, New No. 431, Teynampet,
      <br />
      Anna Salai Chennai - 600018
      <br />
    </>
  );
};
export const officer_address = () => {
  return (
    <>
      Customer Grievance Redressal Officer
      <br />
      Dashboard Account Aggregation Services Private Limited,
      <br />
      Workafella, New No. 431, Teynampet,
      <br />
      Anna Salai Chennai - 600018
      <br />
    </>
  );
};
export const email_general = "general@saafe.in";
export const email_officer = "vijayan@saafe.in";
export const mobile_number = "7845148999";
