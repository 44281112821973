import { getTheme } from "../../Utils/ThemeUtils";

const initState = {
  theme: getTheme(),
};

export default function theme(state = initState, action: any) {
  switch (action.type) {
    case "CHANGE_THEME":
      return {
        ...state,
        theme: action.payload.theme,
      };
    default:
      return state;
  }
}
