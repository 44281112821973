import axios from "axios";

export function getAllUserActivityNotification(page: number, size: number) {
  return axios.get(`/audit?page=${page}&size=${size}&sort=DESC`);
}

// export function getUserActivityByConsent(page: number, size: number) {
//   return axios.get(`/audit/consent?page=${page}&size=${size}`);
// }

// export function getUserActivityByAccount(page: number, size: number) {
//   return axios.get(`/audit/account?page=${page}&size=${size}`);
// }

export function markUserActivityAsRead(body: any) {
  return axios.put(`/audit`, body);
}

export function getAllUserActivity(
  page: number,
  size: number,
  type: string,
  sort: string
) {
  return axios.get(`/audit${type}?page=${page}&size=${size}&sort=${sort}`);
}

export function getAllFIUByUser() {
  return axios.get(`/User/fiu`);
}

export function getAllFIPByUser() {
  return axios.get(`/User/linked/fip`);
}

export function getAllConsentsByFIU(fiu: string, page: number, size: number) {
  return axios.get(`/User/consents/fiu/${fiu}?page=${page}&size=${size}`);
}

export function getAllAccountsByFIP(fip: string, page: number, size: number) {
  return axios.get(`/User/accounts/fip/${fip}?page=${page}&size=${size}`);
}

export function getUserSpecificActivity(
  page: number,
  size: number,
  entityId: string,
  entityType: string
) {
  const url = `/audit/single?page=${page}&size=${size}&entityId=${entityId}&entityType=${entityType}`;

  return axios.get(url);
}
