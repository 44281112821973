import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../../App";
import { SelectedAccounts } from "../../../../../Models/Request/Account/AccountLink";
import { GetConsentByIdResponse } from "../../../../../Models/Response/Consent/GetConsentById";
import { translation } from "../../../../../i18n/translation";
import CommonConsentDetails from "../../CommonConsentDetails";
import "../index.css";

interface IPendingConsentProps {
  consentDetailsByHandle: GetConsentByIdResponse;
  type: string;
  approvalConsent: (type: string, selectedCard: SelectedAccounts[]) => void;
  rejectConsent: (type: string) => void;
  consentUniqueId: string;
}

const PendingConsent: React.FC<IPendingConsentProps> = ({
  approvalConsent,
  consentDetailsByHandle,
  type,
  rejectConsent,
  consentUniqueId,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedCard, setSelectedCard] = useState(
    new Array<SelectedAccounts>()
  );

  const onSubmit = () => {
    approvalConsent("READY", selectedCard);
  };

  const onReject = () => {
    rejectConsent("FAILED");
  };

  return (
    <>
      <div className="pendingConsenRoot">
        <CommonConsentDetails
          type={type}
          consentByUniqueIdDetails={consentDetailsByHandle}
          consentUniqueId={consentUniqueId}
          onChange={setSelectedCard}
        />
      </div>
      <div className="buttonGroupRoot">
        <div className={`${theme.menuDividerColor} activeButtonGroup`}>
          <Button
            onClick={onReject}
            type="text"
            className={`${theme.hintColor} subClass subButton`}
          >
            {t(translation.lblReject)}
          </Button>
          <Button
            disabled={selectedCard.length === 0}
            className={`${theme.textTheme.authButton} ${theme.formItemMarginBottom} borderNoneHeight50 priButton`}
            onClick={onSubmit}
          >
            {t(translation.lblAcceptConsent)}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PendingConsent;
