import { Breadcrumb } from "antd";
import React from "react";
import { getRedirect, getSessionId, getTxnId } from "Services/StorageServices";
import { useTheme } from "App";
import { useHistory } from "react-router-dom";

interface BreadCrumbsProps {
  breadcrumbItems: { route: string; label: string; step?: number }[];
  onBreadcrumSelect?: (stepnumber: number) => void;
  className?: string;
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({
  breadcrumbItems,
  onBreadcrumSelect: handleBreadcrumSelect,
  className,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const handleOnclick = (route: string, step: number) => {
    if (handleBreadcrumSelect && step) {
      handleBreadcrumSelect(step - 1);
    } else {
      history.push({ pathname: route });
    }
  };
  return (
    <>
      {!!getRedirect() && !!getTxnId() && !!getSessionId() ? (
        <></>
      ) : (
        <Breadcrumb
          separator=">"
          className={`${theme.breadCrumbColor} ${className}`}
        >
          {breadcrumbItems.map((breadcrumbItem: any, index: number) => {
            return (
              <Breadcrumb.Item
                key={index}
                onClick={() => {
                  if (index + 1 !== breadcrumbItems.length)
                    handleOnclick(breadcrumbItem.route, breadcrumbItem?.step);
                }}
              >
                {breadcrumbItem.label}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      )}
    </>
  );
};

export default BreadCrumbs;
