import { EventsRequest } from "Models/Request/Redirection/Redirection";
import { storeEvents } from "Services/RedirectionServices/Redirection";
import {
  getExpiryTime,
  getFipId,
  getFiuId,
  getSessionId,
  getSrcRef,
  getTrackingId,
  getTxnId,
  getUserId,
} from "Services/StorageServices";
import { RedirectionEvents } from "./types/RedirectionEvents";

const sendEvents = (eventCode: RedirectionEvents, message: string = "") => {
  if (!!getTrackingId()) {
    const data: EventsRequest = {
      eventCode: eventCode,
      eventMessage: message,
      sessionId: getSessionId() ?? "",
      vuaId: getUserId() ?? "",
      consentHandle: getSrcRef()?.split(",") ?? [],
      fiuId: getFiuId() ?? "",
      fipId: getFipId() ?? "",
      trackingId: getTrackingId() ?? "",
      txnId: getTxnId() ?? "",
      sessionExpiry: getExpiryTime() ?? "",
    };

    storeEvents(data);
  }
};

export default sendEvents;
