import { Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../App";
import "./index.css";

interface TicketStatusProps {
  status: string;
}

const TicketStatus: React.FC<TicketStatusProps> = ({ status }) => {
  const { t } = useTranslation();
  const ticketStatus =
    status === "RESOLVED"
      ? "SOLVED"
      : status === "CREATED"
      ? "PENDING"
      : status;
  const theme = useTheme();
  return (
    <Typography.Text
      className={`${
        ticketStatus === "PENDING"
          ? theme.warningStatus
          : ticketStatus === "SOLVED"
          ? theme.successStatus
          : theme.staleStatus
      } ${
        ticketStatus === "PENDING"
          ? theme.warningStatusBG
          : ticketStatus === "SOLVED"
          ? theme.successStatusBG
          : theme.staleStatusBG
      } ticketStatusText`}
    >
      {t(_.startCase(ticketStatus.toLowerCase()))}
      {/* todo change key */}
    </Typography.Text>
  );
};

export default TicketStatus;
