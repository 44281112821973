import { notification } from "antd";
import axios, { AxiosError } from "axios";
import { UserLoginSuccessResponse } from "Models/Response/Authentication/Otp";
import {
  endRefreshToken,
  pauseLoader,
  startLoader,
  startRefreshToken,
} from "redux/actions";
import store from "redux/store";
import { getAccessToken } from "Services/AuthenticationServices/AuthenticationServices";
import {
  getLocalStorageItem,
  getTrackingId,
  setAccessToken,
  setRefreshToken,
  setVuaId,
  storageConstants,
} from "Services/StorageServices";
import { themes } from "Theme";
import { baseUrl } from "Utils/Constants/baseUrl";
import { QueryParams, Routes } from "Utils/Constants/routes";
import { apiTimeout } from "Utils/Constants/ServerTimeout";

const interceptor = (history: any) => {
  let requestsToRefresh: any[] = [];
  axios.defaults.baseURL = baseUrl;
  axios.interceptors.request.use(
    (request) => {
      if (request.url !== "/public/events") {
        store.dispatch(startLoader());
      }
      if (request.data === undefined) {
        request.data = {};
      }
      request.headers = {
        ...request.headers,
        "Content-Type": "application/json",
        trackingId: `${getTrackingId()}`,
        appVersion: `WEB${store.getState().version.version}`,
      };

      if (
        getLocalStorageItem(storageConstants.AccessToken) &&
        !request.url?.includes("public") &&
        !request.url?.includes("/User/forgetPassword")
      ) {
        request.headers = {
          ...request.headers,
          Authorization: `Bearer ${getLocalStorageItem(
            storageConstants.AccessToken
          )}`,
        };
      }

      request.timeout = apiTimeout;

      return request;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      const originalRequest: any = response.config;
      if (originalRequest.url !== "/public/events") {
        store.dispatch(pauseLoader());
      }

      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error: AxiosError) {
      store.dispatch(pauseLoader());
      const originalRequest: any = error.config;
      if (
        originalRequest.url === "/public/user/refreshtoken" ||
        originalRequest.url === "/public/version" ||
        originalRequest.url.includes("public/events")
      ) {
        return Promise.reject(error);
      }
      if (error.response?.status === 401) {
        if (
          originalRequest.url !== "/public/user/verify-otp" &&
          originalRequest.url !== "/public/user/login" &&
          originalRequest.url !== "/public/user/send-otp" &&
          originalRequest.url !== "/public/user/signup/verify" &&
          originalRequest.url !== "/public/user/signup/mobile/verify" &&
          originalRequest.url !==
            "/public/user/signup/mobile/verify/redirection"
        ) {
          if (!store.getState().refreshToken.isRefreshTokenFetching) {
            // updates the state in store so other failed API with 401 error doesnt get to call the refresh token request
            store.dispatch(startRefreshToken());

            getAccessToken(getLocalStorageItem(storageConstants.RefreshToken))
              .then((res) => res.data)
              .then((res: UserLoginSuccessResponse) => {
                setAccessToken(res.access_token);
                requestsToRefresh.forEach((cb) => cb(res.access_token));
              })
              .catch((refreshTokenError) => {
                setAccessToken("");
                setRefreshToken("");
                setVuaId("");
                notification.open({
                  icon: (
                    <img
                      alt=""
                      src={
                        store.getState().themes.theme === "light"
                          ? themes.light.infoIcon
                          : themes.dark.infoIcon
                      }
                      className="alertIcon"
                    />
                  ),

                  placement: "top",
                  className: `${
                    store.getState().themes.theme === "light"
                      ? themes.light.infoAlert
                      : themes.dark.infoAlert
                  } alertClass`,
                  message: "Session Timeout",
                  description: "",
                  duration: 5,
                });
                history.push({
                  pathname: Routes.Login,
                  search:
                    history.location.pathname !== Routes.Login
                      ? `?${QueryParams.Redirect}=${history.location.pathname}`
                      : undefined,
                });
                requestsToRefresh.forEach((cb) => cb(null));
                return Promise.reject(refreshTokenError);
              })
              .finally(() => {
                requestsToRefresh = [];
                // updates the store state to indicate the there is no current refresh token request and/or the refresh token request is done and there is updated data in session storage
                store.dispatch(endRefreshToken());
              });
          }
          // if there is a current refresh token request, it waits for that to finish and use the updated token data to retry the API call so there will be no Additional refresh token request
          return new Promise((resolve, reject) => {
            requestsToRefresh.push((token: string) => {
              if (token) {
                originalRequest.headers.Authorization = "Bearer " + token;
                resolve(axios(originalRequest));
              }
              reject(error);
            });
          });
        }
      } else if (error.code === "ECONNABORTED") {
        notification.open({
          icon: (
            <img
              alt=""
              src={
                store.getState().themes.theme === "light"
                  ? themes.light.infoIcon
                  : themes.dark.infoIcon
              }
              className="alertIcon"
            />
          ),
          placement: "top",
          className: `${
            store.getState().themes.theme === "light"
              ? themes.light.infoAlert
              : themes.dark.infoAlert
          } alertClass`,
          message: "Server Timeout",
          duration: 5,
        });
      } else if (
        !(
          error.response?.data.errorCode &&
          (error.response?.data.errorCode === "Incorrect Otp" ||
            error.response?.data.errorCode === "Incorrect otp" ||
            error.response?.data.errorCode === "Invalid Otp" ||
            error.response?.data.errorCode === "pin is not valid")
        )
      ) {
        notification.open({
          icon: (
            <img
              alt=""
              src={
                store.getState().themes.theme === "light"
                  ? themes.light.infoIcon
                  : themes.dark.infoIcon
              }
              className="alertIcon"
            />
          ),
          placement: "top",
          className: `${
            store.getState().themes.theme === "light"
              ? themes.light.infoAlert
              : themes.dark.infoAlert
          } alertClass`,
          message: error.response?.data.errorCode
            ? error.response?.data.errorCode
            : "Something went wrong",
          description: error.response?.data.errorMsg
            ? error.response?.data.errorMsg
            : "",
          duration: 5,
        });
      }
      if (error.response?.data.redirectUrl) {
        window.location.replace(error.response?.data.redirectUrl);
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
};

export default interceptor;
