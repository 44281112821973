export enum RedirectionEvents {
  Meta_Information_Success = "Meta_Information_Success",
  Decryption_Success = "Decryption_Success",
  Decryption_Failure = "Decryption_Failure",
  Waiting_For_Otp_Input = "Waiting_For_Otp_Input",
  Init_Login = "Init_Login",
  Init_Login_Success = "Init_Login_Success",
  Init_Login_Failure = "Init_Login_Failure",
  Init_Signup = "Init_Signup",
  Init_Signup_Success = "Init_Signup_Success",
  Init_Signup_Failure = "Init_Signup_Failure",
  Init_Login_Verification = "Init_Login_Verification",
  Login_Verification_Success = "Login_Verification_Success",
  Login_Verification_Failure = "Login_Verification_Failure",
  Init_Signup_Verification = "Init_Signup_Verification",
  Signup_Verification_Success = "Signup_Verification_Success",
  Signup_Verification_Failure = "Signup_Verification_Failure",
  Init_Account_Discovery = "Init_Account_Discovery",
  Init_Auto_Account_Discovery = "Init_Auto_Account_Discovery",
  Account_Discovery_Success = "Account_Discovery_Success",
  No_Accounts_Found = "No_Accounts_Found",
  Account_Discovery_Failure = "Account_Discovery_Failure",
  Init_Account_Link = "Init_Account_Link",
  Init_Account_Link_Success = "Init_Account_Link_Success",
  Init_Account_Link_Failure = "Init_Account_Link_Failure",
  Init_Account_Link_Verification = "Init_Account_Link_Verification",
  Account_Link_Failure = "Account_Link_Failure",
  Account_Link_Success = "Account_Link_Success",
  Consent_Details_View = "Consent_Details_View",
  Init_Consent_Approve = "Init_Consent_Approve",
  Init_Consent_Reject = "Init_Consent_Reject",
  Consent_Approved = "Consent_Approved",
  Consent_Rejected = "Consent_Rejected",
  Consent_Reject_Failed = "Consent_Reject_Failed",
  Consent_Approval_Failed = "Consent_Approval_Failed",
  Consent_Confirmation_Modal_Button_Clicked = "Consent_Confirmation_Modal_Button_Clicked",
  Consent_Confirmation_Back_Button_Clicked = "Consent_Confirmation_Back_Button_Clicked",
  Session_Timeout = "Session_Timeout",
  Encode_Parameters = "Encode_Parameters",
  Redirecting_To_Fiu = "Redirecting_To_Fiu",
  Resend_Login_Verification = "Resend_Login_Verification",
  Get_Fips = "Get_Fips",
  Resend_Signup_Verification = "Resend_Signup_Verification",
}
