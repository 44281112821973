const initState = {
  version: "",
};

export default function versionReducer(state = initState, action: any) {
  switch (action.type) {
    case "STORE_VERSION":
      return {
        version: action.payload.version,
      };
    default:
      return state;
  }
}
