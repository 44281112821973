import { SelectedAccounts } from "Models/Request/Account/AccountLink";
import { DiscoverAccountObject } from "Models/Response/Account/DiscoverAccount";
import LinkedDelinkedAccountResponse, {
  LinkedDelinkedAccountObject,
} from "Models/Response/Account/LinkedDelinkedAccounts";
import UpdatedConsentJourney from "Pages/Accounts/Components/UpdateConsentApproval";
import { getLinkedAccountsOfUser } from "Services/AccountsServices/AccountService";
import { getFipId } from "Services/StorageServices";
import React, { useEffect, useState } from "react";
import "./index.css";

interface Props {
  onOk: (selectedCard: SelectedAccounts[]) => void;
  consentHandle: string;
}

export interface AutoDiscoveryMap {
  [fipId: string]: AutoDiscoveryMapAccounts;
}

interface AutoDiscoveryMapAccounts {
  fipName: string;
  accounts: DiscoverAccountObject[];
}

const SelecteAccounts: React.FC<Props> = ({ consentHandle, onOk }) => {
  const fipId = getFipId();
  const [linkedAccounts, setLinkedAccounts] = useState(
    new Array<LinkedDelinkedAccountObject>()
  );

  const mapToLinkedRefNumberAndFIPHandle = (selectedCard: string[]) => {
    const accounts: SelectedAccounts[] = linkedAccounts
      .filter(
        (account) => selectedCard.indexOf(account.accountRefNumber) !== -1
      )
      .map((account) => {
        return {
          linkRefNumber: account.linkRefNumber,
          fipHandle: account.fipHandle,
          maskedNumber: account.maskedAccNumber,
        };
      });
    onOk(accounts);
  };

  const [linkedAccountsToBeShown, setLinkedAccountsToBeShown] = useState<any>(
    {}
  );

  useEffect(() => {
    getLinkedAccountsOfUser(consentHandle, fipId ? fipId : undefined)
      .then((res) => res.data)
      .then((res: LinkedDelinkedAccountResponse) => {
        setLinkedAccounts(res);
        let t: AutoDiscoveryMap = {};
        res.forEach((e) => {
          if (t[e.fipHandle]) {
            t = {
              ...t,
              [e.fipHandle]: {
                ...t[e.fipHandle],
                accounts: [
                  ...t[e.fipHandle].accounts,
                  {
                    maskedAccNumber: e.maskedAccNumber,
                    accRefNumber: e.accountRefNumber,
                    accType: e.accountType,
                    FIType: e.fiType,
                  },
                ],
              },
            };
          } else {
            t = {
              ...t,
              [e.fipHandle]: {
                fipName: e.fipName,
                accounts: [
                  {
                    maskedAccNumber: e.maskedAccNumber,
                    accRefNumber: e.accountRefNumber,
                    accType: e.accountType,
                    FIType: e.fiType,
                  },
                ],
              },
            };
          }
        });
        setLinkedAccountsToBeShown(t);
      });
  }, [consentHandle, fipId]);

  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        <div style={{ marginTop: 0 }}>
          <UpdatedConsentJourney
            autoDiscoverAccountsMap={linkedAccountsToBeShown}
            consentHandle={consentHandle}
            onChange={mapToLinkedRefNumberAndFIPHandle}
          />
        </div>
      </div>
    </>
  );
};

export default SelecteAccounts;
