export const validEmail = new RegExp(
  "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
);
export const validAadhar = new RegExp("^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$");
export const validAccNo = new RegExp("^[0-9]{9,18}$");
export const validMobile = new RegExp("^([0-9]{10}$)");
export const validCRN = new RegExp("^[0-9]{9}[A-Z]$");
export const validPPAN = new RegExp("^([0-9]{16}$)");
export const validOther = new RegExp("^([a-zA-Z0-9]{1,20})$");
export const validPan = new RegExp("[A-Z]{5}[0-9]{4}[A-Z]{1}$");
