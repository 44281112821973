import { notification } from "antd";
import { useTheme } from "App";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { redirectToUrl } from "Services/UrlShortenerService/UrlShortener";

const UrlShortener: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    const url = history.location.pathname;
    const pathArray = url.split("/");
    const urlId = pathArray[pathArray.length - 1];
    redirectToUrl(urlId).then((res) => {
      if (res.data?.status === "SUCCESS") {
        window.location.replace(res.data.url);
        return;
      } else {
        notification.open({
          duration: 5,
          icon: <img alt="" src={theme.warningIcon} className="alertIcon" />,
          className: `${theme.warningAlert} alertClass`,
          placement: "top",
          message: "Link has been expired",
        });
      }
    });
  }, [theme, history.location]);

  return (
    <>
      <div>Loading...</div>
    </>
  );
};
export default UrlShortener;
