import axios from "axios";
import { baseUrl } from "Utils/Constants/baseUrl";

export function getUser() {
  return axios.get(`/User`);
}

export function updateUser(firstName: string, lastName: string) {
  return axios.put(`/User`, { firstName, lastName });
}

export function updatePin(oldPin: string, newPin: string) {
  return axios.put(`/User/pin`, { oldPin, newPin });
}

export function getVersion() {
  axios.defaults.baseURL = baseUrl;
  return axios.get("/public/version");
}
