import {
  AimOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  FilterOutlined,
  IssuesCloseOutlined,
  LinkOutlined,
  MinusCircleOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import {
  Card,
  Collapse,
  Empty,
  Image,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Skeleton,
  Statistic,
  Tabs,
  Tag,
  Timeline,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UserRespose } from "redux/Reducer/userReducer";
import { useTheme } from "App";
import default_bank from "Assets/ic_default_bank.png";
import { translation } from "i18n/translation";
import {
  getAllAccountsByFIP,
  getAllConsentsByFIU,
  getAllFIPByUser,
  getAllFIUByUser,
  getAllUserActivity,
  getUserSpecificActivity,
} from "../../Services/UserActivityServices/UserActivity";
import { logoBaseUrl } from "../../Utils/Constants/baseUrl";
import { ConsentStatusType } from "../../Utils/Constants/statusTypes";
import useINF from "../Common/Components/InfiniteScroll";
import TimeFromNow from "../Common/Components/TimeFromNow";
import { activityDescription, activityTitle } from "./constants";
import "./index.css";

const pageSize = 20;
const pageSizeRoot = 20;
const UserActivity: React.FC = () => {
  const loading: boolean = useSelector(
    (state: any) => state.loader.searchLoader
  );
  const { t } = useTranslation();
  const [userActivity, setUserActivity] = useState<any>({});
  const [page, setPage] = useState(1);
  const [pageRoot, setPageRoot] = useState(1);
  const [activeTab, setActiveTab] = useState("1");
  const [fiu, setFiu] = useState<any[]>([]);
  const [linkedFip, setLinkedFip] = useState<any[]>([]);
  const [selectedFiu, setSelectedFiu] = useState("");
  const [selectedLinkedFip, setSelectedLinkedFip] = useState("");
  const [consents, setConsents] = useState<any[]>([]);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [entityId, setEntityId] = useState("");
  const [sort, setSort] = useState("DESC");
  const theme = useTheme();
  const [hasMore, setHasMore] = useState(true);
  const [rootHasMore, setRootHasMore] = useState(true);
  const user: UserRespose = useSelector((state: any) => state.user.user);

  const observer = useRef<any>();
  const rootObserver = useRef<any>();
  const { newObserver, ref } = useINF(observer, hasMore, setPage);
  const rootInfiniteScroll = useINF(rootObserver, rootHasMore, setPageRoot);

  observer.current = newObserver.current;
  rootObserver.current = rootInfiniteScroll.newObserver.current;
  const rootRef = rootInfiniteScroll.ref;

  const returnLocalSortedUserActivity = useCallback(
    (res: any, old: any) => {
      const localUserActivity = { ...old };
      res.forEach((r: any) => {
        if (localUserActivity[moment(new Date(r.time)).format("Do MMM YYYY")]) {
          localUserActivity[
            moment(new Date(r.time)).format("Do MMM YYYY")
          ].push(r);
          localUserActivity[
            moment(new Date(r.time)).format("Do MMM YYYY")
          ].sort((a: any, b: any) => {
            if (a.time === b.time) {
              return a.time - b.time;
            }
            if (entityId.length > 0 || sort === "DESC") {
              return a.time < b.time ? 1 : -1;
            } else {
              return b.time < a.time ? 1 : -1;
            }
          });
        } else {
          localUserActivity[moment(new Date(r.time)).format("Do MMM YYYY")] = [
            r,
          ];
        }
      });

      return localUserActivity;
    },
    [entityId, sort]
  );

  const getUserActivity = useCallback(() => {
    if (entityId) {
      getUserSpecificActivity(
        page - 1,
        pageSize,
        entityId,
        activeTab === "2" ? "Consent" : "Account"
      )
        .then((res) => res.data)
        .then((res) => {
          setHasMore(!res.last);
          setUserActivity((old: any) =>
            returnLocalSortedUserActivity(res.content, old)
          );
        });
    } else {
      if (activeTab === "2") {
        if (fiu.length === 0) getAllFIUByUser().then((res) => setFiu(res.data));
      } else if (activeTab === "3") {
        if (linkedFip.length === 0)
          getAllFIPByUser().then((res) => setLinkedFip(res.data));
      } else {
        getAllUserActivity(
          page - 1,
          pageSize,
          activeTab === "2" ? "/consent" : activeTab === "3" ? "/account" : "",
          sort
        )
          .then((res) => res.data)
          .then((res) => {
            setHasMore(!res.last);
            setUserActivity((old: any) =>
              returnLocalSortedUserActivity(res.content, old)
            );
          });
      }
    }
  }, [
    page,
    activeTab,
    entityId,
    fiu.length,
    linkedFip.length,
    returnLocalSortedUserActivity,
    sort,
  ]);

  useEffect(() => {
    getUserActivity();
  }, [getUserActivity]);

  const retrieveAuditBasedOnKey = (key: string) => {
    setActiveTab(key);
    if (page !== 1) {
      setPage(1);
    }
    setHasMore(true);
    setSelectedFiu("");
    setSelectedLinkedFip("");
    setFiu([]);
    setLinkedFip([]);
    setUserActivity({});
    setEntityId("");
  };

  const timeLineRender = (isAllNotification: boolean) => {
    return Object.keys(userActivity).length !== 0 ? (
      <>
        {Object.keys(userActivity).map((userActivities: any, index: number) => (
          <Timeline.Item
            className={`${theme.textTheme.headline1} timeline`}
            key={index}
            label={userActivities}
          >
            {userActivity[userActivities].map(
              (activity: any, index1: number) => (
                <>
                  <Card
                    key={index1}
                    className={`${theme.cardColor} userActivityCard`}
                  >
                    <Skeleton loading={loading} avatar={true}>
                      <Card.Meta
                        title={activityTitle(
                          activity.activityType,
                          activity.activityJson,
                          theme,
                          t,
                          user.firstName
                        )}
                        description={activityDescription(
                          activity.activityType,
                          activity.activityJson,
                          theme,
                          t
                        )}
                      />
                    </Skeleton>
                  </Card>
                  <Typography.Text className={theme.textTheme.subtitle1}>
                    {moment(new Date(activity.time)).format("hh:mm a")}
                  </Typography.Text>
                </>
              )
            )}
          </Timeline.Item>
        ))}
        <div ref={ref} className="refDiv" />
      </>
    ) : (
      <Empty
        image={theme.noConsentFound}
        className={`empty`}
        description={
          <Typography.Text
            className={`${theme.indicatorColor} consentErrorMsg`}
          >
            {t(translation.errorNoDetailsFound)}
          </Typography.Text>
        }
      />
    );
  };

  useEffect(() => {
    if (activeTab === "2" && selectedFiu) {
      getAllConsentsByFIU(selectedFiu, pageRoot - 1, pageSizeRoot)
        .then((res) => res.data)
        .then((res) => {
          setRootHasMore(!res.last);
          setConsents((old) => old.concat(res.content));
        });
    } else if (activeTab === "3" && selectedLinkedFip) {
      getAllAccountsByFIP(selectedLinkedFip, pageRoot - 1, pageSizeRoot)
        .then((res) => res.data)
        .then((res) => {
          setRootHasMore(!res.last);
          setAccounts((old) => old.concat(res.content));
        });
    }
  }, [activeTab, pageRoot, selectedFiu, selectedLinkedFip]);

  const onChangeFIUSelection = (key: string | string[]) => {
    if (key !== undefined) {
      if (fiu[parseInt(key[0], 10)].id !== selectedFiu) {
        setEntityId("");
        setConsents([]);
        setPageRoot(1);
        setRootHasMore(true);
        setSelectedFiu(fiu[parseInt(key[0], 10)].id);
      }
    }
  };

  const onConsentSelectionChange = (key: string | string[]) => {
    if (key !== undefined) {
      if (consents[parseInt(key[0], 10)].consentHandle !== entityId) {
        setUserActivity({});
        setPage(1);
        setHasMore(true);
        setEntityId(consents[parseInt(key[0], 10)].consentHandle);
      }
    }
  };

  const collapseFIUHeader = (fiuArgs: any) => {
    return (
      <div className="fiuCard">
        <div className={`fiuHeader`}>
          <Image
            className="fipLogo"
            src={
              fiuArgs.isSelfConsent
                ? logoBaseUrl + "SAAFE.jpeg"
                : logoBaseUrl + fiuArgs.id + ".jpeg"
            }
            fallback={default_bank}
            preview={false}
            style={{ marginRight: 10 }}
          />

          <div className="cardContent">
            <Typography.Text className={`${theme.textTheme.headline1}`}>
              {fiuArgs.name}
            </Typography.Text>
          </div>
        </div>

        {fiuArgs.isSelfConsent && (
          <div className="fiuTag">
            <Tag className="tag" color={colorRenderer("selfConsent")}>
              {t(translation.lblSelfConsent)}
            </Tag>
          </div>
        )}
      </div>
    );
  };

  const colorRenderer = (consent: any) => {
    if (consent.consentStatusType === ConsentStatusType.REJECTED) {
      return "error";
    } else if (consent.consentStatusType === ConsentStatusType.EXPIRED) {
      return "default";
    } else if (consent.consentStatusType === ConsentStatusType.PAUSED) {
      return "default";
    } else if (consent.consentStatusType === ConsentStatusType.ACTIVE) {
      return "success";
    } else if (consent.consentStatusType === ConsentStatusType.CREATED) {
      return "processing";
    } else {
      return "warning";
    }
  };

  const iconRenderer = (consent: any) => {
    if (consent.consentStatusType === ConsentStatusType.REJECTED) {
      return <CloseCircleOutlined />;
    } else if (consent.consentStatusType === ConsentStatusType.EXPIRED) {
      return <ClockCircleOutlined />;
    } else if (consent.consentStatusType === ConsentStatusType.PAUSED) {
      return <PauseCircleOutlined />;
    } else if (consent.consentStatusType === ConsentStatusType.ACTIVE) {
      return <CheckCircleOutlined />;
    } else if (consent.consentStatusType === ConsentStatusType.CREATED) {
      return <IssuesCloseOutlined />;
    } else {
      return <MinusCircleOutlined />;
    }
  };

  const colorRendererAccount = (account: any) => {
    if (account.accountStatusType === "DELINKED") {
      return "error";
    } else {
      return "success";
    }
  };

  const iconRendererAccount = (account: any) => {
    if (account.accountStatusType === "LINKED") {
      return <LinkOutlined />;
    } else {
      return <DeleteOutlined />;
    }
  };

  const collapseConsentHeader = (consent: any) => {
    return (
      <div style={{ display: "flex" }}>
        <div className="typography">
          <div className="fiuName">
            <div>
              <Statistic
                className={theme.textTheme.subtitle1}
                title="Consent Handle"
                value={consent.consentHandle}
              />
            </div>
            <div style={{ marginTop: 12 }}>
              <Statistic
                className={theme.textTheme.subtitle1}
                title="Purpose"
                value={consent.purpose.text}
                prefix={<AimOutlined className={theme.textTheme.headline1} />}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
              alignItems: "flex-end",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div>
              <Tag
                className="tag"
                icon={iconRenderer(consent)}
                color={colorRenderer(consent)}
              >
                {consent.consentStatusType}
              </Tag>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Statistic
                className={`statastics ${theme.textTheme.subtitle1}`}
                title={t("Created on")}
                value={moment(consent.createdOn).format(
                  "MMMM Do YYYY, h:mm:ss"
                )}
              />
              <TimeFromNow date={consent.updatedOn} title={t("Updated")} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderList = () => {
    return (activeTab === "2" && fiu.length !== 0) ||
      (activeTab === "3" && linkedFip.length !== 0) ? (
      <Collapse
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            className={theme.textTheme.headline1}
            rotate={isActive ? 90 : 0}
          />
        )}
        accordion={true}
        onChange={
          activeTab === "2" ? onChangeFIUSelection : onChangeFIPSelection
        }
        className={`fiuOuterCollapse`}
        destroyInactivePanel={true}
      >
        {(activeTab === "2" ? fiu : linkedFip).map((f, index) => (
          <Collapse.Panel
            style={{ marginTop: 5 }}
            header={collapseFIUHeader(f)}
            key={index}
            className={`${theme.cardColor}  ${theme.entityCard} userActivityCard entiyCard`}
          >
            {(activeTab === "2" && consents.length !== 0) ||
            (activeTab === "3" && accounts.length !== 0) ? (
              <>
                <Collapse
                  expandIconPosition="end"
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined
                      className={theme.textTheme.headline1}
                      rotate={isActive ? 90 : 0}
                    />
                  )}
                  accordion={true}
                  onChange={
                    activeTab === "2"
                      ? onConsentSelectionChange
                      : onAccountSelectionChange
                  }
                  className={`${theme.mainbackgroundColor} ${theme.entityCard} userActivityCard innerCollapse`}
                >
                  {(activeTab === "2" ? consents : accounts).map(
                    (c, index1) => (
                      <Collapse.Panel
                        style={{
                          borderRadius: 12,

                          marginTop: 5,
                        }}
                        className={`${theme.cardColor}  ${theme.entityCard} userActivityCard entiyCard`}
                        header={
                          activeTab === "2"
                            ? collapseConsentHeader(c)
                            : collapseAccountHeader(c)
                        }
                        key={index1}
                      >
                        {Object.keys(userActivity).length !== 0 && entityId ? (
                          timeLineRender(false)
                        ) : (
                          <></>
                        )}
                      </Collapse.Panel>
                    )
                  )}
                </Collapse>
                <div ref={rootRef} className="refDiv" />
              </>
            ) : (
              <Empty
                image={theme.noConsentFound}
                className={`empty`}
                description={
                  <Typography.Text
                    className={`${theme.indicatorColor} consentErrorMsg`}
                  >
                    {t(translation.errorNoDetailsFound)}
                  </Typography.Text>
                }
              />
            )}
          </Collapse.Panel>
        ))}
      </Collapse>
    ) : (
      <Empty
        image={theme.noConsentFound}
        className={`empty`}
        description={
          <Typography.Text
            className={`${theme.indicatorColor} consentErrorMsg`}
          >
            {t(translation.errorNoDetailsFound)}
          </Typography.Text>
        }
      />
    );
  };

  const collapseAccountHeader = (account: any) => {
    return (
      <div style={{ display: "flex" }}>
        <div className="typography">
          <div className="fiuName">
            <div>
              <Statistic
                className={theme.textTheme.subtitle1}
                title="Account number"
                value={account.maskedAccNumber}
              />
            </div>
            <div style={{ marginTop: 12 }}>
              <Statistic
                className={theme.textTheme.subtitle1}
                title="Account Type"
                value={account.accountType}
              />
            </div>
          </div>
          <div className="accountHeader">
            <div>
              <Tag
                className="tag"
                icon={iconRendererAccount(account)}
                color={colorRendererAccount(account)}
              >
                {account.accountStatusType}
              </Tag>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Statistic
                className={`${theme.textTheme.subtitle1} statastics`}
                title={t("Created on")}
                value={moment(account.createdOn).format(
                  "MMMM Do YYYY, h:mm:ss"
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onChangeFIPSelection = (key: string | string[]) => {
    if (key !== undefined) {
      if (linkedFip[parseInt(key[0], 10)].id !== selectedLinkedFip) {
        setAccounts([]);
        setPageRoot(1);
        setEntityId("");
        setRootHasMore(true);
        setSelectedLinkedFip(linkedFip[parseInt(key[0], 10)].id);
      }
    }
  };

  const onAccountSelectionChange = (key: string | string[]) => {
    if (key !== undefined) {
      if (accounts[parseInt(key[0], 10)].linkRefNumber !== entityId) {
        setUserActivity({});
        setPage(1);
        setHasMore(true);
        setEntityId(accounts[parseInt(key[0], 10)].linkRefNumber);
      }
    }
  };

  const options = [
    { label: "ASC", value: "ASC" },
    { label: "DESC", value: "DESC" },
  ];

  const OnRadioFilterChange = ({ target: { value } }: RadioChangeEvent) => {
    setUserActivity({});
    setEntityId("");
    setPage(1);
    setSort(value);
  };

  const appltFilter = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography.Text>Apply filters</Typography.Text>
        <Radio.Group
          options={options}
          onChange={OnRadioFilterChange}
          value={sort}
        />
      </div>
    );
  };

  return (
    <div className={`${theme.mainbackgroundColor} userActivityRoot`}>
      <div className="userActivityRootContainer">
        <Typography>
          <Typography.Title
            level={3}
            className={`${theme.textTheme.headline1} titleClass userActivityPageHeader`}
          >
            {t(translation.lblHistory)}
          </Typography.Title>
          <Typography.Text
            className={`${theme.textTheme.subtitle1} userActivitySubtitle`}
          >
            {t(translation.lblReviewLatestActivity)}
          </Typography.Text>
        </Typography>

        <Tabs
          onChange={retrieveAuditBasedOnKey}
          animated={true}
          defaultActiveKey="1"
          className={`${theme.tabColor} historyTabs`}
          destroyInactiveTabPane={true}
        >
          <Tabs.TabPane tab={t(translation.lblAllNotifications)} key="1">
            <Timeline className="timelineRoot" mode="left">
              {userActivity && Object.keys(userActivity).length !== 0 ? (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Popconfirm
                    icon={<></>}
                    title={appltFilter()}
                    cancelButtonProps={{ hidden: true }}
                    okButtonProps={{ hidden: true }}
                    className={theme.textTheme.headline1}
                  >
                    <FilterOutlined className="filter" />
                  </Popconfirm>
                </div>
              ) : (
                <></>
              )}
              {timeLineRender(true)}
            </Timeline>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t(translation.lblConsentS)} key="2">
            <Timeline className="timelineRoot" mode="left">
              {renderList()}
            </Timeline>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t(translation.lblAccountS)} key="3">
            <Timeline className="timelineRoot" mode="left">
              {renderList()}
            </Timeline>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default UserActivity;
