import { MenuOutlined } from "@ant-design/icons";
import { Context, useTheme } from "App";
import saafe from "Assets/saafe_light.png";
import { encode } from "Services/RedirectionServices/Redirection";
import {
  getExpiryTime,
  getFiuId,
  getRedirect,
  getSessionId,
  getSrcRef,
  getTxnId,
  getUserId,
} from "Services/StorageServices";
import sendEvents from "Utils/Events";
import { RedirectionEvents } from "Utils/types/RedirectionEvents";
import { Button } from "antd";
import Countdown, { CountdownProps } from "antd/lib/statistic/Countdown";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserRespose } from "redux/Reducer/userReducer";
import "./index.css";

const NavBar: React.FC<{
  setCollapsed: any;
  isRedirected: boolean;
}> = ({ setCollapsed, isRedirected }) => {
  const user: UserRespose = useSelector((state: any) => state.user.user);

  const theme = useTheme();
  const { push } = useHistory();
  const { menubar }: any = useContext(Context);

  const collapse = () => {
    setCollapsed(false);
  };

  const redirect = (status: string, errorcode: string) => {
    if (!!getRedirect() && !!getTxnId() && !!getSessionId()) {
      const encodeParam = {
        status,
        errorcode,
        txnid: getTxnId() || "",
        sessionid: getSessionId() || "",
        srcref: getSrcRef() || "",
        userid: getUserId() || "",
        fiuId: getFiuId() || "",
      };

      sendEvents(RedirectionEvents.Encode_Parameters);

      encode(encodeParam).then((res) => {
        sendEvents(RedirectionEvents.Redirecting_To_Fiu);
        window.location.replace(
          `${getRedirect()}?fi=${res.data.fi}&resdate=${
            res.data.resdate
          }&ecres=${res.data.ecres}`
        );
        localStorage.clear();
      });
    } else {
      push("/consent");
    }
  };

  const onFinish: CountdownProps["onFinish"] = () => {
    sendEvents(RedirectionEvents.Session_Timeout);
    redirect("F", "5");
  };

  return (
    <div
      className={`${theme.navbar} ${
        isRedirected ? "redirectionNavbar" : "navbar"
      } `}
    >
      <div className="navBarLogoContainer">
        <img alt="" src={saafe} className="navBarLogo" />
      </div>
      {isRedirected ? (
        <div className="navBarLogoContainer">
          {user.mobileNumber !== "" ? (
            <Countdown
              title="Session timeout"
              value={getExpiryTime() ?? Date.now() + 1000 * 60 * 5}
              className="countDown"
              onFinish={onFinish}
            />
          ) : (
            <></>
          )}
          <img
            alt={getFiuId() as any}
            src={
              process.env.REACT_APP_LOGO_BASE_URL +
              `${!!getFiuId() ? getFiuId() : ""}` +
              ".jpeg"
            }
            className="navBarLogo"
          />
        </div>
      ) : menubar ? (
        <Button onClick={collapse} className={`navBarButton`}>
          <MenuOutlined />
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NavBar;
