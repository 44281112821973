import { BellOutlined } from "@ant-design/icons";
import {
  Badge,
  Card,
  Dropdown,
  Empty,
  Image,
  Menu,
  Statistic,
  Tabs,
  Typography,
} from "antd";
import { useTheme } from "App";
import { AimedIcon, CalendarIcon } from "Assets/icons";
import default_bank from "Assets/ic_default_bank.png";
import { translation } from "i18n/translation";
import { ConsentDetail } from "Models/Response/Consent/GetAllConsent";
import moment from "moment";
import useINF from "Pages/Common/Components/InfiniteScroll";
import ConsentStatus from "Pages/Consent/Components/ConsentStatus";
import { activityTitle } from "Pages/UserActivityComponent/constants";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserRespose } from "redux/Reducer/userReducer";
import {
  getConsentCounts,
  getPaginatedConsentByStatus,
} from "Services/ConsentServices/ConsentService";
import { getRedirect, getSessionId, getTxnId } from "Services/StorageServices";
import {
  getAllUserActivityNotification,
  markUserActivityAsRead,
} from "Services/UserActivityServices/UserActivity";
import {
  ConsentApprovalStatusType,
  ConsentStatusType,
} from "Utils/Constants/statusTypes";
import MultiConsent from "../MultiConsentRedirection";
import "./index.css";
const pageSize = 20;
const { TabPane } = Tabs;
interface Consent {
  [key: number]: {
    numberOfConsent: number;
    consentDetails: ConsentDetail[];
    title: string;
  };
}

const ConsentComponent: React.FC = () => {
  const { push } = useHistory();
  const theme = useTheme();
  const [isNotification, setNotificationRead] = useState(false);
  const [userActivities, setUserActivities] = useState([]);
  const { t } = useTranslation();
  const user: UserRespose = useSelector((state: any) => state.user.user);

  const [consents, setConsents] = useState<Consent>({
    1: {
      numberOfConsent: 0,
      consentDetails: new Array<ConsentDetail>(),
      title: t(translation.lblAllConsent),
    },
    2: {
      numberOfConsent: 0,
      consentDetails: new Array<ConsentDetail>(),
      title: t(translation.lblActiveConsent),
    },
    3: {
      numberOfConsent: 0,
      consentDetails: new Array<ConsentDetail>(),
      title: t(translation.lblPendingConsent),
    },
    4: {
      numberOfConsent: 0,
      consentDetails: new Array<ConsentDetail>(),
      title: t(translation.lblPaused),
    },
    5: {
      numberOfConsent: 0,
      consentDetails: new Array<ConsentDetail>(),
      title: t(translation.lblRevokedConsent),
    },
    6: {
      numberOfConsent: 0,
      consentDetails: new Array<ConsentDetail>(),
      title: t(translation.lblRejectedConsent),
    },
    7: {
      numberOfConsent: 0,
      consentDetails: new Array<ConsentDetail>(),
      title: t(translation.lblExpiredConsent),
    },
  });
  const [page, setPage] = useState<number>(1);
  const [status, setStatus] = useState<string>("");
  const [activeKey, setActiveKey] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreNotification, setHasMoreNotification] = useState(true);
  const [notificationPage, setNotificationPage] = useState(1);
  const notificationObserver = useRef<any>();
  const notificatioInfiniteScroll = useINF(
    notificationObserver,
    hasMoreNotification,
    setNotificationPage
  );
  notificationObserver.current = notificatioInfiniteScroll.newObserver.current;

  const observer = useRef<any>();
  const { newObserver, ref } = useINF(observer, hasMore, setPage);
  observer.current = newObserver.current;

  const currentTheme = useSelector((state: any) => state.themes.theme);

  useEffect(() => {
    if (!!getRedirect() && !!getTxnId() && !!getSessionId()) {
    } else {
      getPaginatedConsentByStatus(
        page - 1,
        pageSize,
        status && status.length ? status : ""
      )
        .then((res) => res.data)
        .then((res) => {
          setHasMore(!res.last);
          setConsents((old) => ({
            ...old,
            [activeKey]: {
              ...old[activeKey],
              consentDetails: old[activeKey].consentDetails.concat(res.content),
              numberOfConsent: res.totalElements,
            },
          }));
        });
      getConsentCounts()
        .then((res1) => res1.data)
        .then((res1) => {
          setNotificationRead(res1.isNotification);
          setConsents((old) => ({
            ...old,
            "1": {
              ...old[1],
              numberOfConsent: res1.numberOfAllConsent,
            },
            "2": {
              ...old[2],
              numberOfConsent: res1.numberOfActiveConsent,
            },
            "3": {
              ...old[3],
              numberOfConsent: res1.numberOfPendingConsent,
            },
            "4": {
              ...old[4],
              numberOfConsent: res1.numberOfPausedConsent,
            },
            "5": {
              ...old[5],
              numberOfConsent: res1.numberOfRevokedConsent,
            },
            "6": {
              ...old[6],
              numberOfConsent: res1.numberOfRejectedConsent,
            },
            "7": {
              ...old[7],
              numberOfConsent: res1.numberOfExpiredConsent,
            },
          }));
        });
    }
  }, [page, status, activeKey]);

  const onDropdownChange = (visible: boolean) => {
    if (!visible) {
      setHasMoreNotification(true);
      setUserActivities([]);
      if (isNotification) setNotificationsAsRead();
    } else {
      setNotificationPage(1);
    }
  };
  useEffect(() => {
    if (!!!getRedirect()) {
      getAllUserActivityNotification(notificationPage - 1, 10)
        .then((res) => res.data)
        .then((res) => {
          setHasMoreNotification(!res.last);
          setUserActivities((prevState) => prevState.concat(res.content));
        });
    }
  }, [notificationPage]);

  const notifications = (
    <Menu
      className={`${theme.mainbackgroundColor} menu notificationMenu`}
      selectable={false}
    >
      {userActivities.map((activity: any, index) => (
        <>
          <Card
            key={activity.id}
            className={`${theme.cardColor}  notificationCard`}
          >
            <div className="notificationDetails">
              <Typography.Text>
                {activityTitle(
                  activity.activityType,
                  activity.activityJson,
                  theme,
                  t,
                  user.firstName,
                  activity.isRead
                )}
              </Typography.Text>
              <Typography.Text
                className={`${theme.textTheme.subtitle1} subtitleDate`}
              >
                {moment(activity.time).startOf("hour").fromNow()}
              </Typography.Text>
            </div>
          </Card>
        </>
      ))}
      <Menu.Item>
        <div ref={notificatioInfiniteScroll.ref} />
      </Menu.Item>
    </Menu>
  );

  const cardClicked = (
    consentUniqueId: string,
    type: string,
    createdOrUpdatedOn: string
  ) => {
    push({
      pathname: `/consent/${type}/${consentUniqueId}`,
      state: { createdOrUpdatedOn },
    });
  };

  const returnNumberOfConsents = (key: number) => {
    return (
      <>
        <Typography.Text className="tabHeader">
          {consents[key].title}
        </Typography.Text>{" "}
        <Badge
          className={`${currentTheme + "BadgeBackground"}`}
          count={consents[key].numberOfConsent}
          showZero={true}
        />
      </>
    );
  };

  const setNotificationsAsRead = () => {
    const userActivityIds = userActivities
      .filter((activity: any) => !activity.isRead)
      .map((activity: any) => activity.id);
    if (userActivityIds.length > 0) {
      markUserActivityAsRead({
        auditsIdList: userActivityIds,
      }).then((res) => setNotificationRead(res.data));
    }
  };

  const onTabChange = (key: number) => {
    setActiveKey(key);
    setHasMore(true);
    setPage(1);
    setConsents((old) => ({
      ...old,
      [activeKey]: {
        ...old[activeKey],
        consentDetails: [],
      },
    }));

    switch (key) {
      case 1:
        setStatus("");
        break;
      case 2:
        setStatus(ConsentStatusType.ACTIVE.toString());
        break;
      case 3:
        setStatus(ConsentStatusType.CREATED.toString());
        break;
      case 4:
        setStatus(ConsentStatusType.PAUSED.toString());
        break;
      case 5:
        setStatus(ConsentStatusType.REVOKED.toString());
        break;
      case 6:
        setStatus(ConsentStatusType.REJECTED.toString());
        break;
      case 7:
        setStatus(ConsentStatusType.EXPIRED.toString());
        break;
      default:
        break;
    }
  };

  const consentCardClick = (consent: ConsentDetail) => {
    cardClicked(
      consent.consentid
        ? consent.consentStatusType !== ConsentStatusType.EXPIRED &&
          consent.consentStatusType !== ConsentStatusType.REJECTED
          ? consent.consentid
          : consent.consentHandle
        : consent.consentHandle,
      consent.consentStatusType === ConsentStatusType.CREATED
        ? ConsentApprovalStatusType.PENDING
        : consent.consentStatusType,
      consent.updatedOn
    );
  };

  const ConsentRender = (key: number) => {
    return (
      <>
        {consents[key].consentDetails && consents[key].consentDetails.length ? (
          <>
            {consents[key].consentDetails.map(
              (consent: ConsentDetail, index) => (
                <Card
                  key={consent.consentHandle}
                  hoverable={true}
                  onClick={() => consentCardClick(consent)}
                  className={`${theme.cardColor} ${theme.consentCardBorder} consentCard`}
                >
                  <div className="consentInfo">
                    <Image
                      className="fipLogo"
                      src={
                        process.env.REACT_APP_LOGO_BASE_URL +
                        consent.fiuId +
                        ".jpeg"
                      }
                      fallback={default_bank}
                      preview={false}
                    />
                    <div className="typography">
                      <div className={`${theme.indicatorColor} fiuName`}>
                        {consent.fiuName ? consent.fiuName : "Self Consent"}
                        <Statistic
                          className={`${theme.hintColor} timeFromNow`}
                          value={moment(consent.updatedOn).fromNow()}
                        />
                      </div>
                      {key === 1 ? (
                        <ConsentStatus
                          status={consent.consentStatusType}
                          className={
                            consent.consentStatusType ===
                              ConsentStatusType.ACTIVE
                              ? `${theme.successStatus} ${theme.successStatusBG}`
                              : consent.consentStatusType ===
                                ConsentStatusType.PAUSED ||
                                consent.consentStatusType ===
                                ConsentStatusType.CREATED
                                ? `${theme.warningStatus} ${theme.warningStatusBG}`
                                : consent.consentStatusType ===
                                  ConsentStatusType.REJECTED
                                  ? `${theme.errorStatus} ${theme.errorStatusBG}`
                                  : consent.consentStatusType ===
                                    ConsentStatusType.REVOKED
                                    ? `${theme.staleStatus} ${theme.staleStatusBG}`
                                    : `${theme.staleStatus} ${theme.staleStatusBG}`
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div
                    className={`${theme.consentDivider} consentCardPurposeDate`}
                  >
                    <div className="displayFlex">
                      <AimedIcon
                        className={`${theme.hintColor} marginRight6 icon`}
                      />
                      <Typography.Text className={`${theme.hintColor}`}>
                        {t(consent.purpose)}
                      </Typography.Text>
                    </div>
                    <div className="displayFlex">
                      <CalendarIcon
                        className={`${theme.hintColor} marginRight6 icon`}
                      />
                      <Typography.Text className={`${theme.hintColor}`}>
                        {moment(consent.consentExpiry).format("DD MMMM, YYYY")}
                      </Typography.Text>
                    </div>
                  </div>
                </Card>
              )
            )}
            <div className="consentCard" style={{ height: 1 }} ref={ref} />
          </>
        ) : (
          <Empty
            image={theme.noConsentFound}
            className={`empty`}
            description={
              <Typography.Text
                className={`${theme.indicatorColor} consentErrorMsg`}
              >
                {t(translation.errorConsentNotFound)}
              </Typography.Text>
            }
          />
        )}
      </>
    );
  };

  return !!getRedirect() ? (
    <MultiConsent />
  ) : (
    <div className={`${theme.bodyBackgroundColor} consentLayout`}>
      <div className="accountRootContainer">
        {consents[1].numberOfConsent !== 0 ? (
          <>
            <div className="tabViewHeader">
              <Typography.Title
                level={3}
                className={`${theme.textTheme.headline1} consentTypo`}
              >
                {t(translation.lblConsents)}
              </Typography.Title>

              <Dropdown
                overlay={notifications}
                trigger={["click"]}
                onOpenChange={onDropdownChange}
                placement="bottomRight"
                overlayClassName={`${theme.notificationShadow} notificationDropDown`}
                destroyPopupOnHide={true}
              >
                {isNotification ? (
                  <Badge dot={true}>
                    <BellOutlined
                      className={`${theme.hintColor} bellNotification`}
                    />
                  </Badge>
                ) : (
                  <BellOutlined
                    className={`${theme.hintColor} bellNotification`}
                  />
                )}
              </Dropdown>
            </div>
            <Tabs
              onChange={(key: string) => onTabChange(parseInt(key, 10))}
              animated={true}
              defaultActiveKey="1"
              className={`${theme.tabColor} tab`}
              destroyInactiveTabPane={true}
            >
              {[1, 2, 3, 4, 5, 6].map((key) => (
                <TabPane
                  className="consentTabs"
                  tab={returnNumberOfConsents(key)}
                  key={key}
                  id={key.toString()}
                >
                  {ConsentRender(key)}
                </TabPane>
              ))}
            </Tabs>
          </>
        ) : (
          <Empty
            image={theme.noConsentFound}
            className={`empty accountEmpty`}
            description={
              <div className="emptyDescription">
                <Typography.Text
                  className={`${theme.indicatorColor} consentErrorMsg`}
                >
                  {t(translation.lblDidNotReceiveConsent)}
                </Typography.Text>
                <br />
                <Typography.Text className={`${theme.authSubTitle}`}>
                  {t(translation.msgNoConsents)}
                </Typography.Text>
              </div>
            }
          />
        )}
      </div>isSelfConsent
    </div>
  );
};

export default ConsentComponent;
