const initState = { isRefreshTokenFetching: false };

export default function refreshTokenReducer(state = initState, action: any) {
  switch (action.type) {
    case "START_REFRESH_TOKEN_FETCH":
      return { isRefreshTokenFetching: true };

    case "FINISH_REFRESH_TOKEN_FETCH":
      return { isRefreshTokenFetching: false };

    default:
      return state;
  }
}
