import { Card, Checkbox, Collapse, Image, Table, Typography } from "antd";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getRedirect } from "Services/StorageServices";
import { useTheme } from "App";
import {
  AimedIconCustomized,
  CalendarIconCustomized,
  ConsentsIconCustomized,
  CreditCardIconCustomized,
} from "Assets/icons";
import default_bank from "Assets/ic_default_bank.png";
import { translation } from "i18n/translation";
import GetConsentByHandleResponse from "Models/Response/Consent/GetConsentByHandle";
import { GetConsentByIdResponse } from "Models/Response/Consent/GetConsentById";
import { logoBaseUrl, primaryConsentPurposeCode } from "Utils/Constants/baseUrl";
import {
  ConsentStatusType,
} from "Utils/Constants/statusTypes";
import BreadCrumbs from "Pages/Common/Components/BreadCrumbs";
import "./index.css";
import { SelectedAccounts } from "Models/Request/Account/AccountLink";
import { convertFiType } from "Utils/Constants/accTypeConversion";

interface IConsentDetailsProps {
  type: string;
  consentByUniqueIdDetails: GetConsentByIdResponse | GetConsentByHandleResponse;
  userAction?: (consentStatusType: ConsentStatusType) => void;
  renderTypeRedirection?: string;
  consentUniqueId?: string;
  onChange?: (selectedCard: SelectedAccounts[]) => void;
  multiConsent?: boolean;
  selectedConsents?: any[];
  onConsentSelectionChangeRedirection?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  consentHandle?: string;
}

const ConsentDetailsCustomized: React.FC<IConsentDetailsProps> = ({
  type,
  consentByUniqueIdDetails,
  userAction,
  renderTypeRedirection,
  consentUniqueId,
  onChange = () => { },
  multiConsent = false,
  selectedConsents,
  onConsentSelectionChangeRedirection = () => { },
  consentHandle = null
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { Text, Title } = Typography;
  const columns = useMemo(
    () => [
      {
        title: t("Type"),
        dataIndex: "type",
      },
      {
        title: t("Operator"),
        dataIndex: "operator",
      },
      {
        title: t("Value"),
        dataIndex: "value",
      },
    ],
    [t]
  );
  const breadCrumbsHeader = () => {
    return (
      !!getRedirect() ? (
        <></>
      ) : (
        <BreadCrumbs
          className="marginBottom16"
          breadcrumbItems={[
            { route: "/consent", label: t(translation.lblConsents) },
            {
              route: location.pathname,
              label: `${consentByUniqueIdDetails.DataConsumer.id}`,
            },
          ]}
        />
      )
    )
  }

  const headerFiuName = () => {
    return (
      <div className="headerTitle" >
        <div className="headerMetaCustomized">
          <Image
            className="fipLogo"
            src={
              logoBaseUrl + consentByUniqueIdDetails.DataConsumer.id + ".jpeg"
            }
            fallback={default_bank}
            preview={false}
          />
          <div className="headerTypo">
            <Title
              style={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "32px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#101828"
              }}
            >
              {consentByUniqueIdDetails.DataConsumer.name ? consentByUniqueIdDetails.DataConsumer.name : "Self Consent"}
            </Title>
          </div>
        </div>
        <div className="customizedCheckBoxBigSize">
          {
            consentByUniqueIdDetails.Purpose.code !== primaryConsentPurposeCode
            && multiConsent === true && consentHandle !== null &&
            <Checkbox
              checked={
                selectedConsents?.indexOf(consentHandle) !== -1
              }
              onClick={onConsentSelectionChangeRedirection}
              id={consentHandle}
              className={`${theme.textTheme.headline5} ${theme.textTheme.headline6} ${theme.checkBoxBorder} checboxSelectAllCustomizedForConsent`}
            />
          }

        </div>
      </div>
    )
  }

  const timeString = (value: any) => {
    if (value === 1) {
      return "Once"
    } else if (value === 2) {
      return "Twice"
    } else {
      return "Recurring - " + value + " times"
    }
  }

  const frequencyGenerator = (fetchType: any, frequency: any) => {
    let result: string = "";
    if (fetchType === "ONETIME") {
      result = "Onetime"
    } else if (fetchType === "PERIODIC") {
      switch (frequency.unit) {
        case ("HOUR"): {
          result = timeString(frequency.value) + " an hour"
          break;
        }
        case ("DAY"): {
          result = timeString(frequency.value) + " a day"
          break;
        }
        case ("MONTH"): {
          result = timeString(frequency.value) + " a month"
          break;
        }
        case ("YEAR"): {
          result = timeString(frequency.value) + " a year"
          break;
        }
        case ("INF"): {
          result = "Unlimited"
          break;
        }
      }
    }

    return result;
  }

  const getOperator = (operator: any) => {
    switch (operator) {
      case (">"): {
        return "greater than";
      }
      case (">="): {
        return "greater ghan or equal";
      }
      case ("<"): {
        return "less than";
      }
      case ("<="): {
        return "less than or equal";
      }
      case ("="): {
        return "equals"
      }
      case ("!="): {
        return "not equals"
      }
    }
  }

  const getTransactionType = (type: any) => {
    if (type === "CREDIT") {
      return "Credit";
    }
    else if ("DEBIT") {
      return "Debit";
    }
    else {
      return type;
    }
  }

  const dataFilterGenerator = (dataFilters: any) => {
    if (dataFilters.length === 0) {
      return "No Data filters applied";
    }

    let result: any = "";
    // eslint-disable-next-line array-callback-return
    dataFilters.map((filter: { type: any; operator: string; value: any; }) => {
      switch (filter.type) {
        case ("TRANSACTIONAMOUNT"): {
          result += "Transactions " + getOperator(filter.operator) + " " + filter.value;
          break;
        }
        case ("TRANSACTIONTYPE"): {
          result = getTransactionType(filter.value) + " " + result;
          break;
        }
      }
    });
    return result;
  }

  const calculateDataWillDeletedOnDate: any = (dataLife: any) => {
    switch (dataLife.unit) {
      case ("DAY"): {
        const daysToAdd = dataLife.value;
        if (daysToAdd <= 1) {
          return daysToAdd + " Day";
        }
        return daysToAdd + " Days";
      }
      case ("MONTH"): {
        const monthsToAdd = dataLife.value;
        if (monthsToAdd <= 1) {
          return monthsToAdd + " Month";
        }
        return monthsToAdd + " Months";
      }
      case ("YEAR"): {
        const yearsToAdd = dataLife.value;
        if (yearsToAdd <= 1) {
          return yearsToAdd + " Year";
        }
        return yearsToAdd + " Years";
      }
      default: {
        return "Forever"
      }
    }
  }

  const headerRender = () => {
    return (
      <div className="customizedConsentDetailCardParentDiv">
        {/* Purpose */}
        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCardCustomized`}
          bordered={false}
        >
          <div
            className={`${theme.consentIconBG} subClass consentDescriptionIcon`}
          >
            <AimedIconCustomized />
          </div>
          <div className="consentDescription">
            <Text className="customizedConsentDetailParameterLabel">
              {t(translation.lblPurposeOfConsentRequest)}
            </Text>
            <Text className={`${theme.indicatorColor} customizedConsentDetailParameterValue`}>
              {t(consentByUniqueIdDetails.Purpose.text)}
            </Text>
          </div>
        </Card>

        {/* Account Types */}
        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCardCustomized`}
          bordered={false}
        >
          <div
            className={`${theme.consentIconBG} subClass consentDescriptionIcon`}
          >
            <ConsentsIconCustomized />
          </div>{" "}
          <div className="consentDescription">
            <Text className="customizedConsentDetailParameterLabel">
              {t(translation.lblAccountTypes)}
            </Text>
            <Text className={`${theme.indicatorColor} customizedConsentDetailParameterValue`}>
              {consentByUniqueIdDetails.fiTypes.map((types, index) => {
                let isComma;
                index === consentByUniqueIdDetails.fiTypes.length - 1
                  ? (isComma = "")
                  : (isComma = ", ");

                return convertFiType(types) + isComma;
              })}
            </Text>
          </div>
        </Card>

        {/* Types of Data */}
        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCardCustomized`}
          bordered={false}
        >
          <div
            className={`${theme.consentIconBG} subClass consentDescriptionIcon`}
          >
            <ConsentsIconCustomized />
          </div>{" "}
          <div className="consentDescription">
            <Text className="customizedConsentDetailParameterLabel">
              {t(translation.lblTypeOfData)}
            </Text>
            <Text className={`${theme.indicatorColor} customizedConsentDetailParameterValue`}>
              {consentByUniqueIdDetails.consentTypes.map((types, index) => {
                let isComma;
                index === consentByUniqueIdDetails.consentTypes.length - 1
                  ? (isComma = "")
                  : (isComma = ", ");

                return types + isComma;
              })}
            </Text>
          </div>
        </Card>

        {/* Transactions */}
        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder}  consentDetailsCardCustomized`}
          bordered={false}
        >
          <div
            className={`${theme.consentIconBG} subClass consentDescriptionIcon consentDetailIconsBG`}
          >
            <CreditCardIconCustomized
            />
          </div>{" "}
          <div className="consentDescription">
            <Text className={`customizedConsentDetailParameterLabel`}>
              {t(translation.lblTransactionsDataPeriod)}
            </Text>
            <Text className={`${theme.indicatorColor} customizedConsentDetailParameterValue`}>
              {moment(consentByUniqueIdDetails.FIDataRange.from).format(
                "DD MMM YYYY"
              )}
              {" to "}

              {moment(consentByUniqueIdDetails.FIDataRange.to).format(
                "DD MMM YYYY"
              )}
            </Text>
          </div>
        </Card>

        {/* Frequency*/}
        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCardCustomized`}
          bordered={false}
        >
          <div
            className={`${theme.consentIconBG} subClass consentDescriptionIcon`}
          >
            <ConsentsIconCustomized />
          </div>{" "}
          <div className="consentDescription">
            <Text className="customizedConsentDetailParameterLabel">
              {t(translation.lblFrequency)}
            </Text>
            <Text className={`${theme.indicatorColor} customizedConsentDetailParameterValue`}>
              {frequencyGenerator(consentByUniqueIdDetails.fetchType, consentByUniqueIdDetails.Frequency)}
            </Text>
          </div>
        </Card>

        {/* Data Filter*/}
        {
          consentByUniqueIdDetails.DataFilter && consentByUniqueIdDetails.DataFilter.length > 0 &&
          <Card
            className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCardCustomized`}
            bordered={false}
          >
            <div
              className={`${theme.consentIconBG} subClass consentDescriptionIcon`}
            >
              <ConsentsIconCustomized />
            </div>{" "}
            <div className="consentDescription">
              <Text className="customizedConsentDetailParameterLabel">
                {t(translation.lblDataFilter)}
              </Text>
              <Text className={`${theme.indicatorColor} customizedConsentDetailParameterValue`}>
                {dataFilterGenerator(consentByUniqueIdDetails.DataFilter)}
              </Text>
            </div>
          </Card>
        }


        {/* Consent Validity */}
        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCardCustomized`}
          bordered={false}
        >
          <div
            className={`${theme.consentIconBG} subClass consentDescriptionIcon`}
          >
            <CalendarIconCustomized
            />
          </div>
          <div className="consentDescription">
            <Text className="customizedConsentDetailParameterLabel">
              {t(translation.lblConsentValidity)}
            </Text>
            <Text className={`${theme.indicatorColor} customizedConsentDetailParameterValue`}>
              {moment(consentByUniqueIdDetails.consentExpiry).format(
                "DD-MMM-YYYY"
              )}
            </Text>
          </div>
        </Card>

        {/* Data Will Be Deleted on */}
        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCardCustomized`}
          bordered={false}
        >
          <div
            className={`${theme.consentIconBG} subClass consentDescriptionIcon`}
          >
            <CalendarIconCustomized
            />
          </div>
          <div className="consentDescription">
            <Text className="customizedConsentDetailParameterLabel">
              {t(translation.lblDataIsStoredFor)}
            </Text>
            <Text className={`${theme.indicatorColor} customizedConsentDetailParameterValue`}>
              {calculateDataWillDeletedOnDate(consentByUniqueIdDetails.DataLife)}
            </Text>
          </div>
        </Card>
      </div>
    );
  };

  const bodyRender = () => {
    return (
      <div className="consentDetailsBody">
        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCardCustomized spaceBetween`}
          bordered={false}
        >
          <Text className={theme.textTheme.subtitle1}>
            {t(translation.lblConsumerID)}
          </Text>
          <Text className={`${theme.indicatorColor} fontSize15FontWeight500`}>
            {consentByUniqueIdDetails.DataConsumer.id}
          </Text>
        </Card>

        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCardCustomized spaceBetween`}
          bordered={false}
        >
          <Text className={theme.textTheme.subtitle1}>
            {t(translation.lblMode)}
          </Text>
          <Text className={`${theme.indicatorColor} fontSize15FontWeight500`}>
            {t(consentByUniqueIdDetails.consentMode)}
          </Text>
        </Card>

        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCardCustomized spaceBetween`}
          bordered={false}
        >
          <Text className={theme.textTheme.subtitle1}>
            {t(translation.lblFetchType)}
          </Text>
          <Text className={`${theme.indicatorColor} fontSize15FontWeight500`}>
            {consentByUniqueIdDetails.fetchType}
          </Text>
        </Card>

        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCardCustomized spaceBetween`}
          bordered={false}
        >
          <Text className={theme.textTheme.subtitle1}>
            {t(translation.lblFITypes)}
          </Text>
          <Text className={`${theme.indicatorColor} fontSize15FontWeight500`}>
            {consentByUniqueIdDetails.fiTypes.map((types, index) => {
              let isComma;
              index === consentByUniqueIdDetails.fiTypes.length - 1
                ? (isComma = "")
                : (isComma = ", ");

              return types + isComma;
            })}
          </Text>
        </Card>

        <Collapse
          className={`${theme.cardColor} 
          ${theme.consentCardBorder}
          ${theme.menuUserFontColor} 
          ${theme.collapseBG} 
          ${theme.cardColor}
          ${theme.collapseColumn} consentDetailsCardCustomized collapseConsent`}
          expandIconPosition="end"
        >
          <Collapse.Panel key={1} header={t(translation.lblFiDataFilter)}>
            <Table
              className={`${theme.cardColor}`}
              columns={columns}
              dataSource={consentByUniqueIdDetails.DataFilter}
              pagination={false}
            />
          </Collapse.Panel>
        </Collapse>
        <div />
      </div>
    );
  };

  const location = useLocation();
  return (
    <>
      {renderTypeRedirection && renderTypeRedirection === "HEADER" ? (
        // <div>
        <div className="detailsHeaderCustomized">
          {breadCrumbsHeader()}
          {headerFiuName()}
          {/* {accountsHeader()} */}
          {headerRender()}
        </div>
        // </div>
      ) : renderTypeRedirection && renderTypeRedirection === "BODY" ? (
        bodyRender()
      ) : (
        <>
          <div className="detailsHeaderCustomized">
            {breadCrumbsHeader()}
            {headerFiuName()}
            {/* {accountsHeader()} */}
            {headerRender()}
          </div>
          {/* {bodyRender()} */}
        </>
      )}
    </>
  );
};

export default ConsentDetailsCustomized;
