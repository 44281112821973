export const translation = {
  errorInvalidPhoneNumber: "errorInvalidPhoneNumber",
  errorInvalidPin: "errorInvalidPin",
  errorRequiredPhoneNumber: "errorRequiredPhoneNumber",
  lblResend: "lblResend",
  lblEnterOTP: "lblEnterOTP",
  lblCodeSendTo: "lblCodeSendTo",
  lblOk: "lblOk",
  lblCancel: "lblCancel",
  lblNext: "lblNext",
  internetNotConnected: "internetNotConnected",
  lblLogout: "lblLogout",
  lblLogin: "lblLogin",
  lblLoginTo: "lblLoginTo",
  msgLogin: "msgLogin",
  lblGetStarted: "lblGetStarted",
  msgLogout: "msgLogout",
  msgDetailsLogout: "msgDetailsLogout",
  lblSessionTimedOut: "lblSessionTimedOut",
  lblEnglish: "lblEnglish",
  lblHindi: "lblHindi",
  lblContinue: "lblContinue",
  lblSelectLanguage: "lblSelectLanguage",
  lblDontHaveAccount: "lblDontHaveAccount",
  lblAlreadyHaveAccount: "lblAlreadyHaveAccount",
  lblCreateAccount: "lblCreateAccount",
  lblFirstBoardingFirst: "lblFirstBoardingFirst",
  lblCreateAccountTo: "lblCreateAccountTo",
  msgRegistration: "msgRegistration",
  lblFirstBoardingSecond: "lblFirstBoardingSecond",
  lblSecondBoardingFirst: "lblSecondBoardingFirst",
  lblSecondBoardingSecond: "lblSecondBoardingSecond",
  lblThirdBoardingFirst: "lblThirdBoardingFirst",
  lblThirdBoardingSecond: "lblThirdBoardingSecond",
  lblEnterAADashboardHandle: "lblEnterAADashboardHandle",
  errorEnterAADashboardHandle: "errorEnterAADashboardHandle",
  lblEnterPin: "lblEnterPin",
  lblCreatePassCode: "lblCreatePassCode",
  lblConfirmPassCode: "lblConfirmPassCode",
  lblSetPin: "lblSetPin",
  errorEnterPin: "errorEnterPin",
  errorEnterValidOTP: "errorEnterValidOTP",
  lblWelcome: "lblWelcome",
  lblSubWelcome: "lblSubWelcome",
  lblMobileNumber: "lblMobileNumber",
  lblAddMobileNumber: "lblAddMobileNumber",
  lblAddNew: "lblAddNew",
  lblAdd: "lblAdd",
  errorMobileNumber: "errorMobileNumber",
  lblAgreeTerms: "lblAgreeTerms",
  errorNoBanksFound: "errorNoBanksFound",
  errorNoIdentifiersFound: "errorNoIdentifiersFound",
  errorNoAccountsFound: "errorNoAccountsFound",
  errorNoDetailsFound: "errorNoDetailsFound",
  errorNoTransactionsFound: "errorNoTransactionsFound",
  errorSomeThingWentWrong: "errorSomeThingWentWrong",
  lblSuccessfullyCreatedAccount: "lblSuccessfullyCreatedAccount",
  lblHomeSearch: "lblHomeSearch",
  lblSearchByMobile: "lblSearchByMobile",
  lblSearchByKeyWords: "lblSearchByKeyWords",
  lblAccountSearch: "lblAccountSearch",
  lblLinkAccounts: "lblLinkAccounts",
  lblLinkBankAccounts: "lblLinkBankAccounts",
  lblLinkAccount: "lblLinkAccount",
  lblLinkedAccounts: "lblLinkedAccounts",
  lblUnLinkAccounts: "lblUnLinkAccounts",
  lblUnLinkedAccounts: "lblUnLinkedAccounts",
  msgUnLinkAccounts: "msgUnLinkAccounts",
  msgUnLinkAccounts2: "msgUnLinkAccounts2",
  msgSubTitleUnLinkAccounts: "msgSubTitleUnLinkAccounts",
  lblNewConsent: "lblNewConsent",
  lblActiveConsent: "lblActiveConsent",
  lblReactivateConsent: "lblReactivateConsent",
  lblPendingConsent: "lblPendingConsent",
  lblAllConsent: "lblAllConsent",
  lblRejectedConsent: "lblRejectedConsent",
  lblExpiredConsent: "lblExpiredConsent",
  lblOtherConsent: "lblOtherConsent",
  msgLinkedAccounts: "msgLinkedAccounts",
  lblReject: "lblReject",
  lblRevoke: "lblRevoke",
  lblPause: "lblPause",
  lblPauseConsent: "lblPauseConsent",
  lblPausedConsent: "lblPausedConsent",
  msgReject: "msgReject",
  lblConfirm: "lblConfirm",
  lblTermsAndConditions: "lblTermsAndConditions",
  lblPrivacyPolicy: "lblPrivacyPolicy",
  lblAboutUs: "lblAboutUs",
  lblChangeLanguage: "lblChangeLanguage",
  lblChangeTheme: "lblChangeTheme",
  lblViewDetails: "lblViewDetails",
  lblAccept: "lblAccept",
  msgAccept: "msgAccept",
  lblSkip: "lblSkip",
  lblAcceptConsent: "lblAcceptConsent",
  lblRejectConsent: "lblRejectConsent",
  lblRevokeConsent: "lblRevokeConsent",
  lblRevokedConsent: "lblRevokedConsent",
  lblAccountDetails: "lblAccountDetails",
  msgAchieveFinanceGoals: "msgAchieveFinanceGoals",
  lblDataSuccessfullyFetched: "lblDataSuccessfullyFetched",
  lblGoodMorning: "lblGoodMorning",
  lblGoodAfterNoon: "lblGoodAfterNoon",
  lblGoodEvening: "lblGoodEvening",
  lblGoodDay: "lblGoodDay",
  lblHello: "lblHello",
  lblSelectAccountForSharingInfo: "lblSelectAccountForSharingInfo",
  lblAccountPermissionAndInfoAccess: "lblAccountPermissionAndInfoAccess",
  lblWantsToAccessAccount: "lblWantsToAccessAccount",
  lblCanAccessAccount: "lblCanAccessAccount",
  lblCanNotAccessAccount: "lblCanNotAccessAccount",
  errorSendingOTP: "errorSendingOTP",
  lblOTPSendSuccess: "lblOTPSendSuccess",
  lblOTPSVerifiedSuccess: "lblOTPSVerifiedSuccess",
  lblAccountRevokeSuccess: "lblAccountRevokeSuccess",
  lblAccountPauseSuccess: "lblAccountPauseSuccess",
  lblAccountUnLinkedSuccess: "lblAccountUnLinkedSuccess",
  lblOTPVerificationFailure: "lblOTPVerificationFailure",
  lblPinVerificationFailure: "lblPinVerificationFailure",
  lblSelectMethodForVerification: "lblSelectMethodForVerification",
  errorConsentNotFound: "errorConsentNotFound",
  lblForgotPin: "lblForgotPin",
  msgForgotPin: "msgForgotPin",
  lblSendOTP: "lblSendOTP",
  lblSend: "lblSend",
  lblSubmit: "lblSubmit",
  lblSave: "lblSave",
  msgPinResetSuccess: "msgPinResetSuccess",
  lblResetPin: "lblResetPin",
  msgConfirmResetPin: "msgConfirmResetPin",
  msgPressBackToExit: "msgPressBackToExit",
  lblLoginWithPin: "lblLoginWithPin",
  lblLoginWithOTP: "lblLoginWithOTP",
  lblFirstName: "lblFirstName",
  lblLastName: "lblLastName",
  errorRequiredFirstName: "errorRequiredFirstName",
  errorRequiredLastName: "errorRequiredLastName",
  lblAnalytics: "lblAnalytics",
  lblHistory: "lblHistory",
  lblRecentActivity: "lblRecentActivity",
  lblFrequentlyAsked: "lblFrequentlyAsked",
  lblHelpCenter: "lblHelpCenter",
  msgHelpCenter: "msgHelpCenter",
  lblSettings: "lblSettings",
  lblGeneral: "lblGeneral",
  lblConsent: "lblConsent",
  lblConsents: "lblConsents",
  lblSelfConsent: "lblSelfConsent",
  lblAllNotifications: "lblAllNotifications",
  lblCreateNewTicket: "lblCreateNewTicket",
  lblCreateTicket: "lblCreateTicket",
  lblTitle: "lblTitle",
  lblMessage: "lblMessage",
  lblAccounts: "lblAccounts",
  lblOppositeLanguage: "lblOppositeLanguage",
  lblCurrentLanguage: "lblCurrentLanguage",
  lblAuditDiscoverAccounts: "lblAuditDiscoverAccounts",
  lblAuditFailureDiscoverAccounts: "lblAuditFailureDiscoverAccounts",
  lblAuditFailureLinkAccounts: "lblAuditFailureLinkAccounts",
  lblAuditAccountLinkWith: "lblAuditAccountLinkWith",
  lblAuditStoreAccountLink: "lblAuditStoreAccountLink",
  lblAuditInitiateAccountLinkVerificationProcess:
    "lblAuditInitiateAccountLinkVerificationProcess",
  lblFIDataStore: "lblFIDataStore",
  lblFIUDataFetch1: "lblFIUDataFetch1",
  lblFIUDataFetch2: "lblFIUDataFetch2",
  lblAADataFetch1: "lblAADataFetch1",
  lblAADataFetch2: "lblAADataFetch2",
  lblAuditLinkAccount: "lblAuditLinkAccount",
  lblAuditAccountLinked: "lblAuditAccountLinked",
  lblUnLinkAccount: "lblUnLinkAccount",
  lblAuditDataPurge: "lblAuditDataPurge",
  lblAuditUnLinkAccount: "lblAuditUnLinkAccount",
  lblAuditStoreSaveFiRequest: "lblAuditStoreSaveFiRequest",
  lblAuditCreateConsent1: "lblAuditCreateConsent1",
  lblAuditCreateConsent2: "lblAuditCreateConsent2",
  lblAuditVerifyConsent: "lblAuditVerifyConsent",
  lblAuditStoreUpdateConsentRequestFirst:
    "lblAuditStoreUpdateConsentRequestFirst",
  lblAuditStoreUpdateConsentRequestSecond:
    "lblAuditStoreUpdateConsentRequestSecond",
  lblAuditByVUAIDFirst: "lblAuditByVUAIDFirst",
  lblAuditIPFiFetch: "lblAuditIPFiFetch",
  lblAuditStoreConsentAccountFirst: "lblAuditStoreConsentAccountFirst",
  lblAuditStoreConsentAccountSecond: "lblAuditStoreConsentAccountSecond",
  lblPeriodic: "lblPeriodic",
  lblConsentExpiry: "lblConsentExpiry",
  lblProfileSummaryTransaction: "lblProfileSummaryTransaction",
  lblPurpose: "lblPurpose",
  lblPurposeOfConsentRequest: "lblPurposeOfConsentRequest",
  lblAccountTypes: "lblAccountTypes",
  lblTypeOfData: "lblTypeOfData",
  lblFrequency: "lblFrequency",
  lblSearchBarPlaceHolder: "lblSearchBarPlaceHolder",
  lblDataFilter: "lblDataFilter",
  lblConsentValidity: "lblConsentValidity",
  lblDataWillBeDeletedOn: "lblDataWillBeDeletedOn",
  lblDataIsStoredFor: "lblDataIsStoredFor",
  msgLinkNewMobileNumberAsIdentifier: "msgLinkNewMobileNumberAsIdentifier",
  lblDiscoverAccounts: "lblDiscoverAccounts",
  lblIdentifiersFirst: "lblIdentifiersFirst",
  lblIdentifiersSecond: "lblIdentifiersSecond",
  lblResume: "lblResume",
  lblResumeConsent: "lblResumeConsent",
  errorRetryAgain: "errorRetryAgain",
  lblUserProfile: "lblUserProfile",
  lblEditProfileInfo: "lblEditProfileInfo",
  lblEditYourInfo: "lblEditYourInfo",
  lblProfileInfo: "lblProfileInfo",
  lblUpdateProfile: "lblUpdateProfile",
  lblHandle: "lblHandle",
  lblUserName: "lblUserName",
  msgUserProfileSuccess: "msgUserProfileSuccess",
  lblOldPin: "lblOldPin",
  lblNewPin: "lblNewPin",
  lblConfirmPin: "lblConfirmPin",
  errorOldPinRequired: "errorOldPinRequired",
  errorNewPinRequired: "errorNewPinRequired",
  errorConfirmPinRequired: "errorConfirmPinRequired",
  errorPinMisMatch: "errorPinMisMatch",
  errorPinDifferent: "errorPinDifferent",
  lblSureToDeleteAccount: "lblSureToDeleteAccount",
  msgSureToDeleteAccount: "msgSureToDeleteAccount",
  lblDeleteAccount: "lblDeleteAccount",
  lblDelete: "lblDelete",
  msgDeleteAccount: "msgDeleteAccount",
  lblDeleteAccountSuccess: "lblDeleteAccountSuccess",
  lblHi: "lblHi",
  lblReportIssue: "lblReportIssue",
  errorNoReportsFound: "errorNoReportsFound",
  lblReportCategory: "lblReportCategory",
  lblReportDescription: "lblReportDescription",
  lblChooseCategory: "lblChooseCategory",
  lblReportSubmitSuccess: "lblReportSubmitSuccess",
  errorReportSubmitFailure: "errorReportSubmitFailure",
  lblNewPageFetchError: "lblNewPageFetchError",
  errorWriteSomeThing: "errorWriteSomeThing",
  lblGoBack: "lblGoBack",
  lblPauseThisConsent: "lblPauseThisConsent",
  lblReactivateThisConsent: "lblReactivateThisConsent",
  lblRejectThisConsent: "lblRejectThisConsent",
  lblRevokeThisConsent: "lblRevokeThisConsent",
  lblDeleteThisAccount: "lblDeleteThisAccount",
  msgSubTitlePauseConsent: "msgSubTitlePauseConsent",
  msgSubTitleReActivateConsent: "msgSubTitleReActivateConsent",
  msgSubTitleRejectConsent: "msgSubTitleRejectConsent",
  msgSubTitleRevokeConsent: "msgSubTitleRevokeConsent",
  lblNewMobileNumber: "lblNewMobileNumber",
  lblPersonalInformation: "lblPersonalInformation",
  lblAppearance: "lblAppearance",
  lblSecurity: "lblSecurity",
  lblAllTickets: "lblAllTickets",
  lblSolved: "lblSolved",
  lblLinkAnotherAccount: "lblLinkAnotherAccount",
  lblExitApp: "lblExitApp",
  lblMobileNumbers: "lblMobileNumbers",
  lblEditOrAddMobileNumbers: "lblEditOrAddMobileNumbers",
  lblTheme: "lblTheme",
  lblLanguage: "lblLanguage",
  lblChangePassCode: "lblChangePassCode",
  lblEnableBioMetrics: "lblEnableBioMetrics",
  msgEnableBioMetrics: "msgEnableBioMetrics",
  lblEnterOldPassCode: "lblEnterOldPassCode",
  lblTrackSpending: "lblTrackSpending",
  lblReviewLatestActivity: "lblReviewLatestActivity",
  lblFindIntelligentAnswers: "lblFindIntelligentAnswers",
  lblFlagIssueSolved: "lblFlagIssueSolved",
  lblType: "lblType",
  lblLinkedConsents: "lblLinkedConsents",
  lblStatus: "lblStatus",
  lblTransactions: "lblTransactions",
  lblTransactionsDataPeriod: "lblTransactionsDataPeriod",
  lblMode: "lblMode",
  lblConsentDuration: "lblConsentDuration",
  lblFiDataType: "lblFiDataType",
  lblConsumerID: "lblConsumerID",
  lblFiDataFilter: "lblFiDataFilter",
  lblSurePauseConsentTo: "lblSurePauseConsentTo",
  lblSureRevokeConsentTo: "lblSureRevokeConsentTo",
  lblSureReActivateConsentTo: "lblSureReActivateConsentTo",
  lblSureAcceptConsentTo: "lblSureAcceptConsentTo",
  lblSureRejectConsentTo: "lblSureRejectConsentTo",
  lblEnsureLinkedMobileNumber: "lblEnsureLinkedMobileNumber",
  lblEnterCodeSentTo: "lblEnterCodeSentTo",
  lblChangePassCodeSuccess: "lblChangePassCodeSuccess",
  msgChangePassCodeSuccess: "msgChangePassCodeSuccess",
  lblReActivateConsentSuccess: "lblReActivateConsentSuccess",
  msgReActivateConsentSuccess: "msgReActivateConsentSuccess",
  lblAccountLinkSuccess: "lblAccountLinkSuccess",
  lblRejectedConsentTo: "lblRejectedConsentTo",
  lblRevokedConsentTo: "lblRevokedConsentTo",
  lblPausedConsentTo: "lblPausedConsentTo",
  lblProvidedConsentToSuccess: "lblProvidedConsentToSuccess",
  msgProvidedConsentToSuccess: "msgProvidedConsentToSuccess",
  msgReActivateConsent: "msgReActivateConsent",
  msgShareConsentData: "msgShareConsentData",
  lblDontHaveAccounts: "lblDontHaveAccounts",
  msgDontHaveAccounts: "msgDontHaveAccounts",
  lblDeletedAccount: "lblDeletedAccount",
  lblAddedNewMobileSuccess: "lblAddedNewMobileSuccess",
  msgAddedNewMobile: "msgAddedNewMobile",
  lblYourInfo: "lblYourInfo",
  hintMobileNumber: "hintMobileNumber",
  lblAsStatedInID: "lblAsStatedInID",
  lblNotifications: "lblNotifications",
  lblReasonsToDelete: "lblReasonsToDelete",
  lblReasonsToReject: "lblReasonsToReject",
  lblReasonsToRevoke: "lblReasonsToRevoke",
  lblDidNotReceiveConsent: "lblDidNotReceiveConsent",
  msgNoConsents: "msgNoConsents",
  lblSelectAccounts: "lblSelectAccounts",
  lblAccountsSelected: "lblAccountsSelected",
  lblSelectAccountsV2: "lblSelectAccountsV2",
  lblResendCodeIn: "lblResendCodeIn",
  lblAccountNumber: "lblAccountNumber",
  lblTransactionDetails: "lblTransactionDetails",
  lblTotalBalance: "lblTotalBalance",
  msgGrievanceInfo: "msgGrievanceInfo",
  msgGrievanceInfoFirst: "msgGrievanceInfoFirst",
  msgGrievanceInfoSecond: "msgGrievanceInfoSecond",
  msgGrievanceInfoThird: "msgGrievanceInfoThird",
  msgBioMetrics: "msgBioMetrics",
  lblEnterIdentifierValue: "lblEnterIdentifierValue",
  errorEmailRequired: "errorEmailRequired",
  errorEnterValidEmail: "errorEnterValidEmail",
  errorEnterValidDOB: "errorEnterValidDOB",
  errorAadhaarRequired: "errorAadhaarRequired",
  errorEnterValidAadhaar: "errorEnterValidAadhaar",
  errorPanRequired: "errorPanRequired",
  errorEnterValidPan: "errorEnterValidPan",
  lblCategoryOfIdentifier: "lblCategoryOfIdentifier",
  lblValueOfIdentifier: "lblValueOfIdentifier",
  lblVerify: "lblVerify",
  lblConsentUpdate: "lblConsentUpdate",
  lblConsentApprove: "lblConsentApprove",
  lblConsentCreated: "lblConsentCreated",
  lblDataExpire: "lblDataExpire",
  lblDataFetch: "lblDataFetch",
  lblDataFIUfetch: "lblDataFIUfetch",
  lblApproved: "lblApproved",
  lblCreated: "lblCreated",
  lblPaused: "lblPaused",
  lblRequiredIdentifier: "lblRequiredIdentifier",
  succesfullLinkMessage: "succesfullLinkMessage",
  succesfullLinkDescription: "succesfullLinkDescription",
  lblSelectBank: "lblSelectBank",
  lblSelectMobile: "lblSelectMobile",
  lblAccountSelection: "lblAccountSelection",
  lblSearchByAccount: "lblSearchByAccount",
  lblSelectAll: "lblSelectAll",
  lblBack: "lblBack",
  errorPasscodeNotMatch: "errorPasscodeNotMatch",
  errorInvalidOTP: "errorInvalidOTP",
  lblCheckMobile: "lblCheckMobile",
  lblResendIn: "lblResendIn",
  lblEnterMobile: "lblEnterMobile",
  lblIssueType: "lblIssueType",
  lblSendTicket: "lblSendTicket",
  lblRequiredField: "lblRequiredField",
  lblAccountS: "lblAccountS",
  lblConsentS: "lblConsentS",
  lblShareWithUs: "lblShareWithUs",
  lblYourFeedback: "lblYourFeedback",
  lblFormReason: "lblFormReason",
  lblFixingIssue: "lblFixingIssue",
  successLogin: "successLogin",
  succesLogout: "succesLogout",
  rejectConsentMessage: "rejectConsentMessage",
  rejectConsentDescription: "rejectConsentDescription",
  lblSecurityDescription: "lblSecurityDescription",
  lblAppearanceDescription: "lblAppearanceDescription",
  acceptSuccesfull: "acceptSuccesfull",
  acceptSuccesfullDescription: "acceptSuccesfullDescription",
  pauseSuccesfull: "pauseSuccesfull",
  revokeSuccesfull: "revokeSuccesfull",
  reactivateSuccesfull: "reactivateSuccesfull",
  rejectSuccesfull: "rejectSuccesfull",
  reactivateDescription: "reactivateDescription",
  revokeDescription: "revokeDescription",
  pauseDescription: "pauseDescription",
  rejectDescription: "rejectDescription",
  consentReject: "consentReject",
  consentRevoke: "consentRevoke",
  consentReady: "consentReady",
  lblApprove: "lblApprove",
  lblApproveConfirmation: "lblApproveConfirmation",
  lblAddIdentifier: "lblAddIdentifier",
  identifierAddSuccess: "identifierAddSuccess",
  errorEnterValidAccountNumber: "errorEnterValidAccountNumber",
  accountDeleteDescripiion: "accountDeleteDescripiion",
  consentPauseDescription: "consentPauseDescription",
  linkedConsentCount: "linkedConsentCount",
  consentUpdateConfirmation: "consentUpdateConfirmation",
  lblFeedback: "lblFeedback",
  lblAuditDeleteIdentifier: "lblAuditDeleteIdentifier",
  msgDeleteIdentifier: "msgDeleteIdentifier",
  identiferDeleteDescription: "identiferDeleteDescription",
  linkedAccountsCount: "linkedAccountsCount",
  lblDeleteThisIdentifier: "lblDeleteThisIdentifier",
  enterIdentifier: "enterIdentifier",
  messageAccountDelete: "messageAccountDelete",
  grievanceOfficer: "grievanceOfficer",
  helpCenterDes: "helpCenterDes",
  System: "System",
  Light: "Light",
  Dark: "Dark",
  lblComingSoon: "lblComingSoon",
  lblAccountNotListed: "lblAccountNotListed",
  lblFITypes: "lblFITypes",
  lblAcceptConsents: "lblAcceptConsents",
  lblFetchType: "lblFetchType",
  lblAddRequiredIdentifiers: "lblAddRequiredIdentifiers",
  lblSelectRequiredIdentifier: "lblSelectRequiredIdentifier",
  lblAddIdentifiers: "lblAddIdentifiers",
  lblSelectIdentifiers: "lblSelectIdentifiers",
  lblOTHER: "lblOTHER",
  lblAboutSaafe: "lblAboutSaafe",
  lblAll: "lblAll",
  lblBank: "lblBank",
  lblMutualFunds: "lblMutualFunds",
  lblInsurance: "lblInsurance",
  lblPension: "lblPension",
  lblRealEstate: "lblRealEstate",
  lblOthers: "lblOthers",
  lblGst: "lblGst",
  lblEquities: "lblEquities",
  lblErrorIdentifier: "lblErrorIdentifier",
};
