import React from "react";
import { useTheme } from "../../../../App";

const ScaffoldComponent: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const theme = useTheme();
  return (
    <div className={`${theme.backgroundColor} ${className}`}>{children}</div>
  );
};

export default ScaffoldComponent;
