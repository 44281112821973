import { LeftOutlined } from "@ant-design/icons";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./index.css";
const TermsAndCondition: React.FC<{ web?: boolean; goBack?: () => void }> = ({
  web,
  goBack,
}) => {
  const history = useHistory();
  const location: any = useLocation();
  return (
    <div className="c6 c46 doc-content">
      <h2
        style={{ display: "flex", width: "100%", justifyContent: "flex-start" }}
        className="c6 c21 c40"
      >
        <span className="c9 c49">
          {location && location.state && location.state.web && (
            <LeftOutlined onClick={() => history.goBack()} />
          )}
          {web && <LeftOutlined onClick={() => goBack && goBack()} />}{" "}
        </span>
        <span style={{ marginLeft: "30%" }} className="c0">
          Terms and Condition (Agreement)
        </span>
      </h2>
      <h3 className="c20 c18 c6">
        <span className="c1" />
      </h3>
      <ol className="c3 lst-kix_list_24-0 start" start={1}>
        <li className="c10 c6 li-bullet-0">
          <h3 style={{ display: "inline" }}>
            <span className="c0">General </span>
          </h3>
        </li>
      </ol>
      <p className="c16">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_1-0 start" start={1}>
        <li className="c20 c37 c6 li-bullet-1">
          <span className="c1">
            This document is an electronic record in terms of the Information
            Technology Act, 2000 and rules there under force, and the provisions
            pertaining to electronic records in various statutes as amended by
            the Information Technology Act, 2000 from time to time. This
            electronic record is generated by a computer system and does not
            require any physical or digital signatures for validation, consent
            or acceptance.
          </span>
        </li>
        <li className="c20 c37 c6 li-bullet-2">
          <span className="c15">
            This &nbsp;Agreement constitute a binding and enforceable legal
            contract between Dashboard Account Aggregation Services Private
            Limited and any End-User of the Services.{" "}
          </span>
          <span className="c9">
            "End-User / Customer/ “You”/” Your”/” Yourself”{" "}
          </span>
          <span className="c1">
            shall mean the individual user accessing Platform via which he/she
            interacts/engages in our Platform and has consented to this
            Agreement. .
          </span>
        </li>
        <li className="c20 c6 c37 li-bullet-1">
          <span className="c15">The terms “</span>
          <span className="c9">Company</span>
          <span className="c15">” / </span>
          <span className="c9">We</span>
          <span className="c15">” / “</span>
          <span className="c9">Us</span>
          <span className="c15">” / “</span>
          <span className="c9">Our</span>
          <span className="c1">
            ” used in these “Agreement” refer to Dashboard Account Aggregation
            Services Private Limited. &nbsp;
          </span>
        </li>
        <li className="c20 c37 c6 li-bullet-1">
          <span className="c15">
            This Agreement govern the access to, and use of the Company’s mobile
            application and website (
          </span>
          <span className="c9">“Platform”</span>
          <span className="c1">
            ) and the Services obtained thereof (defined below)
          </span>
        </li>
        <li className="c20 c37 c6 li-bullet-1">
          <span className="c1">
            By agreeing to this Agreement or by downloading, installing or
            otherwise using the Platform or the Services provided by the
            Company, you accept and agree to the terms and conditions herein. If
            you do not agree to any of these terms, then please do not use the
            Platform or the Services of the Company.
          </span>
        </li>
        <li className="c20 c37 c6 li-bullet-1">
          <span className="c1">
            The Company may modify this Agreement from time to time and such
            modification shall be effective immediately on such modification of
            this Agreement. You agree to be bound to any changes to this
            Agreement when you use the Services after any such modification is
            updated. It is therefore important that you review this Agreement
            regularly upon each use of the Services to ensure you are updated as
            to any changes. In case You are an employee of any organization or
            in case You are authorized to act the on behalf of any
            organization/institution, Your acceptance of this Agreement implies
            acceptance by such relevant organization/institution.
          </span>
        </li>
        <li className="c20 c37 c6 li-bullet-1">
          <span className="c1">
            This Agreement, the Services and the relationship between You and
            Company shall be governed in accordance with the laws of India. You
            agree that all claims, differences, and disputes arising under or in
            connection with or in relation hereto the Platform, this Agreement,
            the Agreement(s) entered into on or through the Platform or the
            relationship between You and Company shall be subject to the
            exclusive jurisdiction of the courts at Chennai.
          </span>
        </li>
        <li className="c20 c37 c6 li-bullet-2">
          <span className="c15">
            These Terms also include our privacy policy, available at{" "}
          </span>
          <span className="c13 c28">
            <a
              className="c26"
              href="https://www.google.com/url?q=https://saafe.in/&sa=D&source=editors&ust=1665397348797475&usg=AOvVaw186w-UL8zkanpNHrM6npx5"
            >
              https://saafe.in/
            </a>
          </span>
          <span className="c15">&nbsp; (“</span>
          <span className="c9">Privacy Policy</span>
          <span className="c15">
            ”), and any guidelines, additional terms, policies, or disclaimers
            made available or issued by us from time to time. We may amend this
            Agreement following the provisions hereunder, and the Agreement
            shall apply to you as amended from time to time. You may always
            review the current version of these Terms on the website (
          </span>
          <span className="c13 c28">
            <a
              className="c26"
              href="https://www.google.com/url?q=https://saafe.in/&sa=D&source=editors&ust=1665397348798052&usg=AOvVaw0K_DfP6g6krNRVELIRNO6h"
            >
              https://saafe.in/
            </a>
          </span>
          <span className="c1">).</span>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_1-0" start={9}>
        <li className="c20 c37 c6 li-bullet-2">
          <span className="c1">
            Terms used in this ‘’Terms and conditions” agreed to by the Customer
            shall have the same meaning as ascribed to them in the Master
            Direction- Non-Banking Financial Company - Account Aggregator
            (Reserve Bank) Directions, 2016 (hereinafter called "AA Master
            Directions") and subsequent amendments thereto, unless the context
            indicates otherwise
          </span>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c0" />
      </p>
      <ol className="c3 lst-kix_list_24-0" start={2}>
        <li className="c6 c11 li-bullet-3">
          <h3 style={{ display: "inline" }}>
            <span className="c0">DEFINITIONS</span>
          </h3>
        </li>
      </ol>
      <ol className="c3 lst-kix_list_37-0 start" start={1}>
        <li className="c24 li-bullet-4">
          <span className="c1">
            “Master Directions” shall mean the Master Direction - Non-Banking
            Financial Company - Account Aggregator(Reserve Bank) Directions,
            2016 as amended, revised, or updated from time to time;
          </span>
        </li>
      </ol>
      <p className="c30 c42">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_37-0" start={2}>
        <li className="c24 li-bullet-3">
          <span className="c1">
            &nbsp;“Financial Information” shall have the same meaning ascribed
            to it in the Master Directions;
          </span>
        </li>
      </ol>
      <p className="c30 c42">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_37-0" start={3}>
        <li className="c24 li-bullet-3">
          <span className="c1">
            “Financial Information Provider” shall have the same meaning
            ascribed to it in the Master Directions;
          </span>
        </li>
      </ol>
      <p className="c30 c42">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_37-0" start={4}>
        <li className="c24 li-bullet-5">
          <span className="c1">
            “Financial Information User” shall have the same meaning ascribed to
            it in the Master Directions;
          </span>
        </li>
      </ol>
      <p className="c30 c42">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_37-0" start={5}>
        <li className="c24 li-bullet-4">
          <span className="c1">
            “Financial Sector Regulator” shall have the same meaning ascribed to
            it in the Master Directions;
          </span>
        </li>
      </ol>
      <p className="c30 c42">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_37-0" start={6}>
        <li className="c24 li-bullet-5">
          <span className="c1">
            “Person” shall have the same meaning ascribed to it in the Master
            Directions;
          </span>
        </li>
      </ol>
      <p className="c23 c30">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_37-0" start={7}>
        <li className="c14 li-bullet-3">
          <span className="c1">
            “RBI” shall mean the Reserve Bank of India.
          </span>
        </li>
      </ol>
      <ol className="c3 lst-kix_list_24-0" start={3}>
        <li className="c10 c6 li-bullet-0">
          <h3 style={{ display: "inline" }}>
            <span className="c0">Eligibility</span>
          </h3>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c0" />
      </p>
      <ol className="c3 lst-kix_list_11-0 start" start={1}>
        <li className="c4 c6 li-bullet-1">
          <span className="c1">
            Competence: Use of the Platform is available only to those
            individuals and organizations who can form legally binding contracts
            under applicable law in their respective jurisdictions. Persons who
            are “incompetent to contract” as per applicable law in their
            jurisdictions are not eligible to use the Platform. If you are a
            minor, i.e., under the age of 18 years, you shall not register as a
            user of the Platform and shall not use the Platform. As a minor, if
            you wish to use the Platform, such use may be made by Your legal
            guardian or parents on the Platform. Company reserves the right to
            terminate Your membership and/or refuse to provide you with access
            to the Platform if it is brought to the Company’s notice or if it is
            discovered that you are under the age of 18 years.
          </span>
        </li>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            Authorization: In case You are registering as a business or a
            corporate entity, You represent that You are duly authorized by the
            business / corporate entity as applicable to accept this Agreement
            and You have the authority to bind that business / corporate entity
            to this Agreement.
          </span>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c0" />
      </p>
      <ol className="c3 lst-kix_list_24-0" start={4}>
        <li className="c10 c6 li-bullet-0">
          <h3 style={{ display: "inline" }}>
            <span className="c0">User Account</span>
          </h3>
        </li>
      </ol>
      <ol className="c3 lst-kix_list_3-0 start" start={1}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            In order to use the Platform and avail the Services offered by
            Company, the Company may require You to fill an online form and
            register themselves (“
          </span>
          <span className="c0">Registration Information</span>
          <span className="c1">”). &nbsp;</span>
        </li>
        <li className="c4 c6 li-bullet-1">
          <span className="c1">
            You will be required to create a profile on the Platform (“
          </span>
          <span className="c0">Profile</span>
          <span className="c1">
            ”). In addition to setting up a username and password to create the
            Profile, you will be required to furnish certain details, including
            but not limited to phone numbers and other personal information. You
            warrant that all information furnished in connection with Your
            Profile is and shall remain accurate and true, and you agree that
            you shall promptly update Your details on the Platform in the event
            of any change to or modification of this information.
          </span>
        </li>
        <li className="c4 c6 li-bullet-1">
          <span className="c1">
            You are solely responsible for maintaining the security and
            confidentiality of Your username and password and agree to
            immediately notify us of any disclosure or unauthorised use of Your
            Profile or any other breach of security with respect to Your
            Profile.
          </span>
        </li>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            You agree and accept that the information provided by You is
            complete, accurate and up-to-date. In the event of any change to
            such information, you shall update the Registration Information and
            Profile to keep it true, accurate, current and complete.{" "}
          </span>
        </li>
      </ol>
      <p className="c8 c6 c50">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_3-0" start={5}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            You acknowledge and accept that the Company has not independently
            verified the information provided by You. The Company shall in no
            way be responsible or liable for the accuracy, inaccuracy,
            obsolescence or completeness of any information provided by You.
          </span>
        </li>
      </ol>
      <p className="c5">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_3-0" start={6}>
        <li className="c4 c6 li-bullet-1">
          <span className="c1">
            If You provide any information that is untrue, inaccurate, obsolete
            or incomplete, or the Company has reasonable grounds to suspect that
            such information is untrue, inaccurate, obsolete or incomplete, the
            Company reserves the right to suspend or terminate Your Profile and
            refuse any and all current or future use of the Services
          </span>
        </li>
      </ol>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_3-0" start={7}>
        <li className="c4 c6 li-bullet-1">
          <span className="c1">
            You agree and understand that You will be solely responsible for
            maintaining the confidentiality of your password, which, together
            with Your login ID, allows You to access the Services. The Company
            shall not be liable for any leak of information on the part of the
            End-User and the consequences of the same. You agree to immediately
            notify us of any disclosure or unauthorized use of Your credentials
            or any other breach of security concerning Your Profile.{" "}
          </span>
        </li>
      </ol>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_3-0" start={8}>
        <li className="c4 c6 li-bullet-1">
          <span className="c1">
            By providing us with Your email address and mobile number, You agree
            to receive all required notices, notifications and information
            electronically on that email address or mobile number. It is Your
            responsibility to update any changes to Your email address and
            mobile number
          </span>
          <span className="c7">.</span>
        </li>
      </ol>
      <p className="c5">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_3-0" start={9}>
        <li className="c4 c6 li-bullet-1">
          <span className="c1">
            If You become aware of any unauthorized use of Your Registration
            Information or Profile, You agree to notify Company immediately at
            the customer service helpdesk, the details of which are available on
            the Platform. The Company shall not be liable for any unauthorized
            use or access unless it is proved that unauthorized use or access
            occurred solely due to reasons directly attributable to Company.
          </span>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c0" />
      </p>
      <ol className="c3 lst-kix_list_24-0" start={5}>
        <li className="c6 c10 li-bullet-0">
          <h3 style={{ display: "inline" }}>
            <span className="c13">Services</span>
          </h3>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c0" />
      </p>
      <ol className="c3 lst-kix_list_12-0 start" start={1}>
        <li className="c4 c6 li-bullet-1">
          <span className="c1">
            Through the Platform, you may avail of the Account Aggregation
            Services referred to as “
          </span>
          <span className="c0">Services</span>
          <span className="c1">
            ” (which term also includes the provision of the Platform to the End
            Customer).
          </span>
        </li>
      </ol>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <p className="c20 c6 c23">
        <span className="c1">For this purpose,</span>
        <span className="c0">&nbsp;"Account Aggregation Services" </span>
        <span className="c1">
          refers to the services provided by us following Master Directions
          which enable the following:
        </span>
      </p>
      <p className="c8 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_15-0 start" start={1}>
        <li className="c2 li-bullet-0">
          <span className="c1">
            Retrieving, viewing, consolidating, organizing, and sharing
            Financial Information that is available with Financial Information
            Providers (FIPs); and
          </span>
        </li>
        <li className="c2 li-bullet-0">
          <span className="c1">
            Presenting Your Financial Information collected from the Financial
            Information Provider to Financial Information Users (FIUs).
          </span>
        </li>
      </ol>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_12-0" start={2}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            For us to render the Services to you, You hereby authorize and
            appoint us as Your agent with limited power to fetch or retrieve,
            with Your consent, Your Financial Information, from Financial
            Information Providers.
          </span>
        </li>
      </ol>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_12-0" start={3}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            The provision of any or all the Services is subject to applicable
            laws in India. Further, the provision of any or all of the Services
            is subject to the sole discretion of the Company and its
            availability on the Platform. Please check our Platform to confirm
            if any particular Services are available.
          </span>
        </li>
      </ol>
      <p className="c16 c23">
        <span className="c7" />
      </p>
      <p className="c8 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_12-0" start={4}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            The information provided by the Company as part of it services is on
            "as is" basis, as received from the Financial Information Provider,
            and without warranty of any kind, express or implied, including, but
            not limited to, implied warranties of merchantability, fitness for a
            particular purpose, title or non-infringement.
          </span>
        </li>
      </ol>
      <p className="c16 c23">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_12-0" start={5}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            Further, all Financial Information or obtained from Financial
            Information Providers may be based on delayed feeds and may not
            reflect the real-time / rates. The Company shall not be responsible
            for any errors or delays in the Financial Information provided to
            End Users as part of its Services or for any actions taken by End
            Users in reliance thereon.
          </span>
        </li>
      </ol>
      <p className="c16 c23">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_12-0" start={6}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            We presently do not charge you any fees for Your use of the
            Services. However, we reserve the right to charge you for the
            Services in accordance with the applicable laws. Should we intend to
            charge for the Services, the fees shall be in line with our pricing
            policy available on our Platform. Additionally, any fees in the
            future shall be levied prospectively and You will be provided a
            prior written notice in this case.
          </span>
        </li>
      </ol>
      <p className="c30 c23">
        <span className="c1" />
      </p>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_24-0" start={6}>
        <li className="c10 c6 li-bullet-0">
          <h3 style={{ display: "inline" }}>
            <span className="c0">Company’s Obligations</span>
          </h3>
        </li>
      </ol>
      <p className="c8">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_25-0 start" start={1}>
        <li className="c4 li-bullet-2">
          <span className="c1">We shall</span>
        </li>
      </ol>
      <p className="c5">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_26-0 start" start={1}>
        <li className="c19 li-bullet-2">
          <span className="c1">
            provide Services to you only based on your explicit consent.
          </span>
        </li>
        <li className="c19 li-bullet-1">
          <span className="c1">
            ensure that the provision of Services to you is backed by
            appropriate agreements and/or authorisations between us, You, and
            the Financial Information Providers.
          </span>
        </li>
        <li className="c19 li-bullet-1">
          <span className="c1">
            ensure not store any Financial Information that relates to You and
            which we may process in connection with the Services.
          </span>
        </li>
        <li className="c19 li-bullet-1">
          <span className="c1">
            not use the services of a third-party service provider for
            undertaking the business of account aggregation.not support your
            transactions by You.{" "}
          </span>
        </li>
        <li className="c19 li-bullet-1">
          <span className="c1">
            ensure appropriate mechanisms for proper customer identification{" "}
          </span>
        </li>
        <li className="c19 li-bullet-2">
          <span className="c1">
            Share information with the Financial Information User as authorized
            by You in accordance with the terms of the consent provided by You.
          </span>
        </li>
        <li className="c19 li-bullet-2">
          <span className="c1">
            None of Your Financial Information accessed by the Company from the
            Financial Information Providers shall reside with Company
          </span>
        </li>
        <li className="c19 li-bullet-2">
          <span className="c1">
            Not access Your user authentication credentials relating to accounts
            with various Financial Information Providers{" "}
          </span>
        </li>
        <li className="c19 li-bullet-2">
          <span className="c1">
            not part with any information that it may come to acquire from/ on
            behalf of You without Your explicit consent.
          </span>
        </li>
        <li className="c19 li-bullet-2">
          <span className="c1">
            not use or access you Financial Information other than for
            performing Services as explicitly requested by the End User.
          </span>
        </li>
      </ol>
      <p className="c16" id="h.gjdgxs">
        <span className="c0" />
      </p>
      <ol className="c3 lst-kix_list_24-0" start={7}>
        <li className="c10 c6 li-bullet-0">
          <h3 style={{ display: "inline" }}>
            <span className="c0">Consent Architecture</span>
          </h3>
        </li>
      </ol>
      <p className="c16">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_16-0 start" start={1}>
        <li className="c4 li-bullet-1">
          <span className="c1">
            The Company will not retrieve, share or transfer your Financial
            Information without Your explicit consent.
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1">
            The Company shall perform the function of obtaining, submitting and
            managing your consent in accordance Master Direction.{" "}
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1">
            The Company shall obtain your consent in a standardized consent
            artefact which shall contain the following details:{" "}
          </span>
        </li>
      </ol>
      <p className="c8 c50">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_20-1 start" start={1}>
        <li className="c20 c31 li-bullet-6">
          <span className="c1">Your identity and contact information.</span>
        </li>
        <li className="c20 c31 li-bullet-6">
          <span className="c1">
            The nature of the Financial Information requested.
          </span>
        </li>
        <li className="c20 c31 li-bullet-6">
          <span className="c1">
            purpose of collecting such Financial Information.
          </span>
        </li>
        <li className="c20 c31 li-bullet-7">
          <span className="c1">
            the identity of the recipients of the Financial Information, if any.
          </span>
        </li>
        <li className="c20 c31 li-bullet-6">
          <span className="c1">
            URL or other address to which notification needs to be sent every
            time the consent artefact is used to access information
          </span>
        </li>
        <li className="c20 c31 li-bullet-8">
          <span className="c1">
            Consent creation date, expiry date, identity and signature/ digital
            signature of the Company; and
          </span>
        </li>
        <li className="c20 c31 li-bullet-6">
          <span className="c1">
            any other attribute as may be prescribed by the RBI. .
          </span>
        </li>
      </ol>
      <p className="c5">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_16-0" start={4}>
        <li className="c4 li-bullet-2">
          <span className="c1">
            At the time of obtaining consent, the Company shall inform You of
            all necessary attributes to be contained in the consent artefact as
            mentioned above and Your right to file complaints with relevant
            authorities in case of non-redressal of grievances.
          </span>
        </li>
        <li className="c4 li-bullet-9">
          <span className="c1">
            The Company shall provide you a functionality to revoke consent to
            obtain information that is rendered accessible by a consent
            artefact, including the ability to revoke consent to obtain parts of
            such information.
          </span>
        </li>
        <li className="c4 li-bullet-9">
          <span className="c1">
            The Company will ensure that the electronic consent artefact is
            capable of being logged, audited and verified.
          </span>
        </li>
        <li className="c4 li-bullet-9">
          <span className="c1">
            You may, at any point in time, access a record of the consents
            provided by You and the Financial Information Users with whom the
            information has been shared on the Platform.
          </span>
        </li>
      </ol>
      <p className="c8">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_24-0" start={8}>
        <li className="c10 c6 li-bullet-0">
          <h3 id="h.30j0zll" style={{ display: "inline" }}>
            <span className="c0">Usage of information</span>
          </h3>
        </li>
      </ol>
      <ol className="c3 lst-kix_list_19-0 start" start={1}>
        <li className="c4 li-bullet-9">
          <span className="c1">
            In the cases where Financial Information has been provided by a
            Financial Information Provider to the Company for transferring to a
            Financial Information User with your explicit consent, the Company
            shall:
          </span>
        </li>
      </ol>
      <ol className="c3 lst-kix_list_27-0 start" start={1}>
        <li className="c20 c27 li-bullet-0">
          <span className="c1">
            verify the identity of the Financial Information User; and, if
            verified,
          </span>
        </li>
        <li className="c20 c27 li-bullet-0">
          <span className="c1">
            securely transfer your information to the intended recipient in
            accordance with the terms of the consent
            artefact.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </li>
      </ol>
      <ol className="c3 lst-kix_list_19-0" start={2}>
        <li className="c4 li-bullet-10">
          <span className="c1">
            In the cases where Financial information has been provided by a
            Financial Information Provider to the Company for transferring to
            you or to a Financial Information User, the Company shall not use or
            disclose except as may be specified in the consent artefact.
          </span>
        </li>
        <li className="c4 li-bullet-2">
          <span className="c1">
            You agree that we may, in accordance with our Privacy Policy,
            collect and use your personal information and technical data and
            related information.
          </span>
        </li>
        <li className="c4 li-bullet-9">
          <span className="c1">
            We may use information and data pertaining to Your use of the
            Services for analytics, trends’ identification, and statistics to
            further enhance the effectiveness and efficiency of the Platform.
          </span>
        </li>
        <li className="c23 c35 li-bullet-2">
          <span className="c1">
            Subject to applicable laws, we may be directed by law enforcement
            agencies or the government and related bodies to disclose data
            related to you in connection with criminal proceedings. You
            understand and agree that in such instances, we shall have the right
            to share such data with relevant agencies or bodies.
          </span>
        </li>
      </ol>
      <h3 className="c20 c18 c6 c23">
        <span className="c0" />
      </h3>
      <ol className="c3 lst-kix_list_24-0" start={9}>
        <li className="c10 c6 li-bullet-0">
          <h3 style={{ display: "inline" }}>
            <span className="c0">End User Rights</span>
          </h3>
        </li>
      </ol>
      <h3 className="c20 c6 c21 c23">
        <span className="c0">
          The Company shall give you access to record of{" "}
        </span>
      </h3>
      <ul className="c3 lst-kix_list_18-1 start">
        <li className="c20 c29 li-bullet-2">
          <span className="c1">the consents provided by You and </span>
        </li>
        <li className="c20 c29 li-bullet-2">
          <span className="c1">
            the Financial Information Users with whom the Financial Information
            has been shared.
          </span>
        </li>
      </ul>
      <h3 className="c20 c18 c6 c23">
        <span className="c1" />
      </h3>
      <ol className="c3 lst-kix_list_24-0" start={10}>
        <li className="c10 c6 li-bullet-0">
          <h3 style={{ display: "inline" }}>
            <span className="c9 c38">Prohibited Use</span>
          </h3>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_13-0 start" start={1}>
        <li className="c4 c6 li-bullet-9">
          <span className="c1">
            As a condition of Your use of the Services, You will not use
            Platform for any purpose that is unlawful or is prohibited by this
            Agreement or by any applicable law. Your usage of the Platform is
            solely Your responsibility.
          </span>
        </li>
      </ol>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_13-0" start={2}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            You shall not, host, display, upload, modify, publish, transmit,
            store, update or share any information that–
          </span>
        </li>
      </ol>
      <ol className="c3 lst-kix_list_6-0 start" start={1}>
        <li className="c2 li-bullet-0">
          <span className="c1">
            belongs to another person and to which the You &nbsp;does not have
            any right;{" "}
          </span>
        </li>
        <li className="c2 li-bullet-0">
          <span className="c1">
            is defamatory, obscene, pornographic, pedophilic, invasive of
            another‘s privacy, including bodily privacy, insulting or harassing
            on the basis of gender, libelous, racially or ethnically
            objectionable, relating or encouraging money laundering or gambling,
            or otherwise inconsistent with or contrary to the laws in force;{" "}
          </span>
        </li>
        <li className="c2 li-bullet-0">
          <span className="c1">is harmful to child; </span>
        </li>
        <li className="c2 li-bullet-0">
          <span className="c1">
            infringes any patent, trademark, copyright or other proprietary
            rights;{" "}
          </span>
        </li>
        <li className="c2 li-bullet-0">
          <span className="c1">
            violates any law for the time being in force;{" "}
          </span>
        </li>
        <li className="c2 li-bullet-0">
          <span className="c1">
            deceives or misleads the addressee about the origin of the message
            or knowingly and intentionally communicates any information which is
            patently false or misleading in nature but may reasonably be
            perceived as a fact;{" "}
          </span>
        </li>
        <li className="c2 li-bullet-0">
          <span className="c1">impersonates another person; </span>
        </li>
        <li className="c2 li-bullet-0">
          <span className="c1">
            threatens the unity, integrity, defense, security or sovereignty of
            India, friendly relations with foreign states, or public order, or
            causes incitement to the commission of any cognizable offence or
            prevents investigation of any offence or is insulting other nation;{" "}
          </span>
        </li>
        <li className="c2 li-bullet-0">
          <span className="c1">
            contains software virus or any other computer code, file or program
            designed to interrupt, destroy or limit the functionality of any
            computer resource;
          </span>
        </li>
        <li className="c2 li-bullet-0">
          <span className="c1">
            &nbsp;is patently false and untrue, and is written or published in
            any form, with the intent to mislead or harass a person, entity or
            agency for financial gain or to cause any injury to any person;.
          </span>
        </li>
      </ol>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_13-0" start={3}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            You shall not use any “deep-link”, “page-scrape”, “robot”, “spider”
            or other automatic devices, program, algorithm or methodology, or
            any similar or equivalent manual process, to access, acquire, copy
            or monitor any portion of the Platform or any content, or in any way
            reproduce or circumvent the navigational structure or presentation
            of the Platform or any content, to obtain or attempt to obtain any
            materials, documents or information through any means not purposely
            made available through the Platform. We reserve our right to bar any
            such activity.
          </span>
        </li>
      </ol>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_13-0" start={4}>
        <li className="c4 c6 li-bullet-9">
          <span className="c1">
            You shall not attempt to gain unauthorized access to any portion or
            feature of the Platform, or any other systems or networks connected
            to the Platform or to any server, computer, network, or to any of
            the services offered on or through the Platform, by hacking,
            password “mining” or any other illegitimate means.
          </span>
        </li>
      </ol>
      <p className="c16 c23">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_13-0" start={5}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            You shall not probe, scan or test the vulnerability of the Platform
            or any network connected to the Platform nor breach the security or
            authentication measures on the Platform or any network connected to
            the Platform. You may not reverse look-up, trace or seek to trace
            any information on any other End User of or visitor to Platform, or
            any other Customer, including any account on the Platform not owned
            by You, to its source, or exploit the Platform or any service or
            information made available or offered by or through the Platform, in
            any way where the purpose is to reveal any information, including
            but not limited to personal identification or information, other
            than Your own information, as provided for by the Platform.
          </span>
        </li>
      </ol>
      <p className="c16 c23">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_13-0" start={6}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            You shall not make any negative, denigrating or defamatory
            statement(s) or comment(s) about Us or the brand name or domain name
            used by Us including the terms Company, or otherwise engage in any
            conduct or action that might tarnish the image or reputation, of
            Company or sellers on platform or otherwise tarnish or dilute any
            Company’s trade or service marks, trade name and/or goodwill
            associated with such trade or service marks, trade name as may be
            owned or used by us. You agree that You will not take any action
            that imposes an unreasonable or disproportionately large load on the
            infrastructure of the Platform or Company’s systems or networks, or
            any systems or networks connected to Company.
          </span>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_13-0" start={7}>
        <li className="c4 c6 li-bullet-10">
          <span className="c1">
            You agree not to use any device, software or routine to interfere or
            attempt to interfere with the proper working of the Platform or any
            transaction being conducted on the Platform, or with any other
            person’s use of the Platform.
          </span>
        </li>
      </ol>
      <p className="c16 c23">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_13-0" start={8}>
        <li className="c4 c6 li-bullet-9">
          <span className="c1">
            You shall not forge headers or otherwise manipulate identifiers in
            order to disguise the origin of any message or transmittal You send
            to Us on or through the Platform or any service offered on or
            through the Platform. You may not pretend that You are, or that You
            represent, someone else, or impersonate any other individual or
            entity.
          </span>
        </li>
      </ol>
      <p className="c16 c23">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_13-0" start={9}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            You shall not use the Platform or any content for any purpose that
            is unlawful or prohibited by these Terms of Use or to solicit the
            performance of any illegal activity or other activity which
            infringes the rights of Company and / or others.
          </span>
        </li>
      </ol>
      <h3 className="c20 c18 c6 c23">
        <span className="c0" />
      </h3>
      <ol className="c3 lst-kix_list_24-0" start={11}>
        <li className="c10 c6 li-bullet-0">
          <h3 style={{ display: "inline" }}>
            <span className="c0">User Obligations</span>
          </h3>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c1" />
      </p>
      <p className="c6 c20">
        <span className="c1">User shall</span>
      </p>
      <p className="c8 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_10-0 start" start={1}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            review the details of all requests that they receive from FIUs
            through our Platform to transfer Financial Information from FIPs to
            such FIU in order to verify the nature and quantum of Financial
            Information requested, the purpose for which such Financial
            Information is being collected and the duration for which it will be
            retained by the FIU;
          </span>
        </li>
      </ol>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_10-0" start={2}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            only provide t consent after having duly verified the details of the
            request to transfer Financial Information &nbsp;
          </span>
        </li>
      </ol>
      <p className="c16 c23">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_10-0" start={3}>
        <li className="c4 c6 li-bullet-9">
          <span className="c1">
            be entitled to revoke their consent for requests to transfer
            Financial Information that have not yet been fulfilled.{" "}
          </span>
        </li>
      </ol>
      <p className="c16 c23">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_10-0" start={4}>
        <li className="c4 c6 li-bullet-10">
          <span className="c1">
            notify the Company immediately if upon becoming aware of any
            unauthorized access to or use of their accounts.
          </span>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c0" />
      </p>
      <ol className="c3 lst-kix_list_24-0" start={12}>
        <li className="c10 c6 li-bullet-0">
          <h3 style={{ display: "inline" }}>
            <span className="c0">Third Party Accounts</span>
          </h3>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c0" />
      </p>
      <ol className="c3 lst-kix_list_30-0 start" start={1}>
        <li className="c4 c6 li-bullet-10">
          <span className="c1">
            By using the Services, you authorize Company to access third-party
            account websites designated by you, on Your behalf, to retrieve
            account information requested by you and you hereby appoint Company
            as Your agent for this limited purpose.{" "}
          </span>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_30-0" start={2}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            You hereby represent to us and that You are a legal owner of the
            account(s) and have the authority to designate Company as Your
            agent, use the Services and to give Company Your passwords, user
            names and all other information you provide.{" "}
          </span>
        </li>
      </ol>
      <p className="c6 c8">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_30-0" start={3}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            The Services do not have the capability to initiate transactions
            affecting Your third-party financial accounts or provide notices or
            instructions affecting such financial accounts. Transactional and
            informational activities initiated by You at such sites are not made
            through the Services and we assume no responsibility for such
            activities.
          </span>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_30-0" start={4}>
        <li className="c4 c6 li-bullet-9">
          <span className="c1">
            You are responsible for all charges associated with third-party
            accounts and agree to comply with the terms of those services. For
            all purposes hereof, You hereby grant Company a limited power of
            attorney, and you hereby appoint Company as Your true and lawful
            attorneys-in-fact and agents, with full power of substitution and
            re-substitution, for you and in Your name, place and stead, in any
            and all capacities, to access third-party sites, retrieve
            information, and use Your information, all as described above, with
            the full power and authority to do and perform each and every act
            and thing requisite and necessary to be done in connection with such
            activities, as fully to all intents and purposes as you might or
            could do in person.{" "}
          </span>
        </li>
      </ol>
      <p className="c8 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_24-0" start={13}>
        <li className="c10 c6 li-bullet-0">
          <h3 style={{ display: "inline" }}>
            <span className="c13">I</span>
            <span className="c0">ntellectual Property</span>
          </h3>
        </li>
      </ol>
      <p className="c16">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_9-0 start" start={1}>
        <li className="c12 c6 c33 li-bullet-0">
          <span className="c1">
            You understands, acknowledges and agrees that the Company is the
            sole owner of all right, titles and interest including any and all
            intellectual property rights in the Platform, design, software,
            interface, colour scheme, content, logos, trade names, trademarks,
            brand names, designs, services and any such intellectual property
            used in connection with the platform.
          </span>
        </li>
        <li className="c12 c6 c33 li-bullet-0">
          <span className="c1">
            Except as stated herein, none of the materials may be modified,
            copied, reproduced, distributed, republished, downloaded, displayed,
            sold, compiled, posted or transmitted in any form or by any means,
            including but not limited to, electronic, mechanical, photocopying,
            recording or other means, without the prior express written
            permission of the Company. Save and except with Company’s prior
            written consent, You may not insert a hyperlink to the Platform, or
            modify / alter any information or materials contained in the
            Platform.
          </span>
        </li>
      </ol>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_9-0" start={3}>
        <li className="c12 c6 c33 li-bullet-0">
          <span className="c1">
            No license or other such right is granted per this Agreement and
            Your access to and/or use of the Platform should not be construed as
            granting, by implication, estoppel or otherwise, any license or
            right to use any trademarks, service marks or logos appearing in the
            Platform without the prior written consent of the Company or the
            relevant third party proprietor thereof.
          </span>
        </li>
      </ol>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_9-0" start={4}>
        <li className="c12 c6 c33 li-bullet-0">
          <span className="c1">
            You may view, print, and/or download a copy of such materials on any
            single computer solely for your personal, informational,
            non-commercial use, provided the User keeps intact all copyright and
            other proprietary notices.
          </span>
        </li>
      </ol>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_24-0" start={14}>
        <li className="c10 c6 li-bullet-0">
          <h3 style={{ display: "inline" }}>
            <span className="c0">Disclaimers And Warranties</span>
          </h3>
        </li>
      </ol>
      <p className="c5 c6">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_23-0 start" start={1}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            We do not warrant the accuracy, suitability, or correctness of any
            Financial Information that is made available on or through the
            Services.
          </span>
        </li>
        <li className="c4 c6 li-bullet-9">
          <span className="c1">
            The Platform and the Services are provided by us on an “as is” basis
            without warranty of any kind, express, implied, statutory or
            otherwise, including the implied warranties of title,
            non-infringement, merchantability, or fitness for a particular
            purpose. Without limiting the foregoing, we make no warranty that:
          </span>
        </li>
      </ol>
      <ol className="c3 lst-kix_list_23-1 start" start={1}>
        <li className="c20 c29 c6 li-bullet-9">
          <span className="c1">
            the Platform or the Services will meet Your requirements or
            expectations, or that Your use of the Services will be
            uninterrupted, timely, secure, or error-free;
          </span>
        </li>
        <li className="c20 c29 c6 li-bullet-2">
          <span className="c1">
            any errors or defects in the Platform will be corrected.
          </span>
        </li>
      </ol>
      <ol className="c3 lst-kix_list_23-0" start={3}>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            No advice or information, whether oral or written, obtained by you
            from us shall create any warranty that is not expressly stated in
            the Agreement.
          </span>
        </li>
        <li className="c4 c6 li-bullet-2">
          <span className="c1">
            You hereby accept full responsibility for any consequences that may
            arise from Your use of the Services, and expressly agree and
            acknowledge that we shall have absolutely no liability in this
            regard.
          </span>
        </li>
        <li className="c4 c6 li-bullet-9">
          <span className="c1">
            To the fullest extent permissible under applicable law, we, our
            affiliates, and related parties each disclaim all liability towards
            you for any loss or damage arising out of or due to:
          </span>
        </li>
      </ol>
      <ol className="c3 lst-kix_list_39-0 start" start={1}>
        <li className="c20 c6 c22 li-bullet-3">
          <span className="c1">
            Your use of, inability to use, or availability or unavailability of
            the Services;
          </span>
        </li>
        <li className="c20 c22 c6 li-bullet-4">
          <span className="c1">
            the occurrence or existence of any defect, interruption, or delays
            in the operation or transmission of information to, from, or through
            the Services, communications failure, theft, destruction or
            unauthorised access to our records, programs, services, server, or
            other infrastructure relating to the Services; or the failure of the
            Services to remain operational for any period of time.
          </span>
        </li>
      </ol>
      <ol className="c3 lst-kix_list_24-0" start={15}>
        <li className="c10 c6 li-bullet-0">
          <h3 style={{ display: "inline" }}>
            <span className="c0">Miscellaneous</span>
          </h3>
        </li>
      </ol>
      <h3 className="c12 c6 c21">
        <span className="c1">.</span>
      </h3>
      <p className="c36">
        <span className="c43 c38" />
      </p>
      <ol className="c3 lst-kix_list_31-0 start" start={1}>
        <li className="c17 c6 li-bullet-11">
          <h3 style={{ display: "inline" }}>
            <span className="c0">Notice</span>
          </h3>
        </li>
      </ol>
      <p className="c12">
        <span className="c1">
          Company may provide You with notices and communications by e-mail,
          SMS, push notifications, regular mail or postings on the Website or by
          any other reasonable means. Except as otherwise set forth herein, any
          notice to Company must be sent by courier or registered mail and
          addressed to the Grievance Officer.
        </span>
      </p>
      <p className="c8">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_31-0" start={2}>
        <li className="c6 c17 li-bullet-11">
          <h3 style={{ display: "inline" }}>
            <span className="c0">Waiver</span>
          </h3>
        </li>
      </ol>
      <p className="c12">
        <span className="c1">
          Enforcement of the Agreement &nbsp;is solely at Company’s discretion
          and failure to enforce a provision in some instances does not
          constitute a waiver of the Company’s right to enforce such a provision
          in other instances.
        </span>
      </p>
      <p className="c16">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_31-0" start={3}>
        <li className="c17 c6 li-bullet-12">
          <h3 style={{ display: "inline" }}>
            <span className="c0">Severability</span>
          </h3>
        </li>
      </ol>
      <p className="c12">
        <span className="c1">
          In the event that any provision of this Agreement is declared by any
          juridical or other competent authority to be void, voidable, illegal
          or otherwise unenforceable or indications of the same are received by
          either me/us or the Company from any relevant competent authority, the
          Company shall have the right to amend that provision in such
          reasonable manner as it thinks fit without illegality or it may be
          severed from this Agreement in such manner as the Company thinks fit
          and the remaining provisions of these Terms shall remain in full force
          and effect unless the Company decides in its sole discretion to stop
          the provision of Services either wholly or in part.
        </span>
      </p>
      <p className="c8 c34">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_31-0" start={4}>
        <li className="c17 c6 li-bullet-13">
          <h3 style={{ display: "inline" }}>
            <span className="c0">Limitation of Liability</span>
          </h3>
        </li>
      </ol>
      <p className="c6 c12">
        <span className="c1">
          In no event will the Company or their associates or any person
          connected with it be liable for any direct, indirect, special,
          punitive, exemplary, or consequential losses or damages of whatsoever
          kind arising out of Your use or access to the Platform, including loss
          of profit or the like, whether or not in the contemplation of the
          parties, whether based on breach of contract, tort (including
          negligence) or otherwise. The Company acts as a consent management
          platform, passing through encrypted data to the data-seeking entities.
          In case of a data breach caused due to the data-seeking entities in a
          relationship with the Company, the Company is not liable for the
          damage or its consequent action.
        </span>
      </p>
      <p className="c8 c6 c34">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_31-0" start={5}>
        <li className="c17 c6 li-bullet-11">
          <h3 style={{ display: "inline" }}>
            <span className="c13">Indemnity</span>
          </h3>
        </li>
      </ol>
      <p className="c12">
        <span className="c1">
          To the full extent permitted by applicable law, the End User, hereby
          agrees to indemnify the Company, and its affiliates, officers,
          directors, agents, employees, and suppliers against any action,
          liability, cost, claim, loss, damage, proceeding or expense suffered
          or incurred if directly or not directly arising from Your use of the
          Platform and Services, or from Your violation of this Agreement and
          applicable laws.
        </span>
      </p>
      <ol className="c3 lst-kix_list_31-0" start={6}>
        <li className="c17 c6 li-bullet-11">
          <h3 style={{ display: "inline" }}>
            <span className="c13">Assignment</span>
          </h3>
        </li>
      </ol>
      <p className="c12">
        <span className="c1 c6">
          You shall not license, sell, transfer, or assign their rights,
          obligations, or covenants under these Terms in any manner without our
          prior written consent. We may grant or withhold this consent at our
          sole discretion and subject to any conditions we deem appropriate. We
          may assign our rights to any of our affiliates, subsidiaries, parent
          companies, or any successor in interest of any business associated
          with the Services without prior notice to you.
        </span>
      </p>
      <p className="c8 c34">
        <span className="c1" />
      </p>
      <ol className="c3 lst-kix_list_31-0" start={7}>
        <li className="c17 c6 li-bullet-12">
          <h3 style={{ display: "inline" }}>
            <span className="c13">Third</span>
            <span className="c13 c6">&nbsp;Party Rights: </span>
            <span className="c1 c6">
              No third party shall have any rights to enforce any terms
              contained herein.
            </span>
          </h3>
        </li>
      </ol>
      <h3 className="c12 c18 c6">
        <span className="c0" />
      </h3>
      <ol className="c3 lst-kix_list_31-0" start={8}>
        <li className="c17 c6 li-bullet-11">
          <h3 style={{ display: "inline" }}>
            <span className="c0">Force Majeure: </span>
          </h3>
        </li>
      </ol>
      <p className="c8">
        <span className="c1 c6" />
      </p>
      <p className="c12">
        <span className="c1 c6">
          We shall have no liability to you if we are prevented from or delayed
          in performing our obligations or from carrying on our business by
          acts, events, omissions, or accidents beyond our reasonable control,
          including, without limitation, strikes, failure of a utility service
          or telecommunications network, the act of God, war, riot, civil
          commotion, pandemic situations, malicious damage, compliance with any
          law or governmental order, rule, regulation, or direction.
        </span>
      </p>
      <h3 className="c12 c6 c18">
        <span className="c0" />
      </h3>
      <ol className="c3 lst-kix_list_31-0" start={9}>
        <li className="c17 c6 li-bullet-11">
          <h3 style={{ display: "inline" }}>
            <span className="c0">Grievance Redressal</span>
          </h3>
        </li>
      </ol>
      <ol className="c3 lst-kix_list_41-0 start" start={1}>
        <li className="c14 li-bullet-5">
          <span className="c1">
            You may contact our designated Grievance Redressal Officer with any
            complaints or queries relating to the Services or these Master Terms
            through registered post or through email, details of which are
            provided below:
          </span>
        </li>
      </ol>
      <p className="c25">
        <span className="c1">Name: Vijayan. R</span>
      </p>
      <p className="c25">
        <span className="c1">Email Address: general@saafe.in</span>
      </p>
      <p className="c25">
        <span className="c15">Address:</span>
        <span>&nbsp;</span>
        <span className="c1">Kind Attention: Customer Service Team</span>
      </p>
      <p className="c25">
        <span className="c1">
          Dashboard Account Aggregation Services Private Limited,
        </span>
      </p>
      <p className="c25">
        <span className="c1">
          Workafella, New No. 431, Teynampet, Anna Salai
        </span>
      </p>
      <p className="c25">
        <span className="c1">Chennai – 600018</span>
      </p>
      <ol className="c3 lst-kix_list_41-0" start={2}>
        <li className="c14 li-bullet-3">
          <span className="c1">
            We shall ensure that Your complaint is resolved within timelines
            prescribed by applicable laws.
          </span>
        </li>
      </ol>
      <ol className="c3 lst-kix_list_31-0" start={10}>
        <li className="c17 c6 li-bullet-12">
          <h3 style={{ display: "inline" }}>
            <span className="c0">Termination</span>
          </h3>
        </li>
      </ol>
      <h3 className="c12 c18 c6">
        <span className="c0" />
      </h3>
      <p className="c44">
        <span>(</span>
        <span className="c1">
          a) This Agreement shall remain in effect unless terminated in
          accordance with the terms hereunder.
        </span>
      </p>
      <p className="c44">
        <span className="c1">
          (b) We may terminate Your access to or use of the Services, or any
          portion thereof, immediately and at any point, at our sole discretion
          if you violate or breach any of the obligations, responsibilities, or
          covenants under this Agreement, or when you cease to become a user of
          our Services.
        </span>
      </p>
      <p className="c39">
        <span className="c1">(c) Upon termination of this Agreement:</span>
      </p>
      <p className="c39">
        <span className="c1">(i) the Profile will expire;</span>
      </p>
      <p className="c39">
        <span className="c1">(ii) the Services will “time-out”; and</span>
      </p>
      <p className="c39">
        <span className="c1">
          (iii) this Agreement shall terminate, except for those clauses that
          expressly or are intended to survive termination or expiry.
        </span>
      </p>
      <div>
        <p className="c48">
          <span className="c32" />
        </p>
        <p className="c47">
          <span className="c32" />
        </p>
      </div>

      <p className="c20 c37 c6">
        <span className="c1">
          By agreeing to the terms and conditions seen on this page, you also
          agree to the Account Aggregator Ecosystem Participation Terms, which
          can be viewed at{" "}
          <a
            className="c26"
            href="https://sahamati.org.in/participation-terms/"
          >
            https://sahamati.org.in/participation-terms/
          </a>
          . These terms govern your use of the Account Aggregator services, and
          you are requested to review these terms before recording your
          agreement.
        </span>
      </p>
    </div>
  );
};

export default TermsAndCondition;
