import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Radio, RadioChangeEvent, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTheme } from "App";
import { translation } from "i18n/translation";
import { ConsentStatusType, DeleteType } from "Utils/Constants/statusTypes";
import OTPInputComponent from "Pages/Common/Components/OTPModal";
import "./index.css";

interface IConsentStatusApprovalComponentProps {
  isAuthTypeSelectionModalOpen?: boolean;
  onCloseAuthTypeSelectionModal?: () => void;
  onAuthTypeRadioSelectionChange?: (event: RadioChangeEvent) => void;
  onAuthTypeModalSubmit: () => void;
  selectedAuthType?: number;
  verifyOTPAuthType?: (otp: string) => void;
  isOTPModalVisible?: boolean;
  onCloseOTPModal?: () => void;
  typeOfModal: string;
  pinModalVisible?: boolean;
  fiu?: string;
  isError?: boolean;
  setIsError?: (isError: boolean) => void;
  errorMessage?: string;
  onResend?: (values?: any) => void;
  consentCount?: number;
  likedAccoutsCount?: number;
}

const isConsentVerificationEnabled: string = "true";
const ConsentStatusApprovalComponent: React.FC<
  IConsentStatusApprovalComponentProps
> = ({
  selectedAuthType,
  isOTPModalVisible,
  isAuthTypeSelectionModalOpen,
  onCloseOTPModal,
  onCloseAuthTypeSelectionModal,
  typeOfModal,
  onAuthTypeRadioSelectionChange,
  onAuthTypeModalSubmit,
  verifyOTPAuthType,
  pinModalVisible,
  fiu,
  isError,
  setIsError,
  errorMessage,
  onResend,
  consentCount,
  likedAccoutsCount,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const loading: boolean = useSelector(
    (state: any) => state.loader.searchLoader
  );
  const mobileNumber: string = useSelector(
    (state: any) => state.user.user.mobileNumber
  );
  const radioSelect = (e: RadioChangeEvent) => {
    onAuthTypeRadioSelectionChange && onAuthTypeRadioSelectionChange(e);
  };

  const consentStatusUpdateOtp = () => {
    onAuthTypeModalSubmit();
  };

  const verifyOtpAuth = (otp: string) => {
    verifyOTPAuthType && verifyOTPAuthType(otp);
  };

  const getTypeOfModal = () => {
    if (typeOfModal === ConsentStatusType.PAUSED) {
      return t(translation.lblPauseThisConsent);
    }
    if (typeOfModal === ConsentStatusType.FAILED) {
      return t(translation.lblRejectThisConsent);
    }
    if (typeOfModal === ConsentStatusType.REVOKED) {
      return t(translation.lblRevokeThisConsent);
    }
    if (typeOfModal === ConsentStatusType.ACTIVE) {
      return t(translation.lblReactivateThisConsent);
    }
    if (
      typeOfModal === DeleteType.ACCOUNTDELETE ||
      typeOfModal === DeleteType.DELETEUSER
    ) {
      return t(translation.lblDeleteThisAccount);
    }
    if (typeOfModal === DeleteType.IDENTIFIERDELETE) {
      return t(translation.lblDeleteThisIdentifier);
    }
    return typeOfModal === ConsentStatusType.READY
      ? t(translation.lblApproveConfirmation)
      : t(typeOfModal);
  };

  const authOptions = [
    { label: "Passcode", value: 0 },
    { label: "OTP", value: 1 },
  ];

  return (
    <>
      {isOTPModalVisible ? (
        <OTPInputComponent
          isOpen={isOTPModalVisible}
          onCancel={onCloseOTPModal ? onCloseOTPModal : () => {}}
          onOtpSubmit={verifyOtpAuth}
          okText={getTypeOfModal()}
          title={getTypeOfModal() + " " + t(translation.lblConsent)}
          onResend={onResend}
          mobileNumber={mobileNumber}
          isError={isError}
          setIsError={setIsError}
          errorMessage={errorMessage}
        />
      ) : (
        <></>
      )}

      {pinModalVisible ? (
        <OTPInputComponent
          isOpen={pinModalVisible}
          isPinModal={true}
          onCancel={onCloseOTPModal ? onCloseOTPModal : () => {}}
          onOtpSubmit={verifyOtpAuth}
          okText={getTypeOfModal()}
          title={getTypeOfModal() + " " + t(translation.lblConsent)}
          isError={isError}
          setIsError={setIsError}
          errorMessage={errorMessage}
        />
      ) : (
        <></>
      )}

      <Modal
        className={`${theme.modalClass} footerRoot modalRoot`}
        closable={false}
        open={isAuthTypeSelectionModalOpen}
        okText={t(translation.lblConfirm)}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        onCancel={onCloseAuthTypeSelectionModal}
        footer={null}
      >
        <div>
          <div
            className={` ${
              typeOfModal === ConsentStatusType.FAILED ||
              typeOfModal === ConsentStatusType.REVOKED ||
              typeOfModal === DeleteType.ACCOUNTDELETE ||
              typeOfModal === DeleteType.DELETEUSER ||
              typeOfModal === DeleteType.IDENTIFIERDELETE
                ? theme.errorStatusBG
                : typeOfModal === ConsentStatusType.READY ||
                  typeOfModal === ConsentStatusType.ACTIVE
                ? theme.successStatusBG
                : typeOfModal === ConsentStatusType.PAUSED
                ? theme.warningStatusBG
                : theme.staleStatusBG
            } iconBackground1`}
          >
            <InfoCircleOutlined
              className={`${
                typeOfModal === ConsentStatusType.FAILED ||
                typeOfModal === ConsentStatusType.REVOKED ||
                typeOfModal === DeleteType.ACCOUNTDELETE ||
                typeOfModal === DeleteType.DELETEUSER ||
                typeOfModal === DeleteType.IDENTIFIERDELETE
                  ? theme.errorStatus
                  : typeOfModal === ConsentStatusType.READY ||
                    typeOfModal === ConsentStatusType.ACTIVE
                  ? theme.successStatus
                  : typeOfModal === ConsentStatusType.PAUSED
                  ? theme.warningStatus
                  : theme.staleStatus
              } modalSymbol acknowledgeIcon`}
            />
          </div>
          <Typography.Text className={`${theme.indicatorColor} title1`}>
            {typeOfModal !== DeleteType.ACCOUNTDELETE &&
            typeOfModal !== DeleteType.DELETEUSER
              ? typeOfModal !== DeleteType.IDENTIFIERDELETE
                ? t(translation.consentUpdateConfirmation, {
                    consentStatus:
                      typeOfModal === ConsentStatusType.ACTIVE ||
                      typeOfModal === ConsentStatusType.READY
                        ? t(translation.lblApprove)
                        : typeOfModal === ConsentStatusType.PAUSED
                        ? t(translation.lblPause)
                        : typeOfModal === ConsentStatusType.REVOKED
                        ? t(translation.lblRevoke)
                        : t(translation.lblReject),

                    fiu: fiu,
                  })
                : t(translation.msgDeleteIdentifier)
              : t(translation.msgUnLinkAccounts)}{" "}
          </Typography.Text>

          <Typography.Text className={`${theme.authSubTitle} subTitle`}>
            {" "}
            {typeOfModal === ConsentStatusType.PAUSED ? (
              t(translation.consentPauseDescription)
            ) : typeOfModal === ConsentStatusType.REVOKED ? (
              t(translation.consentRevoke, { fiu_name: fiu })
            ) : typeOfModal === ConsentStatusType.READY ||
              typeOfModal === ConsentStatusType.ACTIVE ? (
              t(translation.consentReady, { fiu_name: fiu })
            ) : typeOfModal === ConsentStatusType.FAILED ? (
              t(translation.consentReject, { fiu_name: fiu })
            ) : typeOfModal === DeleteType.ACCOUNTDELETE ? (
              <div>
                <Typography.Text className={`${theme.textTheme.headline1}`}>
                  {t(translation.accountDeleteDescripiion)}
                  <br />
                </Typography.Text>
                <Typography.Text className={`${theme.errorStatus} weight500`}>
                  {consentCount && consentCount !== 0
                    ? t(translation.linkedConsentCount, {
                        consentCount: consentCount,
                      })
                    : ""}
                </Typography.Text>
              </div>
            ) : typeOfModal === DeleteType.DELETEUSER ? (
              <div>
                <Typography.Text className={`${theme.textTheme.headline1}`}>
                  {t(translation.msgSureToDeleteAccount)}
                  <br />
                </Typography.Text>
              </div>
            ) : typeOfModal === DeleteType.IDENTIFIERDELETE ? (
              <div>
                <Typography.Text className={`${theme.textTheme.headline1}`}>
                  {t(translation.identiferDeleteDescription)}
                  <br />
                </Typography.Text>
                <Typography.Text className={`${theme.errorStatus} weight500`}>
                  {likedAccoutsCount && likedAccoutsCount !== 0
                    ? t(translation.linkedAccountsCount, {
                        count: likedAccoutsCount,
                      })
                    : ""}
                </Typography.Text>
              </div>
            ) : (
              ""
            )}
          </Typography.Text>
        </div>
        {isConsentVerificationEnabled === "false" ||
        (typeOfModal !== DeleteType.ACCOUNTDELETE &&
          typeOfModal !== DeleteType.IDENTIFIERDELETE &&
          typeOfModal !== DeleteType.DELETEUSER) ? (
          <></>
        ) : (
          <Radio.Group
            options={authOptions}
            onChange={radioSelect}
            value={selectedAuthType}
            className={`${theme.radioButtonColor} radio radioGroup`}
            optionType="button"
          />
        )}
        <div className="otpButtonFooter">
          <Button
            onClick={consentStatusUpdateOtp}
            className={`${
              typeOfModal === ConsentStatusType.FAILED ||
              typeOfModal === ConsentStatusType.REVOKED ||
              typeOfModal === DeleteType.ACCOUNTDELETE ||
              typeOfModal === DeleteType.IDENTIFIERDELETE ||
              typeOfModal === DeleteType.DELETEUSER
                ? theme.errorStatusBG + " " + theme.errorStatus
                : typeOfModal === ConsentStatusType.READY ||
                  typeOfModal === ConsentStatusType.ACTIVE
                ? theme.textTheme.authButton
                : typeOfModal === ConsentStatusType.PAUSED
                ? theme.warningStatus + " " + theme.warningStatusBG
                : theme.staleStatus + " " + theme.staleStatusBG
            } ${
              typeOfModal === ConsentStatusType.READY ||
              typeOfModal === ConsentStatusType.ACTIVE
                ? "approveButton"
                : "primaryButton"
            }`}
            loading={loading}
          >
            {getTypeOfModal()}
          </Button>

          <Button
            onClick={onCloseAuthTypeSelectionModal}
            type="text"
            className={theme.hintColor}
            disabled={loading}
          >
            {t(translation.lblGoBack)}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ConsentStatusApprovalComponent;
