import { Button, Col, Radio, Row, Typography } from "antd";
import { useTheme } from "App";
import darkImage from "Assets/ic_language_dark.png";
import lightImage from "Assets/ic_language_light.png";
import { translation } from "i18n/translation";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getLocalStorageItem,
  getRedirect,
  storageConstants,
} from "Services/StorageServices";
import { QueryParams, Routes } from "Utils/Constants/routes";
import "./index.css";

const LandingPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { replace } = useHistory();
  const location = useLocation();
  const currentTheme = useSelector((state: any) => state.themes.theme);

  const redirect: any = location.state;

  const continueSelection = () => {
    if (!!getRedirect()) {
      replace({
        pathname: redirect.route,
        search: `?${QueryParams.Redirect}=/consent`,
        state: {
          phoneNumber: redirect.phoneNumber,
          isVuaIdDisabled: redirect.isVuaIdDisabled,
        },
      });
    } else {
      getLocalStorageItem(storageConstants.AccessToken)?.length
        ? replace(Routes.Consent)
        : replace(Routes.Login);
    }
  };

  const onChangeLang = (e: any) => {
    i18n.changeLanguage(e.target.value);
  };

  const theme = useTheme();
  const languageOptions = [
    { label: "English", value: "en" },
    { label: "हिन्दी (Hindi)", value: "hi" },
    { label: "తెలుగు (Telugu)", value: "te" },
    { label: "தமிழ் (Tamil)", value: "ta" },
    { label: "മലയാളം (Malayalam)", value: "mal" },
    { label: "ಕನ್ನಡ (Kannada)", value: "kn" },
    { label: "اردو (Urdu)", value: "ur" },
  ];
  return (
    <div
      className={` ${theme.backgroundColor} flexCommonCss languageSelectRoot`}
    >
      {currentTheme === "dark" ? (
        <img style={{ height: 100 }} alt="" src={darkImage} />
      ) : (
        <img style={{ height: 100 }} alt="" src={lightImage} />
      )}

      <Typography.Title
        level={2}
        className={`${theme.textTheme.headline1} titleClass settingsTitle`}
      >
        {t(translation.lblSelectLanguage)}
      </Typography.Title>

      <Radio.Group
        onChange={onChangeLang}
        defaultValue={i18n.language}
        className={`${theme.textTheme.subtitle1} deleteReasons langRadioGroup`}
      >
        <Row className={`langSelectRow ${theme.radioButtonColor}`}>
          {languageOptions.map((lang) => {
            return (
              <Col span={12} className={`langSelectCol`}>
                <Radio
                  key={lang.value}
                  className={`${theme.textTheme.headline6} ${theme.checkBoxBorder} checboxSelectAll`}
                  value={lang.value}
                >
                  {lang.label}
                </Radio>
              </Col>
            );
          })}
          <Col span={12} style={{ maxWidth: "40%" }}></Col>
        </Row>
      </Radio.Group>
      <Button
        className={`${theme.textTheme.authButton} commonButtonBackground primaryButton borderNoneHeight50 langContinueButton`}
        htmlType="submit"
        onClick={continueSelection}
      >
        {t(translation.lblContinue)}
      </Button>
    </div>
  );
};

export default LandingPage;
