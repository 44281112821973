import { useTheme } from "App";
import default_bank from "Assets/ic_default_bank.png";
import { AddIdentifier } from "Models/Request/Identifier/AddIdentifier";
import {
  AutoDiscoverAccountResponse,
  DiscoverAccountObject,
} from "Models/Response/Account/DiscoverAccount";
import { autoDiscoverAccounts } from "Services/AccountsServices/AccountService";
import { getFipId, getFiuId } from "Services/StorageServices";
import { logoBaseUrl } from "Utils/Constants/baseUrl";
import {
  Card,
  Checkbox,
  Collapse,
  Empty,
  Image,
  Skeleton,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { translation } from "i18n/translation";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./index.css";
import { RedirectionEvents } from "Utils/types/RedirectionEvents";
import sendEvents from "Utils/Events";

interface AutoDiscoverAccountLinkProps {
  identifier: AddIdentifier[];
  onCanceled: () => void;
  updateSelectedAccounts: (selectedAccounts: string[]) => void;
  updateSelectedFip: (fipId: string, fipName: string) => void;
  updateSignature: (signature: string) => void;
  updateDiscoveredAccounts: (accouts: DiscoverAccountObject[]) => void;
}

export interface AutoDiscoveryMap {
  [fipId: string]: AutoDiscoveryMapAccounts;
}

interface AutoDiscoveryMapAccounts {
  fipName: string;
  accounts: DiscoverAccountObject[];
  checkAll: boolean;
  indeterminate: boolean;
}

const AutoDiscoverAccountLink: React.FC<AutoDiscoverAccountLinkProps> = ({
  identifier,
  onCanceled,
  updateSelectedAccounts,
  updateSelectedFip,
  updateSignature,
  updateDiscoveredAccounts,
}) => {
  const loading: boolean = useSelector(
    (state: any) => state.loader.searchLoader
  );
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedAccounts, setSelectedAccounts] = useState(new Array<string>());

  const [selectedFIP, setSelectedFIP] = useState("");
  const [autoDiscoverAccountsMap, setAutoDiscoverAccountsMap] =
    useState<AutoDiscoveryMap>({});
  const [activeKeys, setActiveKeys] = useState<number[]>([]);

  const fiuId = getFiuId();

  useEffect(() => {
    updateSelectedAccounts(selectedAccounts);
  }, [selectedAccounts, updateSelectedAccounts]);

  useEffect(() => {
    if (selectedFIP)
      updateSelectedFip(
        selectedFIP,
        autoDiscoverAccountsMap[selectedFIP].fipName
      );
  }, [selectedFIP, updateSelectedFip, autoDiscoverAccountsMap]);

  const onCheckAllChange = (e: CheckboxChangeEvent, fipId: string) => {
    const fip = autoDiscoverAccountsMap[fipId];
    if (selectedFIP !== fipId) {
      setSelectedFIP(fipId);
    }
    setSelectedAccounts(
      e.target.checked ? fip.accounts.map((value) => value.maskedAccNumber) : []
    );

    Object.keys(autoDiscoverAccountsMap)
      .filter((fipFIlter) => fipFIlter !== fipId)
      .forEach((fipFilter) => {
        setAutoDiscoverAccountsMap((prevState: any) => ({
          ...prevState,
          [fipFilter]: {
            ...autoDiscoverAccountsMap[fipFilter],
            indeterminate: false,
            checkAll: false,
          },
        }));
      });

    setAutoDiscoverAccountsMap((prevState: any) => ({
      ...prevState,
      [fipId]: {
        ...autoDiscoverAccountsMap[fipId],
        indeterminate: false,
        checkAll: e.target.checked,
      },
    }));
  };

  useEffect(() => {
    sendEvents(RedirectionEvents.Init_Auto_Account_Discovery);

    autoDiscoverAccounts({
      Identifiers: identifier,
      FiuId: fiuId || "",
      FipId: !!getFipId() ? getFipId()!.split(",") : [],
    })
      .then((res: any) => res.data)
      .then((res: AutoDiscoverAccountResponse) => {
        updateSignature(res.signature);
        const accounts: DiscoverAccountObject[] = [];
        setActiveKeys(Array.from(Array(res.Accounts.length).keys()));
        res.Accounts.forEach((account) => {
          accounts.push(...account.DiscoveredAccounts);
          setAutoDiscoverAccountsMap((prevState: any) => ({
            ...prevState,
            [account.fipId]: {
              fipName: account.fipName,
              accounts: account.DiscoveredAccounts,
              checkAll: false,
              indeterminate: false,
            },
          }));
        });
        updateDiscoveredAccounts(accounts);

        accounts.length > 0
          ? sendEvents(RedirectionEvents.Account_Discovery_Success)
          : sendEvents(RedirectionEvents.No_Accounts_Found);
      })
      .catch(() => {
        sendEvents(RedirectionEvents.Account_Discovery_Failure);
        setTimeout(() => onCanceled(), 2000);
      });
  }, [
    identifier,
    onCanceled,
    fiuId,
    updateDiscoveredAccounts,
    updateSignature,
  ]);

  const collapseFIUHeader = (fipId: any) => {
    const fip = autoDiscoverAccountsMap[fipId];

    return (
      <div className="fiuCard autoDiscover">
        <div className={`fiuHeader`}>
          <Image
            loading="lazy"
            className="fipLogo"
            src={logoBaseUrl + fipId + ".jpeg"}
            fallback={default_bank}
            preview={false}
            style={{ marginRight: 10 }}
          />

          <div className="cardContent">
            <Typography.Text
              className={`${theme.textTheme.headline1} cardContentFIP`}
            >
              {fip.fipName}
            </Typography.Text>
          </div>
        </div>
        <Checkbox
          indeterminate={fip.indeterminate}
          onChange={(e) => onCheckAllChange(e, fipId)}
          checked={fip.checkAll}
          className={`${theme.textTheme.headline5} ${theme.textTheme.headline6} ${theme.checkBoxBorder} checboxSelectAll`}
        >
          {t(translation.lblSelectAll)}
        </Checkbox>
      </div>
    );
  };

  const onAccountsSelect = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    fip: string
  ) => {
    if (selectedFIP !== fip) {
      setSelectedFIP(fip);
      setSelectedAccounts([event.currentTarget.id]);
      Object.keys(autoDiscoverAccountsMap)
        .filter((fipFIlter) => fipFIlter !== fip)
        .forEach((fipFilter) => {
          setAutoDiscoverAccountsMap((prevState: any) => ({
            ...prevState,
            [fipFilter]: {
              ...autoDiscoverAccountsMap[fipFilter],
              indeterminate: false,
              checkAll: false,
            },
          }));
        });
      setAutoDiscoverAccountsMap((prevState: any) => ({
        ...prevState,
        [fip]: {
          ...autoDiscoverAccountsMap[fip],
          indeterminate: 1 !== autoDiscoverAccountsMap[fip].accounts.length,
          checkAll: 1 === autoDiscoverAccountsMap[fip].accounts.length,
        },
      }));
    } else {
      if (selectedAccounts.indexOf(event.currentTarget.id) === -1) {
        setSelectedAccounts([...selectedAccounts, event.currentTarget.id]);
        setAutoDiscoverAccountsMap((prevState: any) => ({
          ...prevState,
          [fip]: {
            ...autoDiscoverAccountsMap[fip],
            indeterminate:
              selectedAccounts.length + 1 !==
              autoDiscoverAccountsMap[fip].accounts.length,
            checkAll:
              selectedAccounts.length + 1 ===
              autoDiscoverAccountsMap[fip].accounts.length,
          },
        }));
      } else {
        setSelectedAccounts(
          selectedAccounts.filter((card) => card !== event.currentTarget.id)
        );
        if (selectedAccounts.length - 1 !== 0)
          setAutoDiscoverAccountsMap((prevState: any) => ({
            ...prevState,
            [fip]: {
              ...autoDiscoverAccountsMap[fip],
              indeterminate: true,
              checkAll: false,
            },
          }));
        else
          setAutoDiscoverAccountsMap((prevState: any) => ({
            ...prevState,
            [fip]: {
              ...autoDiscoverAccountsMap[fip],
              indeterminate: false,
              checkAll: false,
            },
          }));
      }
    }
  };

  return (
    <Skeleton className="marginTop10" loading={loading}>
      {Object.keys(autoDiscoverAccountsMap).length > 0 ? (
        <>
          <Collapse defaultActiveKey={activeKeys}>
            {Object.keys(autoDiscoverAccountsMap).map((fipId, index: number) =>
              autoDiscoverAccountsMap[fipId].accounts &&
              autoDiscoverAccountsMap[fipId].accounts.length > 0 ? (
                <Collapse.Panel
                  collapsible="icon"
                  style={{ marginTop: 5 }}
                  header={collapseFIUHeader(fipId)}
                  key={index}
                  className={`${theme.cardColor}  ${theme.entityCard} accountDiscoveryCard consentA`}
                >
                  <div className="selectAccountsTitle">
                    {autoDiscoverAccountsMap[fipId].accounts.map(
                      (accounts: any, indexD: number) => (
                        <>
                          <Card
                            className={`${theme.cardColor} ${
                              selectedAccounts.indexOf(
                                accounts.maskedAccNumber
                              ) !== -1
                                ? `${theme.textTheme.headline5} consentSelectedCard`
                                : ""
                            } accountsCard 
                              `}
                            bordered={false}
                            key={indexD}
                            id={accounts.maskedAccNumber}
                            onClick={(e) => onAccountsSelect(e, fipId)}
                          >
                            <div className="cardFirstRow">
                              <Typography.Text
                                className={`${theme.accountMaskedNumber} maskedAccNumberDiscover`}
                              >
                                {accounts.maskedAccNumber.slice(
                                  accounts.maskedAccNumber.length - 7
                                )}
                              </Typography.Text>
                              <Checkbox
                                checked={
                                  selectedAccounts.indexOf(
                                    accounts.maskedAccNumber
                                  ) === -1
                                    ? false
                                    : true
                                }
                                className={`${theme.textTheme.headline5} ${theme.textTheme.headline6} ${theme.checkBoxBorder} checboxSelectAll`}
                              />
                            </div>

                            <Typography.Text
                              className={`${theme.hintColor} accountType`}
                            >
                              {accounts.FIType === "DEPOSIT"
                                ? t(_.capitalize(accounts.accType))
                                : t(_.capitalize(accounts.FIType))}
                            </Typography.Text>
                          </Card>
                        </>
                      )
                    )}
                    {autoDiscoverAccountsMap[fipId].accounts &&
                    autoDiscoverAccountsMap[fipId].accounts.length % 2 !== 0 ? (
                      <div className="accountsCard" />
                    ) : (
                      <></>
                    )}
                  </div>
                </Collapse.Panel>
              ) : (
                <></>
              )
            )}
          </Collapse>
        </>
      ) : (
        <Empty
          image={theme.noConsentFound}
          className={`empty noAutoDiscovery`}
          description={
            <Typography.Text
              className={`${theme.indicatorColor} consentErrorMsg`}
            >
              {t(translation.errorNoDetailsFound)}
            </Typography.Text>
          }
        />
      )}
    </Skeleton>
  );
};
export default AutoDiscoverAccountLink;
