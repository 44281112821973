import { MoreOutlined } from "@ant-design/icons";
import { Card, Collapse, Dropdown, Image, Menu, Table, Typography } from "antd";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getRedirect, getSessionId, getTxnId } from "Services/StorageServices";
import { useTheme } from "App";
import {
  AimedIcon,
  CalendarIcon,
  ConsentsIcon,
  CreditCardIcon,
} from "Assets/icons";
import default_bank from "Assets/ic_default_bank.png";
import { translation } from "i18n/translation";
import GetConsentByHandleResponse from "Models/Response/Consent/GetConsentByHandle";
import { GetConsentByIdResponse } from "Models/Response/Consent/GetConsentById";
import { logoBaseUrl } from "Utils/Constants/baseUrl";
import {
  ConsentApprovalStatusType,
  ConsentStatusType,
} from "Utils/Constants/statusTypes";
import BreadCrumbs from "Pages/Common/Components/BreadCrumbs";
import ConsentStatus from "Pages/Consent/Components/ConsentStatus";
import "./index.css";
import SelecteAccounts from "Pages/Common/Components/SelectedAccounts";
import { SelectedAccounts } from "Models/Request/Account/AccountLink";

interface IConsentDetailsProps {
  type: string;
  consentByUniqueIdDetails: GetConsentByIdResponse | GetConsentByHandleResponse;
  userAction?: (consentStatusType: ConsentStatusType) => void;
  renderTypeRedirection?: string;
  consentUniqueId?: string;
  onChange?: (selectedCard: SelectedAccounts[]) => void;
  multiConsent?: boolean;
}

const ConsentDetails: React.FC<IConsentDetailsProps> = ({
  type,
  consentByUniqueIdDetails,
  userAction,
  renderTypeRedirection,
  consentUniqueId,
  onChange = () => { },
  multiConsent = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { Text, Title } = Typography;
  const columns = useMemo(
    () => [
      {
        title: t("Type"),
        dataIndex: "type",
      },
      {
        title: t("Operator"),
        dataIndex: "operator",
      },
      {
        title: t("Value"),
        dataIndex: "value",
      },
    ],
    [t]
  );

  const menu = (
    <Menu className={`${theme.cardColor} menu`}>
      <Menu.Item
        className={`${theme.hintColor} overlayMenuItem commonConsents`}
        key={"Pause"}
        onClick={() => userAction && userAction(ConsentStatusType.PAUSED)}
      >
        {t(translation.lblPause)}
      </Menu.Item>
      <Menu.Item
        className={`${theme.hintColor} overlayMenuItem commonConsents`}
        key={"Revoke"}
        onClick={() => userAction && userAction(ConsentStatusType.REVOKED)}
      >
        {t(translation.lblRevoke)}
      </Menu.Item>
    </Menu>
  );

  const headerRender = () => {
    return (
      <div className="detailsHeader">
        {!!getRedirect() ? (
          <></>
        ) : (
          <BreadCrumbs
            className="marginBottom16"
            breadcrumbItems={[
              { route: "/consent", label: t(translation.lblConsents) },
              {
                route: location.pathname,
                label: `${consentByUniqueIdDetails.DataConsumer.id}`,
              },
            ]}
          />
        )}
        <div className="headerTitle">
          <div className="headerMeta">
            <Image
              className="fipLogo"
              src={
                logoBaseUrl + consentByUniqueIdDetails.DataConsumer.id + ".jpeg"
              }
              fallback={default_bank}
              preview={false}
            />
            <div className="headerTypo">
              <Title
                className={`${theme.textTheme.headline1} titleClass fiuTitle`}
              >
                {consentByUniqueIdDetails.DataConsumer.name ? consentByUniqueIdDetails.DataConsumer.name : "Self Consent"}
              </Title>
              <Text className={`${theme.hintColor}`}>
                {consentByUniqueIdDetails.fetchType !== "ONETIME"
                  ? t("Information will be fetched by FIU ", {
                    value: consentByUniqueIdDetails.Frequency.value,
                    unit: t(consentByUniqueIdDetails.Frequency.unit),
                  })
                  : ""}
                {t("FIU will store information for", {
                  value: consentByUniqueIdDetails.DataLife.value,
                  unit: t(consentByUniqueIdDetails.DataLife.unit),
                })}{" "}
              </Text>
            </div>
          </div>

          <div className="headerInsturction">
            {!!getRedirect() && !!getTxnId() && !!getSessionId() ? (
              <></>
            ) : (
              <div className="displayFlex">
                <ConsentStatus
                  status={type}
                  className={
                    type === ConsentStatusType.ACTIVE
                      ? `${theme.successStatus} ${theme.successStatusBG}`
                      : type === ConsentStatusType.PAUSED ||
                        type === ConsentApprovalStatusType.PENDING
                        ? `${theme.warningStatus} ${theme.warningStatusBG}`
                        : type === ConsentStatusType.REJECTED
                          ? `${theme.errorStatus} ${theme.errorStatusBG}`
                          : type === ConsentStatusType.REVOKED
                            ? `${theme.staleStatus} ${theme.staleStatusBG}`
                            : `${theme.staleStatus} ${theme.staleStatusBG}`
                  }
                />
              </div>
            )}
            {type === ConsentStatusType.ACTIVE ? (
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomRight"
              >
                <MoreOutlined
                  className={`${theme.notificationBackgroundColor} ${theme.hintColor} ${theme.dividerColorBorder} moreIcon`}
                />
              </Dropdown>
            ) : (
              <></>
            )}
          </div>
        </div>

        {!multiConsent && type === ConsentApprovalStatusType.PENDING ? (
          <SelecteAccounts
            consentHandle={consentUniqueId ? consentUniqueId : ""}
            onOk={onChange}
          />
        ) : (
          <></>
        )}

        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder}  consentDetailsCard`}
          bordered={false}
        >
          <div
            className={`${theme.consentIconBG} subClass consentDescriptionIcon consentDetailIconsBG`}
          >
            <CreditCardIcon
              className={`${theme.textTheme.headline5} transactionIcon icon consentDetailIcons`}
            />
          </div>{" "}
          <div className="consentDescription">
            <Text className={theme.textTheme.subtitle1}>
              {t(translation.lblTransactions)}
            </Text>
            <Text className={`${theme.indicatorColor} fontSize15FontWeight500`}>
              {moment(consentByUniqueIdDetails.FIDataRange.from).format(
                "DD MMM YYYY"
              )}
              {" - "}

              {moment(consentByUniqueIdDetails.FIDataRange.to).format(
                "DD MMM YYYY"
              )}
            </Text>
          </div>
        </Card>

        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCard`}
          bordered={false}
        >
          <div
            className={`${theme.consentIconBG} subClass consentDescriptionIcon`}
          >
            <ConsentsIcon
              className={`${theme.textTheme.headline5} transactionIcon icon consentDetailIcons`}
            />
          </div>{" "}
          <div className="consentDescription">
            <Text className={theme.textTheme.subtitle1}>
              {t(translation.lblFiDataType)}
            </Text>
            <Text className={`${theme.indicatorColor} fontSize15FontWeight500`}>
              {consentByUniqueIdDetails.consentTypes.map((types, index) => {
                let isComma;
                index === consentByUniqueIdDetails.consentTypes.length - 1
                  ? (isComma = "")
                  : (isComma = ", ");

                return types + isComma;
              })}
            </Text>
          </div>
        </Card>
        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCard`}
          bordered={false}
        >
          <div
            className={`${theme.consentIconBG} subClass consentDescriptionIcon`}
          >
            <AimedIcon
              className={`${theme.textTheme.headline5} transactionIcon icon consentDetailIcons`}
            />
          </div>
          <div className="consentDescription">
            <Text className={theme.textTheme.subtitle1}>
              {t(translation.lblPurpose)}
            </Text>
            <Text className={`${theme.indicatorColor} fontSize15FontWeight500`}>
              {t(consentByUniqueIdDetails.Purpose.text)}
            </Text>
          </div>
        </Card>
        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCard`}
          bordered={false}
        >
          <div
            className={`${theme.consentIconBG} subClass consentDescriptionIcon`}
          >
            <CalendarIcon
              className={`${theme.textTheme.headline5} transactionIcon icon consentDetailIcons`}
            />
          </div>
          <div className="consentDescription">
            <Text className={theme.textTheme.subtitle1}>
              {t(translation.lblConsentDuration)}
            </Text>
            <Text className={`${theme.indicatorColor} fontSize15FontWeight500`}>
              {moment(consentByUniqueIdDetails.consentStart).format(
                "DD MMM YYYY"
              )}
              {" - "}

              {moment(consentByUniqueIdDetails.consentExpiry).format(
                "DD MMM YYYY"
              )}
            </Text>
          </div>
        </Card>
      </div>
    );
  };

  const bodyRender = () => {
    return (
      <div className="consentDetailsBody">
        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCard spaceBetween`}
          bordered={false}
        >
          <Text className={theme.textTheme.subtitle1}>
            {t(translation.lblConsumerID)}
          </Text>
          <Text className={`${theme.indicatorColor} fontSize15FontWeight500`}>
            {consentByUniqueIdDetails.DataConsumer.id}
          </Text>
        </Card>

        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCard spaceBetween`}
          bordered={false}
        >
          <Text className={theme.textTheme.subtitle1}>
            {t(translation.lblMode)}
          </Text>
          <Text className={`${theme.indicatorColor} fontSize15FontWeight500`}>
            {t(consentByUniqueIdDetails.consentMode)}
          </Text>
        </Card>

        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCard spaceBetween`}
          bordered={false}
        >
          <Text className={theme.textTheme.subtitle1}>
            {t(translation.lblFetchType)}
          </Text>
          <Text className={`${theme.indicatorColor} fontSize15FontWeight500`}>
            {consentByUniqueIdDetails.fetchType}
          </Text>
        </Card>

        <Card
          className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCard spaceBetween`}
          bordered={false}
        >
          <Text className={theme.textTheme.subtitle1}>
            {t(translation.lblFITypes)}
          </Text>
          <Text className={`${theme.indicatorColor} fontSize15FontWeight500`}>
            {consentByUniqueIdDetails.fiTypes.map((types, index) => {
              let isComma;
              index === consentByUniqueIdDetails.fiTypes.length - 1
                ? (isComma = "")
                : (isComma = ", ");

              return types + isComma;
            })}
          </Text>
        </Card>

        <Collapse
          className={`${theme.cardColor} 
    ${theme.consentCardBorder}
    ${theme.menuUserFontColor} 
    ${theme.collapseBG} 
    ${theme.cardColor}
    ${theme.collapseColumn} consentDetailsCard collapseConsent`}
          expandIconPosition="end"
        >
          <Collapse.Panel key={1} header={t(translation.lblFiDataFilter)}>
            <Table
              className={`${theme.cardColor}`}
              columns={columns}
              dataSource={consentByUniqueIdDetails.DataFilter}
              pagination={false}
            />
          </Collapse.Panel>
        </Collapse>
        <div />
      </div>
    );
  };

  const location = useLocation();
  return (
    <>
      {renderTypeRedirection && renderTypeRedirection === "HEADER" ? (
        headerRender()
      ) : renderTypeRedirection && renderTypeRedirection === "BODY" ? (
        bodyRender()
      ) : (
        <>
          {headerRender()}
          {bodyRender()}
        </>
      )}
    </>
  );
};

export default ConsentDetails;
