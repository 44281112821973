import { ColorUtils } from "./ColorUtils";
import "./index.css";
import noConsentFoundLight from "../Assets/noConsentLight.png";
import noConsentFoundDark from "../Assets/noConsentDark.png";
import noAccountFoundLight from "../Assets/noAccountLight.png";
import noAccountFoundDark from "../Assets/noAccountDark.png";
import lightSuccessIcon from "../Assets/lightSuccessIcon.png";
import darkSuccessIcon from "../Assets/darkSuccessIcon.png";
import darkInfoIcon from "../Assets/darkInfoIcon.png";
import lightInfoIcon from "../Assets/lightInfoIcon.png";
import darkWarningIcon from "../Assets/darkWarningIcon.png";
import lightWarningIcon from "../Assets/lightWarningIcon.png";

export const themes = {
  // light theme colors
  light: {
    hintColorStyle: ColorUtils.hintColor,
    hintColor: "lightHintColor",
    highlightColor: "lightHighlightColor",
    disabledColor: "lightDisabledColor",
    popupMenuTheme: {
      color: ColorUtils.cardColor,
      textStyle: ColorUtils.hintColor,
      fontFamily: "Poppins",
    },
    textTheme: {
      headline1: "lightTextThemeHeadline1",
      headline2: "lightTextThemeHeadline2",
      headline3: "lightTextThemeHeadline3",
      headline4: ColorUtils.cardColor,
      headline5: "lightTextThemeHeadline5",
      headline6: "lightTextThemeHeadline5BG",
      bodyText1: ColorUtils.primaryTextColor,
      bodyText2: ColorUtils.secondaryTextColor,
      subtitle1: "lightTextThemeSubtitle1",
      subtitle2: ColorUtils.lightIdentifierSelect,
      overline: ColorUtils.lightSearchBarFillColor,
      caption: ColorUtils.lightShadowColor,
      authButton: "lightTextThemeAuthButton",
      authButtonCustomized: "lightTextThemeAuthButtonCustomized",
    },
    dividerColor: "lightDivider",
    dividerColorBorder: "lightDividerBorder",
    shadowColor: ColorUtils.lightBoxShadowColor,
    hoverColor: "lightHoverColor",
    indicatorColor: "lightIndicatorColor",
    secondaryHeaderColor: ColorUtils.primaryBackgroundColor,
    fontFamily: "Poppins",
    splashColor: ColorUtils.splashColor,
    textSelectionTheme: ColorUtils.primaryColor,
    primaryIconTheme: ColorUtils.headerColor,
    primaryColor: ColorUtils.primaryColor,
    errorColor: ColorUtils.failureColor,
    backgroundColor: "lightBackgroundColor",
    mainbackgroundColor: "lightMainBackgroundColor",
    scaffoldBackgroundColor: ColorUtils.primaryBackgroundColor,
    iconTheme: ColorUtils.headerColor,
    colorScheme: ColorUtils.headerColor,
    cardColor: "lightCardColor",
    authSubTitle: "lightAuthSubTitle",
    authFormLabels: "lightAuthFormLabels",
    authFormBorder: "lightAuthFormBorder",
    authFormSideBar: "lightAuthFormSideBar",
    otpSelectedBorder: "lightOtpSelectedBorder",
    otpModalSymbol: "lightOtpModalSymbol",
    boxShadow: "lightBoxShadow",
    switchColor: "lightSwitchColor",
    acknowledgeModalIconSuccess: "lightAcknowledgeModalIconSuccess",
    formItemMarginBottom: "lightformItemMarginBotton",
    modalClass: "lightModalClass",
    formItem: "lightFormItem",
    selectorColor: "lightSelector",
    brandingText: "lightBrandingText",
    menubarSelect: "lightSelecteMenuBackground",
    menubarSelectColor: "lightSelectMenuColor",
    menuUserBackgroundColor: "lightMenuUserBackground",
    menuUserFontColor: "lightMenuUserColor",
    menuBorderRight: "lightMenuBorder",
    menuDividerColor: "lightDividerColor",
    tabColor: "lightTabColor",
    bodyBackgroundColor: "lightComponentbackgroundColor",
    successStatus: "lightStatusSuccess",
    errorStatus: "lightStatusError",
    warningStatus: "lightStatusWarning",
    staleStatus: "lightStatusStale",
    successStatusBG: "lightStatusSuccessBG",
    errorStatusBG: "lightStatusErrorBG",
    warningStatusBG: "lightStatusWarningBG",
    staleStatusBG: "lightStatusStaleBG",
    consentDivider: "lightConsentDivider",
    consentCardBorder: "lightConsentCard",
    noConsentFound: noConsentFoundLight,
    noAccountFound: noAccountFoundLight,
    notificationBackgroundColor: "lightNotificationBackgroundColor",
    consentIconBG: "lightConsentIconBG",
    collapseBG: "lightCollapseBG",
    collapseColumn: "lightCollapseColumn",
    checkBoxBorder: "lightCheckboxBorder",
    radioBorder: "lightRadioBorder",
    accountMaskedNumber: "lightAccountMaskedNumber",
    radioButtonColor: "lightRadioButtonColor",
    succesModalColor: "lightSuccessModalColor",
    breadCrumbColor: "lightbreadCrumbColor",
    notificationShadow: "lightNotificationShadow",
    successAlert: "lightSuccessAlert",
    warningAlert: "lightWarningAlert",
    infoAlert: "lightInfoAlert",
    errorAlert: "lightErrorAlert",
    successIcon: lightSuccessIcon,
    warningIcon: lightWarningIcon,
    infoIcon: lightInfoIcon,
    menubarSelectIcon: "lightMenubarSelectIcon",
    menubarIcon: "lightMenubarIcon",
    navbar: "lightNavbar",
    loginSignupOtp: "lightLoginSignupOtp",
    entityCard: "lightEntityCard",
    checkBox: "lightCheckbox",
    dobPicker: "ligthDobPicker",
  },
  // dark theme colors
  dark: {
    hintColorStyle: ColorUtils.darkHintColor,
    hintColor: "darkHintColor",
    highlightColor: "darkHighlightColor",
    disabledColor: "darkDisabledColor",
    popupMenuTheme: {
      color: ColorUtils.darkCardColor,
      textStyle: ColorUtils.darkHintColor,
      fontFamily: "Poppins",
    },
    textTheme: {
      headline1: "darkTextThemeHeadline1",
      headline2: "darkTextThemeHeadline2",
      headline3: "darkTextThemeHeadline3",
      headline4: ColorUtils.cardColor,
      headline5: "darkTextThemeHeadline5",
      headline6: "darkTextThemeHeadline5BG",
      bodyText1: ColorUtils.darkPrimaryTextColor,
      bodyText2: ColorUtils.darkTertiaryButtonColor,
      subtitle1: "darkTextThemeSubtitle1",
      subtitle2: ColorUtils.darkIdentifierSelect,
      overline: ColorUtils.darkSearchBarFillColor,
      caption: ColorUtils.darkShadowColor,
      authButton: "darkTextThemeAuthButton",
      authButtonCustomized: "darkTextThemeAuthButtonCustomized",
    },
    dividerColor: "darkDivider",
    dividerColorBorder: "darkDividerBorder",
    shadowColor: ColorUtils.darkBoxShadowColor,
    hoverColor: "darkHoverColor",
    indicatorColor: "darkIndicatorColor",
    secondaryHeaderColor: ColorUtils.darkCardColor,
    fontFamily: "Poppins",
    splashColor: ColorUtils.splashColor,
    textSelectionTheme: ColorUtils.darkHeaderColor,
    primaryIconTheme: ColorUtils.darkHeaderColor,
    primaryColor: ColorUtils.darkPrimaryColor,
    errorColor: ColorUtils.failureColor,
    backgroundColor: "darkBackgroundColor",
    mainbackgroundColor: "darkBackgroundColor",

    scaffoldBackgroundColor: ColorUtils.darkPrimaryBackgroundColor,
    iconTheme: ColorUtils.darkHeaderColor,
    colorScheme: ColorUtils.invertTextColor,
    cardColor: "darkCardColor",
    authSubTitle: "darkAuthSubTitle",
    authFormLabels: "darkAuthFormLabels",
    authFormBorder: "darkAuthFormBorder",
    authFormSideBar: "darkAuthFormSideBar",
    otpSelectedBorder: "darkOtpSelectedBorder",
    otpModalSymbol: "darkOtpModalSymbol",
    boxShadow: "darkBoxShadow",
    switchColor: "darkSwitchColor",
    acknowledgeModalIconSuccess: "darkAcknowledgeModalIconSuccess",
    formItemMarginBottom: "darkformItemMarginBotton",
    modalClass: "darkModalClass",
    formItem: "darkFormItem",
    selectorColor: "darkSelector",
    brandingText: "darkBrandingText",
    menubarSelect: "darkSelecteMenuBackground",
    menubarSelectColor: "darkSelectMenuColor",
    menuUserBackgroundColor: "darkMenuUserBackground",
    menuUserFontColor: "darkMenuUserColor",
    menuBorderRight: "darkMenuBorder",
    menuDividerColor: "darkDividerColor",
    tabColor: "darkTabColor",
    bodyBackgroundColor: "darkComponentbackgroundColor",
    successStatus: "darkStatusSuccess",
    errorStatus: "darkStatusError",
    warningStatus: "darkStatusWarning",
    staleStatus: "darkStatusStale",
    successStatusBG: "darkStatusSuccessBG",
    errorStatusBG: "darkStatusErrorBG",
    warningStatusBG: "darkStatusWarningBG",
    staleStatusBG: "darkStatusStaleBG",
    consentDivider: "darkConsentDivider",
    consentCardBorder: "darkConsentCard",
    noConsentFound: noConsentFoundDark,
    noAccountFound: noAccountFoundDark,
    notificationBackgroundColor: "darkNotificationBackgroundColor",
    consentIconBG: "darkConsentIconBG",
    collapseBG: "darkCollapseBG",
    collapseColumn: "darkCollapseColumn",
    checkBoxBorder: "darkCheckboxBorder",
    radioBorder: "darkRadioBorder",
    accountMaskedNumber: "darkAccountMaskedNumber",
    radioButtonColor: "darkRadioButtonColor",
    succesModalColor: "darkSuccessModalColor",
    breadCrumbColor: "darkbreadCrumbColor",
    notificationShadow: "darkNotificationShadow",
    successAlert: "darkSuccessAlert",
    warningAlert: "darkWarningAlert",
    infoAlert: "darkInfoAlert",
    successIcon: darkSuccessIcon,
    warningIcon: darkWarningIcon,
    infoIcon: darkInfoIcon,
    errorAlert: "darkErrorAlert",
    menubarSelectIcon: "darkMenubarSelectIcon",
    menubarIcon: "darkMenubarIcon",
    navbar: "darkNavbar",
    loginSignupOtp: "darkLoginSignupOtp",
    entityCard: "darkEntityCard",
    checkBox: "darkCheckbox",
    dobPicker: "darkDobPicker",
  },
};
