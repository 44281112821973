import { Button, Divider, Form, Input, Radio, Space, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Context, useTheme } from "../../../App";
import { getGrievanceIssuesTypes } from "../../../Services/GrievanceServices/GrievanceService";

import { translation } from "../../../i18n/translation";
import "./index.css";

interface UpdateConsentProps {
  onFormSubmit: (values: any) => void;
  onClose: () => void;
}

const DeleteAccount: React.FC<UpdateConsentProps> = ({
  onFormSubmit,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [form] = useForm();
  const [reasons, setReasons] = useState<any>([]);
  const [selectedReason, setSelectedReason] = useState("");

  const handleCancel = () => {
    onClose();
  };
  const handleDeleteAccount = (values: any) => {
    onFormSubmit(values);
  };

  useEffect(() => {
    getGrievanceIssuesTypes()
      .then((res) => res.data)
      .then((res) => {
        setReasons(res);
      });
  }, []);

  const { setMenubar }: any = useContext(Context);
  useEffect(() => {
    setMenubar(false);
    return () => setMenubar(true);
  }, [setMenubar]);

  useEffect(() => {
    form.setFieldsValue({
      reasonType: reasons[Object.keys(reasons).sort()[0]],
    });
  }, [reasons, form]);

  return (
    <div className={`${theme.mainbackgroundColor} fill-window`}>
      <div className="deleteAccountRoot">
        <Typography.Title
          level={3}
          className={`${theme.textTheme.headline1} titleClass deleteTitle`}
        >
          {t(translation.lblDeleteAccount)}
        </Typography.Title>
        <Typography.Text className={theme.textTheme.subtitle1}>
          {t(translation.lblFormReason)}
        </Typography.Text>
        <Form
          form={form}
          onFinish={handleDeleteAccount}
          autoComplete="off"
          requiredMark={false}
          scrollToFirstError={true}
          layout="vertical"
          className="deleteForm"
        >
          <div className="formContainer">
            <div className="formDetails">
              <div className="formLabel">
                <Typography.Text className={`${theme.authFormLabels}`}>
                  {t(translation.lblFixingIssue)}
                </Typography.Text>
              </div>
              <div className="formLabel">
                <Typography.Text className={`${theme.authFormLabels}`}>
                  {t(translation.lblReasonsToDelete)}
                </Typography.Text>
              </div>

              <Form.Item
                className={`${theme.authFormLabels} form`}
                name="reasonType"
                rules={[
                  {
                    required: true,
                    message: t(translation.lblRequiredField),
                  },
                ]}
              >
                <Radio.Group
                  className={`${theme.textTheme.subtitle1} deleteReasons`}
                >
                  <Space>
                    {Object.keys(reasons)
                      .sort()
                      .map((key: any) => {
                        return (
                          <div>
                            <Radio
                              className={` ${theme.textTheme.headline6} ${theme.checkBoxBorder} checboxSelectAll`}
                              value={reasons[key]}
                              onChange={(e) =>
                                setSelectedReason(e.target.value)
                              }
                            >
                              {reasons[key]}
                            </Radio>
                          </div>
                        );
                      })}
                  </Space>
                </Radio.Group>
              </Form.Item>
              <div className=" formLabel">
                <Typography.Text className={`${theme.authFormLabels}`}>
                  {t(translation.lblMessage)}
                </Typography.Text>
              </div>
              <Form.Item
                name="reason"
                className={`${theme.authFormLabels} form`}
                rules={[
                  {
                    required: selectedReason === "Others" ? true : false,
                    message: t(translation.lblRequiredField),
                  },
                ]}
              >
                <Input.TextArea
                  className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} ticketMessage`}
                  rows={5}
                  placeholder={t(translation.lblShareWithUs)}
                />
              </Form.Item>
            </div>

            <div className="buttonsRoot">
              <Divider className={theme.dividerColor} />
              <div className="formButtons">
                <Form.Item>
                  <Button
                    onClick={handleCancel}
                    className={`${theme.textTheme.subtitle1} cancelButton`}
                  >
                    {t(translation.lblCancel)}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={`${theme.textTheme.authButton} accountDeleteButton`}
                  >
                    {t(translation.lblDeleteAccount)}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DeleteAccount;
