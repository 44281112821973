import { combineReducers } from "redux";
import loader from "./loadingReducer";
import themes from "./themeReducer";
import user from "./userReducer";
import refreshToken from "./refreshTokenReducer";
import version from "./versionReducer";

export default combineReducers({
  loader,
  themes,
  user,
  refreshToken,
  version,
});
