import LinkedDelinkedAccountResponse from "Models/Response/Account/LinkedDelinkedAccounts";
import { UserLoginSuccessResponse } from "Models/Response/Authentication/Otp";
import ForgotPassword from "Pages/Authentication/Components/ForgotPassword";
import LoginSignupForm from "Pages/Authentication/Components/Form";
import LoginSignupSidePanel from "Pages/Authentication/Components/Greetings";
import UserIdentification from "Pages/LinkAccountIdentifiers/UserIdentification";
import { getLinkedAccountsOfUser } from "Services/AccountsServices/AccountService";
import {
  logInWithPin,
  sendOtp,
  verifyEncryptedOtp,
} from "Services/AuthenticationServices/AuthenticationServices";
import {
  getFipId,
  getRedirect,
  getSessionId,
  getSrcRef,
  getTxnId,
  setCredentialsLocalStorage,
} from "Services/StorageServices";
import { QueryParams, Routes } from "Utils/Constants/routes";
import sendEvents from "Utils/Events";
import { RedirectionEvents } from "Utils/types/RedirectionEvents";
import { translation } from "i18n/translation";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import "./index.css";
import { EncryptOTP } from "Utils/EncryptionUtils";

const LoginComponent: React.FC = () => {
  const { t } = useTranslation();
  const { push, replace } = useHistory();
  const location = useLocation();
  const [otpUniqueID, setOTPUniqueId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const redirect: any = location.state;
  const [visible, setVisibility] = useState(false);
  const [isAddIdentifierVerification, setVerification] = useState(false);
  const [token, setToken] = useState("");
  const [isOtpError, setIsOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  const query: any = useQuery();
  const initiateLoginOtp = (
    values: any,
    isLoginWithPin = false
  ): Promise<boolean> => {
    if (isLoginWithPin) {
      return new Promise((resolve) => {
        logInWithPin(values)
          .then((res) => res.data)
          .then((res: UserLoginSuccessResponse) => {
            setCredentialsLocalStorage(res);

            if (query && query.get(QueryParams.Redirect)) {
              replace(query.get(QueryParams.Redirect));
            } else {
              replace(Routes.Consent);
            }
          })
          .catch((error) => {
            setOtpErrorMessage(error.response?.data.errorCode);
            setIsOtpError(true);
          })
          .finally(() => resolve(false));
      });
    } else {
      sendEvents(RedirectionEvents.Init_Login);

      return new Promise((resolve) => {
        sendOtp(values)
          .then((otpRes) => {
            sendEvents(RedirectionEvents.Init_Login_Success);

            setOTPUniqueId(otpRes.data);
            setPhoneNumber(values.phoneNumber);
            resolve(true);
          })
          .catch((error) => {
            sendEvents(
              RedirectionEvents.Init_Login_Failure,
              error.response?.data.errorCode
            );
            setOtpErrorMessage(error.response?.data.errorCode);
            setIsOtpError(true);
            resolve(false);
          });
      });
    }

    // } else {
    // notification.open({
    //   className: `${theme.successAlert} alertClass`,
    //   icon: <img alt="" src={theme.successIcon} className="alertIcon" />,
    //   message: t("Logged in successfully"),
    //   placement: "top",
    //   duration: 5,
    // });

    // setCredentialsLocalStorage(res);

    // if (query && query.get(QueryParams.redirect)) {
    //   push(query.get(QueryParams.redirect));
    // } else {
    //   replace(Routes.consent);
    // }
    // }
    // });
  };

  const verifyLoginOtp = (values: any): Promise<boolean> => {
    sendEvents(RedirectionEvents.Init_Login_Verification);
    const encryptedOtp = EncryptOTP(values.otp, otpUniqueID)
    return new Promise((resolve) =>
      verifyEncryptedOtp({
        code: encryptedOtp,
        phone_number: phoneNumber,
        grant_type: "password",
        otpUniqueID,
      })
        .then((res) => res.data)
        .then((res: UserLoginSuccessResponse) => {
          sendEvents(RedirectionEvents.Login_Verification_Success);
          setIsOtpError(false);

          setCredentialsLocalStorage(res);

          const fipId = getFipId();

          if (!!getRedirect() && !!getTxnId() && !!getSessionId()) {
            getLinkedAccountsOfUser(getSrcRef()!, fipId ? fipId : undefined)
              .then((res1) => res1.data)
              .then((res2: LinkedDelinkedAccountResponse) => {
                if (!!fipId) {
                  let accountsOfFip: any[] = [];
                  const fipList = fipId.split(",");
                  fipList.forEach((fip: string) => {
                    accountsOfFip.push(
                      ...res2.filter((account) => account.fipHandle === fip)
                    );
                  });

                  if (accountsOfFip.length > 0) {
                    replace(query.get(QueryParams.Redirect));
                  } else {
                    replace({
                      pathname: Routes.LinkAccounts,
                      search: `?${QueryParams.Redirect}=${query.get(
                        QueryParams.Redirect
                      )}`,
                    });
                  }
                } else {
                  if (res2.length > 0) {
                    replace(query.get(QueryParams.Redirect));
                  } else {
                    replace({
                      pathname: Routes.LinkAccounts,
                      search: `?${QueryParams.Redirect}=${query.get(
                        QueryParams.Redirect
                      )}`,
                    });
                  }
                }
              });
          } else {
            if (query && query.get(QueryParams.Redirect)) {
              replace(query.get(QueryParams.Redirect));
            } else {
              replace(Routes.Consent);
            }
          }
          resolve(true);
        })
        .catch((error) => {
          sendEvents(
            RedirectionEvents.Login_Verification_Failure,
            error.response?.data.errorCode
          );
          setOtpErrorMessage(error.response?.data.errorCode);
          setIsOtpError(true);
        })
    );
  };

  const signUp = () => {
    push(Routes.Signup);
  };

  const onCancel = () => {
    setVerification(false);
    setIsOtpError(false);
  };

  const onOTPSubmit = (otp: string, identifierNumber: string) => {
    setToken("");
    const encryptedOtp = EncryptOTP(otp, otpUniqueID)
    verifyEncryptedOtp({
      phone_number: identifierNumber,
      grant_type: "password",
      code: encryptedOtp,
      otpUniqueID,
    })
      .then((res) => res.data)
      .then((res: UserLoginSuccessResponse) => {
        // form.resetFields();
        setIsOtpError(false);

        setVisibility(false);
        setVerification(false);
        setToken(res.access_token);
      })
      .catch((error) => {
        setOtpErrorMessage(error.response?.data.errorCode);
        setIsOtpError(true);
      });
  };

  const onIdentifierEntered = (iNumber: any) => {
    sendOtp({ phoneNumber: iNumber.value, isTermsAndConditionAgreed: true })
      .then((res) => res.data)
      .then((res) => {
        setVerification(true);
        setOTPUniqueId(res);
      });
  };

  const forgotPasssword = (isForgotPassword: boolean) => {
    setVisibility(isForgotPassword);
  };

  const resendLoginOtp = (values: boolean): Promise<any> => {
    sendEvents(RedirectionEvents.Resend_Login_Verification);

    return initiateLoginOtp(values);
  };

  return (
    <>
      {token && <ForgotPassword token={token} />}
      {visible ? (
        <UserIdentification
          handleCancel={onCancel}
          identifierEntered={onIdentifierEntered}
          isAddIdentifierVerification={isAddIdentifierVerification}
          onOTPSubmit={onOTPSubmit}
          label={t(translation.lblEnterMobile)}
          isOtpError={isOtpError}
          setIsOtpError={setIsOtpError}
          otpErrorMessage={otpErrorMessage}
        />
      ) : (
        <div className="loginRoot">
          <LoginSignupForm
            phoneNumber={!!redirect && redirect.phoneNumber}
            formType="LOGIN"
            verifyAuth={verifyLoginOtp}
            initiateAuth={initiateLoginOtp}
            buttonSubSectionText={t(translation.lblDontHaveAccount)}
            buttonSubSectionButton={t(translation.lblCreateAccount)}
            buttonText={t(translation.lblLogin)}
            subButtonClick={signUp}
            forgotPasssword={forgotPasssword}
            isError={isOtpError}
            onResend={resendLoginOtp}
            setIsError={() => setIsOtpError(false)}
            editInformation={() => {
              setOTPUniqueId("");
              setPhoneNumber("");
            }}
            otpErrorMessage={otpErrorMessage}
          />
          <LoginSignupSidePanel />
        </div>
      )}
    </>
  );
};

export default LoginComponent;
