export enum Routes {
  Home = "/home",
  Login = "/login",
  Signup = "/signup",
  LinkAccounts = "/link-account",
  UserActivity = "/user-activity",
  Consent = "/consent",
  Account = "/account",
  Grievance = "/user-grievance",
  Settings = "/settings",
  Terms = "/terms",
  Privacy = "/privacy",
  Download = "/download",
}

export enum QueryParams {
  Redirect = "redirect",
}
