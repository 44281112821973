import { ArrowDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Context, useTheme } from "App";
import { SelectedAccounts } from "Models/Request/Account/AccountLink";
import GetConsentByHandleResponse from "Models/Response/Consent/GetConsentByHandle";
import RedirectionAck from "Pages/Common/Components/RedirectionAck";
import ConsentStatusApproval from "Pages/Consent/Components/StatusApproval";
import {
  getByConsentHandlesMultiConsent,
  multiConsentsApprove,
} from "Services/ConsentServices/ConsentService";
import { encode } from "Services/RedirectionServices/Redirection";
import {
  getFiuId,
  getRedirect,
  getSessionId,
  getSrcRef,
  getTxnId,
  getUserId,
} from "Services/StorageServices";
import { Button, Checkbox, Collapse, Empty, Typography, message } from "antd";
import { translation } from "i18n/translation";
import { t } from "i18next";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import CommonConsentDetails from "../CommonConsentDetails";
import "./index.css";
import SelecteAccounts from "Pages/Common/Components/SelectedAccounts";
import { RedirectionEvents } from "Utils/types/RedirectionEvents";
import sendEvents from "Utils/Events";
import { ConsentApprovalStatusType } from "Utils/Constants/statusTypes";
import ConsentDetailsCustomized from "../CommonConsentDetailsCustomized";
import SelecteAccountsCustomized from "Pages/Common/Components/SelectedAccountsCustomized";
import { customisedFiuIds } from "Utils/Constants/baseUrl";

interface Consent {
  numberOfConsent: number;
  consentDetails: GetConsentByHandleResponse[];
  title: string;
}

const MultiConsent: React.FC = () => {
  const { push } = useHistory();
  const [fius, setFIUs] = useState<string[]>([]);
  const [consents, setConsents] = useState<Consent>({
    consentDetails: [],
    numberOfConsent: 0,
    title: "",
  });
  const { otherAppCustomization }: any = useContext(Context);

  const [ack, setAck] = useState(false);
  const [encodedData, setEncodedData] = useState<any>();

  const [isVerificationModal, setIsVerificationModal] = useState(false);
  const [consentRejectReason, setConsentRejectReason] = useState<string>("");
  const [consentStatus, setConsentStatus] = useState<any>();
  const [customMessage, setCustomMessage] = useState<string>();
  const [selectedAccounts, setSelectedAccounts] = useState<SelectedAccounts[]>(
    []
  );

  useEffect(() => {
    getByConsentHandlesMultiConsent(getSrcRef() || "")
      .then((res) => res.data)
      .then((res) => {
        setFIUs(
          res
            .map((consent: any) => consent.DataConsumer.name)
            .filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i)
        );
        setSelectedConsents(
          res.map((consent: { ConsentHandle: any }) => consent.ConsentHandle)
        );
        setConsents((old) => ({
          ...old,
          consentDetails: old.consentDetails.concat(res),
          numberOfConsent: res.length,
        }));
        sendEvents(RedirectionEvents.Consent_Details_View);
      })
      .catch(() => {
        if (!!getRedirect() && !!getTxnId() && !!getSessionId()) {
          const encodeParam = {
            txnid: getTxnId() || "",
            sessionid: getSessionId() || "",
            srcref: getSrcRef() || "",
            status: "F",
            errorcode: "2",
            userid: getUserId() || "",
            fiuId: getFiuId() || "",
          };

          sendEvents(
            RedirectionEvents.Encode_Parameters,
            "Error while fetching the consent information"
          );

          encode(encodeParam).then((res) => {
            setAck(true);
            setEncodedData(res.data);
            setConsentStatus("FAILED");
            setCustomMessage("Error while fetching the information");
          });
        }
      });
  }, []);

  const [statusRedirection, setStatusRedirection] = useState("");
  const [selectedConsents, setSelectedConsents] = useState<string[]>([]);

  const theme = useTheme();
  const { Panel } = Collapse;

  const panelRender = (consent: GetConsentByHandleResponse) => {
    return (
      <CommonConsentDetails
        consentByUniqueIdDetails={consent}
        type={"PENDING"}
        renderTypeRedirection="HEADER"
        multiConsent={true}
      />
    );
  };
  const accountsHeader = (multiConsent: boolean, type: any) => {
    return (
      !multiConsent && type === ConsentApprovalStatusType.PENDING ? (
        <SelecteAccountsCustomized
          consentHandle={getSrcRef() || ""}
          onOk={setSelectedAccounts}
        />
      ) : (
        <></>
      )
    )
  }
  const ConsentRender = () => {
    return consents.consentDetails && consents.consentDetails.length ? (
      <>
        {consents.consentDetails.length === 1 ? (
          <CommonConsentDetails
            consentByUniqueIdDetails={consents.consentDetails[0]}
            type={"PENDING"}
            onChange={setSelectedAccounts}
            consentUniqueId={getSrcRef() || ""}
          />
        ) : (
          <>
            <SelecteAccounts
              consentHandle={getSrcRef() || ""}
              onOk={setSelectedAccounts}
            />

            <Collapse
              bordered={false}
              expandIconPosition="end"
              className={`fiuOuterCollapse collapseConsent`}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              {consents.consentDetails.map(
                (consent: GetConsentByHandleResponse) => (
                  <Panel
                    key={consent.ConsentHandle}
                    className={`${theme.cardColor}  ${theme.entityCard} userActivityCard entiyCard`}
                    header={panelRender(consent)}
                    extra={
                      <Checkbox
                        className={`${theme.textTheme.headline5} ${theme.textTheme.headline6} ${theme.checkBoxBorder} checboxSelectAll`}
                        checked={
                          selectedConsents.indexOf(consent.ConsentHandle) !== -1
                        }
                        id={consent.ConsentHandle}
                        onClick={onConsentSelectionChangeRedirection}
                      />
                    }
                  >
                    <CommonConsentDetails
                      consentByUniqueIdDetails={consent}
                      type={"PENDING"}
                      renderTypeRedirection="BODY"
                    />
                  </Panel>
                )
              )}
            </Collapse>
          </>
        )}
      </>
    ) : (
      <Empty
        image={theme.noConsentFound}
        className={`empty`}
        description={
          <Typography.Text
            className={`${theme.indicatorColor} consentErrorMsg`}
          >
            {t(translation.errorConsentNotFound)}
          </Typography.Text>
        }
      />
    );
  };

  // this function for putting all 103 purpose code's consents first in the list
  const sortByPurposeCode = (a: any, b: any) => {
    const purposeCodeA = a.Purpose.code;
    const purposeCodeB = b.Purpose.code;

    if ((purposeCodeA === "103" && purposeCodeB === "103")
      || (purposeCodeA !== "103" && purposeCodeB !== "103")) {
      return 0;
    }

    if (purposeCodeA === "103") {
      return -1;
    }

    return 1;
  };
  const contentRef = useRef<HTMLDivElement | null>(null);
  const multiMasterRef = useRef<HTMLDivElement | null>(null);
  const singleMasterRef = useRef<HTMLDivElement | null>(null);
  const [isButtonVisible, setIsButtonvisible] = useState(true);

  const handleScroll = () => {
    if (contentRef.current) {
      const scrollTop = contentRef.current.scrollTop;
      const scrollHeight = contentRef.current.scrollHeight;
      const clientHeight = contentRef.current.clientHeight;

      if (scrollTop + clientHeight < scrollHeight - 150) {
        setIsButtonvisible(true);
      } else {
        setIsButtonvisible(false);
      }
    }
  };
  const handleScrollMultiMasterRef = () => {
    if (multiMasterRef.current) {
      const scrollTop = multiMasterRef.current.scrollTop;
      const scrollHeight = multiMasterRef.current.scrollHeight;
      const clientHeight = multiMasterRef.current.clientHeight;

      if (scrollTop + clientHeight < scrollHeight - 150) {
        setIsButtonvisible(true);
      } else {
        setIsButtonvisible(false);
      }
    }
  };
  const handleScrollSingleMasterRef = () => {
    if (singleMasterRef.current) {
      const scrollTop = singleMasterRef.current.scrollTop;
      const scrollHeight = singleMasterRef.current.scrollHeight;
      const clientHeight = singleMasterRef.current.clientHeight;

      if (scrollTop + clientHeight < scrollHeight - 150) {
        setIsButtonvisible(true);
      } else {
        setIsButtonvisible(false);
      }
    }
  };

  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: "smooth", block: "end" })

      setIsButtonvisible(false);
      const scrollHeight = contentRef.current.scrollHeight;
      const clientHeight = contentRef.current.clientHeight;
      const totalScroll = scrollHeight - clientHeight;
      const increment = 20;

      const scrollStep = () => {
        if (contentRef.current && contentRef.current.scrollTop < totalScroll) {
          contentRef.current.scrollTop += increment;
          requestAnimationFrame(scrollStep);
        }
      };

      scrollStep();
    }
    if (multiMasterRef.current) {
      multiMasterRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      setIsButtonvisible(false);
    }
    if (singleMasterRef.current) {
      singleMasterRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      setIsButtonvisible(false);
    }
  };

  const ConsentRenderCustomized = () => {
    return consents.consentDetails && consents.consentDetails.length ? (
      <>
        {consents.consentDetails.length === 1 ? (
          <div className="modifiedSectionRoot" ref={singleMasterRef} onScroll={handleScrollSingleMasterRef}>
            <div className="accountSection">
              {accountsHeader(false, "PENDING")}
            </div>
            <div className="consentDetailSection" ref={contentRef} onScroll={handleScroll} onTouchMove={handleScroll}>
              <ConsentDetailsCustomized
                consentByUniqueIdDetails={consents.consentDetails[0]}
                type={"PENDING"}
                onChange={setSelectedAccounts}
                consentUniqueId={getSrcRef() || ""}
              />
              <div className="buttonOnlyForMobile">
                <div className="buttonGroupRootConsentCustomized">
                  <div className={`${theme.menuDividerColor} activeButtonGroup`}>
                    <Button
                      disabled={selectedConsents.length <= 0}
                      onClick={onReject}
                      type="text"
                      className={`consentRejectButton`}
                    >
                      {t(translation.lblReject)}
                    </Button>
                    <Button
                      disabled={selectedConsents.length <= 0}
                      className={`${theme.textTheme.authButton} ${theme.formItemMarginBottom} consentApproveButton`}
                      onClick={onApprove}
                    >
                      {
                        consents.consentDetails.length === 1 ?
                          t(translation.lblAcceptConsent)
                          :
                          t(translation.lblAcceptConsents)
                      }
                    </Button>
                  </div>
                </div>
                {
                  isButtonVisible && <div className="scrollDownButtonDiv" >
                    <div style={{
                      position: 'fixed',
                      bottom: 20,
                      display: 'flex',
                      justifyContent: 'center',
                      left: window.innerWidth <= 767 ? '50%' : '65%',
                      transform: 'translateX(-50%)',
                      textAlign: 'center',
                      transition: 'bottom 0.3s ease-in-out',
                      alignItems: 'center',
                    }}>
                      <Button
                        hidden={!isButtonVisible}
                        onClick={scrollToBottom}
                        className={`${theme.textTheme.authButton} ${theme.formItemMarginBottom} scrollDownButton`}
                      >
                        Scroll Down <ArrowDownOutlined />
                      </Button>
                    </div>
                  </div>
                }
              </div>

            </div>
          </div>
        ) : (
          <>
            <div className="modifiedSectionRoot" ref={multiMasterRef} onScroll={handleScrollMultiMasterRef}>
              <div className="accountSection">
                {accountsHeader(false, "PENDING")}
              </div>
              <div className="consentDetailSection" ref={contentRef} onScroll={handleScroll} onTouchMove={handleScroll}>
                {consents.consentDetails.sort(sortByPurposeCode).map(
                  (consent: GetConsentByHandleResponse) => (
                    <ConsentDetailsCustomized
                      consentByUniqueIdDetails={consent}
                      type={"PENDING"}
                      onChange={setSelectedAccounts}
                      selectedConsents={selectedConsents}
                      onConsentSelectionChangeRedirection={onConsentSelectionChangeRedirection}
                      multiConsent={true}
                      consentHandle={consent.ConsentHandle}
                    />
                  )
                )}
                <div className="buttonGroupRootConsentCustomized">
                  <div className={`${theme.menuDividerColor} activeButtonGroup`}>
                    <Button
                      disabled={selectedConsents.length <= 0}
                      onClick={onReject}
                      type="text"
                      className={`consentRejectButton`}
                    >
                      {t(translation.lblReject)}
                    </Button>
                    <Button
                      disabled={selectedConsents.length <= 0}
                      className={`${theme.textTheme.authButton} ${theme.formItemMarginBottom} consentApproveButton`}
                      onClick={onApprove}
                    >
                      {
                        consents.consentDetails.length === 1 ?
                          t(translation.lblAcceptConsent)
                          :
                          t(translation.lblAcceptConsents)
                      }
                    </Button>
                  </div>
                </div>
                {
                  isButtonVisible && <div className="scrollDownButtonDiv" >
                    <div style={{
                      position: 'fixed',
                      bottom: 20,
                      display: 'flex',
                      justifyContent: 'center',
                      left: window.innerWidth <= 767 ? '50%' : '65%',
                      transform: 'translateX(-50%)',
                      textAlign: 'center',
                      transition: 'bottom 0.3s ease-in-out',
                      alignItems: 'center',
                    }}>
                      <Button
                        hidden={!isButtonVisible}
                        onClick={scrollToBottom}
                        className={`${theme.textTheme.authButton} ${theme.formItemMarginBottom} scrollDownButton`}
                      >
                        Scroll Down <ArrowDownOutlined />
                      </Button>
                    </div>
                  </div>
                }

              </div>
            </div>
          </>
        )}
      </>
    ) : (
      <Empty
        image={theme.noConsentFound}
        className={`empty`}
        description={
          <Typography.Text
            className={`${theme.indicatorColor} consentErrorMsg`}
          >
            {t(translation.errorConsentNotFound)}
          </Typography.Text>
        }
      />
    );
  };

  const redirect = (statusP: string, errorcode: string) => {
    if (!!getRedirect() && !!getTxnId() && !!getSessionId()) {
      const encodeParam = {
        status: statusP,
        errorcode,
        txnid: getTxnId() || "",
        sessionid: getSessionId() || "",
        srcref: getSrcRef() || "",
        userid: getUserId() || "",
        fiuId: getFiuId() || "",
      };
      sendEvents(RedirectionEvents.Encode_Parameters);

      encode(encodeParam).then((res) => {
        setAck(true);
        setEncodedData(res.data);
      });
    } else {
      push("/consent");
    }
  };

  const onConsentSelectionChangeRedirection = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (selectedConsents.indexOf(event.currentTarget.id) === -1) {
      setSelectedConsents([...selectedConsents, event.currentTarget.id]);
    } else {
      setSelectedConsents(
        selectedConsents.filter((card) => card !== event.currentTarget.id)
      );
    }
  };

  const onReject = () => {
    setStatusRedirection("FAILED");
    sendEvents(RedirectionEvents.Init_Consent_Reject);
    if (
      otherAppCustomization.isConsentActionConfirmationSupported === "false"
    ) {
      onSelectionModal("FAILED");
    } else {
      setIsVerificationModal(true);
    }
  };

  const onApprove = () => {
    if (selectedAccounts.length === 0) {
      message.info("Please select at-least one account");
    } else {
      setStatusRedirection("READY");
      setSelectedAccounts(selectedAccounts);

      sendEvents(
        RedirectionEvents.Init_Consent_Approve,
        selectedAccounts.map((account) => account.fipHandle).join(",")
      );
      if (
        otherAppCustomization.isConsentActionConfirmationSupported === "false"
      ) {
        onSelectionModal("READY");
      } else {
        setIsVerificationModal(true);
      }
    }
  };

  const closeModal = () => {
    sendEvents(RedirectionEvents.Consent_Confirmation_Back_Button_Clicked);

    setIsVerificationModal(false);
  };

  const onSelectionModal = (status?: string) => {
    sendEvents(
      RedirectionEvents.Consent_Confirmation_Modal_Button_Clicked,
      status ?? statusRedirection
    );

    multiConsentsApprove({
      consentHandle: selectedConsents,
      constentApprovalStatus: status ? status : statusRedirection,
      accounts: selectedAccounts,
    })
      .then((res) => res.data)
      .then((res) => {
        setIsVerificationModal(false);
        setConsentRejectReason(res[0].reasonForRejection);
        setConsentStatus(res[0].ConsentStatus);
        if (res[0].ConsentStatus === "FAILED") {
          sendEvents(
            RedirectionEvents.Consent_Rejected,
            res[0].reasonForRejection ?? ""
          );
          redirect("F", "1");
        } else {
          sendEvents(RedirectionEvents.Consent_Approved);
          redirect("S", "0");
        }
      })
      .catch(() => {
        const approvalStatus = status ? status : statusRedirection;
        if (approvalStatus === "READY") {
          sendEvents(RedirectionEvents.Consent_Approval_Failed);
        } else {
          sendEvents(RedirectionEvents.Consent_Reject_Failed);
        }
      });
  };
  const fiuId = getFiuId();

  return (
    <>
      {ack ? (
        <RedirectionAck
          encodedData={encodedData}
          consentRejectReason={consentRejectReason}
          consentStatus={consentStatus}
          accounts={selectedAccounts}
          custom={customMessage}
        />
      ) : (
        <>
          <ConsentStatusApproval
            typeOfModal={statusRedirection}
            isAuthTypeSelectionModalOpen={isVerificationModal}
            onCloseAuthTypeSelectionModal={closeModal}
            onAuthTypeModalSubmit={onSelectionModal}
            fiu={fius.toString()}
          />
          {
            fiuId != null && fiuId !== undefined &&
              customisedFiuIds &&
              customisedFiuIds.length > 0 &&
              customisedFiuIds.includes(fiuId?.toString()) ?
              (<div className={`${theme.bodyBackgroundColor} multiConsentCollapseCustomized`}>
                {" "}
                {consents.consentDetails.length === 1 ? (
                  <>
                    {ConsentRenderCustomized()}
                    <div className="buttonGroupRootConsentCustomizedSingle">
                      <div className={`${theme.menuDividerColor} activeButtonGroup`}>
                        <Button
                          disabled={selectedConsents.length <= 0}
                          onClick={onReject}
                          type="text"
                          className={`consentRejectButton`}
                        >
                          {t(translation.lblReject)}
                        </Button>
                        <Button
                          disabled={selectedConsents.length <= 0}
                          className={`${theme.textTheme.authButton} ${theme.formItemMarginBottom} consentApproveButton`}
                          onClick={onApprove}
                        >
                          {
                            consents.consentDetails.length === 1 ?
                              t(translation.lblAcceptConsent)
                              :
                              t(translation.lblAcceptConsents)
                          }
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {ConsentRenderCustomized()}
                  </>
                )}

              </div>) :
              (<div className={`${theme.bodyBackgroundColor} multiConsentCollapse`}>
                {" "}
                {consents.consentDetails.length === 1 ? (
                  <div className="pendingConsenRoot">
                    {ConsentRender()}
                  </div>
                ) : (
                  <div className="collpaseRoot">
                    <div className="tabViewHeader">
                      <Typography.Title
                        level={3}
                        className={`${theme.textTheme.headline1} consentTypo`}
                      >
                        {t(translation.lblConsents)}
                      </Typography.Title>
                    </div>
                    {ConsentRender()}
                  </div>
                )}
                <div className="buttonGroupRootConsent">
                  <div className={`${theme.menuDividerColor} activeButtonGroup`}>
                    <Button
                      disabled={selectedConsents.length <= 0}
                      onClick={onReject}
                      type="text"
                      className={`${theme.hintColor} subClass subButton`}
                    >
                      {t(translation.lblReject)}
                    </Button>
                    <Button
                      disabled={selectedConsents.length <= 0}
                      className={`${theme.textTheme.authButton} ${theme.formItemMarginBottom} borderNoneHeight50 priButton`}
                      onClick={onApprove}
                    >
                      {t(translation.lblAcceptConsent)}
                    </Button>
                  </div>
                </div>
              </div>)
          }
        </>
      )}
    </>
  );
};

export default MultiConsent;
