import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "App";
import {
  DecodeResponse,
  metaResponse,
} from "Models/Response/Redirection/redirection";
import { theme } from "redux/actions";
import store from "redux/store";

import {
  setDecryptedCredential,
  setMetaDataResponse,
} from "Services/StorageServices";
import {
  decode,
  metaInformation,
} from "Services/RedirectionServices/Redirection";
import { QueryParams, Routes } from "Utils/Constants/routes";
import sendEvents from "Utils/Events";
import { RedirectionEvents } from "Utils/types/RedirectionEvents";

const Redirection: React.FC = () => {
  const history = useHistory();
  const { setMenubar, setStyles, setOtherAppCustomization }: any =
    useContext(Context);
  const [trackingId, setTrackingId]: any = useState("");

  useEffect(() => {
    const queryParam = new URLSearchParams(history.location.search);
    const fi = queryParam.get("fi");
    const reqdate = queryParam.get("reqdate");
    const ecreq = queryParam.get("ecreq");

    if (!!fi && !!reqdate && !!ecreq && !!trackingId) {
      decode({ fi, ecreq, reqdate })
        .then((res) => res.data)
        .then((res: DecodeResponse) => {
                    store.dispatch(theme("light"));

          setDecryptedCredential(res);
          setMenubar(false);
          setStyles(
            res.customization.fiu_custom_styles
              ? JSON.parse(res.customization.fiu_custom_styles)
              : ""
          );
          setOtherAppCustomization(
            res.customization.otherAppCustomization
              ? JSON.parse(res.customization.otherAppCustomization)
              : ""
          );
          if (
            res.customization &&
            res.customization.otherAppCustomization &&
            JSON.parse(res.customization.otherAppCustomization)
              .isMultiLanguageSupported === "true"
          ) {
            if (res.isUserPresent) {
              history.replace({
                pathname: "/",
                state: { phoneNumber: res.phoneNumber, route: Routes.Login },
              });
            } else {
              history.replace({
                pathname: "/",
                state: {
                  phoneNumber: res.phoneNumber,
                  isVuaIdDisabled: true,
                  route: Routes.Signup,
                },
              });
            }
          } else {
            if (res.isUserPresent) {
              history.replace({
                pathname: Routes.Login,
                search: `?${QueryParams.Redirect}=/consent`,
                state: { phoneNumber: res.phoneNumber },
              });
            } else {
              history.replace({
                pathname: Routes.Signup,
                search: `?${QueryParams.Redirect}=/consent`,
                state: { phoneNumber: res.phoneNumber, isVuaIdDisabled: true },
              });
            }
          }
          sendEvents(RedirectionEvents.Decryption_Success);
        })
        .catch((res) => {
          sendEvents(
            RedirectionEvents.Decryption_Failure,
            res.response?.data.errorCode + " - " + res.response?.data.errorMsg
          );
        });
    }
  }, [trackingId, setMenubar, setStyles, history, setOtherAppCustomization]);

  useEffect(() => {
    metaInformation({})
      .then((res) => res.data)
      .then((res: metaResponse) => {
        setMetaDataResponse(res);
        setTrackingId(res.trackingId);
        const width =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        const height =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;

        sendEvents(
          RedirectionEvents.Meta_Information_Success,
          `Screen resolution: Width - ${width}, Height - ${height}`
        );
      });
  }, []);

  return (
    <>
      <div>Loading...</div>
    </>
  );
};
export default Redirection;
