import axios from "axios";
import {
  AddIdentifier,
  AddIdentifierVerification,
  DeleteIdentifier,
  DeleteIdentifierVerification,
} from "../../Models/Request/Identifier/AddIdentifier";

export function getIdentifiers() {
  return axios.get(`/User/Identifiers`);
}

export function addIdentifier(body: AddIdentifier) {
  return axios.post(`/User/Identifiers`, body);
}

export function addIdentifierVerification(body: AddIdentifierVerification) {
  return axios.post(`/User/Identifiers/Verify`, body);
}

export function addIdentifierVerificationEncrypted(body: AddIdentifierVerification) {
  return axios.post(`/User/Identifiers/Verify-encrypted`, body);
}

export function deleteIdentifier(identifier: DeleteIdentifier) {
  return axios.delete(`/User/Identifiers`, { data: identifier });
}
export function deleteIdentifierVerification(
  body: DeleteIdentifierVerification
) {
  return axios.delete(`/User/Identifiers/Verify`, { data: body });
}

export function deleteIdentifierVerificationEncrypted(
  body: DeleteIdentifierVerification
) {
  return axios.delete(`/User/Identifiers/Verify-encrypted`, { data: body });
}

export function getLinkedAccountsCount(value: string, type: string) {
  return axios.get(
    `/User/Identifiers/LinkedAccountsCount?value=${value}&type=${type}`
  );
}
