import { CalendarOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  RadioChangeEvent,
  Select,
  Typography,
} from "antd";
import notification from "antd/lib/notification";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authType } from "Utils/Constants/authType";
import { useTheme } from "../../App";
import { translation } from "../../i18n/translation";
import { GetIdentifierResponse } from "../../Models/Response/Identifier/Identifier";
import {
  addIdentifier,
  addIdentifierVerificationEncrypted,
  deleteIdentifier,
  deleteIdentifierVerificationEncrypted,
  getIdentifiers,
  getLinkedAccountsCount,
} from "../../Services/IdentifierService/Identifier";
import { IdentifierType } from "../../Utils/Constants/identifierType";
import {
  validAadhar,
  validAccNo,
  validCRN,
  validEmail,
  validMobile,
  validOther,
  validPan,
  validPPAN,
} from "../../Utils/Constants/identifierValidator";
import { DeleteType } from "../../Utils/Constants/statusTypes";
import OTPInputComponent from "../Common/Components/OTPModal";
import StatusApproval from "../Consent/Components/StatusApproval";
import "./index.css";
import moment from "moment";
import { EncryptOTP } from "Utils/EncryptionUtils";

const identifierTypes = Object.values(IdentifierType).filter((iden) => iden === IdentifierType.MOBILE);

const SettingsIdentifier = () => {
  const [identifiers, setIndentifiers] = useState([{ type: "", value: "" }]);
  const [form] = Form.useForm();
  const [otpUniqueID, setOTPUniqueId] = useState("");
  const [isAddIdentifierVerification, setVerification] = useState(false);
  const [isOtpError, setIsOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [selectedIdentifierType, setSelectedIdentifierType] = useState(
    IdentifierType.MOBILE
  );
  const [isIdentifierDelete, setIsIdentifierDelete] = useState(false);
  const [selectedIdentifier, setSelectedIdentifier] =
    useState<GetIdentifierResponse>({
      type: "",
      value: "",
      categoryType: "",
    });
  const [identifierDeleteModal, setIdentifierDeleteModal] = useState(false);
  const [linkedAccountsCount, setLinkedAccountsCount] = useState(0);
  const [radioSelect, setRadioSelect] = useState(0);
  const [isPinModalVisible, setisPinModalVisible] = useState(false);

  const phoneNumber = useSelector((state: any) => state.user.user.mobileNumber);

  useEffect(() => {
    getIdentifiers()
      .then((res) => res.data)
      .then((res) => {
        setIndentifiers(res);
      });
  }, []);
  const showNotification = () => {
    notification.open({
      className: `${
        isIdentifierDelete ? theme.warningAlert : theme.successAlert
      } alertClass`,
      message: isIdentifierDelete
        ? t(translation.lblAuditDeleteIdentifier)
        : t(translation.identifierAddSuccess, {
            identifierType: selectedIdentifierType,
          }),
      placement: "top",
      duration: 5,
      icon: (
        <img
          alt=""
          src={isIdentifierDelete ? theme.warningIcon : theme.successIcon}
          className="alertIcon"
        />
      ),
    });
  };

  const onMobileChange = (e: any) => {
    if (selectedIdentifierType === IdentifierType.MOBILE) {
      if (e.target.value[0] === "0") {
        e.target.value = e.target.value.substring(1);
      }
      e.target.value = e.target.value.replace(/[^0-9]/gi, "");
    } else if (selectedIdentifierType === IdentifierType.PAN) {
      e.target.value = e.target.value.toUpperCase();
    }
  };

  const formSubmit = (iNumber: any) => {
    iNumber.type = selectedIdentifierType;
    iNumber.categoryType =
      selectedIdentifierType === IdentifierType.MOBILE ? "STRONG" : "WEAK";

    if (
      iNumber.type === IdentifierType.DOB &&
      !moment(iNumber.value, "DD/MM/YYYY", true).isValid()
    ) {
      return;
    }

    addIdentifier(iNumber)
      .then((res) => res.data)
      .then((res) => {
        if (selectedIdentifierType === IdentifierType.MOBILE) {
          setVerification(true);
          setOTPUniqueId(res.mobile.otpUniqueID);
        } else {
          getIdentifiers()
            .then((resD) => resD.data)
            .then((resI) => {
              setIndentifiers(resI);
            });
          form.resetFields();
          showNotification();
        }
      });
  };

  const onCancel = () => {
    setIsOtpError(false);
    setIsIdentifierDelete(false);
    setLinkedAccountsCount(0);

    if (radioSelect === 0) setisPinModalVisible(false);
    else setVerification(false);
  };

  const onOTPValueSubmit = (otp: string) => {
    if (isIdentifierDelete) {
      let encryptedOTP = otp;
      if (radioSelect !== 0) {
        // only encrypt if AuthType is OTP, or else don't encrypt
        encryptedOTP = EncryptOTP(otp, otpUniqueID);
      }
      deleteIdentifierVerificationEncrypted({
        otpUniqueID: radioSelect === 0 ? "" : otpUniqueID,
        identifier: selectedIdentifier,
        authType: radioSelect === 0 ? authType.PIN : authType.OTP,
        authValue: encryptedOTP,
      })
        .then(() => {
          getIdentifiers()
            .then((res) => res.data)
            .then((res) => setIndentifiers(res));
          setVerification(false);
          setIsOtpError(false);
          setIsIdentifierDelete(false);
          showNotification();
          setisPinModalVisible(false);
        })
        .catch((error) => {
          setOtpErrorMessage(error.response?.data.errorCode);
          setIsOtpError(true);
        });
    } else {
      const encryptedOTP = EncryptOTP(otp, otpUniqueID);
      addIdentifierVerificationEncrypted({
        phoneNumber: form.getFieldValue("value"),
        code: encryptedOTP,
        otpUniqueID,
        identifierType: selectedIdentifierType,
      })
        .then(() => {
          getIdentifiers()
            .then((res) => res.data)
            .then((res) => setIndentifiers(res));
          setVerification(false);
          setIsOtpError(false);
          showNotification();
          form.resetFields();
        })
        .catch((error) => {
          setOtpErrorMessage(error.response?.data.errorCode);
          setIsOtpError(true);
        });
    }
  };

  const onIdentifierTypeChange = (value: any) => {
    setSelectedIdentifierType(value);
    form.resetFields();
  };
  const customValidator = (_: any, value: string) => {
    if (value) {
      switch (selectedIdentifierType) {
        case IdentifierType.MOBILE: {
          if (!validMobile.test(value)) {
            return Promise.reject(
              new Error(t(translation.errorInvalidPhoneNumber))
            );
          }
          break;
        }
        case IdentifierType.AADHAR: {
          if (!validAadhar.test(value)) {
            return Promise.reject(
              new Error(t(translation.errorEnterValidAadhaar))
            );
          }
          break;
        }
        case IdentifierType.PAN: {
          if (!validPan.test(value)) {
            return Promise.reject(new Error(t(translation.errorEnterValidPan)));
          }
          break;
        }
        case IdentifierType.EMAIL: {
          if (!validEmail.test(value)) {
            return Promise.reject(
              new Error(t(translation.errorEnterValidEmail))
            );
          }
          break;
        }
        case IdentifierType.ACCNO: {
          if (!validAccNo.test(value)) {
            return Promise.reject(
              new Error(t(translation.errorEnterValidAccountNumber))
            );
          }
          break;
        }
        case IdentifierType.CRN: {
          if (!validCRN.test(value)) {
            return Promise.reject(
              new Error(t(translation.errorEnterValidAccountNumber))
            );
          }
          break;
        }
        case IdentifierType.PPAN: {
          if (!validPPAN.test(value)) {
            return Promise.reject(
              new Error(t(translation.errorEnterValidAccountNumber))
            );
          }
          break;
        }
        case IdentifierType.OTHER: {
          if (!validOther.test(value)) {
            return Promise.reject(new Error(t(translation.lblErrorIdentifier)));
          }
          break;
        }
      }
    }
    return Promise.resolve();
  };
  const theme = useTheme();
  const { t } = useTranslation();

  const disabledDate = (current: any) => {
    return current && current.valueOf() > Date.now();
  };

  const initiateDeleteIdentifier = () => {
    setIdentifierDeleteModal(false);

    if (radioSelect === 0) {
      setisPinModalVisible(true);
    } else {
      deleteIdentifier(selectedIdentifier)
        .then((res) => res.data)
        .then((res) => {
          setOTPUniqueId(res);
          setVerification(true);
        });
    }
  };

  const deleteIdentifierConfirmation = (identifier: any) => {
    setSelectedIdentifier(identifier);
    getLinkedAccountsCount(identifier.value, identifier.type)
      .then((res) => res.data)
      .then((res) => {
        setLinkedAccountsCount(res);
        setIsIdentifierDelete(true);

        setIdentifierDeleteModal(true);
      });
  };

  const closeIdentifierDeleteModal = () => {
    setIdentifierDeleteModal(false);
    setIsIdentifierDelete(false);
    setLinkedAccountsCount(0);
  };

  const radioSelectEvent = (e: RadioChangeEvent) => {
    setRadioSelect(e.target.value);
  };

  return (
    <>
      {isAddIdentifierVerification ? (
        <OTPInputComponent
          title={translation.lblVerify}
          isOpen={isAddIdentifierVerification}
          onOtpSubmit={onOTPValueSubmit}
          onCancel={onCancel}
          okText={translation.lblConfirm}
          onResend={() => form.submit()}
          mobileNumber={
            isIdentifierDelete ? phoneNumber : form.getFieldValue("value")
          }
          isError={isOtpError}
          setIsError={setIsOtpError}
          errorMessage={otpErrorMessage}
        />
      ) : (
        <></>
      )}
      {isIdentifierDelete && (
        <StatusApproval
          onResend={initiateDeleteIdentifier}
          isAuthTypeSelectionModalOpen={identifierDeleteModal}
          onCloseAuthTypeSelectionModal={closeIdentifierDeleteModal}
          onAuthTypeModalSubmit={initiateDeleteIdentifier}
          isOTPModalVisible={isAddIdentifierVerification}
          verifyOTPAuthType={onOTPValueSubmit}
          onCloseOTPModal={onCancel}
          typeOfModal={DeleteType.IDENTIFIERDELETE}
          likedAccoutsCount={linkedAccountsCount}
          onAuthTypeRadioSelectionChange={radioSelectEvent}
          selectedAuthType={radioSelect}
          pinModalVisible={isPinModalVisible}
          isError={isOtpError}
          setIsError={setIsOtpError}
          errorMessage={otpErrorMessage}
        />
      )}

      {identifiers && identifiers.length ? (
        <>
          {identifiers
            .filter((identifier) => identifier.type === IdentifierType.MOBILE)
            .map((identifier, index) => (
              <div key={index} className="identifierContainer">
                <Typography.Text className={theme.textTheme.headline1}>
                  {t(`lbl${identifier.type}`)}
                </Typography.Text>
                <Card
                  key={index}
                  className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} addIdentifierCard`}
                >
                  <Typography.Text className={theme.textTheme.headline1}>
                    {identifier.value}
                  </Typography.Text>
                  {identifier.value !== phoneNumber ? (
                    <DeleteOutlined
                      onClick={() => deleteIdentifierConfirmation(identifier)}
                    />
                  ) : (
                    <></>
                  )}
                </Card>
              </div>
            ))}
          {identifiers
            .filter((identifier) => identifier.type !== IdentifierType.MOBILE)
            .map((identifier, index) => (
              <div key={index} className="identifierContainer">
                <Typography.Text className={theme.textTheme.headline1}>
                  {identifier.type.toUpperCase() === "PAN" ? t(`lbl${identifier.type}`).toUpperCase() : t(`lbl${identifier.type}`)}
                </Typography.Text>
                <Card
                  key={index}
                  className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} addIdentifierCard`}
                >
                  <Typography.Text
                    className={theme.textTheme.headline1}
                    ellipsis={true}
                  >
                    {identifier.value}
                  </Typography.Text>
                  <DeleteOutlined
                    onClick={() => deleteIdentifierConfirmation(identifier)}
                  />
                </Card>
              </div>
            ))}
        </>
      ) : (
        <></>
      )}
      {identifiers.length % 2 !== 0 ? (
        <div className="inputContainer" />
      ) : (
        <></>
      )}

      <div className="addIdentifierContainer">
        <Typography.Text className={theme.textTheme.headline1}>
          {t(translation.lblAddIdentifier)}
        </Typography.Text>

        <Form
          form={form}
          name="basic"
          onFinish={formSubmit}
          autoComplete="off"
          scrollToFirstError={true}
          className="addidentifierForm"
        >
          <Input.Group className="identifierSelectorContainer">
            <Select
              onChange={onIdentifierTypeChange}
              popupClassName={theme.selectorColor}
              className={`${theme.selectorColor} ${theme.textTheme.headline1} titleClass addidentifierCard `}
              defaultValue="MOBILE"
            >
              {identifierTypes.map((type, index) => {
                return (
                  <Select.Option
                    key={index}
                    className={`${theme.textTheme.headline1} ${theme.selectorColor} issueSelector `}
                    value={type}
                  >
                    {type === IdentifierType.MOBILE
                      ? t(translation.lblMobileNumber)
                      : type === IdentifierType.PAN ?
                         t(`lbl${type}`).toUpperCase()
                        : t(`lbl${type}`)}
                  </Select.Option>
                );
              })}
            </Select>
            <Form.Item
              name="value"
              rules={[
                {
                  required: true,
                  message: t(translation.lblRequiredField),
                },
                {
                  validator: customValidator,
                },
              ]}
              className="settingsAddidentifierFormItem"
            >
              {selectedIdentifierType === IdentifierType.DOB ? (
                <div className="dob">
                  <DatePicker
                    disabledDate={disabledDate}
                    popupClassName={`${theme.dobPicker} dobPicker`}
                    onChange={(_, dateString: string) =>
                      form.setFieldValue("value", dateString)
                    }
                    allowClear={true}
                    inputReadOnly={true}
                    format={"DD/MM/YYYY"}
                    className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormLabels} ${theme.authFormBorder} identifierInputForm numberContainer identifierInput`}
                    name="value"
                    suffixIcon={
                      <CalendarOutlined className={theme.textTheme.headline1} />
                    }
                    showToday={false}
                  />
                  <Form.Item>
                    <Button
                      className={`${theme.textTheme.headline5} addButton`}
                      htmlType="submit"
                      type="text"
                    >
                      {t(translation.lblAdd)}
                    </Button>
                  </Form.Item>
                </div>
              ) : (
                <Input
                  placeholder={t(translation.enterIdentifier, {
                    identifier: t(`lbl${selectedIdentifierType}`),
                  })}
                  onInput={onMobileChange}
                  className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormLabels} ${theme.authFormBorder} identifierInputForm numberContainer identifierInput`}
                  suffix={
                    <Form.Item>
                      <Button
                        className={`${theme.textTheme.headline5} addButton`}
                        htmlType="submit"
                        type="text"
                      >
                        {t(translation.lblAdd)}
                      </Button>
                    </Form.Item>
                  }
                  value={form.getFieldValue("value")}
                />
              )}
            </Form.Item>
          </Input.Group>
        </Form>
      </div>
    </>
  );
};

export default SettingsIdentifier;
