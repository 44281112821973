import { Button, Divider, Form, Select, Typography, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  createNewGrievance,
  getGrievanceIssuesTypes,
} from "../../../../Services/GrievanceServices/GrievanceService";

import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../../../Utils/Constants/routes";
import "./index.css";

import { useTranslation } from "react-i18next";
import { useTheme, Context } from "../../../../App";
import BreadCrumbs from "../../../Common/Components/BreadCrumbs";
import { translation } from "../../../../i18n/translation";
import { useSelector } from "react-redux";

const GenrateTicket: React.FC = () => {
  const [grievanceIssues, setGrievanceIssues] = useState<any>({});
  const version: string = useSelector((state: any) => state.version.version);
  const [form] = Form.useForm();
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();
  const handleSubmit = (values: any) => {
    values.appVersion = "WEB" + version;
    createNewGrievance(values).then(() => history.replace(Routes.Grievance));
  };
  const handleCancel = () => {
    history.replace(Routes.Grievance);
  };

  const { setMenubar }: any = useContext(Context);
  useEffect(() => {
    setMenubar(false);
    return () => setMenubar(true);
  }, [setMenubar]);
  const location = useLocation();
  return (
    <div className={`${theme.mainbackgroundColor} fill-window`}>
      <div className="generateTicketRoot">
        <BreadCrumbs
          className="generateTicketBreadcrumbs"
          breadcrumbItems={[
            { route: "/user-grievance", label: t(translation.lblHelpCenter) },
            {
              route: location.pathname,
              label: t(translation.lblCreateTicket),
            },
          ]}
        />
        <Typography.Title
          level={3}
          className={`${theme.textTheme.headline1} titleClass generateTicketTitle`}
        >
          {t(translation.lblCreateNewTicket)}
        </Typography.Title>

        <div className="formRoot">
          <Form
            form={form}
            name="basic"
            onFinish={handleSubmit}
            autoComplete="off"
            requiredMark={false}
            scrollToFirstError={true}
            layout="vertical"
            className="generateTicketForm"
          >
            <div className="formContainer">
              <div className="formDetails">
                <div className="formLabel">
                  <Typography.Text className={`${theme.authFormLabels}`}>
                    {t(translation.lblTitle)}
                  </Typography.Text>
                </div>
                <Form.Item
                  name={"issueType"}
                  className={`${theme.authFormLabels} form`}
                  rules={[
                    {
                      required: true,
                      message: t(translation.lblRequiredField),
                    },
                  ]}
                >
                  <Select
                    placeholder={t(translation.lblIssueType)}
                    dropdownClassName={theme.selectorColor}
                    className={`${theme.selectorColor} ${theme.textTheme.headline1} titleClass`}
                    loading={Object.keys(grievanceIssues).length === 0}
                    onDropdownVisibleChange={(open) => {
                      if (open && Object.keys(grievanceIssues).length === 0) {
                        getGrievanceIssuesTypes()
                          .then((res) => res.data)
                          .then((res) => {
                            setGrievanceIssues(res);
                          });
                      }
                    }}
                  >
                    {Object.keys(grievanceIssues)
                      .sort()
                      .map((key, index) => (
                        <Select.Option
                          key={index.toString()}
                          className={`${theme.textTheme.headline1} ${theme.selectorColor} issueSelector`}
                          value={key}
                        >
                          {t(grievanceIssues[key])}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <div className=" formLabel">
                  <Typography.Text className={`${theme.authFormLabels}`}>
                    {t(translation.lblMessage)}
                  </Typography.Text>
                </div>
                <Form.Item
                  name={"description"}
                  className={` form`}
                  rules={[
                    {
                      required: true,
                      message: t(translation.lblRequiredField),
                    },
                  ]}
                >
                  <Input.TextArea
                    className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} ticketMessage`}
                    rows={5}
                    placeholder={t(translation.lblShareWithUs)}
                  />
                </Form.Item>
              </div>

              <div className="buttonsRoot">
                <Divider className={theme.dividerColor} />
                <div className="formButtons">
                  <Form.Item>
                    <Button
                      onClick={handleCancel}
                      className={`${theme.textTheme.subtitle1} cancelButton`}
                    >
                      {t(translation.lblCancel)}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={`${theme.textTheme.authButton} submitButton`}
                    >
                      {t(translation.lblSendTicket)}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default GenrateTicket;
