import React from "react";
import "./index.css";

import { useTheme } from "../../../../App";
import HomePage from "../../../HomePage";
interface ISidePanelProps {
  className?: string;
}

const LoginSignupSidePanel: React.FC<ISidePanelProps> = ({ className }) => {
  const theme = useTheme();
  return (
    <div className={`${className} ${theme.authFormSideBar} typoGraphySection`}>
      <HomePage />
    </div>
  );
};

export default LoginSignupSidePanel;
