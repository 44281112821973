import { EyeInvisibleOutlined, EyeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useTheme } from "App";
import OTPInputModalFooter from "Pages/Common/Components/OTPModal/OTPInputModalFooter";
import { getMaskedNumber } from "Utils/Constants/MaskedNumber";
import { Button, Input, Modal, Typography } from "antd";
import { translation } from "i18n/translation";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react18-input-otp";
import "./index.css";

interface IModalComponentProps {
  isOpen: boolean;
  onOtpSubmit: (otp: string) => void;
  onCancel: () => void;
  okText: string;
  numInputs?: number;
  title?: string;
  onResend?: () => void;
  mobileNumber?: string;
  isPinModal?: boolean;
  isError?: boolean;
  setIsError?: (isError: boolean) => void;
  errorMessage?: string;
  isLinkAccount?: boolean;
}

const OTPInputComponent: React.FC<IModalComponentProps> = ({
  isOpen,
  okText,
  numInputs,
  onOtpSubmit,
  onCancel,
  onResend,
  mobileNumber,
  isPinModal,
  isError,
  setIsError,
  errorMessage,
  isLinkAccount,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const otpResendDuration = 45;
  const [counter, setCounter] = useState(otpResendDuration);
  const timer = useRef<any>();
  const [OTP, setOTP] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setOTP("");
      setCounter(0);
    } else {
      setCounter(otpResendDuration);
    }
  }, [isOpen]);

  const changeInput1 = (e: any) => {
    e.target.value = e.target.value.replace(/[^a-z0-9]/gi, "");
    setOTP(e.target.value);
    setIsError && setIsError(false);
  };

  const changeInput = (otp: string) => {
    setOTP(otp);
    setIsError && setIsError(false);
  };

  useEffect(() => {
    if (counter <= 0) {
      clearTimeout(timer.current);
      return;
    }
    timer.current = setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const resendOTP = () => {
    if (counter === 0) {
      setCounter(otpResendDuration);
      onResend && onResend();
    }
  };

  const otpFooterSubmit = () => {
    if (isLinkAccount && OTP) {
      onOtpSubmit(OTP);
      setOTP("");
    } else if (OTP && (OTP.length === 4 || OTP.length === 6)) {
      onOtpSubmit(OTP);
      setOTP("");
    }
  };

  return (
    <Modal
      className={`${theme.modalClass} footerRoot modalRoot`}
      closable={false}
      open={isOpen}
      okText={okText}
      cancelButtonProps={{
        type: "text",
        style: { width: "50%" },
      }}
      okButtonProps={{
        style: {
          borderRadius: 6.5,
          color: "white",
          width: "50%",
          fontFamily: "Poppins, serif",
          border: "none",
        },
      }}
      footer={
        <OTPInputModalFooter
          onOtpModalFooterSubmit={otpFooterSubmit}
          buttonLabel={t(okText)}
          counter={counter}
          subButtonLabel={t(translation.lblCancel)}
          onCancel={onCancel}
        />
      }
      onCancel={onCancel}
      destroyOnClose={true}
    >
      <div>
        <div className={`${theme.otpModalSymbol} iconBackground1`}>
          <InfoCircleOutlined
            className={`${theme.hoverColor} modalSymbol acknowledgeIcon`}
          />
        </div>
        <Typography.Text className={`${theme.indicatorColor} title1`}>
          {isPinModal
            ? t(translation.lblEnterPin)
            : t(translation.lblCheckMobile)}
        </Typography.Text>
        {isPinModal ? (
          <></>
        ) : (
          <Typography.Text className={`${theme.authSubTitle} subTitle`}>
            {t(translation.lblCodeSendTo, {
              mobileNumber: getMaskedNumber(mobileNumber ?? ""),
            })}
          </Typography.Text>
        )}
        {isLinkAccount ? (
          <Input.Password
            autoComplete="off"
            type="password"
            value={OTP}
            inputMode="text"
            onChange={changeInput1}
            iconRender={(visible: any) =>
              visible ? (
                <EyeOutlined style={{ color: theme.hintColorStyle }} />
              ) : (
                <EyeInvisibleOutlined style={{ color: theme.hintColorStyle }} />
              )
            }
            className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} ${theme.formItem} formItemPinInput`}
          />
        ) : (
          <OtpInput
            value={OTP}
            onChange={changeInput}
            containerStyle={"containerClass"}
            className={`${theme.textTheme.headline2} ${theme.indicatorColor} subClass`}
            inputStyle={"otpInput"}
            isInputNum={true}
            shouldAutoFocus={true}
            numInputs={isPinModal && isPinModal ? 6 : numInputs ? numInputs : 4}
            isInputSecure={true}
          />
        )}{" "}
        {isError ? (
          <Typography.Text className={`${theme.errorStatus} otpError`}>
            {errorMessage && errorMessage.toUpperCase().includes("OTP")
              ? t(translation.errorInvalidOTP)
              : errorMessage?.toUpperCase().includes("PIN")
              ? t(translation.errorInvalidPin)
              : ""}
          </Typography.Text>
        ) : (
          <></>
        )}
        {isPinModal ? (
          <></>
        ) : (
          <>
            <br />
            <Button
              className={`${theme.hintColor} subClass primaryButton paddingLeft0`}
              id="resendButton"
              type="text"
              onClick={resendOTP}
            >
              {counter !== 0
                ? t(translation.lblResendIn, { secs: counter })
                : t(translation.lblResend)}
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default OTPInputComponent;
