import { CopyrightOutlined, EditFilled } from "@ant-design/icons";
import { useTheme } from "App";
import saafe from "Assets/saafeIcon.png";
import PinInputComponent from "Pages/Common/Components/PinInput";
import {
  getFiuId,
  getRedirect,
  getSessionId,
  getTxnId,
  storageConstants,
} from "Services/StorageServices";
import { LoginSignupFormType } from "Utils/Constants/loginSignupFormTypes";
import { Button, Checkbox, Form, Input, Typography } from "antd";
import { translation } from "i18n/translation";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OtpInput from "react18-input-otp";
import "./index.css";
import { Routes } from "Utils/Constants/routes";

interface ILoginSignupFormProps {
  formType: string;
  buttonSubSectionText: string;
  buttonSubSectionButton: string;
  className?: string;
  subButtonClick: () => void;
  buttonText: string;
  phoneNumber?: string;
  forgotPasssword?: (is: boolean) => void;
  isError: boolean;
  setIsError: () => void;
  onResend: (values: any) => void;
  editInformation?: () => void;
  otpErrorMessage: string;
  initiateAuth: (values: any, isLoginWithPin?: boolean) => Promise<boolean>;
  verifyAuth: (values: any) => Promise<boolean>;
}

export enum FormType {
  LOGIN = "LOGIN",
  CreateAccount = "Create Account",
}

const LoginSignupForm: React.FC<ILoginSignupFormProps> = ({
  formType,
  buttonSubSectionButton,
  buttonSubSectionText,
  buttonText,
  subButtonClick,
  phoneNumber,
  forgotPasssword,
  onResend,
  isError,
  setIsError,
  editInformation,
  otpErrorMessage,
  initiateAuth,
  verifyAuth,
}) => {
  const [isLoginWithPinState, setIsLoginWithPin] = useState(false);
  const [isOTPModalVisible, setOTPModalVisibility] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const [form] = Form.useForm();
  const [disableInputPhoneNumber, setDisableInputPhoneNumber] = useState(false);
  const otpResendDuration = 45;
  const [counter, setCounter] = useState(otpResendDuration);
  const loading: boolean = useSelector(
    (state: any) => state.loader.searchLoader
  );
  const timer = useRef<any>();
  const [isTermsAndConditionVisible, setIsTermsAndConditionVisible] =
    useState(true);

  const changeInput = () => {
    if (isError) {
      setIsError();
    }
  };

  useEffect(() => {
    if (isError) {
      form.setFieldValue("otp", "");
    }
  }, [isError, form]);

  useEffect(() => {
    if (!isOTPModalVisible) {
      form.setFieldValue("otp", "");
      setCounter(0);
    } else {
      setCounter(otpResendDuration);
    }
  }, [isOTPModalVisible, form]);
  useEffect(() => {
    if (counter <= 0) {
      clearTimeout(timer.current);
      return;
    }
    timer.current = setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  useEffect(() => {
    if (!!getRedirect() && !!getTxnId() && !!getSessionId()) {
      setIsTermsAndConditionVisible(true);
    } else {
      setIsTermsAndConditionVisible(true);
    }
  }, [isOTPModalVisible]);

  const resendOTP = () => {
    if (counter === 0) {
      setCounter(otpResendDuration);
      onResend(
        form.getFieldsValue([
          LoginSignupFormType.PhoneNumber,
          "isTermsAndConditionAgreed",
        ])
      );
      form.setFieldValue("otp", "");
    }
  };

  const loginWithPin = () => {
    setIsLoginWithPin(!isLoginWithPinState);

    if (isLoginWithPinState) {
      form.setFieldsValue({ pin: "" });
    }
    setOTPModalVisibility(false);
  };

  const onMobileChange = (e: any) => {
    if (isError) {
      setIsError();
    }
    if (e.target.value[0] === "0") {
      e.target.value = e.target.value.substring(1);
    }
    e.target.value = e.target.value.replace(/[^0-9]/gi, "");
  };

  useEffect(() => {
    if (!!phoneNumber && phoneNumber.length === 10) {
      form.setFieldsValue({ phoneNumber });
      setDisableInputPhoneNumber(true);
      form.submit();
    }
  }, [phoneNumber, form]);

  const setVuaIdAsMobileNumber = (change: any) => {
    const phoneNumberField = change.filter(
      (c: any) => c.name.toString() === LoginSignupFormType.PhoneNumber
    )[0];
    const vuaIdField = change.filter(
      (c: any) => c.name.toString() === LoginSignupFormType.VuaId
    )[0];
    phoneNumberField &&
      phoneNumberField.touched &&
      phoneNumberField.value &&
      phoneNumberField.value.length === 10 &&
      !vuaIdField &&
      form.setFieldsValue({ vuaId: phoneNumberField.value });
  };

  const formSubmitM = (values: any) => {
    if (isError) {
      setIsError();
    }
    if (isOTPModalVisible) {
      verifyAuth(values).then(() => {
        form.resetFields();
        setOTPModalVisibility(false);
      });
    } else {
      initiateAuth(values, isLoginWithPinState).then((res) => {
        if (res) setOTPModalVisibility(true);
      });
    }
  };

  const editUserNumber = () => {
    if (editInformation) {
      editInformation();
    }
    setOTPModalVisibility(false);
  };

  useEffect(() => {
    if ("OTPCredential" in window) {
      const ac = new AbortController();

      setTimeout(() => {
        // abort after 5 minutes
        ac.abort();
      }, 5 * 60 * 1000);

      const credManager: any = navigator.credentials;

      credManager
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp: any) => {
          form.setFieldValue("otp", otp.code);
          form.submit();
          ac.abort();
        })
        .catch((_err: any) => {
          ac.abort();
        });
    }
  }, [form]);

  return (
    <>
      <div
        className={` ${theme.backgroundColor} flexCommonCss spaceBetween  flex50`}
      >
        <div className="height10" />
        <Form
          form={form}
          className={` ${theme.backgroundColor} flexCommonCss justfifyContentCenter width100`}
          name="basic"
          onFinish={formSubmitM}
          autoComplete="off"
          onFieldsChange={setVuaIdAsMobileNumber}
          scrollToFirstError={true}
        >
          <div className="barndingLogos">
            <img src={saafe} alt="" width={40} height={56} />
            {!!getRedirect() && !!getTxnId() && !!getSessionId() ? (
              <img
                src={
                  process.env.REACT_APP_LOGO_BASE_URL +
                  `${!!getFiuId() ? getFiuId() : ""}` +
                  ".jpeg"
                }
                className="fiuLogo"
                alt={storageConstants.FiuId}
                width={40}
                height={56}
              />
            ) : (
              <></>
            )}
          </div>

          <Typography.Text
            className={`${theme.textTheme.headline1} formTypeText`}
          >
            {formType === FormType.LOGIN
              ? t(translation.lblLoginTo, { appName: "Saafe" })
              : t(translation.lblCreateAccountTo, { appName: "Saafe" })}
          </Typography.Text>
          <Typography.Text className={`${theme.authSubTitle} formSubTitle`}>
            {formType === FormType.LOGIN
              ? t(translation.msgLogin)
              : t(translation.msgRegistration)}
          </Typography.Text>

          <div className="labelTopMargin inputCss ">
            <Typography.Text
              className={`${theme.authFormLabels} font14weight400`}
            >
              {t(translation.lblMobileNumber)}
            </Typography.Text>
            <Form.Item
              className={`${theme.formItemMarginBottom}`}
              name={LoginSignupFormType.PhoneNumber}
              rules={[
                {
                  required: true,
                  message: t(translation.errorMobileNumber),
                },
                {
                  len: 10,
                  message: t(translation.errorInvalidPhoneNumber),
                },
              ]}
            >
              <Input
                className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} width100borderRadius8MarginTop8Height50`}
                id="mobile"
                type="tel"
                onInput={onMobileChange}
                maxLength={10}
                placeholder={t(translation.hintMobileNumber)}
                disabled={isOTPModalVisible || disableInputPhoneNumber}
                suffix={
                  isOTPModalVisible && !disableInputPhoneNumber ? (
                    <EditFilled onClick={editUserNumber} />
                  ) : null
                }
              />
            </Form.Item>
          </div>

          {!getRedirect() && !getTxnId() && !getSessionId() ? (
            formType !== FormType.LOGIN ? (
              <>
                <div className="inputCss ">
                  <Typography.Text
                    className={`${theme.authFormLabels} font14weight400`}
                  >
                    {t(translation.lblCreatePassCode)}
                  </Typography.Text>
                  <PinInputComponent
                    label="••••••"
                    className="loginPinMarginBottom"
                    disabled={isOTPModalVisible}
                  />
                </div>
              </>
            ) : (
              <>
                <Typography.Text
                  className={`${theme.hintColor} loginPin inputCss`}
                  onClick={loginWithPin}
                >
                  {!isLoginWithPinState
                    ? t(translation.lblLoginWithPin)
                    : t(translation.lblLoginWithOTP)}
                </Typography.Text>
                {isLoginWithPinState ? (
                  <>
                    <div className="inputCss ">
                      <Typography.Text
                        className={`${theme.authFormLabels} font14weight400`}
                      >
                        {t("Passcode")}
                      </Typography.Text>
                      <PinInputComponent
                        label="••••••"
                        onChange={(_e) => {
                          if (isError) {
                            setIsError();
                          }
                        }}
                      />
                    </div>
                    <Typography.Text
                      className={`${theme.textTheme.headline5} widthAutoCursor`}
                      onClick={() => forgotPasssword && forgotPasssword(true)}
                    >
                      {t("Forgot your passcode?")}
                    </Typography.Text>
                  </>
                ) : (
                  <></>
                )}
              </>
            )
          ) : (
            <></>
          )}
          {isOTPModalVisible && (
            <div className="labelTopMargin inputCss ">
              <Typography.Text
                className={`${theme.authFormLabels} font14weight400`}
              >
                {t("OTP")}
              </Typography.Text>
              <Form.Item
                className={`${theme.formItemMarginBottom}`}
                name="otp"
                rules={[
                  {
                    required: true,
                    message: t(translation.lblRequiredField),
                  },
                  {
                    len: 4,
                    message: "",
                  },
                ]}
              >
                <OtpInput
                  onChange={changeInput}
                  containerStyle={"loginSignupOtpContainer"}
                  inputStyle={`${theme.loginSignupOtp} loginSignupOtp`}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  isInputSecure={true}
                  className={`${theme.indicatorColor} subClass`}
                />
              </Form.Item>
              <Button
                className={`${theme.hintColor} subClass primaryButton paddingLeft0`}
                id="resendButton"
                type="text"
                onClick={resendOTP}
              >
                {counter !== 0
                  ? t(translation.lblResendIn, { secs: counter })
                  : t(translation.lblResend)}
              </Button>
            </div>
          )}

          {isError ? (
            <Typography.Text className={`${theme.errorStatus} otpError`}>
              {otpErrorMessage
                ? otpErrorMessage
                : t(translation.errorInvalidOTP)}
              <br />
            </Typography.Text>
          ) : (
            <></>
          )}

          {isTermsAndConditionVisible && (
            <Form.Item
              className="inputCss"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, checked) => {
                    if (!checked) {
                      return Promise.reject(
                        new Error(t(translation.lblAgreeTerms))
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
              name={"isTermsAndConditionAgreed"}
              initialValue={true}
            >
              <Checkbox
                className={`${theme.checkBox} termsAndCondition`}
                id="isTermsAndConditionAgreed"
              >
                <Typography.Text
                  className={`${theme.textTheme.headline1} agreed`}
                >
                  I agree to{" "}
                  <Typography.Link
                    href={Routes.Terms}
                    target="_blank"
                    className={`${theme.textTheme.headline1} termsAndCondition`}
                  >
                    Terms & Conditions
                  </Typography.Link>{" "}
                  and{" "}
                  <Typography.Link
                    href={Routes.Privacy}
                    target="_blank"
                    className={`${theme.textTheme.headline1} termsAndCondition`}
                  >
                    Privacy Policy
                  </Typography.Link>
                </Typography.Text>
              </Checkbox>
            </Form.Item>
          )}

          <Form.Item className={`${theme.formItemMarginBottom} otpButton`}>
            <Button
              loading={loading}
              className={`${theme.textTheme.authButton} commonButtonBackground primaryButton formItemButton borderNoneHeight50`}
              htmlType="submit"
            >
              {isOTPModalVisible || isLoginWithPinState
                ? t(buttonText)
                : t(translation.lblSendOTP)}
            </Button>
          </Form.Item>
          {!disableInputPhoneNumber && (
            <div className="displayFlexMarginTop32AlignCenter">
              <Typography.Text
                className={`${theme.hintColor} donthaveaccountText primaryButton`}
              >
                {t(buttonSubSectionText)}
              </Typography.Text>

              <Button
                className={`${theme.textTheme.headline5} subClass primaryButton paddingLeft5`}
                onClick={subButtonClick}
                id="createAccountButton"
                type="text"
              >
                {t(buttonSubSectionButton)}
              </Button>
            </div>
          )}
        </Form>
        <div
          className={`${
            formType === FormType.LOGIN ? "copyrightLeft" : "copyrightRight"
          } copyright`}
        >
          <CopyrightOutlined className={theme.textTheme.subtitle1} />
          <Typography.Text
            className={`${theme.textTheme.subtitle1} copyrightText`}
          >
            Saafe {moment().format("YYYY")}
          </Typography.Text>
        </div>
      </div>
    </>
  );
};

export default LoginSignupForm;
