import { Context } from "App";
import Account from "Pages/Accounts/Components";
import AccountDetails from "Pages/Accounts/Components/AccountDetails";
import LinkAccount from "Pages/Accounts/Components/LinkAccount";
import Menubar from "Pages/Common/Components/Menubar";
import NavBar from "Pages/Common/Components/NavBar";
import Consent from "Pages/Consent/Components/ConsentRoot";
import ConsentDetails from "Pages/Consent/Components/Details";
import Grievance from "Pages/Grievance/Components";
import GenrateTicket from "Pages/Grievance/Components/GenrateTicket";
import TicketDetail from "Pages/Grievance/Components/TicketDetail";
import Settings from "Pages/Settings";
import ChangePassword from "Pages/Settings/ChangePassword";
import DashboardComponent from "Pages/UserActivityComponent";
import {
  getLocalStorageItem,
  getRedirect,
  getSessionId,
  getTxnId,
  storageConstants,
} from "Services/StorageServices";
import { getUser } from "Services/UserProfileServices";
import { QueryParams, Routes } from "Utils/Constants/routes";
import { Drawer } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { userInformation } from "redux/actions";
import store from "redux/store";
import "./index.css";

const Root: React.FC<{
  setUser: (user: any) => void;
}> = ({ setUser }) => {
  const history = useHistory();

  const [isUserLoaded, setIsUserLoaded] = useState(false);

  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const accessToken = getLocalStorageItem(storageConstants.AccessToken);

    if (!accessToken) {
      history.push({
        pathname: Routes.Login,
        search:
          history.location.pathname !== Routes.Login
            ? `?${QueryParams.Redirect}=${history.location.pathname}`
            : undefined,
      });
    } else {
      getUser().then((res) => {
        if (
          !getRedirect() &&
          !getTxnId() &&
          !getSessionId() &&
          !res.data.isUserPin
        ) {
          history.push({
            pathname: `${Routes.Settings}/change-password`,
            state: { token: true },
          });
        }
        setUser(res.data);
        setIsUserLoaded(true);
      });
    }
  }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderConsentDetails = (props: any) => (
    <ConsentDetails
      {...props}
      consentUniqueId={props.match.params.consentUniqueId}
      type={props.match.params.type}
    />
  );
  const isRedirected = !!getRedirect() && !!getTxnId() && !!getSessionId();

  const renderAccountDetails = (props: any) => <AccountDetails {...props} />;
  const renderGrievanceDetails = (props: any) => <TicketDetail {...props} />;
  const MenubarComponent = Menubar as unknown as React.ComponentClass<any>;

  const renderChangePassword = (props: any): React.ReactElement => {
    const ChangePasswordComponent = ChangePassword as unknown as React.ComponentClass<any>;

    return <ChangePasswordComponent {...props} />;
  };

  const redirectLogin = () => <Redirect to={Routes.Login} />;

  const redirectConsent = () => <Redirect to={Routes.Consent} />;
  const renderNavbar = () => {
    if (isRedirected) {
      return <NavBar setCollapsed={setCollapsed} isRedirected={true} />;
    } else {
      return <NavBar setCollapsed={setCollapsed} isRedirected={false} />;
    }
  };

  const { menubar }: any = useContext(Context);
  return (
    <>
      <div className="rootLayoutContainer">
        {renderNavbar()}

        <div className={`${isRedirected ? "rootLayout93vh" : ""} rootLayout`}>
          {menubar ? (
            <>
              {collapsed ? (
                <MenubarComponent key="menubarKey" />
              ) : (
                <Drawer
                  placement="right"
                  open={!collapsed}
                  onClose={() => setCollapsed(true)}
                  className="mobileDrawer"
                  headerStyle={{
                    display: "none",
                    justifyContent: "flex-end",
                  }}
                  bodyStyle={{
                    padding: 0,
                  }}
                >
                  <MenubarComponent setCollapsed={setCollapsed} />
                </Drawer>
              )}
            </>
          ) : (
            <></>
          )}

          {isUserLoaded ? (
            <Switch>
              <Route
                exact={true}
                path={Routes.LinkAccounts}
                component={LinkAccount}
              />
              <Route
                exact={true}
                path={Routes.UserActivity}
                component={DashboardComponent}
              />
              <Route exact={true} path={Routes.Consent} component={Consent} />
              <Route exact={true} path={Routes.Account} component={Account} />
              <Route
                exact={true}
                path={`${Routes.Account}/:accountid`}
                render={renderAccountDetails}
              />

              <Route
                exact={true}
                path="/consent/:type/:consentUniqueId"
                render={renderConsentDetails}
              />
              <Route exact={true} path="/settings" component={Settings} />
              <Route
                exact={true}
                path={Routes.Grievance}
                component={Grievance}
              />
              <Route
                exact={true}
                path={`${Routes.Grievance}/generate-ticket`}
                component={GenrateTicket}
              />
              <Route
                exact={true}
                path={`${Routes.Grievance}/:id`}
                render={renderGrievanceDetails}
              />
              <Route
                exact={true}
                path={`${Routes.Settings}/change-password`}
                render={renderChangePassword}
              />

              {getLocalStorageItem(storageConstants.AccessToken)?.length ? (
                <Route path="*" render={redirectConsent} />
              ) : (
                <Route path="*" render={redirectLogin} />
              )}
            </Switch>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = () => {
  return {
    setUser: (user: any) => store.dispatch(userInformation(user)),
  };
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
