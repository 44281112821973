export const convertFiType = (fiType: string) => {
    return accountTypesMapWithFiType[fiType] !== null ? accountTypesMapWithFiType[fiType] : fiType;
}

const accountTypesMapWithFiType: any = {
    "DEPOSIT": "Bank Account",
    "TERM_DEPOSIT": "Fixed Deposit",
    "TERM-DEPOSIT": "Fixed Deposit",
    "RECURRING_DEPOSIT": "Recurring Deposit",
    "CP": "CP",
    "GOVT_SECURITIES": "Government Securities",
    "SIP": "SIP",
    "EQUITIES": "Equities",
    "MUTUAL_FUNDS": "Mutual Funds",
    "ETF": "ETF",
    "IDR": "IDR",
    "CIS": "CIS",
    "AIF": "AIF",
    "INVIT": "INVIT",
    "REIT": "REIT",
    "BONDS": "Bonds",
    "DEBENTURES": "Debentures",
    "GSTR1_3B": "GSTR",
    "GENERAL_INSURANCE": "General Insurance",
    "LIFE_INSURANCE": "Life Insurance",
    "INSURANCE_POLICIES": "Insurance Policies",
    "NPS": "NPS",
    "EPF": "EPF",
    "PPF": "PPF",
    "OTHER": "Other"
}