import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Context, useTheme } from "App";
import default_bank from "Assets/ic_default_bank.png";
import FIPModel from "Models/FIPModel";
import { AddIdentifier } from "Models/Request/Identifier/AddIdentifier";
import AccountLinkConfirmationResponse, {
  AccountLinkConfirmation,
} from "Models/Response/Account/AccountLink";
import {
  DiscoverAccountObject,
  DiscoverAccountResponse,
} from "Models/Response/Account/DiscoverAccount";
import AutoDiscoverAccountLink from "Pages/Accounts/Components/AutoDiscoverAccountLink";
import BreadCrumbs from "Pages/Common/Components/BreadCrumbs";
import OTPInputComponent from "Pages/Common/Components/OTPModal";
import LinkAccountIdentifiers from "Pages/LinkAccountIdentifiers";
import {
  discoverAccounts,
  linkAccountVerificationPost,
  linkAccounts,
} from "Services/AccountsServices/AccountService";
import { getFips } from "Services/FipServices/FipServices";
import {
  getDob,
  getFiTypesRequiredForConsent,
  getFipId,
  getIsAutoDiscoveryEnabled,
  getPan,
  getPhoneNumber,
  getRedirect,
  getSrcRef,
  storageConstants,
} from "Services/StorageServices";
import { logoBaseUrl } from "Utils/Constants/baseUrl";
import { IdentifierType } from "Utils/Constants/identifierType";
import { QueryParams, Routes } from "Utils/Constants/routes";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Empty,
  Image,
  Input,
  Skeleton,
  Tabs,
  Tag,
  Typography,
  notification,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { translation } from "i18n/translation";
import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "./index.css";
import { RedirectionEvents } from "Utils/types/RedirectionEvents";
import sendEvents from "Utils/Events";
import { EncryptOTP } from "Utils/EncryptionUtils";

const LinkAccounts: React.FC = () => {
  const fipId = getFipId();
  const isAutoDiscoveryEnabled = getIsAutoDiscoveryEnabled() === "true";
  const phoneNumber = getPhoneNumber();
  const pan = getPan();
  const dob = getDob();
  const [fiGroupMaster, setFiGroupMaster] = useState<any>({
    BANK: [],
    EQUITIES: [],
    MUTUAL_FUNDS: [],
    INSURANCE: [],
    PENSION: [],
    GST: [],
    REAL_ESTATE: [],
    OTHER_INVESTMENTS: [],
  });
  const [fiGroup, setFiGroup] = useState<any>({
    BANK: [],
    EQUITIES: [],
    MUTUAL_FUNDS: [],
    INSURANCE: [],
    PENSION: [],
    GST: [],
    REAL_ESTATE: [],
    OTHER_INVESTMENTS: [],
  });
  const [currentFip, setCurrentFip] = useState<FIPModel>({
    id: "",
    name: "",
    host: "",
    version: "",
    fiTypeList: [""],
    Identifiers: [{ category: "", type: "" }],
    isEnabled: false,
  });
  const [discoverAccountsList, storeDiscoverAccounts] = useState<
    DiscoverAccountObject[]
  >([]);

  const [discoverAccountsListToBeShown, storeDiscoverAccountsToBeShown] =
    useState<DiscoverAccountObject[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState(new Array<string>());
  const [isOTPModalVisible, setIsOTPModalVisible] = useState(false);
  const [identifiers, setIdentifiers] = useState(new Array<AddIdentifier>());
  const [signature, setSignature] = useState("");
  const [refNumber, setRefNumber] = useState("");
  const [step, setStep] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  const history = useHistory();
  const [isOtpError, setIsOtpError] = useState(false);
  const [isAutoDiscovery, setIsAutoDiscovery] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const reduxState = useSelector((state: any) => {
    return { theme: state.themes.theme, loading: state.loader.searchLoader };
  });
  const { otherAppCustomization }: any = useContext(Context);

  const [isAddIdentifierVisible, setIsAddIdentifierVisible] = useState(false);
  const [isSearch, setIsSearch] = useState(true);

  const location: any = useLocation();
  const useQuery = () => {
    return React.useMemo(() => new URLSearchParams(location.search), []);
  };
  const { setMenubar }: any = useContext(Context);
  const query: any = useQuery();
  const linkAccountBreadcrumbItems = [
    { route: "/account", label: t(translation.lblAccounts) },
    { route: "#", label: t(translation.lblLinkAccounts), step: 1 },
    { route: "#", label: t(translation.lblMobileNumber), step: 2 },
    { route: "#", label: t(translation.lblAccountNumber), step: 3 },
  ];

  useEffect(() => {
    setMenubar(false);
    return () => {
      if (!getSrcRef()) {
        setMenubar(true);
      }
    };
  });
  useEffect(() => {
    sendEvents(RedirectionEvents.Get_Fips);
    const consentHandles = location.state ? location.state : getSrcRef() ? getSrcRef() : null
    getFips(consentHandles)
      .then((res: { data: any }) => res.data)
      .then((res: any) => {
        if (fipId && fipId.length > 0) {
          let localFIP: any = {
            BANK: [],
            EQUITIES: [],
            MUTUAL_FUNDS: [],
            INSURANCE: [],
            PENSION: [],
            GST: [],
            REAL_ESTATE: [],
            OTHER_INVESTMENTS: [],
          };
          const fipList = fipId.split(",");
          fipList.forEach((fip: string) => {
            Object.keys(res).forEach((key) => {
              const filterFip = res[key].filter(
                (p: any) => p.id.toUpperCase() === fip.toUpperCase()
              );
              if (filterFip.length > 0) {
                localFIP = {
                  ...localFIP,
                  [key]: [...localFIP[key], filterFip[0]],
                };
              }
            });
          });

          // Removing fip-category fow which there is no FIP present
          if (localFIP !== undefined && localFIP !== null) {
            for (let key in localFIP) {
              if (Array.isArray(localFIP[key]) && localFIP[key].length === 0) {
                delete localFIP[key];
              }
            }
          }
          if (fipList.length === 1) {
            const res: any = Object.values(localFIP).flat(1);
            setCurrentFip(res[res.findIndex((f: any) => f.id === fipId)]);
            setStep(1);
          }
          setFiGroup(localFIP);
          setFiGroupMaster(localFIP);
        } else {
          setFiGroup(res);
          setFiGroupMaster(res);
        }
      });
  }, [fipId, location.state]);

  const selectedAccountAuto = useCallback(
    (selectedAccountsParams: string[]) => {
      setSelectedAccounts(selectedAccountsParams);
    },
    []
  );

  const selectedFIPAuto = useCallback(
    (fipIdParams: string, fipName: string) => {
      setCurrentFip({
        id: fipIdParams,
        name: fipName,
        fiTypeList: [],
        host: "",
        version: "",
        Identifiers: [],
        isEnabled: false,
      });
    },
    []
  );

  const onCanceled = useCallback(() => {
    if (query && query.get(QueryParams.Redirect)) {
      history.push({
        pathname: query.get(QueryParams.Redirect),
      });
    } else {
      history.push({ pathname: Routes.Account });
    }
  }, [history, query]);

  useEffect(() => {
    let identifier: AddIdentifier[] = [
      {
        type: "MOBILE",
        value: phoneNumber ? phoneNumber : "",
        categoryType: "STRONG",
      },
    ];
    if (pan !== null) {
      identifier.push(
        {
          type: "PAN",
          value: pan ? pan : "",
          categoryType: "WEAK",
        }
      );
    }
    if (dob !== null) {
      identifier.push(
        {
          type: "DOB",
          value: dob ? dob : "",
          categoryType: "WEAK",
        }
      );
    }
    setIdentifiers(identifier);
    if (isAutoDiscoveryEnabled) {
      setIsAutoDiscovery(true);
      setStep(2);
      setSearchValue("");
    }
  }, [fipId, phoneNumber, pan, dob, fiGroupMaster, isAutoDiscoveryEnabled]);

  const onFIPSelect = (FIP: FIPModel) => {
    setCurrentFip(FIP);
    setStep(step + 1);
    setSearchValue("");
    setFiGroup(fiGroupMaster);
  };

  const discoverAccountWithIdentifiers = () => {
    sendEvents(RedirectionEvents.Init_Account_Discovery, currentFip.id);

    discoverAccounts({
      FipId: currentFip.id,
      Identifiers: identifiers,
      FITypes: [],
    })
      .then((res: { data: any }) => res.data)
      .then((res: DiscoverAccountResponse) => {
        res.DiscoveredAccounts.length > 0
          ? sendEvents(
            RedirectionEvents.Account_Discovery_Success,
            currentFip.id
          )
          : sendEvents(RedirectionEvents.No_Accounts_Found, currentFip.id);
        let discoveredAccounts: DiscoverAccountObject[] = []
        if (getRedirect() !== undefined && getRedirect() !== null) {
          // Only filter accounts based on fi type required in consents
          const fiTypesRequiredForConsent = getFiTypesRequiredForConsent();
          if (fiTypesRequiredForConsent !== undefined && fiTypesRequiredForConsent !== null) {
            const fiTypesRequiredArray = fiTypesRequiredForConsent?.split(",");
            res.DiscoveredAccounts.forEach((account: DiscoverAccountObject) => {
              if (fiTypesRequiredArray?.includes(account.FIType)) {
                discoveredAccounts.push(account);
              }
            })
          }
        } else {
          // For normal account discovery keep it as it is
          discoveredAccounts = res.DiscoveredAccounts;
        }
        storeDiscoverAccounts(discoveredAccounts);
        storeDiscoverAccountsToBeShown(discoveredAccounts);
        setSignature(res.signature);
      })
      .catch(() => {
        sendEvents(RedirectionEvents.Account_Discovery_Failure, currentFip.id);
        storeDiscoverAccounts([]);
        storeDiscoverAccountsToBeShown([]);
      });
    setStep(step + 1);
    setSearchValue("");
  };

  useEffect(() => {
    if (discoverAccountsList.length !== discoverAccountsListToBeShown.length) {
      setIsSearch(false);
    } else {
      setIsSearch(true);
    }
  }, [discoverAccountsList, discoverAccountsListToBeShown]);

  const onAccountsSelect = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (selectedAccounts.indexOf(event.currentTarget.id) === -1) {
      setSelectedAccounts([...selectedAccounts, event.currentTarget.id]);
      setIndeterminate(
        selectedAccounts.length + 1 !== discoverAccountsList.length
      );
      setCheckAll(selectedAccounts.length + 1 === discoverAccountsList.length);
    } else {
      setSelectedAccounts(
        selectedAccounts.filter((card) => card !== event.currentTarget.id)
      );
      if (selectedAccounts.length - 1 !== 0) setIndeterminate(true);
      else setIndeterminate(false);
      setCheckAll(false);
    }
  };

  const linkAccount = () => {
    sendEvents(RedirectionEvents.Init_Account_Link, currentFip.id);

    linkAccounts({
      FipId: currentFip.id,
      Accounts: discoverAccountsList.filter(
        (account) =>
          selectedAccounts.filter(
            (value) => value === account.maskedAccNumber
          )[0]
      ),
      signature,
    })
      .then((res: { data: any }) => res.data)
      .then((_res: AccountLinkConfirmationResponse) => {
        sendEvents(RedirectionEvents.Init_Account_Link_Success, currentFip.id);
        setIsOTPModalVisible(true);
        setRefNumber(_res.refNumber);
      })
      .catch(() => {
        sendEvents(RedirectionEvents.Init_Account_Link_Failure, currentFip.id);
      });
  };

  const onLinkAccountOtp = (otp: string) => {
    sendEvents(RedirectionEvents.Init_Account_Link_Verification, currentFip.id);
    const otpEncrypted = EncryptOTP(otp, refNumber);
    linkAccountVerificationPost({
      accountRefNumber: refNumber,
      token: otpEncrypted,
      fipId: currentFip.id
    })
      .then((res: { data: any }) => res.data)
      .then((_res: AccountLinkConfirmation) => {
        sendEvents(RedirectionEvents.Account_Link_Success, currentFip.id);

        notification.open({
          duration: 5,
          icon: <img alt="" src={theme.successIcon} className="alertIcon" />,
          placement: "top",
          message: t(translation.succesfullLinkMessage, {
            fip: currentFip.name,
          }),

          className: `${theme.successAlert} alertClass`,
          description: t(translation.succesfullLinkDescription, {
            accounts: selectedAccounts.join(", "),
          }),
        });
        setIsOTPModalVisible(false);
        setIsOtpError(false);
        setSelectedAccounts([]);
        setCurrentFip({
          id: "",
          name: "",
          host: "",
          version: "",
          fiTypeList: [""],
          Identifiers: [],
          isEnabled: false,
        });

        if (query && query.get(QueryParams.Redirect)) {
          history.push({
            pathname: query.get(QueryParams.Redirect),
          });
        } else {
          history.push(Routes.Account);
        }
      })
      .catch(
        (error: {
          response: { data: { errorCode: React.SetStateAction<string> } };
        }) => {
          sendEvents(
            RedirectionEvents.Account_Link_Failure,
            `FipId: ${currentFip.id}, Msg: ${error.response?.data.errorCode}`
          );
          setOtpErrorMessage(error.response?.data.errorCode);
          setIsOtpError(true);
        }
      );
  };

  const onCancel = () => {
    setIsOTPModalVisible(false);
    setIsOtpError(false);
  };

  const onSearchValueChange = (value: any) => {
    setSearchValue(value.target.value);
    if (step === 0) {
      value.target.value
        ? Object.keys(fiGroupMaster).forEach((key) =>
          setFiGroup((old: any) => ({
            ...old,
            [key]: fiGroupMaster[key].filter(
              (p: any) =>
                p.name
                  .toUpperCase()
                  .indexOf(value.target.value.toUpperCase()) !== -1 ||
                p.id
                  .toUpperCase()
                  .indexOf(value.target.value.toUpperCase()) !== -1
            ),
          }))
        )
        : setFiGroup(fiGroupMaster);
    } else if (step === 2) {
      storeDiscoverAccountsToBeShown(
        value.target.value
          ? discoverAccountsList.filter(
            (p) =>
              p.maskedAccNumber
                .toUpperCase()
                .indexOf(value.target.value.toUpperCase()) !== -1 ||
              p.accType
                .toUpperCase()
                .indexOf(value.target.value.toUpperCase()) !== -1
          )
          : discoverAccountsList
      );
    }
  };

  const goBack = () => {
    setIdentifiers([]);
    if (step === 2) {
      setStep(step - 1);
      setSelectedAccounts([]);
      setIndeterminate(false);
      storeDiscoverAccounts([]);
      storeDiscoverAccountsToBeShown([]);
    } else {
      setStep(step - 1);
    }
  };

  const onIdentifierSelect = (e: any) => {
    setIdentifiers(e);
  };

  const updateSignature = useCallback(
    (signatureParams: string) => setSignature(signatureParams),
    []
  );

  const identifierSelectCallback = useCallback(onIdentifierSelect, []);

  const returnNumberOfFi = (key: string) => {
    return (
      <>
        <Typography.Text className="tabHeader">
          {getFiType(key)}
        </Typography.Text>{" "}
        <Badge
          className={`${reduxState.theme + "BadgeBackground"}`}
          count={
            key === "ALL"
              ? Object.values(fiGroup).flat(1).length
              : fiGroup[key].length
          }
          overflowCount={999}
          showZero={true}
        />
      </>
    );
  };

  const renderFi = (fipList: any) => {
    return fipList && fipList.length > 0 ? (
      <>
        {fipList.map((FIP: FIPModel, index: number) => (
          <Card
            key={index}
            className={`${theme.cardColor} ${theme.consentCardBorder} ${FIP.isEnabled ? "enabled" : "disabled"
              }FIP consentDetailsCard linkAccountCard`}
            id={index.toString()}
            hoverable={true}
            onClick={() => (FIP.isEnabled ? onFIPSelect(FIP) : undefined)}
          >
            <div className="fipCard">
              <div className="fiHeader">
                <Image
                  className="fipLogo"
                  src={logoBaseUrl + FIP.id + ".jpeg"}
                  fallback={default_bank}
                  preview={false}
                />

                <Typography.Text
                  className={`${theme.textTheme.headline3} fipNameLinkAccount`}
                >
                  {FIP.name}
                </Typography.Text>
                {FIP.isEnabled ? (
                  <RightOutlined
                    className={` ${theme.hintColor} rightOutlined`}
                  />
                ) : (
                  <Tag className={`${theme.staleStatusBG} comingSoonTag`}>
                    <Typography.Text className={`${theme.staleStatus} `}>
                      {t(translation.lblComingSoon)}
                    </Typography.Text>
                  </Tag>
                )}
              </div>
              {FIP.Identifiers &&
                FIP.Identifiers.filter(
                  (s) =>
                    s.type !== "" &&
                    s.type !== " " &&
                    s.type !== IdentifierType.MOBILE
                ).length > 0 ? (
                <div className="requiresHeader">
                  <Typography.Text className={theme.indicatorColor}>
                    {t(translation.lblRequiredIdentifier, {
                      value: FIP.Identifiers.map(
                        (identifier: { type: string | undefined }) => {
                          return identifier.type
                            ? " " + _.capitalize(identifier.type)
                            : " MOBILE";
                        }
                      ),
                    })}
                  </Typography.Text>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Card>
        ))}
        {fipList && fipList.length % 2 !== 0 ? (
          <div className="linkAccountCard consentDetailsCard" />
        ) : (
          <></>
        )}
      </>
    ) : (
      <Empty
        image={theme.noConsentFound}
        className={`empty marginTop10`}
        description={
          <Typography.Text
            className={`${theme.indicatorColor} consentErrorMsg`}
          >
            {t(translation.errorNoBanksFound)}
          </Typography.Text>
        }
      />
    );
  };
  const getFiType = (type: string) => {
    if (type === "REAL_ESTATE") return t(translation.lblRealEstate);
    else if (type === "ALL") return t(translation.lblAll);
    else if (type === "BANK") return t(translation.lblBank);
    else if (type === "EQUITIES") return t(translation.lblEquities);
    else if (type === "GST") return t(translation.lblGst);
    else if (type === "MUTUAL_FUNDS") return t(translation.lblMutualFunds);
    else if (type === "INSURANCE") return t(translation.lblInsurance);
    else if (type === "PENSION") return t(translation.lblPension);
    else if (type === "OTHER_INVESTMENTS") return t(translation.lblOthers);
  };
  const renderAllFi = () => {
    return Object.keys(fiGroup) &&
      Object.keys(fiGroup).length > 0 &&
      Object.values(fiGroup).flat(1).length > 0 ? (
      renderFi(
        Object.values(fiGroup)
          .flat(1)
          .filter(
            (v: any, i, a) => a.findIndex((v2: any) => v2.id === v.id) === i
          )
          .sort(function (x: any, y: any) {
            return x.isEnabled === y.isEnabled ? 0 : x.isEnabled ? -1 : 1;
          })
      )
    ) : (
      <Empty
        image={theme.noConsentFound}
        className={`empty marginTop10`}
        description={
          <Typography.Text
            className={`${theme.indicatorColor} consentErrorMsg`}
          >
            {t(translation.errorNoBanksFound)}
          </Typography.Text>
        }
      />
    );
  };

  const updateDiscoverAccounts = useCallback(
    (accounts) => storeDiscoverAccounts(accounts),
    []
  );

  const renderStep = () => {
    return step === 0 ? (
      <Tabs
        className={`${theme.tabColor} tab fipTab`}
        destroyInactiveTabPane={true}
      >
        <Tabs.TabPane
          className="fipList"
          tab={returnNumberOfFi("ALL")}
          key={"ALL"}
        >
          <Skeleton loading={reduxState.loading} className="marginTop10">
            {renderAllFi()}
          </Skeleton>
        </Tabs.TabPane>
        {Object.keys(fiGroup)
          .sort()
          .map((fi) => {
            return (
              <Tabs.TabPane
                className="fipList"
                tab={returnNumberOfFi(fi)}
                key={fi}
              >
                {renderFi(fiGroup[fi])}
              </Tabs.TabPane>
            );
          })}
      </Tabs>
    ) : step === 1 ? (
      <LinkAccountIdentifiers
        isAddIdentifierVisible={isAddIdentifierVisible}
        onIdentifierValueSelect={identifierSelectCallback}
        inputSearchValue={searchValue}
        identifierTypes={currentFip.Identifiers}
      />
    ) : (
      <>
        {isAutoDiscovery ? (
          <AutoDiscoverAccountLink
            identifier={identifiers}
            onCanceled={onCanceled}
            updateSelectedAccounts={selectedAccountAuto}
            updateSelectedFip={selectedFIPAuto}
            updateSignature={updateSignature}
            updateDiscoveredAccounts={updateDiscoverAccounts}
          />
        ) : (
          <Skeleton className="marginTop10" loading={reduxState.loading}>
            <div className="listAccounts">
              {discoverAccountsListToBeShown &&
                discoverAccountsListToBeShown.length ? (
                <>
                  {discoverAccountsListToBeShown.map((accounts, index) => (
                    <Card
                      className={`${theme.cardColor} ${theme.consentCardBorder
                        }  accountsCard ${selectedAccounts.indexOf(accounts.maskedAccNumber) !==
                          -1
                          ? `${theme.textTheme.headline5} selectedCard`
                          : ""
                        }`}
                      bordered={false}
                      key={index}
                      id={accounts.maskedAccNumber}
                      onClick={onAccountsSelect}
                    >
                      <div className="cardFirstRow">
                        <Typography.Text
                          className={`${theme.accountMaskedNumber} maskedAccNumberDiscover`}
                        >
                          {accounts.maskedAccNumber}
                        </Typography.Text>
                        <Checkbox
                          checked={
                            selectedAccounts.indexOf(
                              accounts.maskedAccNumber
                            ) === -1
                              ? false
                              : true
                          }
                          className={`${theme.textTheme.headline5} ${theme.textTheme.headline6} ${theme.checkBoxBorder} checboxSelectAll`}
                        />
                      </div>

                      <Typography.Text
                        className={`${theme.hintColor} accountType`}
                      >
                        {accounts.FIType === "DEPOSIT"
                          ? t(_.capitalize(accounts.accType))
                          : t(_.capitalize(accounts.FIType))}
                      </Typography.Text>
                    </Card>
                  ))}
                  {discoverAccountsListToBeShown &&
                    discoverAccountsListToBeShown.length % 2 !== 0 ? (
                    <div />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Empty
                  image={theme.noConsentFound}
                  className={`empty marginTop10`}
                  description={
                    <Typography.Text
                      className={`${theme.indicatorColor} consentErrorMsg`}
                    >
                      {t(translation.errorNoAccountsFound)}
                    </Typography.Text>
                  }
                />
              )}
            </div>
          </Skeleton>
        )}
      </>
    );
  };

  const theme = useTheme();
  const [indeterminate, setIndeterminate] = useState(false);
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setSelectedAccounts(
      e.target.checked
        ? discoverAccountsList.map((value, index) => value.maskedAccNumber)
        : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const goToLinkAccountAutoDiscovery = () => {
    sessionStorage.removeItem(storageConstants.IsAutoDiscoveryEnabled);
    setStep(0);
    setIsAutoDiscovery(false);
  };

  const [checkAll, setCheckAll] = useState(false);
  return (
    <>
      {isOTPModalVisible ? (
        <OTPInputComponent
          onCancel={onCancel}
          isOpen={isOTPModalVisible}
          onOtpSubmit={onLinkAccountOtp}
          okText={t(translation.lblConfirm)}
          numInputs={6}
          title={t(translation.lblLinkAccounts)}
          onResend={linkAccount}
          mobileNumber={
            identifiers.filter((r) => r.type === "MOBILE")[0]?.value
          }
          isError={isOtpError}
          setIsError={setIsOtpError}
          errorMessage={otpErrorMessage}
          isLinkAccount={true}
        />
      ) : (
        <></>
      )}

      <div className={`${theme.bodyBackgroundColor} linkAccountRoot`}>
        <div className="linkAccountContainer">
          <div>
            {fipId === "" || (fipId && fipId?.length > 0) ? (
              <></>
            ) : (
              <BreadCrumbs
                className="deletebreadCrumbsClass marginBottom16"
                breadcrumbItems={linkAccountBreadcrumbItems.slice(0, step + 2)}
                onBreadcrumSelect={setStep}
              />
            )}
            <Typography.Text className={`${theme.indicatorColor} header`}>
              {step === 0
                ? t(translation.lblSelectBank)
                : step === 1
                  ? t(translation.lblSelectMobile)
                  : isAutoDiscovery
                    ? t(translation.lblLinkBankAccounts)
                    : t(translation.lblAccountSelection, {
                      fipName: currentFip.name,
                    })}
            </Typography.Text>
            {step === 1 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                <Typography.Text className={`${theme.hintColor} marginTop8`}>
                  {t(translation.lblEnsureLinkedMobileNumber)}
                </Typography.Text>{" "}
                {otherAppCustomization.isSecondaryIdentifierSupported ===
                  "false" ? (
                  <></>
                ) : (
                  <Button
                    type="text"
                    onClick={() =>
                      setIsAddIdentifierVisible(!isAddIdentifierVisible)
                    }
                    className={`${theme.hintColor} subClass addIdentifierButton`}
                    icon={
                      isAddIdentifierVisible ? (
                        <MinusCircleOutlined />
                      ) : (
                        <PlusCircleOutlined />
                      )
                    }
                  >
                    {t(translation.lblAddIdentifier)}
                  </Button>
                )}
              </div>
            ) : (
              <></>
            )}
            {isAutoDiscovery ||
              otherAppCustomization.isIdentifierSearchSupported === "false" ? (
              <></>
            ) : (
              <div className="searchInputRoot">
                <Input
                  value={searchValue}
                  className={`${theme.authFormBorder} ${theme.hintColor} ${theme.backgroundColor} searchInput filterFip`}
                  prefix={<SearchOutlined className="font16" />}
                  placeholder={
                    step === 0
                      ? t(translation.lblHomeSearch)
                      : step === 1
                        ? t(translation.lblSearchByMobile)
                        : t(translation.lblSearchByAccount)
                  }
                  onChange={onSearchValueChange}
                />
                {step === 2 ? (
                  <Checkbox
                    disabled={!isSearch}
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                    className={`${theme.textTheme.headline5} ${theme.textTheme.headline6} ${theme.checkBoxBorder} checboxSelectAll`}
                  >
                    {t(translation.lblSelectAll)}
                  </Checkbox>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
          {renderStep()}
        </div>
        <div className="buttonGroupRoot linkAccountButtonGroup">
          <div className={`${theme.menuDividerColor}`}>
            <div>
              <Button
                onClick={onCanceled}
                type="text"
                className={`${theme.hintColor} subClass subButton`}
              >
                {t(translation.lblCancel)}
              </Button>
            </div>
            {step !== 0 ? (
              <div className="buttonGroupLinkAccount">
                {isAutoDiscovery ? (
                  <div className="noAccountsFound">
                    <Button
                      onClick={goToLinkAccountAutoDiscovery}
                      type="text"
                      className={`${theme.hintColor}  linkaccountBack subButton`}
                    >
                      {t(translation.lblAccountNotListed)}
                    </Button>
                  </div>
                ) : (
                  <Button
                    disabled={
                      fipId && fipId.length > 0 && step === 1
                        ? fipId.split(",").length === 1
                        : false
                    }
                    onClick={goBack}
                    type="text"
                    className={`${theme.hintColor}  linkaccountBack subButton`}
                  >
                    {t(translation.lblBack)}
                  </Button>
                )}
                <Button
                  disabled={
                    step === 1 && identifiers.length === 0
                      ? true
                      : step === 2 && selectedAccounts.length === 0
                        ? true
                        : false
                  }
                  className={`${theme.textTheme.authButton} ${theme.formItemMarginBottom} borderNoneHeight50 priButton`}
                  onClick={
                    step === 1 ? discoverAccountWithIdentifiers : linkAccount
                  }
                >
                  {step === 1
                    ? t(translation.lblContinue)
                    : selectedAccounts.length > 1
                      ? t(translation.lblLinkAccounts)
                      : t(translation.lblLinkAccount)}
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkAccounts;
