import { RightOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  notification,
  Radio,
  RadioChangeEvent,
  Select,
  Tag,
  Typography,
} from "antd";
import { useTheme } from "App";
import aboutUsLight from "Assets/aboutUs.png";
import aboutUsDark from "Assets/aboutUsDark.png";
import { translation } from "i18n/translation";
import ConsentStatusApprovalComponent from "Pages/Consent/Components/StatusApproval";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { theme, userInformation } from "redux/actions";
import { UserRespose } from "redux/Reducer/userReducer";
import store from "redux/store";

import privacyLight from "Assets/privacy.png";
import privacyDark from "Assets/privacyDark.png";
import termsLight from "Assets/terms.png";
import termsDark from "Assets/termsDark.png";

import { LockIcon } from "Assets/icons";
import SettingsIdentifier from "Pages/SettingsIdentifiers";
import {
  deleteUserInitiate,
  deletUserEncrypted,
  sendOtp,
  verifyEncryptedOtp
} from "Services/AuthenticationServices/AuthenticationServices";
import { setThemeStorage } from "Services/StorageServices";
import { updateUser } from "Services/UserProfileServices";
import { authType } from "Utils/Constants/authType";
import { Routes } from "Utils/Constants/routes";
import { DeleteType } from "Utils/Constants/statusTypes";
import { getTheme } from "Utils/ThemeUtils";
import Profile from "../Profile";
import DeleteAccount from "./DeleteAccount";
import "./index.css";
import OTPModal from "Pages/Common/Components/OTPModal";
import { EncryptOTP } from "Utils/EncryptionUtils";

const Settings: React.FC<{
  user: UserRespose;
  reduxTheme: any;
  setTheme: (theme: any) => void;
  setUser: (user: any) => void;
}> = ({ setTheme, user, setUser, reduxTheme }) => {
  const { t, i18n } = useTranslation();
  const [changedValues, setChangedValues] = useState({
    firstName: "",
    lastName: "",
    default: { firstName: "", lastName: "" },
  });
  const [aboutSaafe, setAboutSaafe] = useState(false);

  const versionB: boolean = useSelector((state: any) => state.version.version);
  const [reasons, setReasons] = useState<any>({});

  const theme = useTheme();
  // const [is2{faEnabled, setIs2faEnabled] = useState(false);
  const { push, replace } = useHistory();
  const [language, setLanguage] = useState(i18n.language);
  const [settingsTheme, setSettingsTheme] = useState(reduxTheme);

  const [isOTPModalVisible, setIsOTPModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPinModalVisible, setIsPinModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [otpUniqueID, setOTPUniqueId] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [radioSelect, setRadioSelect] = useState(0);

  const updateValues = (type: string, value: any) => {
    setChangedValues((old) => ({ ...old, [type]: value }));
  };

  const updateUserData = () => {
    if (changedValues.firstName !== "" && changedValues.lastName !== "") {
      updateUser(changedValues.firstName, changedValues.lastName);
      setUser({
        firstName: changedValues.firstName,
        lastName: changedValues.lastName,
        mobileNumber: user.mobileNumber,
        vuaId: user.vuaId,
      });
    }
  };

  const [isOtpError, setIsOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const memoizedCallback = useCallback(updateValues, []);
  const handleOnBlur = useCallback(updateUserData, [
    changedValues,
    user,
    setUser,
  ]);
  // const faOptions = [
  //   { label: t("Off"), value: false },
  //   { label: t("On"), value: true },
  // ];
  const themeOptions = [
    { label: "System", value: "system" },

    { label: "Light", value: "light" },
    { label: "Dark", value: "dark" },
  ];
  const languageOptions = [
    { label: "English", value: "en" },
    { label: "हिन्दी (Hindi)", value: "hi" },
    { label: "తెలుగు (Telugu)", value: "te" },
    { label: "தமிழ் (Tamil)", value: "ta" },
    { label: "മലയാളം (Malayalam)", value: "mal" },
    { label: "ಕನ್ನಡ (Kannada)", value: "kn" },
    { label: "اردو (Urdu)", value: "ur" },
  ];

  const themeLocal = useTheme();

  const closeModal = () => {
    setIsOTPModalVisible(false);
    setIsOtpError(false);
    setIsModalVisible(false);
  };

  // useEffect(() => {
  //   isTwoFactorEnabled().then((res) => setIs2faEnabled(res.data));
  // }, [setIs2faEnabled]);

  const onThemeChange = ({ target: { value } }: RadioChangeEvent) => {
    setSettingsTheme(value);
    setThemeStorage(value);
    setTheme(getTheme());
  };

  const onLanguageChange = (value: string) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  // const onFAChange = ({ target: { value } }: RadioChangeEvent) => {
  //   setIs2faEnabled(value);
  //   isTwoFactorEnabled().then((res) => {
  //     if (res.data !== value) changeTwoFactorEnabled();
  //   });
  // };

  const otherSectionItems = [
    {
      value: t(translation.lblTermsAndConditions),
      onclick: () => {
        push({ pathname: Routes.Terms, state: { web: true } });
      },
      prefix: (
        <img
          alt=""
          onClick={() => {
            push({ pathname: Routes.Terms, state: { web: true } });
          }}
          src={reduxTheme === "light" ? termsLight : termsDark}
          className="otherPrefixIcon"
        />
      ),
    },
    {
      value: t(translation.lblAboutUs),
      onclick: () => {
        setAboutSaafe(true);
      },
      prefix: (
        <img
          alt=""
          onClick={() => {
            setAboutSaafe(true);
          }}
          className="otherPrefixSaafeIcon"
          src={reduxTheme === "light" ? aboutUsLight : aboutUsDark}
        />
      ),
    },
    {
      value: t(translation.lblPrivacyPolicy),
      onclick: () => {
        push({ pathname: Routes.Privacy, state: { web: true } });
      },
      prefix: (
        <img
          alt=""
          onClick={() => {
            push({ pathname: Routes.Privacy, state: { web: true } });
          }}
          src={reduxTheme === "light" ? privacyLight : privacyDark}
          className="otherPrefixIcon"
        />
      ),
    },
  ];

  const handleChangePassword = () => {
    sendOtp({
      phoneNumber: user.mobileNumber,
      isTermsAndConditionAgreed: true,
    }).then((res) => {
      setOTPUniqueId(res.data);
      setIsOTPModalVisible(true);
    });
  };
  const handleOtpSubmit = (value: any) => {
    const encryptedOtp = EncryptOTP(value, otpUniqueID);
    verifyEncryptedOtp({
      code: encryptedOtp,
      phone_number: user.mobileNumber,
      grant_type: "password",
      otpUniqueID,
    })
      .then(() => {
        setIsOTPModalVisible(false);
        setIsOtpError(false);
        push({
          pathname: `${Routes.Settings}/change-password`,
          state: { token: true },
        });
      })
      .catch((error) => {
        setOtpErrorMessage(error.response?.data.errorCode);
        setIsOtpError(true);
      });
  };

  const handleDeleteUser = () => {
    // push(`${Routes.Settings}/delete-account`);
    setIsModalVisible(true);
  };

  const closeAboutSaafe = () => {
    setAboutSaafe(false);
  };

  const onAuthtypeSubmit = () => {
    setIsModalVisible(false);

    setIsVisible(false);
    setIsFormVisible(true);
  };
  const onClose = () => {
    setIsFormVisible(false);
    setIsOtpError(false);

    if (radioSelect === 0) setIsPinModalVisible(false);
    else setIsOTPModalVisible(false);

    setIsVisible(true);
  };

  const radioSelectEvent = (e: RadioChangeEvent) => {
    setRadioSelect(e.target.value);
  };

  const verifyOTPAuth = (otp: string) => {
    let encryptedOTP = otp;
    if (radioSelect !== 0) {
      encryptedOTP = EncryptOTP(otp, otpUniqueID)
    }
    deletUserEncrypted({
      otpUniqueID: radioSelect === 0 ? "" : otpUniqueID,
      authType: radioSelect === 0 ? authType.PIN : authType.OTP,
      authValue: encryptedOTP,
      reason: reasons["reason"],
      reasonType: reasons["reasonType"],
    })
      .then(() => {
        setIsOtpError(false);

        replace(Routes.Login);

        notification.open({
          className: `${theme.infoAlert} alertClass`,
          icon: <img alt="" src={theme.infoIcon} className="alertIcon" />,
          placement: "top",
          message: t(translation.lblDeleteAccountSuccess),
          duration: 5,
        });
      })
      .catch((error) => {
        setOtpErrorMessage(error.response?.data.errorCode);
        setIsOtpError(true);
      });
  };

  const handleFormSubmit = (values: any) => {
    setReasons(values);
    if (radioSelect === 0) {
      setIsPinModalVisible(true);
    } else {
      deleteUserInitiate().then((res) => {
        setOTPUniqueId(res.data);
        setIsOTPModalVisible(true);
      });
    }
  };

  return (
    <>
      {isFormVisible && (
        <DeleteAccount
          onFormSubmit={handleFormSubmit}
          onClose={() => {
            setIsFormVisible(false);
            setIsVisible(true);
          }}
        />
      )}
      <ConsentStatusApprovalComponent
        typeOfModal={DeleteType.DELETEUSER}
        isOTPModalVisible={isOTPModalVisible}
        isAuthTypeSelectionModalOpen={isModalVisible}
        onCloseAuthTypeSelectionModal={closeModal}
        onAuthTypeModalSubmit={onAuthtypeSubmit}
        onAuthTypeRadioSelectionChange={radioSelectEvent}
        selectedAuthType={radioSelect}
        verifyOTPAuthType={verifyOTPAuth}
        pinModalVisible={isPinModalVisible}
        onCloseOTPModal={onClose}
        isError={isOtpError}
        setIsError={setIsOtpError}
        errorMessage={otpErrorMessage}
        onResend={handleFormSubmit}
      />
      {isVisible ? (
        <div className={`${themeLocal.mainbackgroundColor} settingsRoot`}>
          <Modal
            width={"40%"}
            className={`${themeLocal.modalClass} aboutSaafe`}
            footer={null}
            open={aboutSaafe}
            onCancel={closeAboutSaafe}
            destroyOnClose={true}
          >
            <Typography.Paragraph
              style={{ textAlign: "justify" }}
              className={`${themeLocal.menubarSelectColor} weight500`}
            >
              {t(translation.lblAboutSaafe)}
            </Typography.Paragraph>

            {versionB && (
              <Tag className="versionTag" color="default">
                Version: {versionB}
              </Tag>
            )}
          </Modal>

          {isOTPModalVisible ? (
            <OTPModal
              onCancel={closeModal}
              isOpen={isOTPModalVisible}
              onOtpSubmit={handleOtpSubmit}
              onResend={handleChangePassword}
              okText={t(translation.lblConfirm)}
              title={t(translation.lblChangePassCode)}
              mobileNumber={user.mobileNumber}
              isError={isOtpError}
              setIsError={setIsOtpError}
              errorMessage={otpErrorMessage}
            />
          ) : (
            <></>
          )}

          <Typography.Title
            level={2}
            className={`${themeLocal.textTheme.headline1} titleClass settingsTitle`}
          >
            {t(translation.lblSettings)}
          </Typography.Title>

          <div className="settingsContainerRoot">
            <div className="settingsContainer">
              <div className="settingsType">
                <Typography.Text
                  className={`${themeLocal.textTheme.headline1} titleClass settingTitle`}
                >
                  {t(translation.lblPersonalInformation)}
                </Typography.Text>
                <Typography.Text
                  className={`${themeLocal.textTheme.subtitle1} settingSubtitle`}
                >
                  {t(translation.lblEditProfileInfo)}
                </Typography.Text>
              </div>
              <div className="settingsValues">
                <Profile
                  updateValues={memoizedCallback}
                  onblur={handleOnBlur}
                  changedValues={changedValues}
                  user={user}
                />
              </div>
            </div>

            <div className="settingsContainer">
              <div className="settingsType">
                <Typography.Text
                  className={`${themeLocal.textTheme.headline1} titleClass settingTitle`}
                >
                  {t(translation.lblMobileNumbers)}
                </Typography.Text>
                <Typography.Text
                  className={`${themeLocal.textTheme.subtitle1} settingSubtitle`}
                >
                  {t(translation.lblEditOrAddMobileNumbers)}
                </Typography.Text>
              </div>
              <div className="settingsValues">
                <SettingsIdentifier />
              </div>
            </div>

            <div className="settingsContainer">
              <div className="settingsType">
                <Typography.Text
                  className={`${themeLocal.textTheme.headline1} titleClass settingTitle`}
                >
                  {t(translation.lblAppearance)}
                </Typography.Text>
                <Typography.Text
                  className={`${themeLocal.textTheme.subtitle1} settingSubtitle`}
                >
                  {t(translation.lblAppearanceDescription)}
                </Typography.Text>
              </div>

              <div className="settingsValues">
                <div className="inputContainer">
                  <div className="languageSelection">
                    <Typography.Text
                      className={`${themeLocal.textTheme.subtitle1} settingsSubtitle`}
                    >
                      {t(translation.lblLanguage)}
                    </Typography.Text>
                    <Select
                      defaultValue={language}
                      onChange={onLanguageChange}
                      placeholder={t(translation.lblSelectLanguage)}
                      popupClassName={`${themeLocal.selectorColor}`}
                      className={`${themeLocal.selectorColor} ${themeLocal.textTheme.headline1} titleClass languageSelector`}
                    >
                      {languageOptions.map((lang) => (
                        <Select.Option
                          key={lang.value}
                          value={lang.value}
                          className={`${themeLocal.textTheme.headline1} ${themeLocal.selectorColor} issueSelector`}
                        >
                          {lang.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="inputContainer">
                  <Typography.Text
                    className={`${themeLocal.textTheme.subtitle1} settingsSubtitle`}
                  >
                    {t(translation.lblTheme)}
                  </Typography.Text>
                  <Radio.Group
                    className={`${themeLocal.radioButtonColor} settingsRadio themeRadio`}
                    options={themeOptions}
                    onChange={onThemeChange}
                    value={settingsTheme}
                    optionType="button"
                  />
                </div>
              </div>
            </div>
            <div className="settingsContainer">
              <div className="settingsType">
                <Typography.Text
                  className={`${themeLocal.textTheme.headline1} titleClass settingTitle`}
                >
                  {t(translation.lblSecurity)}
                </Typography.Text>
                <Typography.Text
                  className={`${themeLocal.textTheme.subtitle1} settingSubtitle`}
                >
                  {t(translation.lblSecurityDescription)}
                </Typography.Text>
              </div>
              <div className="settingsValues">
                <div onClick={handleChangePassword} className="inputContainer">
                  <Input
                    className={`${themeLocal.textTheme.headline2} ${themeLocal.authFormBorder} securityButtons`}
                    prefix={
                      <LockIcon
                        className={`${themeLocal.textTheme.headline5} icon lockIcon securityIcons`}
                      />
                    }
                    value={t(translation.lblChangePassCode)}
                    readOnly={true}
                    suffix={
                      <RightOutlined
                        className={themeLocal.textTheme.headline2}
                      />
                    }
                  />
                </div>
                <div onClick={handleDeleteUser} className="inputContainer">
                  <Input
                    className={`${themeLocal.textTheme.headline2} ${themeLocal.authFormBorder} securityButtons`}
                    value={t(translation.lblDeleteAccount)}
                    readOnly={true}
                    suffix={
                      <Button
                        className={`${themeLocal.cardColor} ${themeLocal.textTheme.headline2} securitySubmitButtons`}
                      >
                        <RightOutlined
                          className={themeLocal.textTheme.headline2}
                        />
                      </Button>
                    }
                  />
                </div>
              </div>
            </div>

            <div className="settingsContainer">
              <div className="settingsType">
                <Typography.Text
                  className={`${themeLocal.textTheme.headline1} titleClass settingTitle`}
                >
                  {t(translation.lblOTHER)}
                </Typography.Text>
              </div>
              <div className="settingsValues">
                {otherSectionItems.map((item, index) => (
                  <div
                    key={index}
                    onClick={item.onclick}
                    className="inputContainer"
                  >
                    <Input
                      className={`${themeLocal.textTheme.headline2} ${themeLocal.authFormBorder} securityButtons`}
                      value={item.value}
                      readOnly={true}
                      prefix={item.prefix}
                      suffix={
                        <Button
                          className={`${themeLocal.cardColor} ${themeLocal.textTheme.headline2} securitySubmitButtons`}
                        >
                          <RightOutlined
                            className={themeLocal.textTheme.headline2}
                          />
                        </Button>
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const mapDispatchToProps = () => {
  return {
    setTheme: (themeL: string) => store.dispatch(theme(themeL)),
    setUser: (changedUser: UserRespose) =>
      store.dispatch(userInformation(changedUser)),
  };
};

const mapStateToProps = (state: any) => ({
  user: state.user.user,
  reduxTheme: state.themes.theme,
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
