import { Card, notification, RadioChangeEvent } from "antd";
import { useTheme } from "App";
import { translation } from "i18n/translation";
import { SelectedAccounts } from "Models/Request/Account/AccountLink";
import { GetConsentByIdResponse } from "Models/Response/Consent/GetConsentById";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  consentApprovalInitiation,
  consentApprove,
  getConsentByConsentHandle,
  getConsentByConsentId,
  updateConsentStatus,
  updateConsentStatusVerification,
} from "Services/ConsentServices/ConsentService";
import { authType } from "Utils/Constants/authType";
import { Routes } from "Utils/Constants/routes";
import {
  ConsentApprovalStatusType,
  ConsentStatusType,
} from "Utils/Constants/statusTypes";

import FeedbackForm from "Pages/Consent/Components/ConsentFeedback";
import ConsentByStatus from "Pages/Consent/Components/CosentsByType";
import PendingConsent from "Pages/Consent/Components/CosentsByType/PendingConsent";
import ConsentStatusApproval from "Pages/Consent/Components/StatusApproval";
import "./index.css";

interface IConsentDetailsProps {
  consentUniqueId: string;
  type: string;
}
const isConsentVerificationEnabled =
  process.env.REACT_APP_IS_CONSENT_VERIFICATION_ENABLED;

const ConsentDetails: React.FC<IConsentDetailsProps> = ({
  type,
  consentUniqueId,
}) => {
  const [selectedCard, setSelectedCard] = useState(
    new Array<SelectedAccounts>()
  );
  const [isVerificationModal, setIsVerificationModal] = useState(false);
  const [radioSelect, setRadioSelect] = useState(0);
  const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
  const [isPinModalVisible, setPinModalVisible] = useState(false);
  const [statusType, setStatusType] = useState("");
  const [otpUniqueID, setOTPUniqueId] = useState("");
  const [visible, setVisible] = useState(true);
  const [isRevokeConsentVisible, setisRevokeConsentVisible] = useState(false);
  const [updateStatusFormValues, setUpdateStatusFormValues] = useState({
    message: "",
  });

  const [isOtpError, setIsOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");

  const [approvalStatus, setApprovalStatus] = useState("");
  const { t } = useTranslation();
  const [consentDetailsById, setConsentDetailsById] = useState({
    Customer: { id: "" },
    DataConsumer: { name: "", id: "" },
    DataFilter: [{ type: "", operator: "", value: "" }],
    DataLife: { unit: "", value: 0 },
    FIDataRange: { from: "", to: "" },
    Frequency: { unit: "", value: 0 },
    Purpose: { category: { type: "" }, code: "", refUri: "", text: "" },
    consentExpiry: "",
    consentMode: "",
    consentStart: "",
    consentTypes: [""],
    fetchType: "",
    fiTypes: [""],
    DataProvider: { id: "", type: "" },
    Accounts: [
      {
        fiType: "",
        fipId: "",
        fipName: "",
        accType: "",
        linkRefNumber: "",
        maskedAccNumber: "",
      },
    ],
  });

  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    if (!isVerificationModal && approvalStatus !== "") {
      setApprovalStatus("");
    }
  }, [isVerificationModal, approvalStatus]);

  const renderAlerts = (
    consentApprovalType?: any,
    reasonForRejection?: string
  ) => {
    if (type === ConsentApprovalStatusType.PENDING) {
      if (approvalStatus === "FAILED" || consentApprovalType === "FAILED") {
        notification.open({
          duration: 5,
          icon: <img alt="" src={theme.infoIcon} className="alertIcon" />,
          className: `${theme.infoAlert} alertClass`,
          placement: "top",
          message: reasonForRejection
            ? reasonForRejection
            : t(translation.rejectConsentMessage, {
                fiu: consentDetailsById.DataConsumer.id,
              }),
          description: t(translation.rejectConsentDescription, {
            fiu: consentDetailsById.DataConsumer.id,
          }),
        });
      } else {
        notification.open({
          duration: 5,
          icon: <img alt="" src={theme.successIcon} className="alertIcon" />,
          className: `${theme.successAlert} alertClass`,
          placement: "top",
          message: t(translation.acceptSuccesfull, {
            fiu: consentDetailsById.DataConsumer.id,
          }),
          description: t(translation.acceptSuccesfullDescription, {
            accounts: selectedCard.map(
              (account: SelectedAccounts) => " " + account.maskedNumber
            ),
          }),
        });
      }
    } else {
      switch (consentApprovalType ? consentApprovalType : statusType) {
        case "PAUSED":
          notification.open({
            duration: 5,
            icon: <img alt="" src={theme.warningIcon} className="alertIcon" />,
            className: `${theme.warningAlert} alertClass`,
            placement: "top",
            message: t(translation.pauseSuccesfull, {
              fiu: consentDetailsById.DataConsumer.id,
            }),
            description: t(translation.pauseDescription, {
              fiu: consentDetailsById.DataConsumer.id,
            }),
          });

          break;
        case "REVOKED":
          notification.open({
            duration: 5,
            icon: <img alt="" src={theme.infoIcon} className="alertIcon" />,
            className: `${theme.infoAlert} alertClass`,
            placement: "top",
            message: t(translation.revokeSuccesfull, {
              fiu: consentDetailsById.DataConsumer.id,
            }),
            description: t(translation.revokeDescription, {
              fiu: consentDetailsById.DataConsumer.id,
            }),
          });

          break;
        case "ACTIVE":
          notification.open({
            duration: 5,
            icon: <img alt="" src={theme.successIcon} className="alertIcon" />,
            className: `${theme.successAlert} alertClass`,
            placement: "top",
            message: t(translation.reactivateSuccesfull, {
              fiu: consentDetailsById.DataConsumer.id,
            }),
            description: t(translation.reactivateDescription, {
              fiu: consentDetailsById.DataConsumer.id,
            }),
          });

          break;
      }
    }
  };

  const approveConsentWithoutOTP = () => {
    consentApprove({
      consentHandle: consentUniqueId,
      constentApprovalStatus: approvalStatus,
      ...(selectedCard && {
        accounts: selectedCard,
      }),
    })
      .then((res) => res.data)
      .then((res) => {
        closeModal();
        renderAlerts(res.ConsentStatus, res.reasonForRejection);

        history.replace(
          `${Routes.Consent}/${
            res.ConsentStatus === "FAILED"
              ? "REJECTED"
              : ConsentStatusType.ACTIVE
          }/${
            res.consentId
              ? res.ConsentStatus !== "FAILED"
                ? res.consentId
                : consentUniqueId
              : consentUniqueId
          }`
        );
      });
  };

  const rejectConsent = (consentApprovalType: string) => {
    setApprovalStatus(consentApprovalType);
    setIsVerificationModal(true);
  };

  const approveConsent = (
    consentApprovalType: string,
    selectedAccounts: SelectedAccounts[]
  ) => {
    setApprovalStatus(consentApprovalType);
    setSelectedCard(selectedAccounts);
    setIsVerificationModal(true);
  };

  const radioSelectEvent = (e: RadioChangeEvent) => {
    setRadioSelect(e.target.value);
  };

  const closeModal = () => {
    setIsVerificationModal(false);
  };

  const onSelectionModal = () => {
    if (isConsentVerificationEnabled === "false") {
      if (type === ConsentApprovalStatusType.PENDING) {
        approveConsentWithoutOTP();
      } else {
        updateConsentByStatusWithoutOtp();
      }
    } else {
      if (
        approvalStatus === "FAILED" ||
        statusType === ConsentStatusType.REVOKED
      ) {
        setVisible(false);
        setisRevokeConsentVisible(true);
        setIsVerificationModal(false);
      } else {
        if (radioSelect === 0) {
          setPinModalVisible(true);
          setIsVerificationModal(false);
        } else {
          if (type === ConsentApprovalStatusType.PENDING) {
            consentApprovalInitiation(consentUniqueId, approvalStatus)
              .then((res) => res.data)
              .then((res) => {
                setIsOtpModalVisible(true);
                setIsVerificationModal(false);
                setOTPUniqueId(res.otpUniqueID);
              });
          } else {
            updateConsentStatus(consentUniqueId, statusType)
              .then((res) => res.data)
              .then((res) => {
                setIsOtpModalVisible(true);
                setIsVerificationModal(false);
                setOTPUniqueId(res.otpUniqueID);
              });
          }
        }
      }
    }
  };

  const handleUpdateConsent = (values: any) => {
    values && setUpdateStatusFormValues(values);
    if (type === ConsentApprovalStatusType.PENDING) {
      if (radioSelect === 0) {
        setPinModalVisible(true);
      } else {
        consentApprovalInitiation(consentUniqueId, approvalStatus)
          .then((res) => res.data)
          .then((res) => {
            setIsOtpModalVisible(true);
            setIsVerificationModal(false);
            setOTPUniqueId(res.otpUniqueID);
          });
      }
    } else {
      if (radioSelect === 0) {
        setPinModalVisible(true);
      } else {
        updateConsentStatus(consentUniqueId, statusType)
          .then((res) => res.data)
          .then((res) => {
            setIsOtpModalVisible(true);
            setIsVerificationModal(false);
            setOTPUniqueId(res.otpUniqueID);
          });
      }
    }
  };
  const updateConsentByStatusWithoutOtp = () => {
    updateConsentStatusVerification({
      consentId: consentUniqueId,
      consentStatus: statusType,
    }).then(() => {
      closeModal();
      renderAlerts();

      history.replace(`${Routes.Consent}/${statusType}/${consentUniqueId}`);
    });
  };

  const updateConsentByStatus = (updateStatusType: string) => {
    setStatusType(updateStatusType);
    setIsVerificationModal(true);
  };

  const verifyOTPAuth = (otp: string) => {
    if (type === ConsentApprovalStatusType.PENDING) {
      if (radioSelect === 0) {
        consentApprove({
          consentHandle: consentUniqueId,
          constentApprovalStatus: approvalStatus,
          authType: authType.PIN,
          authValue: otp,
          accounts: selectedCard,
          otpUniqueID,
          ...(approvalStatus === "FAILED" && {
            reason: updateStatusFormValues.message,
          }),
        })
          .then((res) => {
            setPinModalVisible(false);
            setVisible(true);
            setIsOtpError(false);
            setisRevokeConsentVisible(false);

            renderAlerts();

            history.replace(
              `${Routes.Consent}/${
                approvalStatus === "FAILED"
                  ? "REJECTED"
                  : ConsentStatusType.ACTIVE
              }/${res.data.consentId ? res.data.consentId : consentUniqueId}`
            );
          })
          .catch((error) => {
            setOtpErrorMessage(error.response?.data.errorCode);
            setIsOtpError(true);
          });
      } else {
        consentApprove({
          consentHandle: consentUniqueId,
          constentApprovalStatus: approvalStatus,
          authType: authType.OTP,
          authValue: otp,
          accounts: selectedCard,
          otpUniqueID,
          ...(approvalStatus === "FAILED" && {
            reason: updateStatusFormValues.message,
          }),
        })
          .then((res) => {
            setIsOtpModalVisible(false);
            setVisible(true);
            setisRevokeConsentVisible(false);
            setIsOtpError(false);

            renderAlerts();

            history.replace(
              `${Routes.Consent}/${
                approvalStatus === "FAILED"
                  ? "REJECTED"
                  : ConsentStatusType.ACTIVE
              }/${res.data.consentId ? res.data.consentId : consentUniqueId}`
            );
          })
          .catch((error) => {
            setOtpErrorMessage(error.response?.data.errorCode);
            setIsOtpError(true);
          });
      }
    } else {
      if (radioSelect === 0) {
        updateConsentStatusVerification({
          consentId: consentUniqueId,
          consentStatus: statusType,
          authType: authType.PIN,
          ...(statusType === ConsentStatusType.REVOKED && {
            reason: updateStatusFormValues.message,
          }),
          authValue: otp,
        })
          .then(() => {
            setVisible(true);
            setisRevokeConsentVisible(false);
            setPinModalVisible(false);
            renderAlerts();
            setIsOtpError(false);

            history.replace(
              `${Routes.Consent}/${statusType}/${consentUniqueId}`
            );
          })
          .catch((error) => {
            setOtpErrorMessage(error.response?.data.errorCode);
            setIsOtpError(true);
          });
      } else {
        updateConsentStatusVerification({
          consentId: consentUniqueId,
          consentStatus: statusType,
          authType: authType.OTP,
          authValue: otp,
          ...(statusType === ConsentStatusType.REVOKED && {
            reason: updateStatusFormValues.message,
          }),
          otpUniqueID,
        })
          .then(() => {
            setIsOtpModalVisible(false);
            setIsOtpError(false);

            setVisible(true);
            setisRevokeConsentVisible(false);
            renderAlerts();

            history.replace(
              `${Routes.Consent}/${statusType}/${consentUniqueId}`
            );
          })
          .catch((error) => {
            setOtpErrorMessage(error.response?.data.errorCode);
            setIsOtpError(true);
          });
      }
    }
  };

  const onResendOtp = () => {
    if (approvalStatus === "FAILED" || statusType === ConsentStatusType.REVOKED)
      handleUpdateConsent("");
    else onSelectionModal();
  };

  useEffect(() => {
    if (
      type === ConsentStatusType.REJECTED ||
      type === ConsentStatusType.EXPIRED ||
      type === ConsentApprovalStatusType.PENDING
    ) {
      getConsentByConsentHandle(consentUniqueId)
        .then((res) => res.data)
        .then((res: GetConsentByIdResponse) => setConsentDetailsById(res))
        .catch(() => {
          history.replace(Routes.Consent);
        });
    } else {
      getConsentByConsentId(consentUniqueId)
        .then((res) => res.data)
        .then((res: GetConsentByIdResponse) => setConsentDetailsById(res));
    }
  }, [consentUniqueId, history, type]);

  const onClose = () => {
    setIsOtpModalVisible(false);
    setPinModalVisible(false);
    setIsOtpError(false);
  };

  return (
    <>
      {isRevokeConsentVisible && (
        <FeedbackForm
          bankName={consentDetailsById.DataConsumer.id}
          setIsFormVisible={setisRevokeConsentVisible}
          setVisible={setVisible}
          onFormSubmit={handleUpdateConsent}
          type={approvalStatus.length !== 0 ? approvalStatus : statusType}
        />
      )}

      <ConsentStatusApproval
        typeOfModal={approvalStatus.length !== 0 ? approvalStatus : statusType}
        isAuthTypeSelectionModalOpen={isVerificationModal}
        onCloseAuthTypeSelectionModal={closeModal}
        onAuthTypeModalSubmit={onSelectionModal}
        onAuthTypeRadioSelectionChange={radioSelectEvent}
        selectedAuthType={radioSelect}
        verifyOTPAuthType={verifyOTPAuth}
        isOTPModalVisible={isOtpModalVisible}
        pinModalVisible={isPinModalVisible}
        onCloseOTPModal={onClose}
        fiu={consentDetailsById.DataConsumer.id}
        isError={isOtpError}
        setIsError={setIsOtpError}
        errorMessage={otpErrorMessage}
        onResend={onResendOtp}
      />
      {visible && (
        <Card
          loading={false}
          className={`${theme.bodyBackgroundColor} consentDetails ${
            type === ConsentApprovalStatusType.PENDING ||
            type === ConsentStatusType.PAUSED
              ? "justifyContent"
              : ""
          }`}
          bordered={false}
        >
          {type === ConsentApprovalStatusType.PENDING ? (
            <PendingConsent
              consentDetailsByHandle={consentDetailsById}
              type={type}
              rejectConsent={rejectConsent}
              approvalConsent={approveConsent}
              consentUniqueId={consentUniqueId}
            />
          ) : (
            <ConsentByStatus
              consentDetailsById={consentDetailsById}
              type={type}
              onConsentStatusUpdate={updateConsentByStatus}
            />
          )}
        </Card>
      )}
    </>
  );
};

export default ConsentDetails;
