import React from "react";
import { Card, Typography } from "antd";

import { useLocation } from "react-router-dom";
import { useTheme } from "../../../../App";
import "./index.css";
import TicketStatus from "../TicketStatus";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../../Common/Components/BreadCrumbs";
import { translation } from "../../../../i18n/translation";

const TicketDetail: React.FC = () => {
  const theme = useTheme();
  const location: any = useLocation();
  const ticket = location.state.gr;
  const { t } = useTranslation();

  return (
    <div className={`${theme.mainbackgroundColor} ticketDetailsRoot`}>
      <BreadCrumbs
        breadcrumbItems={[
          { route: "/user-grievance", label: t(translation.lblHelpCenter) },
          { route: location.pathname, label: t(ticket.issueType) },
        ]}
      />
      <div className="ticketDetailsHeader">
        <Typography.Title
          level={3}
          className={`${theme.textTheme.headline1} titleClass`}
        >
          {t(ticket.issueType)}
        </Typography.Title>
        <Typography.Title className="ticketStatus" level={5}>
          <TicketStatus status={ticket.status} />
        </Typography.Title>
      </div>
      <Card className={`${theme.cardColor} ticketDetailsCard`}>
        <Typography.Text
          className={`${theme.textTheme.headline1} ticketDetailsText`}
        >
          {t(ticket.description)}
        </Typography.Text>
      </Card>
      {ticket.feedback && (
        <>
          <Typography.Text className={theme.textTheme.headline1}>
            {t(translation.lblFeedback)}
          </Typography.Text>
          <Card className={`${theme.cardColor} ticketDetailsCard`}>
            <Typography.Text
              className={`${theme.textTheme.headline1} ticketDetailsText`}
            >
              {t(ticket.feedback)}
            </Typography.Text>
          </Card>
        </>
      )}
    </div>
  );
};

export default TicketDetail;
