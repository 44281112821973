import React, { useEffect } from "react";
import { android_url, ios_url, webapp_url } from "Utils/Constants/mobileAppUrl";

const Download: React.FC<{}> = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
     if (/android/i.test(userAgent)) {
      window.location.href = String(android_url);
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      window.location.href = String(ios_url);
    } else {
      window.location.href = String(webapp_url);
    }
  }, []);

  return null;
};

export default Download;
