import { Typography } from "antd";
import moment from "moment";
import { translation } from "../../i18n/translation";
import { ConsentStatusType } from "../../Utils/Constants/statusTypes";

export const activityTitle = (
  type: string,
  jsonString: string,
  theme: any,
  t: any,
  user: string,
  isRead = false
) => {
  const json = JSON.parse(jsonString);

  switch (type) {
    case "AA_UPDATE_CONSENT_APPROVAL_STATUS":
    case "AA_UPDATE_CONSENT_APPROVAL_STATUS_ACCOUNT":
      return (
        <Typography.Text
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {t(translation.lblConsentUpdate, {
            consent_status:
              json.constentApprovalStatus === "READY"
                ? t(translation.lblApproved)
                : t(translation.lblRejectedConsent),
          })}
        </Typography.Text>
      );
    case "AA_UPDATE_CONSENT_STATUS":
    case "AA_UPDATE_CONSENT_STATUS_ACCOUNT":
      return (
        <Typography
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {json.consentStatusType === ConsentStatusType.ACTIVE ? (
            t(translation.lblConsentApprove)
          ) : (
            <Typography.Text
              className={`${
                isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
              } titleClass historyCardTitle`}
            >
              {t(translation.lblConsentUpdate, {
                consent_status: t(getConsentStatusType(json.consentStatusType)),
              })}{" "}
            </Typography.Text>
          )}
        </Typography>
      );

    case "AA_STORE_CREATE_CONSENT_REQUEST":
      return (
        <Typography.Text
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {t(translation.lblConsentCreated, {
            fiu_name: json.fiu?.name ? json.fiu?.name : user,
          })}
        </Typography.Text>
      );
    case "AA_STORE_ACCOUNT_LINK_UPDATE":
      return (
        <>
          <Typography
            className={`${
              isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
            } titleClass historyCardTitle`}
          >
            <Typography.Text
              className={`${
                isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
              } titleClass historyCardTitle`}
            >
              {t(translation.lblAuditAccountLinked)}{" "}
            </Typography.Text>
          </Typography>
        </>
      );
    case "AA_FIP_ACCOUNT_DISCOVERY":
      return (
        <Typography
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {t(translation.lblAuditDiscoverAccounts)}
        </Typography>
      );
    case "AA_FIP_ACCOUNT_DISCOVERY_FAILURE":
      return (
        <Typography
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {t(translation.lblAuditFailureDiscoverAccounts)}
        </Typography>
      );

    case "AA_FIP_ACCOUNT_LINK_FAILURE":
      return (
        <Typography
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {t(translation.lblAuditFailureLinkAccounts)}
        </Typography>
      );

    case "AA_STORE_OTP":
      return (
        <Typography
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {t(translation.lblOTPSendSuccess)}
        </Typography>
      );

    case "ACCOUNT_DELINK":
      return (
        <Typography.Text
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {t(translation.lblUnLinkAccount)}
        </Typography.Text>
      );
    case "AA_STORE_SAVE_FI_REQUEST":
    case "AA_STORE_SAVE_FI_REQUEST_ACCOUNT":
      return (
        <Typography.Text
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {t(translation.lblFIDataStore)}
        </Typography.Text>
      );
    case "AA_PURGE_FI_DATA_FETCHED":
    case "AA_PURGE_FI_DATA_FETCHED_ACCOUNT":
      return (
        <Typography.Text
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {t(translation.lblAuditDataPurge)}
        </Typography.Text>
      );

    case "AA_PURGE_FI_DATA_EXPIRE":
    case "AA_PURGE_FI_DATA_EXPIRE_ACCOUNT":
      return (
        <Typography.Text
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {t(translation.lblDataExpire)}
        </Typography.Text>
      );
    case "AA_FI_DATA_FETCH":
    case "AA_FI_DATA_FETCH_ACCOUNT":
      return (
        <Typography.Text
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {t(translation.lblDataFetch, { fipId: json?.fipId ?? "FIP" })}
        </Typography.Text>
      );
    case "FIU_FI_DATA_FETCH":
    case "FIU_FI_DATA_FETCH_ACCOUNT":
      return (
        <Typography.Text
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {t(translation.lblDataFIUfetch, {
            fiuId: json.fiuId.length ? json.fiuId : "FIU",
          })}
        </Typography.Text>
      );
    case "IDENTIFIER_DELETE":
      return (
        <Typography.Text
          className={`${
            isRead ? theme.textTheme.subtitle1 : theme.textTheme.headline1
          } titleClass historyCardTitle`}
        >
          {t(translation.lblAuditDeleteIdentifier)}
        </Typography.Text>
      );

    default:
      return null;
  }
};

export const activityDescription = (
  type: string,
  jsonString: string,
  theme: any,
  t: any
) => {
  const json = JSON.parse(jsonString);

  const { Text } = Typography;

  switch (type) {
    case "ACCOUNT_DELINK":
      return (
        <>
          {json.fip && (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>FIP : </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.fip?.name}
              </Text>
            </div>
          )}
        </>
      );

    case "AA_UPDATE_CONSENT_APPROVAL_STATUS":
    case "AA_UPDATE_CONSENT_APPROVAL_STATUS_ACCOUNT":
      return (
        <>
          {json.accounts && (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>FIP : </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.accounts.map(
                  (account: { fipHandle: any }, index: number) => (
                    <>
                      <Text
                        className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                        type="secondary"
                      >
                        {account.fipHandle}{" "}
                        {index + 1 === json.accounts.length ? "" : " ,"}
                      </Text>
                    </>
                  )
                )}
              </Text>
            </div>
          )}
          {json.reasonForRejection ? (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>Reason : </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.reasonForRejection}
              </Text>
            </div>
          ) : (
            <></>
          )}
        </>
      );

    case "AA_UPDATE_CONSENT_STATUS":
    case "AA_UPDATE_CONSENT_STATUS_ACCOUNT":
      return (
        <>
          {json.fiu && (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>FIU : </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.fiu?.name}
              </Text>
            </div>
          )}
          {json.consentStatusType && (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>Status : </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.consentStatusType}
              </Text>
            </div>
          )}
        </>
      );

    case "AA_STORE_CREATE_CONSENT_REQUEST":
      return (
        <>
          {json.fiu && json.fiu.name && (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>FIU : </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.fiu.name}
              </Text>
            </div>
          )}
        </>
      );
    case "AA_STORE_ACCOUNT_LINK_UPDATE":
      return (
        <>
          {json.maskedAccNumber && (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>
                MaskedAccNumber :{" "}
              </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.maskedAccNumber}
              </Text>
            </div>
          )}
          {json.fip && (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>FIP : </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.fip?.name}
              </Text>
            </div>
          )}
        </>
      );
    case "AA_FIP_ACCOUNT_DISCOVERY":
      return (
        <>
          {json.fip && (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>FIP : </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.fip?.name}
              </Text>
            </div>
          )}
        </>
      );

    case "AA_FIP_ACCOUNT_DISCOVERY_FAILURE":
    case "AA_FIP_ACCOUNT_LINK_FAILURE":
      return (
        <>
          {json.fip && (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>FIP : </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.fip?.name}
              </Text>
            </div>
          )}
          {json.reason && (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>Reason : </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.reason}
              </Text>
            </div>
          )}
        </>
      );

    case "AA_STORE_SAVE_FI_REQUEST":
    case "AA_STORE_SAVE_FI_REQUEST_ACCOUNT":
      return (
        <>
          {json.FIDataRange.expiry && (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>Expire On :</Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {moment(json.FIDataRange.expiry).format("D-MMM-YYYY")}
              </Text>
            </div>
          )}
        </>
      );
    case "AA_PURGE_FI_DATA_FETCHED":
    case "AA_PURGE_FI_DATA_EXPIRE":
    case "AA_PURGE_FI_DATA_FETCHED_ACCOUNT":
    case "AA_PURGE_FI_DATA_EXPIRE_ACCOUNT":
      return (
        <>
          {json.fipId ? (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>Fip : </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.fipId}
              </Text>
            </div>
          ) : (
            <></>
          )}
          {json.maskedAccRefNumber && (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>Account : </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.maskedAccRefNumber}
              </Text>
            </div>
          )}
        </>
      );
    case "AA_FI_DATA_FETCH":
    case "FIU_FI_DATA_FETCH":
    case "AA_FI_DATA_FETCH_ACCOUNT":
    case "FIU_FI_DATA_FETCH_ACCOUNT":
      return (
        <>
          {json.fipId ? (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>FIP : </Text>
              <Text
                className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                type="secondary"
              >
                {json.fipId}
              </Text>
            </div>
          ) : (
            <></>
          )}
          {json?.accountInfo && (
            <div className="userActivityDescripitionItems">
              <Text className={theme.textTheme.subtitle1}>Accounts : </Text>
              <div className="historyAccounts">
                {json?.accountInfo?.map((account: any, index: number) => (
                  <>
                    <Text
                      className={`${theme.textTheme.headline1} titleClass marginLeft5`}
                      type="secondary"
                    >
                      {account.maskedAccNumber}{" "}
                      {index + 1 === json?.accountInfo?.length ? "" : " ,"}
                    </Text>
                  </>
                ))}
              </div>
            </div>
          )}
        </>
      );

    case "IDENTIFIER_DELETE":
      return (
        <>
          <div className="userActivityDescripitionItems">
            <Text className={theme.textTheme.subtitle1}>Type : </Text>
            <Text
              className={`${theme.textTheme.headline1} titleClass marginLeft5`}
              type="secondary"
            >
              {json.type}
            </Text>
          </div>
        </>
      );

    default:
      return null;
  }
};

const getConsentStatusType = (consentStatusType: string) => {
  switch (consentStatusType) {
    case ConsentStatusType.CREATED:
      return translation.lblCreated;
    case ConsentStatusType.REVOKED:
      return translation.lblRevokedConsent;
    case ConsentStatusType.PAUSED:
      return translation.lblPaused;
    case ConsentStatusType.REJECTED:
      return translation.lblRejectedConsent;
    case ConsentStatusType.EXPIRED:
      return translation.lblExpiredConsent;
  }
};
