import { Button, Divider, Form, notification, Typography } from "antd";
import { Context, useTheme } from "App";
import { translation } from "i18n/translation";
import ForgotPassword from "Pages/Authentication/Components/ForgotPassword";
import BreadCrumbs from "Pages/Common/Components/BreadCrumbs";
import PinInput from "Pages/Common/Components/PinInput";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { userInformation } from "redux/actions";
import { UserRespose } from "redux/Reducer/userReducer";
import store from "redux/store";
import {
  forgotPin,
  signOut,
} from "Services/AuthenticationServices/AuthenticationServices";
import {
  clearCredLocalStorage,
  getSessionState,
} from "Services/StorageServices";
import { getUser, updatePin } from "Services/UserProfileServices";
import { Routes } from "Utils/Constants/routes";
import "./index.css";

const ChangePassword: React.FC<{
  setUser: (user: any) => void;
}> = ({ setUser }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [changePin, setChangePin] = useState({
    oldPin: "",
    newPin: "",
    confirmNewPin: "",
  });
  const user: UserRespose = useSelector((state: any) => state.user.user);

  const history = useHistory();

  const location: any = useLocation();

  const handleSubmit = (values: any) => {
    if (user.isUserPin) {
      updatePin(values.oldPin, values.newPin).then(() => {
        signOut(getSessionState() ?? "").finally(() => {
          notification.open({
            className: `${theme.successAlert} alertClass`,
            message: t(translation.lblChangePassCodeSuccess),
            placement: "top",
            icon: <img alt="" src={theme.successIcon} className="alertIcon" />,
            duration: 5,
          });

          clearCredLocalStorage();
          history.push(Routes.Login);
        });
      });
    } else {
      forgotPin(values.newPin).then(() => {
        notification.open({
          className: `${theme.successAlert} alertClass`,
          message: t(translation.lblChangePassCodeSuccess),
          placement: "top",
          icon: <img alt="" src={theme.successIcon} className="alertIcon" />,
          duration: 5,
        });

        getUser().then((res) => {
          setUser(res.data);
        });

        history.replace(Routes.Consent);
        form.resetFields();
      });
    }
  };

  const { setMenubar }: any = useContext(Context);
  useEffect(() => {
    setMenubar(false);
    return () => setMenubar(true);
  }, [setMenubar]);

  useEffect(() => {
    if (!(location.state && location.state.token))
      history.replace(Routes.Settings);
  }, [location, history]);

  const handleCancel = () => {
    history.replace({
      pathname: Routes.Settings,
    });
  };

  const checkNewPassword = (_: any, value: string) => {
    if (value && value === changePin.oldPin)
      return Promise.reject(new Error(t(translation.errorPinDifferent)));
    return Promise.resolve();
  };

  const checkConfirmPassword = (_: any, value: string) => {
    if (value && value !== changePin.newPin && value.length === 6)
      return Promise.reject(new Error(t(translation.errorPinMisMatch)));
    return Promise.resolve();
  };

  return (
    <>
      <div className={`${theme.mainbackgroundColor} fill-window`}>
        {isForgotPassword && (
          <ForgotPassword
            token=""
            onClose={() => {
              history.push(Routes.Settings);
            }}
            buttonText={t(translation.lblContinue)}
          />
        )}

        {!isForgotPassword && (
          <div className="changePasswordRoot">
            {user.isUserPin && (
              <BreadCrumbs
                className="changePasswordbreadCrumbs"
                breadcrumbItems={[
                  { route: "/settings", label: t(translation.lblSettings) },
                  {
                    route: location.pathname,
                    label: t(translation.lblChangePassCode),
                  },
                ]}
              />
            )}
            <Typography.Title
              level={3}
              className={`${theme.textTheme.headline1} titleClass changePasswordTitle`}
            >
              {t(translation.lblChangePassCode)}
            </Typography.Title>
            <Form
              form={form}
              name="basic"
              onFinish={handleSubmit}
              autoComplete="off"
              requiredMark={false}
              scrollToFirstError={true}
              layout="vertical"
              className="changePasswordForm"
            >
              <div className="formContainer">
                <div className="formDetails">
                  {user.isUserPin ? (
                    <>
                      <div className=" passwordformLabel">
                        <Typography.Text className={`${theme.authFormLabels}`}>
                          {t(translation.lblOldPin)}
                        </Typography.Text>
                      </div>
                      <Form.Item
                        name="oldPin"
                        className={`${theme.authFormLabels} passwordform`}
                      >
                        <PinInput
                          label={t(translation.lblOldPin)}
                          nameField="oldPin"
                          className="marginBottom8"
                          onChange={(pin) => {
                            setChangePin((prevState) => ({
                              ...prevState,
                              oldPin: pin,
                            }));
                          }}
                        />

                        <Typography.Text
                          className={`${theme.textTheme.headline5} widthAutoCursor `}
                          onClick={() => {
                            setIsForgotPassword(true);
                          }}
                        >
                          {t(translation.lblForgotPin)}
                        </Typography.Text>
                      </Form.Item>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className=" passwordformLabel">
                    <Typography.Text className={`${theme.authFormLabels}`}>
                      {t(translation.lblNewPin)}
                    </Typography.Text>
                  </div>
                  <Form.Item
                    //name="newPin"
                    className={`${theme.authFormLabels} passwordform`}
                  >
                    <PinInput
                      label={t(translation.lblNewPin)}
                      nameField="newPin"
                      customRule={checkNewPassword}
                      onChange={(pin) =>
                        setChangePin((prevState) => ({
                          ...prevState,
                          newPin: pin,
                        }))
                      }
                    />
                  </Form.Item>

                  <div className=" passwordformLabel">
                    <Typography.Text className={`${theme.authFormLabels}`}>
                      {t(translation.lblConfirmPin)}
                    </Typography.Text>
                  </div>
                  <Form.Item
                    //name="newPin"
                    className={`${theme.authFormLabels} passwordform`}
                  >
                    <PinInput
                      label={t(translation.lblConfirmPin)}
                      nameField="confirmNewPin"
                      customRule={checkConfirmPassword}
                      onChange={(pin) =>
                        setChangePin((prevState) => ({
                          ...prevState,
                          confirmNewPin: pin,
                        }))
                      }
                    />
                  </Form.Item>
                </div>

                <div className="buttonsRoot">
                  <Divider className={theme.dividerColor} />
                  <div className="formButtons">
                    <Form.Item>
                      {user.isUserPin && (
                        <Button
                          onClick={handleCancel}
                          className={`${theme.textTheme.subtitle1} cancelButton`}
                        >
                          {t(translation.lblCancel)}
                        </Button>
                      )}
                      <Button
                        type="primary"
                        htmlType="submit"
                        className={`${theme.textTheme.authButton} submitButton`}
                      >
                        {t(translation.lblConfirm)}
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = () => {
  return {
    setUser: (user: any) => store.dispatch(userInformation(user)),
  };
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
