import sendEvents from "Utils/Events";
import { RedirectionEvents } from "Utils/types/RedirectionEvents";
import { Form } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  signupMobileVerificationInitiate,
  signupMobileVerificationRedirection,
  signupMobileVerificationWithEncryptedOTP,
  signupVerification,
} from "../../../../Services/AuthenticationServices/AuthenticationServices";
import {
  getRedirect,
  getSessionId,
  getTxnId,
  setCredentialsLocalStorage,
} from "../../../../Services/StorageServices";
import { LoginSignupFormType } from "../../../../Utils/Constants/loginSignupFormTypes";
import { QueryParams, Routes } from "../../../../Utils/Constants/routes";
import { vuaIdSuffix } from "../../../../Utils/Constants/vuaIdSuffix";
import { translation } from "../../../../i18n/translation";
import ScaffoldComponent from "../../../Common/Components/Scaffold";
import LoginSignupForm, { FormType } from "../Form";
import LoginSignupSidePanel from "../Greetings";
import UserInformation from "../UserInformation";
import "./index.css";
import { EncryptOTP } from "Utils/EncryptionUtils";

const SignupComponent: React.FC = () => {
  const [profileVisible, setProfileVisible] = useState(false);
  const location = useLocation();
  const redirect: any = location.state;
  const [otpUniqueID, setOTPUniqueId] = useState("");
  const [signUpDTO, setSignUpDTO] = useState<any>({});
  const [signedMobile, setSignedMobile] = useState("");
  const [isOtpError, setIsOtpError] = useState(false);
  // eslint-disable-next-line
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { push, replace } = useHistory();

  const initiateOTPVerification = (values: any): Promise<boolean> => {
    setSignUpDTO(values);
    sendEvents(RedirectionEvents.Init_Signup);

    return new Promise((resolve) =>
      signupMobileVerificationInitiate({
        phoneNumber: values.phoneNumber,
        isTermsAndConditionAgreed: values.isTermsAndConditionAgreed,
      })
        .then((res) => res.data)
        .then((res) => {
          sendEvents(RedirectionEvents.Init_Signup_Success);
          setOTPUniqueId(res);
          resolve(true);
        })
        .catch((error) => {
          sendEvents(
            RedirectionEvents.Init_Signup_Failure,
            error.response?.data.errorCode
          );
          resolve(false);
        })
    );
  };

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const query: any = useQuery();

  const onSignupVerificationOtp = (values: any): Promise<boolean> => {
    if (getRedirect() && getTxnId() && getSessionId()) {
      return onSignupVerificationOtpRedirection(values);
    } else {
      return new Promise((resolve) => {
        const encryptedOTP = EncryptOTP(values.otp, otpUniqueID)
        signupMobileVerificationWithEncryptedOTP({
          code: encryptedOTP,
          phoneNumber: signUpDTO.phoneNumber,
          otpUniqueID,
        })
          .then((res) => res.data)
          .then((res) => {
            setSignedMobile(res);
            setProfileVisible(true);
            setCredentialsLocalStorage(res);
            resolve(true);
          })
          .catch((error) => {
            setOtpErrorMessage(error.response?.data.errorCode);
            setIsOtpError(true);
          });
      });
    }
  };

  const onSignupVerificationOtpRedirection = (
    values: any
  ): Promise<boolean> => {
    sendEvents(RedirectionEvents.Init_Signup_Verification);

    return new Promise((resolve) =>
      signupMobileVerificationRedirection({
        code: values.otp,
        phoneNumber: signUpDTO.phoneNumber,
        otpUniqueID,
      })
        .then((res) => res.data)
        .then((res) => {
          // setSignedMobile(res);
          // setProfileVisible(true);
          sendEvents(RedirectionEvents.Signup_Verification_Success);
          setCredentialsLocalStorage(res);
          if (query && query.get(QueryParams.Redirect)) {
            replace({
              pathname: Routes.LinkAccounts,
              search: `?${QueryParams.Redirect}=${query.get(
                QueryParams.Redirect
              )}`,
            });
          } else {
            replace(Routes.LinkAccounts);
          }
          resolve(true);
        })
        .catch((error) => {
          sendEvents(
            RedirectionEvents.Signup_Verification_Failure,
            error.response?.data.errorCode
          );
          setOtpErrorMessage(error.response?.data.errorCode);
          setIsOtpError(true);
        })
    );
  };

  const logIn = () => {
    push(Routes.Login);
  };

  const formSubmit = (values: any) => {
    signupVerification({
      phoneNumber: signedMobile,
      pin: signUpDTO.pin,
      firstName: values.firstName,
      lastName: values.lastName,
      vuaId: values.vuaId + vuaIdSuffix.Value,
    })
      .then((res) => {
        setCredentialsLocalStorage(res.data);
        setProfileVisible(false);
        if (query && query.get(QueryParams.Redirect)) {
          replace(query.get(QueryParams.Redirect));
        } else {
          replace(Routes.LinkAccounts);
        }
      })
      .catch((error) => {
        setOtpErrorMessage(error.response?.data.errorCode);
        setIsOtpError(true);
      });
  };

  const checkVuaId = (_: any, value: string) => {
    if (
      value &&
      value.length === 10 &&
      Number(value) &&
      value !== signUpDTO.phoneNumber
    ) {
      return Promise.reject(
        new Error("10 digit vuaId must be same as mobile number")
      );
    }
    return Promise.resolve();
  };

  const setVuaIdAsMobileNumber = (change: any) => {
    const phoneNumberField = change.filter(
      (c: any) => c.name.toString() === LoginSignupFormType.PhoneNumber
    )[0];
    const vuaIdField = change.filter(
      (c: any) => c.name.toString() === LoginSignupFormType.VuaId
    )[0];
    phoneNumberField &&
      phoneNumberField.touched &&
      phoneNumberField.value &&
      phoneNumberField.value.length === 10 &&
      !vuaIdField &&
      form.setFieldsValue({ vuaId: phoneNumberField.value });
  };

  const resendSignupOtp = (values: any): Promise<any> => {
    sendEvents(RedirectionEvents.Resend_Signup_Verification);

    return initiateOTPVerification(values);
  };

  return (
    <>
      {!profileVisible || (getRedirect() && getTxnId() && getSessionId()) ? (
        <div className="signupRoot">
          <LoginSignupSidePanel className="customClass" />

          <LoginSignupForm
            className={"signFormTitle"}
            formType={FormType.CreateAccount}
            phoneNumber={!!redirect && redirect.phoneNumber}
            buttonSubSectionText={t(translation.lblAlreadyHaveAccount)}
            buttonSubSectionButton={t(translation.lblLogin)}
            initiateAuth={initiateOTPVerification}
            verifyAuth={onSignupVerificationOtp}
            subButtonClick={logIn}
            buttonText={t(translation.lblContinue)}
            isError={isOtpError}
            onResend={resendSignupOtp}
            setIsError={() => setIsOtpError(false)}
            otpErrorMessage={otpErrorMessage}
          />
        </div>
      ) : (
        <ScaffoldComponent className="width">
          <Form
            form={form}
            className="width displayFlexJustifyCenter"
            name="basic"
            onFinish={formSubmit}
            autoComplete="off"
            onFieldsChange={setVuaIdAsMobileNumber}
            scrollToFirstError={true}
            initialValues={{ vuaId: signUpDTO.phoneNumber }}
            requiredMark={false}
          >
            <UserInformation vuaIdValidator={checkVuaId} form={form} />
          </Form>
        </ScaffoldComponent>
      )}
    </>
  );
};

export default SignupComponent;
