import axios from "axios";
import {
  AccountDelinkInitiateRequest,
  AccountDelinkVerificationRequest,
} from "../../Models/Request/Account/AccountDelink";
import { AccountLinkInitiateRequest, LinkAccRequest } from "../../Models/Request/Account/AccountLink";
import {
  AutoDiscoverAccountRequest,
  DiscoverAccountRequest,
} from "../../Models/Request/Account/DiscoverAccount";

export function getLinkedAccountsOfUser(
  consentHandle?: string,
  fipId?: string
) {
  return axios.get(
    `/User/linkedaccount${
      consentHandle && consentHandle !== undefined
        ? `?consentHandle=${consentHandle}`
        : ""
    }${
      fipId && fipId !== undefined && fipId.length > 0
        ? `${
            consentHandle && consentHandle !== undefined ? "&" : "?"
          }fipId=${fipId}`
        : ""
    }`
  );
}

export function discoverAccounts(
  discoverAccountRequest: DiscoverAccountRequest
) {
  return axios.post("/User/account/discovery", discoverAccountRequest);
}

export function autoDiscoverAccounts(
  discoverAccountRequest: AutoDiscoverAccountRequest
) {
  return axios.post("/User/account/autoDiscovery", discoverAccountRequest);
}

export function linkAccounts(accountLinkBody: AccountLinkInitiateRequest) {
  return axios.post("/User/account/link", accountLinkBody);
}

export function linkAccountVerification(
  RefNumber: string,
  otp: string,
  handleId: string
) {
  return axios.get(`/User/account/link/${RefNumber}/${otp}?FipId=${handleId}`);
}

export function linkAccountVerificationPost(
  linkAccRequest: LinkAccRequest
) {
  return axios.post(`/User/account/linking`, linkAccRequest);
}

export function delinkAccounts(
  accountdeLinkBody: AccountDelinkInitiateRequest
) {
  return axios.post("/User/account/delink", accountdeLinkBody);
}

export function delinkAccountVerification(
  delinkAccountVerificationBody: AccountDelinkVerificationRequest
) {
  return axios.delete("/User/account/delink/Verification", {
    data: delinkAccountVerificationBody,
  });
}
export function getConsentLinkedWithAccount(
  FipId: string,
  linkRefNumber: string
) {
  return axios.get(
    `User/account/getAllConsentsCount?FipId=${FipId}&linkRefNumber=${linkRefNumber}`
  );
}
