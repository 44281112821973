import { SelectedAccounts } from "Models/Request/Account/AccountLink";
import {
  clearCredLocalStorage,
  getFiuName,
  getRedirect,
} from "Services/StorageServices";
import { Result } from "antd";
import Countdown from "antd/lib/statistic/Countdown";
import { translation } from "i18n/translation";
import React from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import sendEvents from "Utils/Events";
import { RedirectionEvents } from "Utils/types/RedirectionEvents";

interface RedirectionAckProps {
  encodedData: any;
  consentRejectReason?: string;
  consentStatus: string;
  accounts?: SelectedAccounts[];
  custom?: string;
}

const RedirectionAck: React.FC<RedirectionAckProps> = ({
  encodedData,
  consentRejectReason,
  consentStatus,
  accounts,
  custom,
}) => {
  const { t } = useTranslation();

  const onFinish = () => {
    sendEvents(RedirectionEvents.Redirecting_To_Fiu);

    window.location.replace(
      `${getRedirect()}?fi=${encodedData.fi}&resdate=${
        encodedData.resdate
      }&ecres=${encodedData.ecres}`
    );
    clearCredLocalStorage();
  };

  return (
    <Result
      className="resultAck"
      status={consentStatus === "FAILED" ? "error" : "success"}
      title={
        custom
          ? custom
          : consentStatus === "FAILED"
          ? consentRejectReason
            ? consentRejectReason
            : t(translation.rejectConsentMessage, {
                fiu: getFiuName(),
              })
          : t(translation.acceptSuccesfull, {
              fiu: getFiuName(),
            })
      }
      subTitle={
        consentStatus !== "FAILED" &&
        t(translation.acceptSuccesfullDescription, {
          accounts:
            accounts &&
            accounts.map(
              (account: SelectedAccounts) => " " + account.maskedNumber
            ),
        })
      }
      extra={[
        <Countdown
          title="Redirecting in"
          value={Date.now() + 1000 * 5}
          onFinish={onFinish}
        />,
      ]}
    />
  );
};

export default RedirectionAck;
