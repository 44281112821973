import { Button, Form, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "App";
import saafe from "Assets/saafeIcon.png";
import { translation } from "i18n/translation";
import "./index.css";

interface WrapperProps {
  title: string;
  subTitle?: string;
}

const Wrapper: React.FC<WrapperProps> = ({ title, subTitle, children }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <div className="flexCommonCss wrapperRoot">
      <img src={saafe} alt="" width={40} height={56} />

      {title && (
        <Typography.Text
          className={`${theme.textTheme.headline3} wrapperTitle`}
        >
          {t(title)}
        </Typography.Text>
      )}

      {subTitle && (
        <Typography.Text className={`${theme.authSubTitle} wrapperSubTitle`}>
          {t(subTitle)}
        </Typography.Text>
      )}

      {children}

      <Form.Item className={`${theme.formItemMarginBottom} userProfileButton`}>
        <Button
          // loading={loading}
          className={`${theme.textTheme.authButton} commonButtonBackground primaryButton formItemButton borderNoneHeight50`}
          htmlType="submit"
        >
          {t(translation.lblContinue)}
        </Button>
      </Form.Item>
    </div>
  );
};

export default Wrapper;
