import i18n from "i18next";
import engTranslation from "./en/translation.json";
import hinTranslation from "./hin/translation.json";
import knTranslation from "./kn/translation.json";
import malTranslation from "./mal/translation.json";
import teTranslation from "./te/translation.json";
import urTranslation from "./ur/translation.json";
import taTranslation from "./ta/translation.json";

import LanguageDetector from "i18next-browser-languagedetector";

import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    translation: engTranslation,
  },
  hi: {
    translation: hinTranslation,
  },
  kn: {
    translation: knTranslation,
  },
  mal: {
    translation: malTranslation,
  },
  te: {
    translation: teTranslation,
  },
  ur: {
    translation: urTranslation,
  },
  ta: {
    translation: taTranslation,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    supportedLngs: ["en", "hi", "kn", "mal", "te", "ur", "ta"],

    detection: {
      order: [
        "localStorage",
        "querystring",
        "cookie",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["localStorage"],
    },
  });
