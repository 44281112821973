export enum ConsentStatusType {
  CREATED = "CREATED",
  REVOKED = "REVOKED",
  PAUSED = "PAUSED",
  ACTIVE = "ACTIVE",
  REJECTED = "REJECTED",
  EXPIRED = "EXPIRED",
  FAILED = "FAILED",
  READY = "READY",
  APPROVED= "APPROVED",
}

export enum ConsentApprovalStatusType {
  PENDING = "PENDING",
}
export enum DeleteType {
  ACCOUNTDELETE = "ACCOUNTDELETE",
  IDENTIFIERDELETE = "IDENTIFIERDELETE",
  DELETEUSER = "DELETEUSER",
}
