export const startLoader = () => ({
  type: "START_SEARCH",
});

export const pauseLoader = () => ({
  type: "FINISH_SEARCH",
});

export const theme = (themeN: string) => ({
  type: "CHANGE_THEME",
  payload: {
    theme: themeN,
  },
});

export const userInformation = (user: any) => ({
  type: "STORE_USER",
  payload: {
    user,
  },
});

export const versionDispatch = (version: string) => ({
  type: "STORE_VERSION",
  payload: {
    version,
  },
});

export const startRefreshToken = () => ({
  type: "START_REFRESH_TOKEN_FETCH",
});

export const endRefreshToken = () => ({
  type: "FINISH_REFRESH_TOKEN_FETCH",
});
