import { Carousel, Typography } from "antd";
import homeCarouselImage1 from "Assets/Accounts.png";
import homeCarouselImage2 from "Assets/Consent.png";
import homeCarouselImage3 from "Assets/Consentdata.png";
import { translation } from "i18n/translation";
import React from "react";
import { useTranslation } from "react-i18next";
import "./index.css";

const HomePage: React.FC = () => {
  const { Paragraph } = Typography;
  const { t } = useTranslation();

  return (
    <Carousel className="carouselStyle" autoplay={true} autoplaySpeed={3000}>
      <div id="carouselLayout">
        <img src={homeCarouselImage1} alt="" className="homepageImage" />
        <Paragraph className="typoGraphyPara">
          {t(translation.lblFirstBoardingFirst)}
          <br />
          {t(translation.lblFirstBoardingSecond)}
        </Paragraph>
      </div>

      <div id="carouselLayout">
        <img src={homeCarouselImage2} alt="" className="homepageImage" />
        <Paragraph className="typoGraphyPara">
          {t(translation.lblSecondBoardingFirst)}
          <br />
          {t(translation.lblSecondBoardingSecond)}
        </Paragraph>
      </div>

      <div id="carouselLayout">
        <img src={homeCarouselImage3} alt="" className="homepageImage" />
        <Paragraph className="carouselMargin typoGraphyPara">
          {t(translation.lblThirdBoardingFirst)}
          <br />
          {t(translation.lblThirdBoardingSecond)}
        </Paragraph>
      </div>
    </Carousel>
  );
};

export default HomePage;
