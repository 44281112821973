import { PlusOutlined } from "@ant-design/icons";
import { useTheme } from "App";
import default_bank from "Assets/ic_default_bank.png";
import { DiscoverAccountObject } from "Models/Response/Account/DiscoverAccount";
import { logoBaseUrl } from "Utils/Constants/baseUrl";
import { QueryParams, Routes } from "Utils/Constants/routes";
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Empty,
  Image,
  Skeleton,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { translation } from "i18n/translation";
import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

interface AutoDiscoverAccountLinkProps {
  autoDiscoverAccountsMap: AutoDiscoveryMap;
  isAutoDiscovery?: boolean;
  consentHandle?: string;
  onChange: (selectedAccount: string[]) => void;
}

export interface AutoDiscoveryMap {
  [fipId: string]: AutoDiscoveryMapAccounts;
}

interface AutoDiscoveryMapAccounts {
  fipName: string;
  accounts: DiscoverAccountObject[];
  checkAll?: boolean;
  indeterminate?: boolean;
}

const UpdatedConsentJourney: React.FC<AutoDiscoverAccountLinkProps> = ({
  autoDiscoverAccountsMap,
  isAutoDiscovery = false,
  consentHandle,
  onChange,
}) => {
  const loading: boolean = useSelector(
    (state: any) => state.loader.searchLoader
  );
  const theme = useTheme();
  const history = useHistory();

  const [selectedFIP, setSelectedFIP] = useState("");
  const [totalNumberOfAccounts, setTotalAccounts] = useState(0);
  const [selectedAccounts, setSelectedAccounts] = useState(new Array<string>());

  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  const onCheckAllChange = (e: CheckboxChangeEvent, fipId: string = "") => {
    if (!e.target.checked) {
      setSelectedAccounts([]);
      setCheckAll(false);
    } else if (!isAutoDiscovery) {
      let accounts: string[] = [];
      Object.keys(autoDiscoverAccountsMap).forEach((fip) => {
        accounts = [
          ...accounts,
          ...autoDiscoverAccountsMap[fip].accounts.map(
            (value) => value.accRefNumber
          ),
        ];
      });
      setSelectedAccounts(accounts);
      setCheckAll(true);
    } else {
      const fip = autoDiscoverAccountsMap[fipId];
      if (selectedFIP !== fipId) {
        setSelectedFIP(fipId);
      }
      setSelectedAccounts(fip.accounts.map((value) => value.accRefNumber));
    }
  };

  useEffect(() => {
    onChange(selectedAccounts);
    // eslint-disable-next-line
  }, [selectedAccounts]);

  useEffect(() => {
    let accounts: string[] = [];
    let tolalAccounts: number = 0;
    Object.keys(autoDiscoverAccountsMap).forEach((fip) => {
      const localAccounts: string[] = autoDiscoverAccountsMap[fip].accounts.map(
        (value) => value.accRefNumber
      );
      tolalAccounts += localAccounts.length;
      accounts = [...accounts, ...localAccounts];
    });
    setTotalAccounts(tolalAccounts);
    setSelectedAccounts(accounts);
    setCheckAll(true);
  }, [autoDiscoverAccountsMap]);

  const collapseFIUHeader = (fipId: any) => {
    const fip = autoDiscoverAccountsMap[fipId];

    return (
      <div className="fiuCard autoDiscover">
        <div className={`fiuHeader`}>
          <Image
            loading="lazy"
            className="fipLogo"
            src={logoBaseUrl + fipId + ".jpeg"}
            fallback={default_bank}
            preview={false}
            style={{ marginRight: 10 }}
          />

          <div className="cardContent">
            <Typography.Text
              className={`${theme.textTheme.headline1} cardContentFIP`}
            >
              {fip.fipName}
            </Typography.Text>
          </div>
        </div>
        {isAutoDiscovery ? (
          <Checkbox
            indeterminate={fip.indeterminate}
            onChange={(e) => onCheckAllChange(e, fipId)}
            checked={fip.checkAll}
            className={`${theme.textTheme.headline5} ${theme.textTheme.headline6} ${theme.checkBoxBorder} checboxSelectAll`}
          >
            {t(translation.lblSelectAll)}
          </Checkbox>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const onAccountsSelect = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    fip: string
  ) => {
    if (!isAutoDiscovery || selectedFIP === fip) {
      if (selectedAccounts.indexOf(event.currentTarget.id) === -1) {
        setSelectedAccounts([...selectedAccounts, event.currentTarget.id]);
        setIndeterminate(selectedAccounts.length + 1 !== totalNumberOfAccounts);
        setCheckAll(selectedAccounts.length + 1 === totalNumberOfAccounts);
      } else {
        setSelectedAccounts(
          selectedAccounts.filter((card) => card !== event.currentTarget.id)
        );

        if (selectedAccounts.length - 1 !== 0) setIndeterminate(true);
        else setIndeterminate(false);

        setCheckAll(false);
      }
    } else {
      setSelectedFIP(fip);
      setSelectedAccounts([event.currentTarget.id]);
    }
  };

  const linkAccountsRedirect = () => {
    history.push({
      pathname: Routes.LinkAccounts,
      search: `?${QueryParams.Redirect}=${history.location.pathname}`,
      state: consentHandle,
    });
  };

  return (
    <Skeleton loading={loading}>
      <div className="selectAccountsTitle">
        <Button
          className={`${theme.hintColor} ${theme.textTheme.headline5} subClass subButton`}
          type="text"
          onClick={linkAccountsRedirect}
        >
          <PlusOutlined /> {t(translation.lblLinkAccounts)}
        </Button>

        {Object.keys(autoDiscoverAccountsMap).length > 0 ? (
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            className={`${theme.textTheme.headline5} ${theme.textTheme.headline6} ${theme.checkBoxBorder} checboxSelectAll`}
          >
            {t(translation.lblSelectAll)}
          </Checkbox>
        ) : (
          <></>
        )}
      </div>
      {Object.keys(autoDiscoverAccountsMap).length > 0 ? (
        <>
          <Collapse
            activeKey={Object.keys(autoDiscoverAccountsMap).map(
              (r, index) => index
            )}
            bordered={false}
            style={{ marginBottom: 32 }}
          >
            {Object.keys(autoDiscoverAccountsMap).map((fipId, index: number) =>
              autoDiscoverAccountsMap[fipId].accounts &&
              autoDiscoverAccountsMap[fipId].accounts.length > 0 ? (
                <Collapse.Panel
                  style={{ marginTop: 5 }}
                  header={collapseFIUHeader(fipId)}
                  key={index}
                  showArrow={false}
                  className={`${theme.cardColor} ${theme.entityCard} accountDiscoveryCard consentA`}
                >
                  <div className="selectAccountsTitle">
                    {autoDiscoverAccountsMap[fipId].accounts.map(
                      (accounts: DiscoverAccountObject, indexD: number) => (
                        <>
                          <Card
                            className={` ${
                              selectedAccounts.indexOf(
                                accounts.accRefNumber
                              ) !== -1
                                ? `${theme.textTheme.headline5} consentSelectedCard`
                                : ""
                            } accountsCard
                            `}
                            bordered={false}
                            key={indexD}
                            id={accounts.accRefNumber}
                            onClick={(e) => onAccountsSelect(e, fipId)}
                          >
                            <div className="cardFirstRow">
                              <Typography.Text
                                className={`${theme.accountMaskedNumber} maskedAccNumberDiscover`}
                              >
                                {accounts.maskedAccNumber.slice(
                                  accounts.maskedAccNumber.length - 7
                                )}
                              </Typography.Text>
                              <Typography.Text
                                className={`${theme.hintColor} accountType`}
                              >
                                (
                                {accounts.FIType === "DEPOSIT"
                                  ? t(_.capitalize(accounts.accType))
                                  : t(_.capitalize(accounts.FIType))}
                                )
                              </Typography.Text>
                              <Checkbox
                                checked={
                                  selectedAccounts.indexOf(
                                    accounts.accRefNumber
                                  ) === -1
                                    ? false
                                    : true
                                }
                                className={`${theme.textTheme.headline5} ${theme.textTheme.headline6} ${theme.checkBoxBorder} checboxSelectAll`}
                              />
                            </div>
                          </Card>
                        </>
                      )
                    )}
                    {autoDiscoverAccountsMap[fipId].accounts &&
                    autoDiscoverAccountsMap[fipId].accounts.length % 2 !== 0 ? (
                      <div />
                    ) : (
                      <></>
                    )}
                  </div>
                </Collapse.Panel>
              ) : (
                <></>
              )
            )}
          </Collapse>
        </>
      ) : (
        <Empty
          image={theme.noAccountFound}
          className={`empty accountEmpty`}
          description={
            <>
              <Typography.Text
                className={`${theme.indicatorColor} consentErrorMsg`}
              >
                {t(translation.lblDontHaveAccounts)}
              </Typography.Text>
            </>
          }
        />
      )}
    </Skeleton>
  );
};

export default UpdatedConsentJourney;
