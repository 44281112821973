import { SelectedAccounts } from "Models/Request/Account/AccountLink";
import { DiscoverAccountObject } from "Models/Response/Account/DiscoverAccount";
import React from "react";
import "./index.css";
import UpdatedConsentJourneyCustomized from "Pages/Accounts/Components/UpdateConsentApprovalCustomized";

interface Props {
  onOk: (selectedCard: SelectedAccounts[]) => void;
  consentHandle: string;
}

export interface AutoDiscoveryMap {
  [fipId: string]: AutoDiscoveryMapAccounts;
}

interface AutoDiscoveryMapAccounts {
  fipName: string;
  accounts: DiscoverAccountObject[];
}

const SelecteAccountsCustomized: React.FC<Props> = ({ consentHandle, onOk }) => {

  return (
    <>
      <div
        style={{
          width: "100%"
        }}
      >
        <UpdatedConsentJourneyCustomized
          consentHandle={consentHandle}
          onOk={onOk}
        />
      </div>
    </>
  );
};

export default SelecteAccountsCustomized;
