import { Form, Input, Typography } from "antd";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getThemeStorage } from "Services/StorageServices";
import { useTheme } from "App";
import { translation } from "i18n/translation";
import { isVuaExists } from "Services/AuthenticationServices/AuthenticationServices";
import { LoginSignupFormType } from "Utils/Constants/loginSignupFormTypes";
import { vuaIdSuffix } from "Utils/Constants/vuaIdSuffix";
import Wrapper from "Pages/Common/Components/Wrapper";
import "./index.css";

interface IUserProp {
  vuaIdValidator: (_: any, value: string) => void;
  form: any;
}

const UserInformation: React.FC<IUserProp> = ({ vuaIdValidator, form }) => {
  const theme = useTheme();
  const [feedback, setFeedback] = useState(true);

  const onNameChange = (e: any) => {
    if (e.target.value.length <= 1) {
      e.target.value = e.target.value.toUpperCase();
    }
    e.target.value = e.target.value.replace(/[^A-Za-z]+/g, "");
  };
  const onVUANameChange = (e: any) => {
    e.target.value = e.target.value.replace(/[^A-Za-z0-9]+/g, "");
    if (e.target.value.trim()) {
      isVuaExistsDebounce();
    }
  };

  const isVuaExistsDebounce = debounce(() =>
    isVuaExists({
      vuaId: form.getFieldValue("vuaId") + vuaIdSuffix.Value,
    }).then((res) => {
      if (res.data === false) {
        setFeedback(false);
      } else {
        setFeedback(true);
      }
    })
  );

  useEffect(() => {
    isVuaExistsDebounce();
    // eslint-disable-next-line
  }, []);

  const { t } = useTranslation();

  return (
    <Wrapper
      title={t(translation.lblYourInfo)}
      subTitle={t(translation.lblAsStatedInID)}
    >
      <div className="userProfile">
        <Typography.Text className={`${theme.authFormLabels} font14weight400`}>
          {t(translation.lblFirstName)}
        </Typography.Text>
        <Form.Item
          rules={[
            {
              required: true,
              message: "* Required field",
            },
          ]}
          name={LoginSignupFormType.FirstName}
          className={`${theme.formItemMarginBottom}`}
        >
          <Input
            id="firstName"
            type="text"
            onInput={onNameChange}
            placeholder={t("Ex.") + " Youssef"}
            className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} width100borderRadius8MarginTop8Height50`}
          />
        </Form.Item>
      </div>

      <div className="userProfile">
        <Typography.Text className={`${theme.authFormLabels} font14weight400`}>
          {t(translation.lblLastName)}
        </Typography.Text>
        <Form.Item
          rules={[
            {
              required: true,
              message: "* Required field",
            },
          ]}
          name={LoginSignupFormType.LastName}
          className={`${theme.formItemMarginBottom}`}
        >
          <Input
            id="lastName"
            type="text"
            className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} width100borderRadius8MarginTop8Height50`}
            onInput={onNameChange}
            placeholder={t("Ex.") + " Bsheer"}
          />
        </Form.Item>
      </div>

      <div className="userProfile">
        <Typography.Text className={`${theme.authFormLabels} font14weight400`}>
          {t(translation.lblUserName)}
        </Typography.Text>
        <Form.Item
          hasFeedback={true}
          rules={[
            {
              required: true,
              message: "* Required field",
            },
            {
              validator: vuaIdValidator,
            },
          ]}
          validateStatus={feedback ? `error` : "success"}
          help={feedback && "Vua id already taken"}
          name={LoginSignupFormType.VuaId}
          className={`${theme.formItemMarginBottom} formItemMarginBotton`}
        >
          <Input
            id={
              getThemeStorage() === "dark"
                ? "darkThemeInput"
                : "lightThemeInput"
            }
            type="text"
            onInput={onVUANameChange}
            placeholder={t("Ex.") + " youssefbsheer"}
            suffix={vuaIdSuffix.Value}
            className={`${theme.textTheme.headline2} ${theme.authFormLabels} ${theme.authFormBorder} width100borderRadius8MarginTop8Height50`}
          />
        </Form.Item>
      </div>
    </Wrapper>
  );
};

export default UserInformation;
