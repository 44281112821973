import { MoreOutlined } from "@ant-design/icons";

import {
  Card,
  Dropdown,
  Empty,
  Image,
  Menu,
  notification,
  RadioChangeEvent,
  Statistic,
  Tag,
  Typography,
} from "antd";
import { useTheme } from "App";
import { AimedIcon, CalendarIcon } from "Assets/icons";
import default_bank from "Assets/ic_default_bank.png";
import { translation } from "i18n/translation";
import _ from "lodash";
import { ConsentDetail } from "Models/Response/Consent/GetAllConsent";
import moment from "moment";
import BreadCrumbs from "Pages/Common/Components/BreadCrumbs";
import useINF from "Pages/Common/Components/InfiniteScroll";
import FeedbackForm from "Pages/Consent/Components/ConsentFeedback";
import ConsentStatus from "Pages/Consent/Components/ConsentStatus";
import ConsentStatusApprovalComponent from "Pages/Consent/Components/StatusApproval";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  delinkAccounts,
  delinkAccountVerification,
  getConsentLinkedWithAccount,
} from "Services/AccountsServices/AccountService";
import { getConsetsByAccount } from "Services/ConsentServices/ConsentService";
import { authType } from "Utils/Constants/authType";
import { Routes } from "Utils/Constants/routes";
import {
  ConsentApprovalStatusType,
  ConsentStatusType,
  DeleteType,
} from "Utils/Constants/statusTypes";
import "./index.css";
const pageSize = 20;
const AccountDetails: React.FC = () => {
  const { push, replace } = useHistory();
  const theme = useTheme();
  const location: any = useLocation();
  const [account, setAccount] = useState<any>({});
  const [consents, setConsents] = useState([]);

  const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPinModalVisible, setIsPinModalVisible] = useState(false);
  const [radioSelect, setRadioSelect] = useState(0);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [otpId, setOtpId] = useState("");
  const [isOtpError, setIsOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [linkedConsentsCount, setLinkedConsentsCount] = useState(0);
  const [formValues, setFormValues] = useState({
    message: "",
  });

  useEffect(() => {
    if (location.state && location.state.account) {
      setAccount(location.state.account);
      getConsetsByAccount(
        location.state.account.linkRefNumber,
        page - 1,
        pageSize
      )
        .then((res) => res.data)
        .then((res) => {
          setHasMore(!res.last);
          setConsents((old) => old.concat(res.content));
        });
    } else {
      replace(Routes.Account);
    }
  }, [location, page, replace]);

  const observer = useRef<any>();

  const { newObserver, ref } = useINF(observer, hasMore, setPage);

  observer.current = newObserver.current;

  const { t } = useTranslation();

  const onDeleteAccount = () => {
    getConsentLinkedWithAccount(account.fipHandle, account.linkRefNumber)
      .then((res) => res.data)
      .then((res) => {
        setLinkedConsentsCount(res);
        setIsModalVisible(true);
      });
  };
  const menu = (
    <Menu className={`${theme.cardColor} menuAccountDelete`}>
      <Menu.Item
        className={`${theme.hintColor}`}
        key={"Delete"}
        onClick={onDeleteAccount}
      >
        {t(translation.lblDelete)}
      </Menu.Item>
    </Menu>
  );
  const cardClicked = (
    consentUniqueId: string,
    type: string,
    createdOrUpdatedOn: string
  ) => {
    push({
      pathname: `/consent/${type}/${consentUniqueId}`,
      state: { createdOrUpdatedOn },
    });
  };

  const consentCardClick = (consent: ConsentDetail) => {
    cardClicked(
      consent.consentid
        ? consent.consentStatusType !== ConsentStatusType.EXPIRED &&
          consent.consentStatusType !== ConsentStatusType.REJECTED
          ? consent.consentid
          : consent.consentHandle
        : consent.consentHandle,
      consent.consentStatusType === ConsentStatusType.CREATED
        ? ConsentApprovalStatusType.PENDING
        : consent.consentStatusType,
      consent.updatedOn
    );
  };

  const renderConsents = () => {
    return (
      <>
        {consents.map((consent: any, index: number) => (
          <Card
            key={consent.consentHandle}
            hoverable
            onClick={() => consentCardClick(consent)}
            className={`${theme.cardColor} ${theme.consentCardBorder} consentCard`}
          >
            <div className="consentInfo">
              <Image
                className="fipLogo"
                src={
                  process.env.REACT_APP_LOGO_BASE_URL +
                  (consent.isSelfConsent ? consent.fipId : consent.fiuId) +
                  ".jpeg"
                }
                fallback={default_bank}
                preview={false}
              />
              <div className="typography">
                <div className={`${theme.indicatorColor} fiuName`}>
                  {consent.isSelfConsent ? consent.fipName : consent.fiuName}
                  <Statistic
                    className={`${theme.hintColor} timeFromNow`}
                    value={moment(consent.updatedOn).fromNow()}
                  />
                </div>

                <div className="tagStatus">
                  {consent.isSelfConsent ? (
                    <div className="fiuTag">
                      <Tag className="tag" color={"warning"}>
                        {t(translation.lblSelfConsent)}
                      </Tag>
                    </div>
                  ) : (
                    <></>
                  )}
                  <ConsentStatus
                    status={consent.consentStatusType}
                    className={
                      consent.consentStatusType === ConsentStatusType.ACTIVE
                        ? `${theme.successStatus} ${theme.successStatusBG}`
                        : consent.consentStatusType ===
                            ConsentStatusType.PAUSED ||
                          consent.consentStatusType ===
                            ConsentStatusType.CREATED
                        ? `${theme.warningStatus} ${theme.warningStatusBG}`
                        : consent.consentStatusType ===
                          ConsentStatusType.REJECTED
                        ? `${theme.errorStatus} ${theme.errorStatusBG}`
                        : consent.consentStatusType ===
                          ConsentStatusType.REVOKED
                        ? `${theme.staleStatus} ${theme.staleStatusBG}`
                        : `${theme.staleStatus} ${theme.staleStatusBG}`
                    }
                  />
                </div>
              </div>
            </div>

            <div className={`${theme.consentDivider} consentCardPurposeDate`}>
              <div className="displayFlex">
                <AimedIcon className={`${theme.hintColor} marginRight6 icon`} />
                <Typography.Text className={`${theme.hintColor}`}>
                  {t(consent.purpose)}
                </Typography.Text>
              </div>
              <div className="displayFlex">
                <CalendarIcon
                  className={`${theme.hintColor} marginRight6 icon`}
                />
                <Typography.Text className={`${theme.hintColor}`}>
                  {moment(consent.consentExpiry).format("DD MMMM, YYYY")}
                </Typography.Text>
              </div>
            </div>
          </Card>
        ))}
        {consents.length % 2 !== 0 ? <div className="consentCard" /> : <></>}
        <div ref={ref} />
      </>
    );
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const radioSelectEvent = (e: RadioChangeEvent) => {
    setRadioSelect(e.target.value);
  };

  const onAuthtypeSubmit = () => {
    setIsModalVisible(false);

    setIsVisible(false);
    setIsFormVisible(true);
  };
  const onClose = () => {
    setIsFormVisible(false);
    setIsOtpError(false);

    if (radioSelect === 0) setIsPinModalVisible(false);
    else setIsOtpModalVisible(false);

    setIsVisible(true);
  };

  const renderAlert = () => {
    notification.open({
      className: `${theme.infoAlert} alertClass`,
      icon: <img alt="" src={theme.infoIcon} className="alertIcon" />,
      placement: "top",
      message: t(translation.messageAccountDelete, {
        fipName: account.fipName,
        maskedNumber: account.maskedAccNumber,
      }),
      duration: 5,
    });
  };
  const verifyOTPAuth = (otp: string) => {
    if (radioSelect === 0) {
      delinkAccountVerification({
        FipId: account.fipHandle,
        txnId: account.txnId,
        linkRefNumber: account.linkRefNumber,
        authType: authType.PIN,
        authValue: otp,
        otpUniqueID: "",
        reason: formValues.message,
      })
        .then(() => {
          setIsPinModalVisible(false);
          setIsOtpError(false);

          renderAlert();
          replace(Routes.Account);
        })
        .catch((error) => {
          setOtpErrorMessage(error.response?.data.errorCode);
          setIsOtpError(true);
        });
    } else {
      delinkAccountVerification({
        FipId: account.fipHandle,
        txnId: account.txnId,
        linkRefNumber: account.linkRefNumber,
        authType: authType.OTP,
        authValue: otp,
        otpUniqueID: otpId,
        reason: formValues.message,
      })
        .then(() => {
          setIsOtpModalVisible(false);
          renderAlert();
          setIsOtpError(false);

          replace(Routes.Account);
        })
        .catch((error) => {
          setOtpErrorMessage(error.response?.data.errorCode);
          setIsOtpError(true);
        });
    }
  };

  const handleFormSubmit = (values: any) => {
    values && setFormValues(values);

    if (radioSelect === 0) {
      setIsPinModalVisible(true);
    } else {
      setIsOtpModalVisible(true);
      delinkAccounts({
        FipId: account.fipHandle,
        txnId: account.txnId,
        linkRefNumber: account.linkRefNumber,
      })
        .then((res) => res.data)
        .then((res) => {
          setOtpId(res.otpUniqueID);
        });
    }
  };
  return (
    <>
      {isFormVisible && (
        <FeedbackForm
          bankName={account.fipName}
          setIsFormVisible={setIsFormVisible}
          setVisible={setIsVisible}
          onFormSubmit={handleFormSubmit}
          type={DeleteType.ACCOUNTDELETE}
        />
      )}
      <ConsentStatusApprovalComponent
        typeOfModal={DeleteType.ACCOUNTDELETE}
        isOTPModalVisible={isOtpModalVisible}
        isAuthTypeSelectionModalOpen={isModalVisible}
        onCloseAuthTypeSelectionModal={closeModal}
        onAuthTypeModalSubmit={onAuthtypeSubmit}
        onAuthTypeRadioSelectionChange={radioSelectEvent}
        selectedAuthType={radioSelect}
        verifyOTPAuthType={verifyOTPAuth}
        pinModalVisible={isPinModalVisible}
        onCloseOTPModal={onClose}
        isError={isOtpError}
        setIsError={setIsOtpError}
        errorMessage={otpErrorMessage}
        onResend={handleFormSubmit}
        consentCount={linkedConsentsCount}
      />
      {isVisible && (
        <div className={`${theme.mainbackgroundColor} accountDetialsRoot`}>
          <div className="accountDetailsRootContainer">
            <BreadCrumbs
              className="marginBottom16"
              breadcrumbItems={[
                { route: "/account", label: t(translation.lblAccounts) },
                {
                  route: location.pathname,
                  label: `${account.fipName}`,
                },
              ]}
            />
            <div className="accountDetailsHeader">
              <div className="accountName">
                <Image
                  className="fipLogo"
                  src={
                    process.env.REACT_APP_LOGO_BASE_URL +
                    account.fipHandle +
                    ".jpeg"
                  }
                  fallback={default_bank}
                  preview={false}
                />
                <Typography.Title
                  level={3}
                  className={`${theme.textTheme.headline1} titleClass accountDetailsTitle`}
                >
                  {account.fipName}
                </Typography.Title>
              </div>

              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomRight"
              >
                <MoreOutlined
                  className={`${theme.notificationBackgroundColor} ${theme.hintColor} ${theme.dividerColorBorder} moreIcon accountMoreIcon`}
                />
              </Dropdown>
            </div>
            <div className="accountDetailsCards ">
              <Card
                className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCard spaceBetween`}
                bordered={false}
              >
                <Typography.Text className={theme.textTheme.subtitle1}>
                  {t(translation.lblAccountNumber)}
                </Typography.Text>
                <Typography.Text
                  className={`${theme.indicatorColor} fontSize15FontWeight500`}
                >
                  {account.maskedAccNumber}
                </Typography.Text>
              </Card>
              <Card
                className={`${theme.cardColor} ${theme.consentCardBorder} consentDetailsCard spaceBetween`}
                bordered={false}
              >
                <Typography.Text className={theme.textTheme.subtitle1}>
                  {t(translation.lblType)}
                </Typography.Text>
                <Typography.Text
                  className={`${theme.indicatorColor} fontSize15FontWeight500`}
                >
                  {t(_.capitalize(account.accountType))}
                </Typography.Text>
              </Card>
            </div>

            {consents && consents.length ? (
              <div className="linkedConsents">
                <Typography.Title
                  level={3}
                  className={`${theme.textTheme.headline1} titleClass linkedConsentsTitle`}
                >
                  {t(translation.lblLinkedConsents)}
                </Typography.Title>
                <div className="linkedConsentsCardsContainer">
                  {consents && consents.length ? (
                    <>{renderConsents()}</>
                  ) : (
                    <Empty
                      image={theme.noConsentFound}
                      className={`empty`}
                      description={
                        <Typography.Text
                          className={`${theme.indicatorColor} consentErrorMsg`}
                        >
                          {t(translation.errorNoDetailsFound)}
                        </Typography.Text>
                      }
                    />
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AccountDetails;
